import { request } from '@/utils/request'

//获取查询产品名称及类型
export function getSearchConditionList() {
    return request({
        url: `/api/v1/course/courseInfo/getAllProductName`,
        method: 'get',
    });
}

//获取全部视频
export function feachVideoList(searchObj, current, size) {
    return request({
        url: `/api/v1/course/courseInfo/findAllVideoList`,
        method: 'post',
        data: {
            ...searchObj,
            pageNo: current,
            pageSize: size
        }
    });
}

//获取视频详情
export function getVideoDetails(courseId,id) {
    return request({
        url: `/api/v1/course/courseInfo/getVideoByCourseId`,
        method: 'post',
        data: {
            courseId,
            id
        }
    });
}
