<template>
    <div
        id="applicationBox"
        v-loading="loading"
        element-loading-text="内容转换中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
    >
        <!--新旧蒙文转换-->
        <div class="w12 h100 contentBox">
            <div class="translateBox">
                <div class="applicationBtnBox">
                    <p>{{ left == 0 ? "西里尔文" : "传统蒙古文" }}</p>
                    <img
                        src="~assets/images/transformationImg.png"
                        alt=""
                        @click="changeType"
                    />
                    <p>{{ right == 0 ? "传统蒙古文" : "西里尔文" }}</p>
                </div>
                <div class="translateContent">
                    <div class="c-trueBtn" @click="conversion">
                        自动
                        <br />
                        转换
                    </div>
                    <p class="backImg"></p>
                    <div class="translate-text-box" :style="{ left: left }">
                        <div class="cText textBox">
                            <i
                                class="el-icon-close"
                                v-if="left == 0"
                                @click="clearText('xText')"
                            ></i>
                            <el-input
                                type="textarea"
                                class="h100"
                                :class="left == 0 ? 'pright34' : 'pleft34'"
                                v-model="xText"
                                :placeholder="left == 0 ? '请输入内容' : ''"
                                :disabled="left == '50%' ? true : false"
                                clearable
                            ></el-input>
                        </div>
                        <div
                            class="operationBox"
                            :class="left == 0 ? 'back1' : 'back2'"
                        >
                            <div v-if="left == '50%'" class="rightIcon">
                                <!-- <i class="el-icon-refresh"></i> -->
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="复制文字"
                                    placement="top"
                                >
                                    <i
                                        id="cyr"
                                        class="el-icon-document-copy"
                                        :data-clipboard-text="xText"
                                        @click="copytext('cyr')"
                                    ></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="translate-text-box" :style="{ right: right }">
                        <div class="mText textBox">
                            <i
                                class="el-icon-close"
                                v-if="right == '50%'"
                                @click="clearText('mText')"
                            ></i>
                            <el-input
                                type="textarea"
                                class="h100 menk"
                                :class="right == 0 ? 'pleft34' : 'pright34'"
                                v-model="mText"
                                :placeholder="
                                    right == '50%' ? '    ' : ''
                                "
                                :disabled="right == 0 ? true : false"
                            ></el-input>
                        </div>
                        <div
                            class="operationBox"
                            :class="right == 0 ? 'back2' : 'back1'"
                        >
                            <div style="color:red" v-if="right == '50%'">注意事项：请采用蒙古文国家标准编码(支持编码：蒙文国家标准编码、蒙科立编码）</div>
                            <div v-if="right == 0" class="rightIcon">
                                <!-- <i class="el-icon-refresh"></i> -->
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="复制文字"
                                    placement="top"
                                >
                                    <i
                                        class="el-icon-document-copy"
                                        :data-clipboard-text="mText"
                                        id="mw"
                                        @click="copytext('mw')"
                                    ></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import Clipboard from "clipboard";
import { codeMkConvert } from "api/product";
import { isMgl, isMkMgl } from "utils/fontCodeType";
export default {
    data() {
        return {
            loading: false,
            left: 0,
            right: 0,
            backImg: require("@/assets/images/product/backImg.jpg"),
            xText: "",
            mText: "",
            from: "cyr",
            to: "mw",
            disabled: false,
            userInfo: PcCookie.get(Key.userInfoKey)
                ? JSON.parse(PcCookie.get(Key.userInfoKey))
                : null,
            maxlength:2000
        };
    },
    methods: {
        copytext(type) {
            let that = this;
            let clipboard = null;
            if (type == "cyr") {
                clipboard = new Clipboard("#cyr");
            } else if(type == "mw"){
                clipboard = new Clipboard("#mw");
            }
            clipboard.on("success", () => {
                that.$message.success("复制成功！");
                clipboard.destroy();
            });
            clipboard.on("error", () => {
                that.$message.success("复制失败！");
                clipboard.destroy();
            });
        },
        conversion() {
            let content = this.left == 0 ? this.xText : this.mText;
            if(content.length == 0){
                this.$message({
                    type:"warning",
                    message:'请输入内容！'
                })
            }else{
                if(this.left != 0){
                    if(isMgl(this.mText) || isMkMgl(this.mText)){
                        this.loading = true;
                        this.enterTranslate(this.from, this.to, content, this.productId)
                    }else{
                        this.$message({
                            type:"warning",
                            message:'请输入国家标准编码蒙古文或蒙科立编码蒙古文！'
                        })
                        return false
                    }
                }else{
                    this.loading = true;
                    this.enterTranslate(this.from, this.to, content)
                }
            }
        },
        enterTranslate(from,to,content){
            codeMkConvert(from, to, content).then(
                (res) => {
                    if (res.success) {
                        if (this.left == 0) {
                            this.mText = res.body.data.data;
                        } else {
                            this.xText = res.body.data.data;
                        }
                        this.loading = false;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                        this.loading = false;
                    }
                }
            );
        },
        changeType() {
            this.left = this.left == 0 ? "50%" : "0";
            this.right = this.right == 0 ? "50%" : "0";
            this.from = this.left == 0 ? "cyr" : "mw";
            this.to = this.left == 0 ? "mw" : "cyr";
            this.cText = "";
            this.mText = "";
        },
        clearText(type) {
            if (type == "xText") {
                this.xText = "";
            } else if (type == "mText") {
                this.mText = "";
            }
        },
    },
};
</script>
<style>
.uploadDoc .el-upload--text{
    display: flex;
}
.uploadDoc  .changeImg{
    color: #252b3a !important;
}
.translateContent .el-textarea.is-disabled .el-textarea__inner {
    background: #fafafa;
    color: #252b3a;
}
.translateContent .h100 textarea {
    height: 100%;
    font-size: 18px;
    color: #252b3a;
    border: 0 !important;
    padding: 20px 0 !important;
    font-family: "Menksoft2012";
}
.menk textarea {
    text-align: justify;
    -webkit-text-orientation: sideways-right;
    text-orientation: sideways-right;
    display: table-column;
    font-family: 'Menksoft2012';
    -webkit-writing-mode: vertical-lr !important;
    writing-mode: vertical-lr !important;
    font-size: 22px !important;
}
.pleft34 textarea {
    background: #fafafa;
}
.pright34 textarea {
    background: #fff;
}

.cQuestionBox .el-select {
    width: 142px;
    margin-left: 20px;
}
.cQuestionBox .el-select .el-input__inner {
    height: 28px;
    line-height: 28px;
    border: 0;
    color: #252b3a;
}
.cQuestionBox .el-select .el-input__icon {
    line-height: 28px;
    color: #252b3a;
}
.cQuestion .h260 .el-form-item__content,
.cQuestion .h260 .el-textarea,
.cQuestion .h260 .el-textarea__inner {
    height: 200px;
}
.cQuestion .el-form-item__label{
    line-height: 28px;
    padding: 0;
}
.mQuestion .el-form-item__label {
    font-size: 14px;
    color: #252b3a;
    line-height: 28px;
    padding: 0;
}
.mQuestion .el-form-item {
    margin-bottom: 12px;
}
.mQuestion .el-col-11,
.mQuestion .el-col-11 .el-form-item,
.mQuestion .el-col-11 .el-textarea,
.mQuestion .el-col-11 .el-textarea__inner {
    height: 100%;
}
.mQuestion .el-col-11 .el-form-item__content {
    height: 242px;
}
</style>

<style lang="less" scoped>
.c-trueBtn {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 101;
    margin: -33px 0 0 -33px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    background: #1c4392;
    font-size: 16px;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
}
.fileTranslateHeader{
    width: 100%;
    height: 48px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.fileTranslateBox{
    height: calc(100% - 120px);
}
.filePromptText{
    height: 42px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    align-items: center;
    span{
        color: #ff1b3b;
    }
}
.flieName{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #333;
    white-space: nowrap;
    i{
        margin-right: 12px;
        font-size: 24px;
    }
}
.menkTextBox{
    padding: 12px;
    height: calc(100% - 42px);
    width: 96%;
    margin: 0 12px;
    overflow-y: hidden;
    overflow-x: auto;
    -o-writing-mode: vertical-lr;
    -moz-writing-mode: vertical-lr;
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    -webkit-writing-mode: tb-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: tb-lr;
    text-orientation: sideways-right;
    -webkit-text-orientation: sideways-right;
    word-break: keep-all;
    p{
        font-size: 18px;
        color: #252b3a;
        line-height: 30px;
        font-family: 'Menksoft2012';
    }
    p.hover{
        background: #fff100;
    }
}

.menkTextBox::-webkit-scrollbar {
    height: 10px;
}
.menkTextBox::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
}
.menkTextBox::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
}
.fileTextBox{
    height: 100%;
    padding: 16px;
    overflow-y: auto;
    p{
        font-size: 14px;
        color: #252b3a;
        line-height: 30px;
    }
    p.hover{
        background: #fff100;
    }
}
.fileTextBox::-webkit-scrollbar {
    width: 10px;
}
.fileTextBox::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
}
.fileTextBox::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
}
.h290 {
    height: 290px;
}
.h260 {
    height: 260px;
}
.cQuestionFormBox {
    flex: 1;
}
.cQuestionTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 34px;
    margin-bottom: 16px;
    > p {
        font-size: 18px;
        font-family: "思源黑体";
        font-weight: bold;
        color: #252b3a;
    }
    > span {
        font-size: 14px;
        font-family: "思源黑体";
        color: #b0b0b0;
        margin-left: 16px;
    }
    > .el-icon-close {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
        font-size: 20px;
        color: #b0b0b0;
        cursor: pointer;
    }
}
.cQuestionBox {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.questionNewsBox {
    padding: 14px 20px;
    width: 560px;
    height: 490px;
    background: #fff;
}
.questionSubmitBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
}
.backImg {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 99;
    margin-left: -0.5px;
    width: 1px;
    height: 100%;
    background: #e5e5e5;
}
.back1 {
    background: #fff;
}
.back2 {
    background: #fafafa;
}
.menk {
    -webkit-writing-mode: vertical-lr;
    -webkit-text-orientation: sideways-right;
    writing-mode: vertical-lr;
    text-orientation: sideways-right;
}
.operationBox {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    div {
        height: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        i {
            font-size: 20px;
            font-weight: bold;
            color: #252b3a;
            margin: 0 10px;
            cursor: pointer;
        }
        span {
            font-size: 14px;
            color: #252b3a;
        }
    }
    .num {
        color: #6f7583;
    }
    .rightIcon {
        width: 100%;
        justify-content: flex-end;
        i {
            color: #a0a0a0;
            font-weight: normal;
        }
    }
}
.pright34 {
    padding-right: 34px;
    padding-left: 12px;
}
.pleft34 {
    padding-left: 34px;
    padding-right: 12px;
    background: #fafafa;
}
.textBox {
    height: 100%;
    position: relative;
    .el-icon-close {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 99;
        font-size: 22px;
        cursor: pointer;
    }
}
.bleft1 {
    border-left: solid 1px #e5e5e5;
}
.translate-text-box {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
}
.fanyiImg {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
    margin: -31.5px 0 0 -31.5px;
    cursor: pointer;
}
.translateContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
}
.applicationBtnBox {
    align-items: center;
    height: 48px;
    line-height: 48px;
    border-bottom: solid 1px #e5e5e5;
    text-align: center;
    p {
        display: inline-block;
        font-size: 16px;
        color: #252b3a;
        width: 120px;
    }
    img {
        cursor: pointer;
        margin: 0 256px;
        vertical-align: middle;
    }
}
.translateBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: solid 1px #e5e5e5;
    background: #fff;
    border-radius: 8px;
}
.contentBox {
    height: 100%;
    padding: 60px 0;
    position: relative;
}
</style>
