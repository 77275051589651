<template>
    <div class="main-container" style="background: #f3f5f8">
        <div class="w12 overflowh" >
            <div class="details-crumbs">
                <p class="bar2"></p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/case' }"
                        >客户案例</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{details.projectName}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="project-details">
                <div class="list-news">
                    <img :src="details.projectTypeImg" alt="">
                    <div class="case-news-box">
                        <img :src="details.partyALogo" alt="">
                        <div class="projectName">
                            <p>项目名：</p>
                            <span>{{details.projectName}}</span>
                        </div>
                        <div class="inText">
                            <p>项目概况：</p>
                            <span>
                                {{details.projectsurvey}}
                            </span>
                        </div>
                        <div class="caseTypeBox">
                            <p v-for="item in  details.caseType" :key="item.id">{{item.text}}</p>
                        </div>
                    </div>
                </div>
                <div class="details-xqNews-box">
                    <div class="textBox">
                        <p>客户介绍</p>
                        <div>{{details.khIntroduction}}</div>
                    </div>
                    <div class="textBox">
                        <p>项目背景及业务挑战</p>
                        <div v-html="unEscapeHTML(details.challengesText)"></div>
                    </div>
                    <div class="textBox">
                        <p>解决方案</p>
                        <div v-html="unEscapeHTML(details.programmeText)"></div>
                        <div v-for="(item) in details.imgList" :key="item.id" class="projectImglist">
                            <img :src="item.imgUrl" alt="" @click="showBigImg(item)">
                        </div>
                    </div>
                    <div class="textBox">
                        <p>客户收益</p>
                        <div v-html="unEscapeHTML(details.khProfit)"></div>
                    </div>
                </div>
            </div>
        </div>
         <el-dialog
            :visible.sync="visible"
            :before-close="handleClose"
        >
            <img :src="showImg" alt="" class="fntshowImg">
        </el-dialog>
    </div>
</template>
<script>
import proJectList from 'utils/projectList'
export default {
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙古文OCR，机器翻译，固什词典，wps蒙古文专版，蒙古文校对，编码转换",
            },
        ],
    },
    data() {
        return {
            details:{},
            projectId:'',
            visible:false,
            showImg:''
        }
    },
    created() {
        this.projectId = this.$route.query.projectId
        this.fetchData(this.projectId)
    },
    methods:{
        showBigImg(item) {
            this.showImg = item.imgUrl
            this.visible = true
        },
        fetchData(projectId) {
            proJectList.forEach(item => {
                if(projectId == item.id){
                    this.details = item
                }
            })
        },
        unEscapeHTML(html) {
            html = "" + html;
            return html
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&amp;/g, "&")
                .replace(/&quot;/g, '"')
                .replace(/&apos;/g, "'");
        },
        handleClose() {
            this.visible = false;
        },
    }
}
</script>
<style lang="less" scoped>
.fntshowImg{
    max-width: 100%;
    margin: 0 auto;
    display: block;
}
.projectImglist{
    margin: 20px 0;
    text-align: center;
    img{
        max-width: 100%;
    }
}
.textBox{
    display: flex;
    flex-direction: column;
    color: #252b3a;
    margin-bottom: 20px;
    >p{
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 12px;
    }
    >div{
        font-size: 14px;
        line-height: 22px;
    }
}
.details-xqNews-box{
    margin: 20px 0;
    padding: 30px;
    background: #fff;
    box-shadow: 0px 0px 6px  rgba(0, 0, 0, .2);
}
.project-details{
    padding: 6px;
    margin: 20px 0;
}
.case-news-box{
    flex: 1;
    height: 100%;
    margin-left: 30px;
    >img{
        margin-bottom: 8px;
        height: 50px;
    }
    .projectName{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        font-weight: bold;
        color: #252b3a;
        margin-bottom: 8px;
        p{
            width: 72px;
        }
    }
    .inText{
        height: 108px;
        font-size: 14px;
        color: #252b3a;
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        p{
            font-weight: bold;
        }
        span{
            width: 72px;
            flex: 1;
            line-height: 22px;
        }
    }
    .caseTypeBox{
        display: flex;
        flex-wrap: wrap;
        p{
            padding: 0 36px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            font-weight: bold;
            color: #1b4094;
            background: #e0eaff;
            border-radius: 15px;
            margin: 0 4px;
        }
    }
}
.list-news{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 276px;
    padding: 13px 16px;
    background: #fff;
    margin-bottom: 28px;
    box-shadow: 0px 0px 6px  rgba(0, 0, 0, .2);
    >img{
        height: 246px;
        width: 286px;
    }
}
</style>
