<template>
    <div
        class="c-news-li pointer ellipsis"
        :class="{ pictureNews: path === '/home-activity-details' }"
        @click.stop="itemClick()"
    >
        <slot name="newsText"></slot>
        <slot name="pictureNews"></slot>
    </div>
</template>

<script>
export default {
    name: "AboutTabBar",
    props: {
        path: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
    },
    methods: {
        itemClick() {
            if (this.path == "/home-article-details") {
                this.$router.push({
                    path: this.path,
                    query: { articleId: this.id },
                });
            } else if (this.path == "/about-recruit-details") {
                this.$router.push({
                    path: this.path,
                    query: { recruitId: this.id },
                });
            } else if (this.path == "/home-activity-details") {
                //console.log('敬请期待...');
                this.$router.push({
                    path: this.path,
                    query: { activityId: this.id },
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
.c-news-li {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #252b3a;
    text-align: left;
}
.c-news-li:hover {
    color: #1b4094;
}
.pictureNews {
    height: 88px;
    border: solid 1px #e6e6e6;
    overflow: hidden;
    margin: 30px 0;
    img {
        width: 100%;
        height: 100%;
    }
}
</style>
