<template>
    <div class="main-container overflowh" style="background: #f3f5f8">
        <div class="w12">
            <div class="breadcrumb">
                <p class="bar2"></p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/college' }"
                        >蒙科立学院</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>精品课程 </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="course_nav_box">
                <Search
                    v-if="hasCondition"
                    @searchTypeQery="searchTypeQery"
                    @searchInputQery="searchInputQery"
                    :productInfos="productInfos"
                    :types="types"
                />
                <div class="course-list" v-if="hasNews">
                    <course-list>
                        <course-item
                            v-for="item in collegeList"
                            :key="item.id"
                            :id="item.id"
                            :productId="searchObj.productId"
                            :courseId="item.courseInfo.id"
                        >
                            <img
                                :src="item.coursewareVideo.cover"
                                slot="coverImg"
                                class="coverImg"
                                alt=""
                            />
                            <h3 class="news-title ellipsis" slot="title">
                                {{ item.coursewareVideo.name }}
                            </h3>
                            <span slot="time">
                                {{showTime(item.coursewareVideo.totalDuration)}}
                            </span>
                            <span slot="num">{{ item.courseInfo.studyCount }}人学习</span>
                        </course-item>
                    </course-list>
                    <el-row type="flex" justify="center" :background="true">
                        <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="page.current"
                            :page-size="page.size"
                            :total="page.total"
                            layout="prev, pager, next"
                        >
                        </el-pagination>
                    </el-row>
                </div>
                <el-empty
                    description="暂无数据"
                    :image-size="200"
                    v-else
                ></el-empty>
            </div>
        </div>
    </div>
</template>
<script>
import { feachVideoList, getSearchConditionList } from "api/college";
import Search from "views/routerViews/College/Course/List/Search";
import CourseList from "components/common/courseList/courseList";
import CourseItem from "components/common/courseList/courseItem";
export default {
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙古文WPS2002，蒙古文字体，蒙科立学院，蒙科立整词输入法，蒙古文WPS2002，语音识别",
            },
        ],
    },
    components: { Search, CourseList, CourseItem },
    computed:{
        showTime(){
            return function(tiem) {
                let minute = parseInt(tiem/60)
                let second = tiem % 60
                return  minute + "分" + second + '秒'
            }
        }
    },
    data() {
        return {
            hasNews: true,
            hasCondition: false,
            page: {
                size: 12,
                total: 0,
                current: 1,
            },
            productInfos: [],
            types: [],
            collegeList: [],
            searchObj:{
                productId:'',
                type:'',
                name:''
            },
        };
    },
    created() {
        this.getConditionList();
        this.fetchData();
    },
    methods: {
        getConditionList() {
            getSearchConditionList().then((res) => {
                if (res.errorCode == "2000") {
                    this.productInfos = res.body.data.productInfos;
                    this.types = res.body.data.types;
                    this.hasCondition = true;
                }
            });
        },
        fetchData() {
            feachVideoList(this.searchObj, this.page.current, this.page.size).then(
                (res) => {
                    if (res.success) {
                        this.collegeList = res.body.data.rows;
                        this.page.total = res.body.data.total;
                        this.hasNews = this.page.total == 0 ? false :  true
                    }
                }
            );
        },
        //输入框查询
        searchInputQery(data) {
            this.searchObj.productId = ''
            this.searchObj.type = ''
            this.searchObj.name = data
            this.page.current = 1
            this.fetchData()
        },
        //类型查询
        searchTypeQery(data) {
            this.searchObj.productId = data.productId
            this.searchObj.type = data.typeId
            this.searchObj.name = ''
            this.page.current = 1
            this.fetchData()
        },
        handleCurrentChange(current) {
            this.page.current = current
            this.fetchData()
        },
    },
};
</script>
<style>
.course_nav_box .el-pagination button,
.course_nav_box .el-pager li {
    background: none !important;
}
.course_nav_box .el-empty {
    height: 400px;
}
</style>
<style lang="less" scoped>
.course_nav_box {
    margin-bottom: 50px;
}
.course-list {
    margin-top: 7px;
}
.breadcrumb {
    padding-top: 48px;
    height: 96px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .bar2 {
        height: 16px;
        width: 2px;
        background: #6f7583;
        margin-right: 5px;
    }
}
</style>
