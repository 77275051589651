<template>
    <div class="purchaseBox h100">
        <div class="purchase-title">购买记录</div>
        <div v-if="hasPru">
            <div class="purchase-list">
                <div class="purchase-item" v-for="item in purList" :key="item.id">
                    <div class="purchase-name">{{item.product.name}}</div>
                    <div class="purchase-price">消费金额：{{item.order.actualAmount}}元</div>
                    <div class="purchase-time">过期时间：{{item.expiryDate}}</div>
                </div>
            </div>
            <el-row type="flex" justify="center" style="margin-top:16px;">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="page.current"
                    :page-size="page.size"
                    :total="page.total"
                    layout="prev, pager, next"
                >
                </el-pagination>
            </el-row>
        </div>
        <div class="noneNews" v-else>
            <img src="~assets/images/personal/nonImg.png" alt="">
            <div class="promptText">
                <p>
                    暂时没有购买购买记录
                    <!-- ，<span class="pointer">快去选购吧 >></span> -->
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { getMinePurchase } from 'api/personal'
export default {
    data() {
        return {
            page:{
                size: 9,
                total: null,
                current: 1
            },
            purList:[],
            hasPru:false
        }
    },
    created() {
        this.getPurList()
    },
    methods:{
        getPurList() {
            getMinePurchase(this.page.current, this.page.size).then(res => {
                if(res.errorCode == '2000'){
                    this.purList = res.body.data.rows
                    this.page.total = res.body.data.total
                    if(this.page.total != 0){
                        this.hasPru = true
                    }else{
                        this.hasPru = false
                    }
                }
            })
        },
        handleCurrentChange(current) {
            this.page.current = current
            this.getPurList()
        }
    }
}
</script>
<style lang="less" scoped>
.noMore{
    line-height: 36px;
    font-size: 14px;
    color: #818181;
    text-align: right;
    margin-top: 10px;
}
.purchase-item{
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 14px;
    margin-top: 26px;
    border-bottom: dashed 1px #8e8e8e;
    font-size: 14px;
    color: #252b3a;
    justify-content: space-between;
    .purchase-name{
        width: 320px;
    }
}
.purchase-title{
    font-size: 18px;
    color: #252b3a;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
}
.purchaseBox{
    padding: 0 42px;
    background: #fff;
}
</style>
