<template>
    <div class="package-container">
        <div class="w12 h100">
            <div class="package-title">
                <p class="menkTitle"> <br/> <br/> <br/> <br/></p>
                <span>产品服务套餐</span>
            </div>
            <div class="remarks">服务1：</div>
            <div class="package-list-box">
                <div class="package-li-box" >
                    <div class="package-type">
                        <span>单张识别服务</span>
                    </div>
                    <div class="norms">规格：线上识别，以 2000 字符为单位，1 元/2 千字符，最低 1 元一次，实时付费</div>
                    <p class="ocrBar1"></p>
                    <div class="package-btn-box mleft192" @click="enterUse">立即使用</div>
                </div>
            </div>
            <div class="remarks">服务2：</div>
            <div class="package-li2-box">
                <div class="package2-type">
                    <span>委托批量识别</span>
                    <p>
                        <i>*</i>
                        <span>
                            委托批量识别，以累计
                            页数计算（要求：每张
                            图识别 8000 字符以内），
                            选择相应规格付费。需
                            要用户通过指定邮件发
                            送原图给蒙科立。
                        </span>
                    </p>
                </div>
                <div class="package2-news-box">
                    <div class="package2-news" v-for="i in 4" :key="i">
                        <div class="package-rule-box">
                            <div class="package-rule-news-box">
                                <p>规格：</p>
                                <div>
                                    <em>{{paper(packageList[i].field1)}}</em>
                                    <p>页</p>
                                </div>
                            </div>
                            <div class="package-rule-news-box">
                                <p>有效时长：</p>
                                <div>
                                    <em>{{efficientTime(packageList[i].efficientTime)}}</em>
                                    <p>有效</p>
                                </div>
                            </div>
                        </div>
                        <div class="package-price-box">
                            <p>{{packageList[i].price}}</p>
                            <span>元/{{packageList[i].field2}}</span>
                        </div>
                        <div class="package-btn-box" @click="enterPay(i)">立即选购</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie"
export default {
    props:{
        packageList:{
            type:Array,
            default() {
                return []
            }
        },
        name:{
            type:String,
            default:''
        },
        productId:{
            type:String,
            default:''
        }
    },
    computed:{
        efficientTime() {
            return function (type) {
                let retrunType = "";
                if(type == 1){
                    retrunType = '24个小时'
                }else{
                    retrunType = type + '天'
                }
                return retrunType;
            };
        },
        paper() {
            return function (num) {
                let paperNum = num/8000;
                return paperNum;
            };
        }
    },
    data() {
        return {
            token: PcCookie.get(Key.tokenKey),
        }
    },
    methods: {
        enterUse() {
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再下载！",
                    type: "warning",
                });
            } else {
                let routeData = this.$router.resolve({
                    path:'/app-menkocr',
                    query:{
                        productId:this.productId
                    }
                });
                window.open(routeData.href, "_blank");
            }
        },
        enterPay(index){
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再下载！",
                    type: "warning",
                });
            } else {
                this.$router.push({
                    path:'/product-ocr-order',
                    query:{
                        productId:this.productId,
                        packageType: index
                    }
                })
            }
        }
    }
}
</script>
<style lang="less" scoped>
.package-rule-box{
    width: 640px;
    height: 86px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 20px;

}
.package2-news{
    display: flex;
    flex-direction: row;
     display: flex;
    flex-direction: row;
    align-items: center;
    background: #fcfdfd;
    font-family: '思源黑体';
    border: solid 1px #fff;
}
.package2-news:hover, .package2-news.active{
    border:solid 1px #3c7fff;
}
.package2-news:hover .package-btn-box, .package2-news.active .package-btn-box{
    background: #ff6701;
}
.package2-news-box{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.package2-type{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 226px;
    background: -webkit-linear-gradient(#ebf1fb, #f5f6f9);
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(#ebf1fb, #f5f6f9);
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(#ebf1fb, #f5f6f9);
    /* Firefox 3.6 - 15 */
    background: linear-gradient(#ebf1fb, #f5f6f9);
    p{
        margin-top: 12px;
        width: 156px;
        display: flex;
        flex-direction: row;
        span{
            font-size: 12px;
            line-height: 16px;
            color: #252b3a;
        }
        i{
            font-size: 24px;
            color: #ff0000;
            margin-right: 6px;
        }
    }
    >span{
        color: #252b3a;
        font-size: 20px;
        font-weight: bold;
    }
}
.package-li2-box{
    display: flex;
    flex-direction: row;
    border: solid 1px #e6e6e6;
}
.mleft192{
    margin-left: 192px;
}
.ocrBar1{
    width: 1px;
    height: 68px;
    background: #eeeeee;
}
.norms{
    margin: 0 44px  0 24px;
    font-size: 16px;
    color: #252b3a;
}
.remarks{
    font-size: 14px;
    color: #252b3a;
    line-height: 40px;
}
.package-btn-box{
    width: 110px;
    height: 36px;
    font-size: 14px;
    color: #fff;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    background: #3c7fff;
}
.package-price-box{
    padding-left: 20px;
    width: 198px;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    p{
        font-size: 25px;
        color: #ff6701;
        margin-right: 4px;
    }
    span{
        font-size: 12px;
        color: #252b3a;
    }
}
.package-rule-news-box{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    >p{
        font-size: 16px;
        color: #252b3a;
    }
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 214px;
        height: 56px;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 0 6px 0 #c3d3ef;
        em{
            font-size: 25px;
            color: #252b3a;
            font-weight: bold;
        }
        p{
            margin-left: 6px;
            font-size: 14px;
            color: #252b3a;
            font-weight: bold;
            font-style: italic;
        }
    }
}
.package-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 226px;
    height: 100%;
    background: -webkit-linear-gradient(#ebf1fb, #f5f6f9);
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(#ebf1fb, #f5f6f9);
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(#ebf1fb, #f5f6f9);
    /* Firefox 3.6 - 15 */
    background: linear-gradient(#ebf1fb, #f5f6f9);
    p{
        font-size: 35px;
        color: #ff6701;
        font-weight: bold;
        margin-right: 6px;
    }
    span{
        color: #252b3a;
        font-size: 20px;
        font-weight: bold;
    }
}
.package-li-box{
    height: 102px;
    border: solid 1px #e6e6e6;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fcfdfd;
    font-family: '思源黑体';
}
.package-li-box:hover{
    border: solid 1px #3c7fff;
}
.package-li-box:hover .package-btn-box{
    background: #ff6701;
}
.package-li-box.active{
    border: solid 1px #3c7fff;
}
.package-li-box.active .package-btn-box{
    background: #ff6701;
}
.package-container{
    padding-bottom: 62px;
    background: #fff;
    .package-title{
        padding: 33px 0;
        font-size: 35px;
        color: #252b3a;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        p{
            margin-right: 12px;
        }
    }
    .package-list{
        height: 376px;
    }
}
</style>
