<template>
    <div class="main-container" style="background:#f3f5f8">
        <Banner/>
        <List :list="list" v-if="haseNew" :productId="productId"/>
    </div>
</template>
<script>
import Banner from 'views/routerViews/Download/Font/Banner'
import List from 'views/routerViews/Download/Font/List'
import { getFontDetails } from "api/product";
export default {
    components:{ Banner, List },
    data() {
        return {
            productId:'',
            list:[],
            haseNew:false,
        }
    },
    created() {
        this.productId = this.$route.query.productId
        this.fetchData(this.productId)
    },
    methods: {
        fetchData(productId) {
            getFontDetails(productId).then(res => {
                if (res.success) {
                    this.list = res.body.data;
                    this.haseNew = true
                }
            })
        }
    }
}
</script>


