<template>
    <div>
        <div class="menk-honor">
            <div class="w12 h100">
                <div class="title">
                    <p class="menkTitle"> <br/> <br/> <br/> <br/></p>
                    <span>荣誉资质</span>
                </div>
                <div class="menk-honor-swiper">
                    <div class="swiper-btn">
                        <p class="pointer swiper-left-btn">
                            <i class="el-icon-arrow-left"></i>
                        </p>
                    </div>
                    <div class="honor-swiper">
                        <swiper
                            ref="mySwiper"
                            :options="swiperOption"
                            class="swiper-no-swiping"
                        >
                            <swiper-slide
                                v-for="(item, index) in honorList"
                                :key="index"
                            >
                                <div
                                    class="swiper-img-box"
                                    @click="showBigPicture(item)"
                                >
                                    <img
                                        src="~assets/images/honorImg.jpg"
                                        alt=""
                                        class="honorBackImg"
                                    />
                                    <div class="honorImgBox">
                                        <img :src="item.img" alt="" />
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="swiper-btn">
                        <p class="pointer swiper-right-btn">
                            <i class="el-icon-arrow-right"></i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="showBigImgBox" v-if="visible">
            <i class="el-icon-close" @click="handleClose"></i>
            <div class="imgNewsBox">
                <img :src="bigPicture" alt="" />
            </div>
        </div>
        <!-- <el-dialog :visible.sync="visible" :before-close="handleClose">
            <img :src="bigPicture" width="100%" alt="" />
        </el-dialog> -->
    </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
    components: { swiper, swiperSlide },
    data() {
        let vue = this;
        return {
            activeId: 0,
            bigPicture: "",
            visible: false,
            honorList: [
                {
                    id: 2,
                    img: require("@/assets/images/zsImg3.jpg"),
                },
                {
                    id: 3,
                    img: require("@/assets/images/zsImg4.jpg"),
                },
                {
                    id: 4,
                    img: require("@/assets/images/zsImg5.jpg"),
                },
                {
                    id: 6,
                    img: require("@/assets/images/zsImg7.jpg"),
                },
                {
                    id: 7,
                    img: require("@/assets/images/zsImg8.jpg"),
                },
                {
                    id: 8,
                    img: require("@/assets/images/zsImg9.jpg"),
                },
                {
                    id: 9,
                    img: require("@/assets/images/zsImg10.jpg"),
                },
                {
                    id: 10,
                    img: require("@/assets/images/zsImg11.jpg"),
                },
                {
                    id: 11,
                    img: require("@/assets/images/zsImg12.jpg"),
                },
                {
                    id: 12,
                    img: require("@/assets/images/zsImg13.jpg"),
                },
                {
                    id: 13,
                    img: require("@/assets/images/zsImg14.jpg"),
                },
            ],
            swiperOption: {
                navigation: {
                    nextEl: ".swiper-right-btn",
                    prevEl: ".swiper-left-btn",
                },
                slidesPerView: 4,
                on: {
                    slideChange: function () {
                        vue.activeId = this.activeIndex;
                    },
                },
            },
        };
    },
    methods: {
        showBigPicture(item) {
            this.bigPicture = item.img;
            this.visible = true;
        },
        handleClose() {
            this.visible = false;
            this.bigPicture = "";
        },
    },
};
</script>
<style lang="less" scoped>
.showBigImgBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
        position: fixed;
        top: 20px;
        right: 26px;
        z-index: 99999;
        font-size: 20px;
        cursor: pointer;
        color: #fff;
        font-weight: bold;
    }
    .imgNewsBox {
        height: 92%;
        width: 96%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            display: inline-block;
            max-height: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;
            width: auto;
            height: auto;
        }
    }
}
.menk-honor {
    height: 606px;
    background: #0f348d;
    .title {
        padding-top: 84px;
        height: 186px;
        font-size: 35px;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        p{
            margin-right: 12px;
        }
    }
    .menk-honor-swiper {
        height: 331px;
        position: relative;
        display: flex;
        flex-direction: row;
        .swiper-btn {
            width: 28px;
            height: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            p {
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.9);
            }
            i {
                font-size: 22px;
            }
        }
        .honor-swiper {
            width: 1144px;
            height: 100%;
            .swiper-container {
                height: 100%;
            }
            .swiper-img-box {
                margin: 0 5px;
                height: 100%;
                position: relative;
                .honorBackImg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 3;
                }
                .honorImgBox {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 4;
                    padding: 20px 24px 20px 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
}
</style>
