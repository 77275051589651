<template>
  <div
    id="applicationBox"
    style="background: #f3f5f8"
    v-loading="loading"
    element-loading-text="校对中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <div class="w12 h100">
        <div class="proofBox">
            <div class="proof-handle-box">
                <div class="proof-left-box">
                    <div class="proof-condition-box">
                        <div class="proof-condition-item">
                            <el-form ref="form" :model="form" label-width="80px" label-position="top">
                                <el-form-item label="编码转换">
                                    <el-checkbox-group v-model="form.to"  @change="handleChange1">
                                        <el-checkbox label="">自动判断蒙古文编码</el-checkbox>
                                    </el-checkbox-group>
                                    <el-checkbox-group v-model="form.code_to"  @change="handleChange2">
                                        <el-checkbox label="">自动转换为蒙古文编码国家标准</el-checkbox>
                                    </el-checkbox-group>
                                    <el-checkbox-group v-model="form.bgbex" @change="handleChange3">
                                        <el-checkbox label="">传统蒙古文编码“共享工程”标准</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                                <el-form-item label="校对选项">
                                    <el-radio-group v-model="form.from">
                                        <el-radio  :label="1">词形校对，并标记未识别词</el-radio>
                                        <el-radio  :label="2">词形和读音校对，并作标记</el-radio>
                                        <el-radio  :label="30">词形和读音校对，并自动纠正，但不做标记</el-radio>
                                        <el-radio  :label="31">词形和读音校对，并自动纠正，但只标记未识别词（词形错误）</el-radio>
                                        <el-radio  :label="32">词形和读音校对，并自动纠正，做未识别词（词形错误）和同形词标记</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                            <div class="begin-proof" @click="enterProof">开始校对</div>
                            <p class="p-barb1"></p>
                            <div class="p-explain-box">
                                <div class="p-explain-text">说明：</div>
                                <div class="p-explain-news">
                                    <div class="p-explain-left">
                                        <img src="~assets/images/product/proofImg1.png" alt="">
                                        <p>红色：</p>
                                    </div>
                                    <div class="p-explain-right">
                                        红色表示未能识别的同形异音词（自动校对已识别86%以上的同
                                        形词），需要用户根据上下文进行判断，并选择正确读音。
                                    </div>
                                </div>
                                <div class="p-explain-news">
                                    <div class="p-explain-left">
                                        <img src="~assets/images/product/proofImg2.png" alt="">
                                        <p>蓝色：</p>
                                    </div>
                                    <div class="p-explain-right">
                                        蓝色表示词形错误的单词或未识别词，自动校对之后请用户手动
                                        修改词形错误的单词。
                                    </div>
                                </div>
                                <div class="p-explain-news">
                                    <div class="p-explain-left">
                                        <img src="~assets/images/product/proofImg3.png" alt="">
                                        <p>绿色：</p>
                                    </div>
                                    <div class="p-explain-right">
                                        绿色表示读音错误的单词，需要用户人工判断并修改；采用“自
                                        动纠错”后不会出现该标记代码，但偶尔在在有些附加成分后面
                                        出现该标记代码，这是因为其前一个词没有被识别。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="proof-right-box">
                    <div class="proof-right-title">转换结果</div>
                    <div class="proof-result menkTitle">
                        <i
                            class="el-icon-close"
                            v-if="resultText.length > 0"
                            @click="clear"
                        ></i>
                        <el-input
                            type="textarea"
                            placeholder="    ......"
                            v-model="form.content"
                            v-if="resultText.length ==0"
                        ></el-input>
                        <div v-html="unEscapeHTML(resultText)" v-if="resultText.length !=0" class="overTextBox"></div>
                        <el-row type="flex" justify="end" class="elRowBox"  v-if="resultText.length !=0" >
                            <el-tooltip
                                v-if="resultText.length != 0"
                                class="item pointer"
                                effect="dark"
                                content="复制转换结果文字"
                                placement="top"
                            >
                                <i
                                    id="result"
                                    class="el-icon-document-copy"
                                    :data-clipboard-text="resultText"
                                    @click="copytext('result')"
                                ></i>
                            </el-tooltip>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import { menkProof } from 'api/product'
export default {
    data() {
        return {
            loading:false,
            form:{
                from:32,
                to:true,
                code_to:true,
                bgbex:false,
                content:''
            },
            resultText:''
        }
    },
    created() {
        this.productId = this.$route.query.productId
    },
    methods:{
        copytext() {
            let that = this;
            let clipboard = new Clipboard("#result");
            clipboard.on("success", () => {
                that.$message.success("复制成功！");
                clipboard.destroy();
            });
            clipboard.on("error", () => {
                that.$message.success("复制失败！");
                clipboard.destroy();
            });
        },
        clear(){
            this.resultText = ''
            this.form.content = ''
        },
        enterProof(){
            if(this.form.content.length == 0){
                this.$message({
                    type:'warning',
                    message:'请输入内容'
                })
            }else{
                this.loading = true
                menkProof(this.form,this.productId).then(res => {
                    if(res.success && res.errorCode == '2000'){
                        this.resultText = res.body.data
                        this.loading = false
                    }else{
                        this.$message({
                            type:'error',
                            message:res.msg
                        })
                        this.loading = false
                    }
                })
            }
        },
        handleChange1(value){
            if(value){
                this.form.bgbex = false
            }else{
                this.form.code_to = true
            }
        },
        handleChange2(value){
            if(value){
                this.form.bgbex = false
            }else{
                this.form.to = true
            }
        },
        handleChange3(value){
            if(value){
                this.form.to = false
                this.form.code_to = false
            }
        },
        unEscapeHTML(html) {
            html = "" + html;
            return html
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&amp;/g, "&")
                .replace(/&quot;/g, '"')
                .replace(/&apos;/g, "'");
        },
    }
}
</script>
<style>
.proof-right-box .el-textarea {
    width: 100%;
    height: 100%;
}
.proof-right-box .el-textarea textarea {
    height: 100%;
    width: 100%;
    color: #252b3a !important;
    font-size: 18px !important;
    font-family: "MenkGarqag" !important;
    border: none;
    background: none;
    writing-mode: tb-lr !important;
    -webkit-writing-mode: vertical-lr !important;
    -webkit-text-orientation: sideways-right !important;
    writing-mode: vertical-lr !important;
    text-orientation: sideways-right !important;
    display: table-column;
    word-break: keep-all;
    text-align: justify;
}
.proof-condition-item  .el-form-item{
    margin: 0;
}
.proof-condition-item .el-form-item__content{
    line-height: 30px;
}
.proof-condition-item .el-form-item__label{
    font-size: 18px;
    font-weight: bold;
    color: #252b3a;
    line-height: 32px;
    padding: 0;
}
.proof-condition-item .el-checkbox,.proof-condition-item .el-radio{
    width: 420px;
    line-height: 30px;
}
.proof-condition-item .el-radio .el-radio__label{
    color:#252b3a;
}
</style>
<style lang="less" scoped>
.elRowBox {
    position: absolute;
    bottom: 6px;
    right: 20px;
}
.el-icon-close{
    position: absolute;
    right: 3px;
    top: 0;
    font-size: 18px;
    cursor: pointer;
}
.overTextBox{
    padding: 20px 0 0;
    height: calc(100% - 20px);
    width: 680px;
    overflow-x: auto;
    color: #252b3a;
    line-height: 32px;
}
.proof-result{
    position: relative;
    margin-top: 8px;
    height: calc(100% - 42px);
    width: 700px;
    background: #fff;
    border: solid 1px #e5e5e5;
    border-radius: 8px;
   padding: 10px;
}
.proof-right-title{
    font-size: 18px;
    font-weight: bold;
    color: #252b3a;
    line-height: 32px;
    padding: 0;
}
.proof-right-box{
    width: 700px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.p-explain-right{
    flex: 1;
    line-height: 22px;
    font-size: 14px;
    color: #252b3a;
}
.p-explain-left{
    width: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 22px;
    font-size: 14px;
    color: #252b3a;
    p{
        margin-left: 4px;
    }
}
.p-explain-news{
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;

}
.p-explain-text{
    font-size: 18px;
    font-weight: bold;
    color: #252b3a;
    line-height: 32px;
    padding: 0;
}
.p-explain-box{
    display: flex;
    flex-direction: column;
}
.p-barb1{
    height: 1px;
    margin: 20px 0 12px;
    background: #dcdcdc;
}
.begin-proof{
    width: 144px;
    height: 34px;
    background: #1b4094;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 34px;
    cursor: pointer;
}

.proof-left-box{
    width: 500px;
    height: 100%;
}
.proof-condition-item{
    width: 470px;
}
.proof-handle-box{
    display: flex;
    flex-direction: row;
    height: 100%;
    border-radius: 12px;
    position: relative;
}
.proofTitleBox {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: bold;
    font-family: "思源黑体";
    color: #252b3a;
    height: 46px;
    p {
        width: 500px;
        padding-left: 16px;
    }
    span{
        width: 700px;
        padding-left: 16px;
    }
}
.proofBox {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    height: 100%;
}
#applicationBox{
    min-height: 726px;
}
</style>
