<template>
    <div id="per-tab-bar" class="tablist">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "PerTabBar",
};
</script>

