<template>
    <div id="app">
        <router-view v-if="isRouterAlive" />
    </div>
</template>
<script>
import { beforeEncrypt } from "@/utils/encryptData.js";
import { getPublicKey } from "api/perCheck";
export default {
    name: "app",
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            isRouterAlive: true,
        };
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        setWechatUserInfo(uuId) {
            getPublicKey().then((res) => {
                if (res.success) {
                    const { body } = res;
                    const publicKey = body.data.publicKey;
                    const redisKey = body.data.redisKey;
                    let formStr = JSON.stringify(uuId);
                    const weChatNews = beforeEncrypt(
                        16,
                        publicKey,
                        redisKey,
                        formStr
                    );
                    this.$store.dispatch("WeChatLogin",weChatNews).then((res) => {
                        const { success } = res;
                        if (success) {
                            this.reload();
                        }
                    });
                }
            });
        },
    },
    watch: {
        $route(to) {
            let uuId = to.query.uuId;
            if (uuId != undefined) {
                if (to.path != "/weChat-register") {
                    this.setWechatUserInfo(uuId);
                } else {
                    console.log("第一次扫码，完善信息去");
                }
            }
        },
    },
};
</script>
