<template>
    <div class="college-recommend">
        <div class="w12">
            <div class="module-title">
                <div class="title">
                    <img src="~assets/images/recommendImg.png" alt="">
                    <p>每日好课推荐</p>
                </div>
                <div class="more pointer" @click="enterList">查看更多 >></div>
            </div>
            <div class="news-box">
                <div class="news-left h100">
                    <img src="~assets/images/collegeBackImg.png" alt="">
                    <div class="news-text">
                        <p>蒙科立学院全力打造</p>
                        <span>针对蒙科立产品使用技巧的学习平台</span>
                    </div>
                </div>
                <div class="course-list h100">
                    <div class="course-li pointer" v-for="item in collegeList" :key="item.id" @click="enterDetails(item)">
                        <div class="course-img">
                            <img :src="item.coursewareVideo.cover" alt="">
                            <p class="ellipsis">{{item.coursewareVideo.name}}</p>
                        </div>
                        <div class="course-nwes">
                            <p class="ellipsis">{{item.courseInfo.name}}</p>
                            <div class="course-content">
                                <div>
                                    <i class="el-icon-time"></i>
                                    <span>{{showTime(item.coursewareVideo.totalDuration)}}</span>
                                </div>
                                <div>
                                    <i class="el-icon-user-solid"></i>
                                    <span>{{item.courseInfo.studyCount}}人学习</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { feachVideoList } from 'api/college'
export default {
    computed:{
        showTime(){
            return function(tiem) {
                let minute = parseInt(tiem/60)
                let second = tiem % 60
                return  minute + "分" + second + '秒'
            }
        }
    },
    data() {
        return {
            page:{
                current:1,
                size:6
            },
            searchObj:{
                productId:'',
                type:'',
                name:''
            },
            collegeList:[]
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            feachVideoList(this.searchObj,this.page.current, this.page.size).then(res => {
                if(res.success){
                    this.collegeList = res.body.data.rows
                }
            })
        },
        enterDetails(item) {
            this.$router.push({
                path:'/college-course-details',
                query:{
                    courseId:item.courseInfo.id,
                    id:item.id,
                    productId:item.courseInfo.productId,
                }
            })
        },
        enterList() {
            this.$router.push('/college-course')
        }
    }
}
</script>
<style lang="less" scoped>
.course-list{
    width: 894px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    .course-li{
        margin-left: 10px;
        width: 288px;
        height: 198px;
        border: solid 1px #dcdee1;
        background: #fff;
        .course-nwes{
            padding: 0 15px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            .course-content{
                font-size: 12px;
                color: #818181;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
            >p{
                margin-bottom: 10px;
                font-size: 14px;
                color: #252b3a;
            }
        }
        .course-img{
            height: 138px;
            position: relative;
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
            }
            p{
                position: absolute;
                left: 0;
                top: 0;
                z-index:4;
                width: 100%;
                height: 138px;
                line-height: 138px;
               font-size: 16px;
               color: #fff;
               font-weight: bold;
               text-align: center;
                padding: 0 16px;
            }
        }
    }
}
.news-box{
    height: 410px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .news-left{
        width: 287px;
        position: relative;
        background: #fff;
        img{
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 3;
        }
        .news-text{
            position: absolute;
            left: 18px;
            right: 18px;
            top: 30px;
            z-index: 4;
            color: #252b3a;
            p{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 16px;
            }
            span{
                font-size: 14px;
            }
        }
    }
}
.module-title{
    margin-top: 36px;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        p{
            margin-left: 12px;
            font-size: 18px;
            color: #252b3a;
            font-weight: bold;
        }
    }
    .more{
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
    }
}
</style>
