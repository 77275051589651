<template>
    <div class="w12 fontBox">
        <div class="font-nav">
            <div
                class="font-nav-li"
                v-for="(item, index) in navList"
                :key="item.id"
                :class="{ 'active': activeIndex == index }"
                @click="changeNav(index)"
            >
                <span></span>
                <p>{{ item.typeTxt }}</p>
            </div>
        </div>
        <div class="list-news-box" v-if="activeIndex == 0">
            <div class="list-news" @click="enterDetails('project01')">
                <img src="~assets/images/case/img1.png" alt="">
                <div class="case-news-box">
                    <img src="~assets/images/case/logoImg1.png" alt="">
                    <div class="projectName">
                        <p>项目名：</p>
                        <span>业务信息化系统</span>
                    </div>
                    <div class="inText">
                        <p>项目概况：</p>
                        <span>
                            为创建知识型企业、打造学习型职工，随着业务精细化管理的不断深入，
                            企业对信息化的应用需求也逐渐增长。有了众多精细化管理业务系统的
                            建设和运行，大大提高了企业的信息化水平，在这种情况下实现内部业
                            务流程无纸化办公是公司在当前信息化部
                            署实施的重点。
                        </span>
                    </div>
                    <div class="caseTypeBox">
                        <p>交通运输</p>
                    </div>
                </div>
            </div>
            <div class="list-news"  @click="enterDetails('project02')">
                <img src="~assets/images/case/img2.png" alt="">
                <div class="case-news-box">
                    <img src="~assets/images/case/logoImg2.png" alt="">
                    <div class="projectName">
                        <p>项目名：</p>
                        <span>农民工实名管理系统</span>
                    </div>
                    <div class="inText">
                        <p>项目概况：</p>
                        <span>
                            农民工实名管理系统主要解决了公路交通、建设施工等领域开放式施工现场的农
                            民工劳务人员的实名登记、安全培训、考试、档案管理、考勤管理、劳务人员
                            在岗实名核查等棘手问题。
                        </span>
                    </div>
                    <div class="caseTypeBox">
                        <p>交通运输</p>
                    </div>
                </div>
            </div>
            <div class="list-news" @click="enterDetails('project04')">
                <img src="~assets/images/case/img4.jpg" alt="">
                <div class="case-news-box">
                    <img src="~assets/images/case/logoImg1.png" alt="">
                    <div class="projectName">
                        <p>项目名：</p>
                        <span>内蒙古公路交通信息化平台</span>
                    </div>
                    <div class="inText">
                        <p>项目概况：</p>
                        <span>
                            该软件系统的建设主要为解决公路建设项目前期基础信息采集工作存在不规范、
                            不统一、信息不对称、沟通不畅、重复劳动等问题，满足公司信息化平台建设
                            以及在建项目资料归档需求，实现总公司所辖各路段技术信息标准化管理，
                            便于项目后期管养建设过程中能够快速精确查找所需资料，节省文档交流成本
                            ，提高工作效率。
                        </span>
                    </div>
                    <div class="caseTypeBox">
                        <p>交通运输</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-news-box" v-if="activeIndex == 1 || activeIndex == 3">
            <div class="list-news" @click="enterDetails('project03')">
                <img src="~assets/images/case/img3.png" alt="">
                <div class="case-news-box">
                    <img src="~assets/images/case/logoImg3.png" alt="">
                    <div class="projectName">
                        <p>项目名：</p>
                        <span>网络培训及考试系统</span>
                    </div>
                    <div class="inText">
                        <p>项目概况：</p>
                        <span>
                            随着计算机信息化日新月异的发展，计算机网络发挥了越来越大的作用。
                            如何更合理、更高效地将计算机网络信息的高效性应用到我们教育教学
                            学习考试上，一直是我们持续关注、研发的主题和目标。
                        </span>
                    </div>
                    <div class="caseTypeBox">
                        <p>教育</p>
                        <p>科技互联网</p>
                    </div>
                </div>
            </div>
            <div class="list-news" @click="enterDetails('project06')">
                <img src="~assets/images/case/img6.png" alt="">
                <div class="case-news-box">
                    <img src="~assets/images/case/logoImg6.png" alt="">
                    <div class="projectName">
                        <p>项目名：</p>
                        <span>《江格尔》声像图文数据库</span>
                    </div>
                    <div class="inText">
                        <p>项目概况：</p>
                        <span>
                            随着全球化趋势的增强，经济和社会的急剧变迁，《江格尔》的生存、保护和发展也遇到了新的情况和问题，
                            形势十分严峻。著名的民间艺人有的已经过世，在世的也都已经年届高龄，面临着“人亡歌息”的危险。
                            因此《江格尔》声像图文数据库项目建立是对《江格尔》传承人和资料的抢救和保护工作，必须抓紧，
                            以使这部宝贵的史诗长唱于世间。
                        </span>
                    </div>
                    <div class="caseTypeBox">
                        <p>政务</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-news-box" v-if="activeIndex == 2">
            <div class="list-news" @click="enterDetails('project05')">
                <img src="~assets/images/case/img5.png" alt="">
                <div class="case-news-box">
                    <img src="~assets/images/case/logoImg5.png" alt="">
                    <div class="projectName">
                        <p>项目名：</p>
                        <span>内蒙古自治区非物质文化遗产保护中心</span>
                    </div>
                    <div class="inText">
                        <p>项目概况：</p>
                        <span>
                            《内蒙古蒙古族非物质文化遗产数据库建设——民间文学类数据库》建设的社会价值在于非物质文化遗产的保护、
                            传承、创新发展首次运用了新信息传播技术手段，面向全世界传播蒙古族优秀的非物质文化遗产，这具有
                            极其重要的意义。特别是保障少数民族文化安全、信息安全、边疆安全、西部少数民族社会的稳定均具有无
                            法用经济效益计算和衡量的巨大社会效益。
                        </span>
                    </div>
                    <div class="caseTypeBox">
                        <p>政务</p>
                    </div>
                </div>
            </div>
            <div class="list-news" @click="enterDetails('project07')">
                <img src="~assets/images/case/img7.png" alt="">
                <div class="case-news-box">
                    <img src="~assets/images/case/logoImg7.png" alt="">
                    <div class="projectName">
                        <p>项目名：</p>
                        <span>中国审判法律应用支持系统（蒙古文版）“法信蒙古文版</span>
                    </div>
                    <div class="inText">
                        <p>项目概况：</p>
                        <span>
                           为深入贯彻落实十九大对于全面依法治国提出的新要求，更好地服务以审判为中心的诉讼制度改革，
                           更好的服务民族地区法治建设发展，促进法治理念传播与法治文化建设，力争将“法信”蒙古文版打
                           造成致力于为全国少数民族法官、当事人以及少数民族群众使用本民族语言开展司法审判，参与诉讼
                           服务，交流审判经验，汇聚审判资源的综合性法律知识服务平台。
                        </span>
                    </div>
                    <div class="caseTypeBox">
                        <p>政务</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-news-box" v-if="activeIndex == 4">
            <el-empty description="暂无数据"></el-empty>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
export default {
    data() {
        return {
            activeIndex: 0,
            details:{},
            trueTypeCode:'',
            trueLoadPath:'',
            visible:false,
            showImg:'',
            showTitle:'',
            num:0,
            token: PcCookie.get(Key.tokenKey),
            navList:[
                {
                    id:0,
                    typeTxt:'交通运输'
                },
                {
                    id:1,
                    typeTxt:'教育'
                },
                {
                    id:2,
                    typeTxt:'政务&企业'
                },
                {
                    id:3,
                    typeTxt:'科技互联网'
                },
                {
                    id:4,
                    typeTxt:'其他'
                }
            ]
        };
    },
    methods:{
        changeNav(index) {
            this.activeIndex = index
        },
        enterDetails(id){
            this.$router.push({
                path:'/case-details',
                query:{
                    projectId:id
                }
            })
        }
    }
};
</script>
<style lang="less" scoped>
.case-news-box{
    flex: 1;
    height: 100%;
    margin-left: 30px;
    >img{
        margin-bottom: 8px;
        height: 50px;
    }
    .projectName{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        font-weight: bold;
        color: #252b3a;
        margin-bottom: 8px;
        p{
            width: 72px;
        }
    }
    .inText{
        height: 108px;
        font-size: 14px;
        color: #252b3a;
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        p{
            font-weight: bold;
        }
        span{
            width: 72px;
            flex: 1;
            line-height: 22px;
            height: 110px;
            overflow: hidden;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
        }
    }
    .caseTypeBox{
        display: flex;
        flex-wrap: wrap;
        p{
            padding: 0 36px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            font-weight: bold;
            color: #1b4094;
            background: #e0eaff;
            border-radius: 15px;
            margin: 0 4px;
        }
    }
}
.list-news{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 276px;
    padding: 13px 16px;
    background: #fff;
    margin-bottom: 28px;
    box-shadow: 0px 0px 6px  rgba(0, 0, 0, .2);
    cursor: pointer;
    >img{
        height: 246px;
        width: 286px;
    }
}
.fontBox {
    margin-top: 98px;
    padding-bottom: 20px;
    overflow: hidden;
    .font-nav {
        float: left;
        width: 228px;
        padding: 0 10px;
        border-right: solid 1px #dadada;
        .font-nav-li {
            cursor: pointer;
            padding: 0 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 65px;
            font-style: 14px;
            color: #252b3a;
            border-bottom: solid 1px #d2d2d2;
            span {
                margin-right: 14px;
                width: 8px;
                height: 8px;
                background: #dfe9ff;
                border-radius: 50%;
            }
        }
        .font-nav-li.active {
            color: #1b4094;
            background: -webkit-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: -o-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: -moz-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: linear-gradient(to right, #f3f5f8, #cfdcfa);
            span {
                background: #1b4094;
            }
        }
    }
    .list-news-box {
        padding: 6px ;
        margin-left: 310px;
    }
}
</style>
