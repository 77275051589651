<template>
    <div class="aboutUs" @click.stop>
        <Introduction />
        <div class="companyNews">
            <Modular path="/home-news-list" newType="article">
                <p slot="title">最新资讯</p>
                <AboutBar slot="list">
                    <AboutTabBar
                        v-for="item in articleList"
                        :key="item.id"
                        path="/home-article-details"
                        :id="item.id"
                    >
                        <span slot="newsText">{{ item.title }}</span>
                    </AboutTabBar>
                </AboutBar>
            </Modular>
            <Modular path="/about-recruit">
                <p slot="title">加入我们</p>
                <AboutBar slot="list">
                    <AboutTabBar
                        v-for="item in recruitList"
                        :key="item.id"
                        path="/about-recruit-details"
                        :id="item.id"
                    >
                        <span slot="newsText">招聘{{ item.duties }}({{item.salary}})</span>
                    </AboutTabBar>
                </AboutBar>
            </Modular>
            <Modular path="/home-activity-list" newType="activity">
                <p slot="title">最新活动</p>
                <AboutBar slot="list" v-if="activityList.length == 0">暂无数据</AboutBar>
                <AboutBar slot="list"  v-if="activityList.length != 0">
                    <AboutTabBar
                        v-for="item in activityList"
                        :key="item.id"
                        path="/home-activity-details"
                        :id="item.id"
                    >
                        <img :src="item.imageUrl" alt="" slot="pictureNews" />
                    </AboutTabBar>
                </AboutBar>
            </Modular>
        </div>
    </div>
</template>
<script>
import { getArticleList } from "api/article";
import { getRecruitList } from 'api/recruit';
import { getActivityList } from "api/activity";
import Introduction from "components/contents/about/introduction";
import Modular from "components/contents/about/modular";
import AboutBar from "components/common/aboutbar/AboutBar";
import AboutTabBar from "components/common/aboutbar/AboutTabBar";
export default {
    components: { Introduction, Modular, AboutBar, AboutTabBar },
    data() {
        return {
            articleList:[],
            articlePage:{
                total:0,
                pageNo:1,
                pageSize:7
            },
            recruitPage:{
                total:0,
                pageNo:1,
                pageSize:7
            },
            activityPage:{
                total:0,
                pageNo:1,
                pageSize:2
            },
            deptId:"",
            recruitList: [],
            activityList: [],
        };
    },
    created() {
        this.fetchData()
        this.fetchRecruit()
        this.fetchactivityData()
    },
    methods: {
        fetchRecruit(){
            getRecruitList(this.deptId, this.recruitPage.pageNo,this.recruitPage.pageSize).then(res => {
                if(res.errorCode == '2000'){
                    this.recruitList = res.body.data.list
                }
            })
        },
        fetchData(){
            getArticleList(
                this.articlePage.pageNo,
                this.articlePage.pageSize
            ).then((res) => {
                if (res.success && res.errorCode === '2000') {
                    this.articleList = res.body.data.list
                }
            });
        },
        fetchactivityData(){
            getActivityList(
                this.activityPage.pageNo,
                this.activityPage.pageSize
            ).then((res) => {
                if (res.success && res.errorCode === '2000') {
                    this.activityList = res.body.data.list
                    this.hasArticle = true
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.aboutUs {
    display: flex;
    flex-direction: row;
    height: 340px;
}
.companyNews {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
}
</style>
