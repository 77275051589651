<template>
    <div class="menkEditor">
        <quill-editor
            ref="myQuillEditor"
            v-model="content"
            :options="editorOption"
            :placeholder="editorOption.placeholder"
            @change="onEditorChange"
        />
    </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor, Quill } from "vue-quill-editor";
let FontFamily = [
    "Menksoft2012",
    "MenkGarqag",
    "MenkHara",
    "MenkSonin",
    "MenkHawang",
    "MenkAmglang",
    "MenkNarin",
    "MenkBudun",
    "MenkQimig",
    "MenkQimed",
    "MenkScnin",
    "MenkTenigun",
];
const QuillFontFamily = Quill.import("attributors/style/font");
QuillFontFamily.whitelist = FontFamily;
Quill.register(QuillFontFamily, true);
const FontSize = [
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "30px",
    "32px",
    "36px",
];
const QuillFontSize = Quill.import("attributors/style/size");
QuillFontSize.whitelist = FontSize;
Quill.register(QuillFontSize, true);
const QuillAlign = Quill.import('attributors/style/align');
QuillAlign.whitelist = ['right', 'center', 'justify'];
Quill.register(QuillAlign, true);
const toolbarOptions = [
    [
        "bold",
        "italic",
        'strike',
        {list: "ordered"},
        {list: "bullet"},
        {'align':['right', 'center', 'justify']},
        {font: FontFamily},
        {size: FontSize},

        {color: []},
        "clean"
    ],
];
export default {
    props:{
        clearText:{
            type:String,
            default:''
        }
    },
    components: {
        quillEditor,
    },
    data() {
        return {
            content: "",
            editorOption: {
                modules: {
                    toolbar: toolbarOptions,
                },
                theme: "snow",
                placeholder: "",
            },
        };
    },
    methods: {
        onEditorChange(){
            this.$emit('contentChange',this.content)
        },
    },
    watch:{
        clearText(val){
            this.content = val
        }
    }
};
</script>
<style>
/* 字体大小 */
.ql-snow .ql-picker.ql-size .ql-picker-label::before {
    font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: "16px";
    font-size: 16px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: "18px";
    font-size: 18px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
    content: "20px";
    font-size: 20px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
    content: "22px";
    font-size: 22px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
    content: "24px";
    font-size: 24px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
    content: "26px";
    font-size: 26px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
    content: "30px";
    font-size: 30px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
    content: "32px";
    font-size: 32px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
    content: "36px";
    font-size: 36px;
}
.ql-size-36px {
    font-size: 36px;
}
.ql-size-32px {
    font-size: 32px;
}
.ql-size-30px {
    font-size: 30px;
}
.ql-size-26px {
    font-size: 26px;
}
.ql-size-24px {
    font-size: 24px;
}
.ql-size-22px {
    font-size: 22px;
}
.ql-size-20px {
    font-size: 20px;
}
.ql-size-18px {
    font-size: 18px;
}
.ql-size-16px {
    font-size: 16px;
}
</style>
<style>
/* 字体库 */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Menksoft2012"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Menksoft2012"]::before {
    content: "Menksoft2012";
    font-family: "Menksoft2012" !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkGarqag"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkGarqag"]::before {
    content: "MenkGarqag";
    font-family: "MenkGarqag";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkHara"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkHara"]::before {
    content: "MenkHara";
    font-family: "MenkHara";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkSonin"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkSonin"]::before {
    content: "MenkSonin";
    font-family: "MenkSonin" !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkHawang"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkHawang"]::before {
    content: "MenkHawang";
    font-family: "MenkHawang";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkAmglang"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkAmglang"]::before {
    content: "MenkAmglang";
    font-family: "MenkAmglang";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkNarin"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkNarin"]::before {
    content: "MenkNarin";
    font-family: "MenkNarin";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkBudun"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkBudun"]::before {
    content: "MenkBudun";
    font-family: "MenkBudun";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkQimig"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkQimig"]::before {
    content: "MenkQimig";
    font-family: "MenkQimig";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkQimed"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkQimed"]::before {
    content: "MenkQimed";
    font-family: "MenkQimed";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkScnin"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkScnin"]::before {
    content: "MenkScnin";
    font-family: "MenkScnin";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="MenkTenigun"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="MenkTenigun"]::before {
    content: "MenkTenigun";
    font-family: "MenkTenigun";
}
.ql-font-Menksoft2012 {
    font-family: "Menksoft2012";
}
.ql-font-MenkGarqag {
    font-family: "MenkGarqag";
}
.ql-font-MenkHara {
    font-family: "MenkHara";
}
.ql-font-MenkSonin {
    font-family: "MenkSonin";
}
.ql-font-MenkHawang {
    font-family: "MenkHawang";
}
.ql-font-MenkAmglang {
    font-family: "MenkAmglang";
}
.ql-font-MenkNarin {
    font-family: "MenkNarin";
}
.ql-font-MenkBudun {
    font-family: "MenkBudun";
}
.ql-font-MenkQimig {
    font-family: "MenkQimig";
}
.ql-font-MenkQimed {
    font-family: "MenkQimed";
}
.ql-font-MenkScnin {
    font-family: "MenkScnin";
}
.ql-font-MenkTenigun {
    font-family: "MenkTenigun";
}
.ql-snow .ql-picker.ql-font {
    width: 132px;
}
</style>
<style lang="less">
.menkEditor .ql-container {
    flex: 1;
}
.menkEditor .ql-picker-label{
    border:solid 1px #ccc !important
}
.ql-container {
    overflow-x: auto;
}
.ql-editor {
    font-family: "Menksoft2012";
    font-size: 18px;
    -webkit-writing-mode: vertical-lr;
    -webkit-text-orientation: sideways-right;
    writing-mode: vertical-lr;
    cursor: vertical-text;
    overflow-y: hidden;
    min-width: 100%;
}
</style>
<style lang="less" scoped>
.menkEditor {
    height: 100%;
}
.quill-editor {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
}
</style>

<style>
@import "../../../assets/css/editorFont.css";
</style>
