<template>
    <div
        id="applicationBox"
        v-loading="loading"
        element-loading-text="内容翻译中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
    >
        <!-- 7天翻译历史记录开始 -->
        <div
            class="tHistoryBtn"
            :class="{ show: showHistory }"
            @click="showHistory = !showHistory"
        >
            <img
                src="~assets/images/product/tHistoryBtnImg.png"
                class="tHistoryBtnImg"
                alt=""
            />
            <div class="tHistoryBtnContent">
                <p v-if="!showHistory">历史记录</p>
                <img
                    src="~assets/images/product/tShowImg.png"
                    v-if="!showHistory"
                    alt=""
                />
                <p v-if="showHistory">收起</p>
                <img
                    src="~assets/images/product/tHideImg.png"
                    v-if="showHistory"
                    alt=""
                />
            </div>
        </div>
        <div class="tHistoryBox" v-if="showHistory">
            <div class="tHistoryContent">
                <div class="tHistoryTasBox">
                    <div class="tHistoryModuleTitle">
                        <img
                            src="~assets/images/product/historyImg.png"
                            alt=""
                        />
                        <div>
                            <h4>历史记录</h4>
                            <p>最近7天的使用记录</p>
                        </div>
                    </div>
                    <div class="tHistoryTas">
                        <p
                            :class="{ active: historyType == 0 }"
                            @click="historyType = 0"
                        >
                            文本翻译
                        </p>
                        <p
                            :class="{ active: historyType == 1 }"
                            @click="historyType = 1"
                        >
                            文档翻译
                        </p>
                    </div>
                </div>
                <div id="tHistoryListBox">
                    <!-- 历史文本翻译列表 -->
                    <div class="historyListBox" v-if="historyType == 0">
                        <!-- 文本翻译模块 -->
                        <div
                            v-for="(keyItem, keyIndex) in historyTextList"
                            :key="keyIndex"
                            class="hisItemBox"
                        >
                            <div
                                v-for="(dataItem, key, dataIndex) in keyItem"
                                :key="dataIndex"
                            >
                                <div class="historyItem">
                                    <div
                                        class="textHistoryDate"
                                        @click="
                                            toggleHistoryItem(key, 0, keyIndex)
                                        "
                                    >
                                        <p>{{ key }}</p>
                                        <i
                                            :class="
                                                dataItem.isActive
                                                    ? 'el-icon-arrow-up'
                                                    : 'el-icon-arrow-down'
                                            "
                                        ></i>
                                    </div>
                                    <div v-if="dataItem.isActive">
                                        <div
                                            class="historyItemContent"
                                            v-for="(
                                                item, index
                                            ) in dataItem.dataList"
                                            :key="index"
                                        >
                                            <p class="historyItemSpot"></p>
                                            <div
                                                class="historyItemTimeDownload"
                                            >
                                                <div class="historyItemTime">
                                                    <span>{{
                                                        item.timePart
                                                    }}</span>
                                                    <div>
                                                        <p
                                                            class="mw"
                                                            v-if="
                                                                item.f == 'mw'
                                                            "
                                                        >
                                                            蒙
                                                        </p>
                                                        <p
                                                            class="zh"
                                                            v-if="
                                                                item.f == 'zh'
                                                            "
                                                        >
                                                            汉
                                                        </p>
                                                        <i
                                                            class="
                                                                el-icon-right
                                                            "
                                                        ></i>
                                                        <p
                                                            class="mw"
                                                            v-if="
                                                                item.t == 'mw'
                                                            "
                                                        >
                                                            蒙
                                                        </p>
                                                        <p
                                                            class="zh"
                                                            v-if="
                                                                item.t == 'zh'
                                                            "
                                                        >
                                                            汉
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="historyDetails">
                                                <div class="originalTextBox">
                                                    <div
                                                        class="originalTextTop"
                                                    >
                                                        <h6>原文</h6>
                                                        <div
                                                            :id="'ori' + index"
                                                            :data-clipboard-text="
                                                                item.ori
                                                            "
                                                            @click="
                                                                copyHistorytext(
                                                                    $event,
                                                                    'ori' +
                                                                        index
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    el-icon-document-copy
                                                                "
                                                            ></i>
                                                            <span>复制</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="
                                                            originalText
                                                            mkBox
                                                        "
                                                        v-if="item.f == 'mw'"
                                                    >
                                                        <div
                                                            class="
                                                                menkText
                                                                hisMenkText
                                                            "
                                                        >
                                                            {{ item.ori }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="originalText zh"
                                                        v-else
                                                    >
                                                        {{ item.ori }}
                                                    </div>
                                                </div>
                                                <div class="originalTextBox">
                                                    <div
                                                        class="originalTextTop"
                                                    >
                                                        <h6>译文</h6>
                                                        <div
                                                            :id="'dst' + index"
                                                            :data-clipboard-text="
                                                                item.dst
                                                            "
                                                            @click="
                                                                copyHistorytext(
                                                                    $event,
                                                                    'dst' +
                                                                        index
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    el-icon-document-copy
                                                                "
                                                            ></i>
                                                            <span>复制</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="
                                                            originalText
                                                            mkBox
                                                        "
                                                        v-if="item.t == 'mw'"
                                                    >
                                                        <div
                                                            class="
                                                                menkText
                                                                hisMenkText
                                                            "
                                                        >
                                                            {{ item.dst }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="originalText zh"
                                                        v-else
                                                    >
                                                        {{ item.dst }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 文本翻译模块结束 -->
                    </div>
                    <!-- 文档翻译列表 -->
                    <div class="historyListBox" v-if="historyType == 1">
                        <div class="textHistoryList">
                            <div
                                v-for="(keyItem, keyIndex) in historyFileList"
                                :key="keyIndex"
                            >
                                <div
                                    v-for="(
                                        dataItem, key, dataIndex
                                    ) in keyItem"
                                    :key="dataIndex"
                                >
                                    <div class="historyItem">
                                        <div
                                            class="textHistoryDate"
                                            @click="
                                                toggleHistoryItem(
                                                    key,
                                                    1,
                                                    keyIndex
                                                )
                                            "
                                        >
                                            <p>{{ key }}</p>
                                            <i
                                                :class="
                                                    dataItem.isActive
                                                        ? 'el-icon-arrow-up'
                                                        : 'el-icon-arrow-down'
                                                "
                                            ></i>
                                        </div>
                                        <div v-if="dataItem.isActive">
                                            <div
                                                class="historyItemContent"
                                                v-for="(
                                                    item, index
                                                ) in dataItem.dataList"
                                                :key="index"
                                            >
                                                <p class="historyItemSpot"></p>
                                                <div
                                                    class="
                                                        historyItemTimeDownload
                                                    "
                                                >
                                                    <div
                                                        class="historyItemTime"
                                                    >
                                                        <span>{{
                                                            item.timePart
                                                        }}</span>
                                                        <div>
                                                            <p
                                                                class="mw"
                                                                v-if="
                                                                    item.f ==
                                                                    'mw'
                                                                "
                                                            >
                                                                蒙
                                                            </p>
                                                            <p
                                                                class="zh"
                                                                v-if="
                                                                    item.f ==
                                                                    'zh'
                                                                "
                                                            >
                                                                汉
                                                            </p>
                                                            <i
                                                                class="
                                                                    el-icon-right
                                                                "
                                                            ></i>
                                                            <p
                                                                class="mw"
                                                                v-if="
                                                                    item.t ==
                                                                    'mw'
                                                                "
                                                            >
                                                                蒙
                                                            </p>
                                                            <p
                                                                class="zh"
                                                                v-if="
                                                                    item.t ==
                                                                    'zh'
                                                                "
                                                            >
                                                                汉
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="
                                                            historyItemDownload
                                                        "
                                                    >
                                                        <div
                                                            @click="
                                                                downloadHistoryFile(
                                                                    item,
                                                                    'ori'
                                                                )
                                                            "
                                                        >
                                                            <img
                                                                src="~assets/images/product/historyFileImg.png"
                                                                alt=""
                                                            />
                                                            <p>原文下载</p>
                                                        </div>
                                                        <div
                                                            @click="
                                                                downloadHistoryFile(
                                                                    item,
                                                                    'dst'
                                                                )
                                                            "
                                                        >
                                                            <img
                                                                src="~assets/images/product/historyFileImg.png"
                                                                alt=""
                                                            />
                                                            <p>译文下载</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 7天翻译历史记录结束 -->
        <!--文档翻译-->
        <div class="w12 h100" v-if="isFileUpload">
            <div class="fileTranslateHeader">
                <el-button
                    size="small"
                    style="margin-right: 16px"
                    @click="isFileUpload = false"
                    ><i class="el-icon-arrow-left"></i>返回</el-button
                >
                <el-upload
                    class="avatar-uploader"
                    accept=".doc,.docx,.rtf,.txt,.wps"
                    action="#"
                    :http-request="uploadFile"
                    :show-file-list="false"
                >
                    <el-button size="small">上传新文档</el-button>
                </el-upload>
            </div>
            <div class="fileTranslateBox">
                <div class="translateBox">
                    <div class="applicationBtnBox">
                        <p>{{ left == 0 ? "汉语" : "传统蒙古文" }}</p>
                        <img
                            src="~assets/images/transformationImg.png"
                            alt=""
                            @click="changeType"
                        />
                        <p>{{ right == 0 ? "传统蒙古文" : "汉语" }}</p>
                    </div>
                    <div class="translateContent">
                        <img
                            src="~assets/images/product/fanyiImg.png"
                            alt=""
                            class="fanyiImg"
                            @click="goFileTrans"
                        />
                        <p class="backImg"></p>
                        <div class="translate-text-box" :style="{ left: left }">
                            <div class="cText textBox">
                                <!--汉文~蒙文-->
                                <div
                                    class="flieName"
                                    v-if="isFileName && from == 'zh'"
                                >
                                    <i class="el-icon-document"></i>
                                    {{ fileName }}
                                </div>
                                <div
                                    class="fileTextBox"
                                    v-if="!isFileName"
                                    id="zhFileList"
                                >
                                    <p
                                        v-for="(item, index) in cFileTextList"
                                        :key="index"
                                        @mouseenter="textHighlight(index, 'zh')"
                                        @mouseleave="textDarken(index)"
                                    >
                                        {{ item }}
                                    </p>
                                </div>
                            </div>
                            <div
                                class="operationBox"
                                :class="left == 0 ? 'back1' : 'back2'"
                            >
                                <div v-if="left == '50%'" class="rightIcon">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="复制翻译文字132"
                                        placement="top"
                                    >
                                        <i
                                            id="zhf"
                                            class="el-icon-document-copy"
                                            :data-clipboard-text="zhFileText"
                                            @click="copytext('zhf')"
                                        ></i>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div
                            class="translate-text-box"
                            :style="{ right: right }"
                        >
                            <!--蒙文~汉文-->
                            <div
                                class="flieName"
                                v-if="isFileName && from == 'mw'"
                            >
                                <i class="el-icon-document"></i>
                                {{ fileName }}
                            </div>
                            <div class="menkTextBox" id="mwFileList">
                                <div class="">
                                    <p
                                        v-for="(item, index) in mFileTextList"
                                        :key="index"
                                        @mouseenter="textHighlight(index, 'mw')"
                                        @mouseleave="textDarken(index)"
                                    >
                                        {{ item }}
                                    </p>
                                </div>
                            </div>
                            <div
                                v-if="right == 0"
                                class="operationBox"
                                :class="right == 0 ? 'back2' : 'back1'"
                            >
                                <div v-if="right == 0" class="rightIcon">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="复制翻译文字"
                                        placement="top"
                                    >
                                        <i
                                            class="el-icon-document-copy"
                                            :data-clipboard-text="mFileText"
                                            id="mwf"
                                            @click="copytext('mwf')"
                                        ></i>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filePromptText">
                <span>*</span>
                <p>
                    注意：机器翻译工具一次最多可翻译30000字的中文.docx、doc、.txt、.wps、rtf格式文档，如果您要翻译的字数过多，请将文档分成多个小于三万字的文件，分批次上传。
                </p>
            </div>
        </div>
        <!--文本翻译-->
        <div class="w12 h100 contentBox" v-if="!isFileUpload">
            <div class="translateBox">
                <div class="applicationBtnBox">
                    <p>{{ left == 0 ? "汉语" : "传统蒙古文" }}</p>
                    <img
                        src="~assets/images/transformationImg.png"
                        alt=""
                        @click="changeType"
                    />
                    <p>{{ right == 0 ? "传统蒙古文" : "汉语" }}</p>
                </div>
                <div class="translateContent">
                    <img
                        src="~assets/images/product/fanyiImg.png"
                        alt=""
                        class="fanyiImg"
                        @click="goTrans"
                    />
                    <p class="backImg"></p>
                    <div class="translate-text-box" :style="{ left: left }">
                        <div class="cText textBox">
                            <i
                                class="el-icon-close"
                                v-if="left == 0"
                                @click="clearText('cText')"
                            ></i>
                            <el-input
                                type="textarea"
                                class="h100"
                                :class="left == 0 ? 'pright34' : 'pleft34'"
                                v-model="cText"
                                :placeholder="left == 0 ? '请输入内容' : ''"
                                :maxlength="maxlength"
                                :disabled="left == '50%' ? true : false"
                                clearable
                            ></el-input>
                        </div>
                        <div
                            class="operationBox"
                            :class="left == 0 ? 'back1' : 'back2'"
                        >
                            <div v-if="left == 0" class="uploadDoc">
                                <el-upload
                                    class="avatar-uploader"
                                    accept=""
                                    action="#"
                                    :http-request="uploadFile"
                                    :show-file-list="false"
                                >
                                    <i class="el-icon-upload"></i>
                                    <p class="changeImg">上传文档</p>
                                </el-upload>
                            </div>
                            <div></div>
                            <div v-if="left == 0" class="num">
                                {{ nowNumc }}/{{ maxlength }}
                            </div>
                            <div v-if="left == '50%'" class="rightIcon">
                                <!-- <i class="el-icon-refresh"></i> -->
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="复制翻译文字"
                                    placement="top"
                                >
                                    <i
                                        id="zh"
                                        class="el-icon-document-copy"
                                        :data-clipboard-text="cText"
                                        @click="copytext('zh')"
                                    ></i>
                                </el-tooltip>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="意见反馈1"
                                    placement="top"
                                >
                                    <i
                                        class="el-icon-edit-outline"
                                        @click="showQuestin('mw')"
                                    ></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="translate-text-box" :style="{ right: right }">
                        <div class="mText textBox">
                            <i
                                class="el-icon-close"
                                v-if="right == '50%'"
                                @click="clearText('mText')"
                            ></i>
                            <el-input
                                type="textarea"
                                class="h100 menk"
                                :class="right == 0 ? 'pleft34' : 'pright34'"
                                v-model="mText"
                                :placeholder="
                                    right == '50%'
                                        ? '    '
                                        : ''
                                "
                                :maxlength="maxlength"
                                :disabled="right == 0 ? true : false"
                            ></el-input>
                        </div>
                        <div
                            class="operationBox"
                            :class="right == 0 ? 'back2' : 'back1'"
                        >
                            <div v-if="right == '50%'" class="uploadDoc">
                                <el-upload
                                    class="avatar-uploader"
                                    accept=""
                                    action="#"
                                    :http-request="uploadFile"
                                    :show-file-list="false"
                                >
                                    <i class="el-icon-upload"></i>
                                    <p class="changeImg">上传文档</p>
                                </el-upload>
                            </div>
                            <div style="color: red" v-if="right == '50%'">
                                注意事项：目前机器翻译只支持国家标准编码蒙古文！
                            </div>
                            <div v-if="right == '50%'" class="num">
                                {{ nowNumm }}/{{ maxlength }}
                            </div>
                            <div v-if="right == 0" class="rightIcon">
                                <!-- <i class="el-icon-refresh"></i> -->
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="复制翻译文字"
                                    placement="top"
                                >
                                    <i
                                        class="el-icon-document-copy"
                                        :data-clipboard-text="mText"
                                        id="mw"
                                        @click="copytext('mw')"
                                    ></i>
                                </el-tooltip>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="意见反馈"
                                    placement="top"
                                >
                                    <i
                                        class="el-icon-edit-outline"
                                        @click="showQuestin('zh')"
                                    ></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="questionSubmitBox" v-if="isShow">
            <div class="questionNewsBox">
                <div class="cQuestionBox">
                    <div class="cQuestionTop" style="margin: 0">
                        <p>翻译建议反馈</p>
                        <span>{{
                            languagesType == "zh" ? "汉文—蒙文" : "蒙文—汉文"
                        }}</span>
                        <i class="el-icon-close" @click="hideQuestion"></i>
                    </div>
                    <div class="cQuestionFormBox">
                        <el-form
                            ref="cQuestion"
                            :model="cQuestion"
                            label-width="126px"
                            label-position="top"
                            class="cQuestion"
                            v-if="languagesType == 'zh'"
                            :rules="rules"
                        >
                            <el-form-item label="错误类型:" prop="fbType">
                                <el-radio-group v-model="cQuestion.fbType">
                                    <el-radio label="1" :disabled="disabled"
                                        >用词不当</el-radio
                                    >
                                    <el-radio label="2" :disabled="disabled"
                                        >语法错误</el-radio
                                    >
                                    <el-radio label="3" :disabled="disabled"
                                        >其他</el-radio
                                    >
                                </el-radio-group>
                            </el-form-item>
                            <el-row
                                class="h260"
                                type="flex"
                                justify="space-between"
                            >
                                <el-col :span="11">
                                    <el-form-item
                                        label="原文（汉）:"
                                        class="h260"
                                        prop="origContent"
                                    >
                                        <el-input
                                            type="textarea"
                                            v-model="cQuestion.origContent"
                                            maxlength="100"
                                            :disabled="disabled"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11">
                                    <el-form-item
                                        label="建议译文（蒙）:"
                                        class="h260"
                                        prop="destContent"
                                    >
                                        <el-input
                                            class="menk"
                                            type="textarea"
                                            v-model="cQuestion.destContent"
                                            maxlength="100"
                                            :disabled="disabled"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                        <el-form
                            ref="mQuestion"
                            :model="mQuestion"
                            label-width="130px"
                            label-position="top"
                            class="mQuestion"
                            v-if="languagesType == 'mw'"
                            :rules="rules"
                        >
                            <el-form-item label="错误类型:" prop="fbType">
                                <el-radio-group v-model="mQuestion.fbType">
                                    <el-radio
                                        label="用词不当"
                                        value="1"
                                        :disabled="disabled"
                                    ></el-radio>
                                    <el-radio
                                        label="语法错误"
                                        value="2"
                                        :disabled="disabled"
                                    ></el-radio>
                                    <el-radio
                                        label="其他"
                                        value="3"
                                        :disabled="disabled"
                                    ></el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-row
                                class="h290"
                                type="flex"
                                justify="space-between"
                            >
                                <el-col :span="11">
                                    <el-form-item
                                        label="原文（蒙）:"
                                        prop="origContent"
                                    >
                                        <el-input
                                            class="menk"
                                            type="textarea"
                                            v-model="mQuestion.origContent"
                                            maxlength="100"
                                            :disabled="disabled"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11">
                                    <el-form-item
                                        label="建议译文（汉）:"
                                        prop="destContent"
                                    >
                                        <el-input
                                            type="textarea"
                                            v-model="mQuestion.destContent"
                                            maxlength="100"
                                            :disabled="disabled"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                        <el-row
                            type="flex"
                            justify="center"
                            v-if="languagesType == 'zh'"
                        >
                            <el-button
                                type="primary"
                                @click="submitQestion('cQuestion')"
                                >提交</el-button
                            >
                        </el-row>
                        <el-row
                            type="flex"
                            justify="center"
                            v-if="languagesType == 'mw'"
                        >
                            <el-button
                                type="primary"
                                @click="submitQestion('mQuestion')"
                                :disabled="disabled"
                                >提交</el-button
                            >
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import Clipboard from "clipboard";
import {
    translate,
    translateFeedback,
    translateDoct,
    getTranslateHisroty,
} from "api/product";
import { isMgl } from "utils/fontCodeType";
export default {
    data() {
        return {
            loading: false,
            left: 0,
            right: 0,
            backImg: require("@/assets/images/product/backImg.jpg"),
            cText: "",
            mText: "",
            maxlength: 2000,
            nowNumc: 0,
            nowNumm: 0,
            from: "zh",
            to: "mw",
            disabled: false,
            productId: "",
            languagesType: "zh",
            userInfo: PcCookie.get(Key.userInfoKey)
                ? JSON.parse(PcCookie.get(Key.userInfoKey))
                : null,
            cQuestion: {
                destContent: "",
                fbType: "",
                fromLang: "",
                origContent: "",
                productId: "",
                toLang: "",
            },
            mQuestion: {
                destContent: "",
                fbType: "",
                fromLang: "",
                memberId: "",
                origContent: "",
                productId: "",
                toLang: "",
            },
            isShow: false,
            //文件上传
            isFileUpload: false,
            fielCustomPath: null,
            isFileName: false,
            fileName: "",
            cFileTextList: [],
            zhFileText: "",
            mFileTextList: [],
            mFileText: "",
            widthNum: 0,
            heightNum: 0,
            rules: {
                fbType: [
                    {
                        required: true,
                        message: "请选择错误类型",
                        trigger: "change",
                    },
                ],
                origContent: [
                    {
                        required: true,
                        message: "请输入内容",
                        trigger: "blur",
                    },
                ],
                destContent: [
                    {
                        required: true,
                        message: "请输入内容",
                        trigger: "blur",
                    },
                ],
            },
            showHistory: false,
            historyType: 0,
            historyTextList: [],
            historyFileList: [],
        };
    },
    mounted(){
        this.feachTranslateHisroty();
        this.setContainerStyles(0);
    },
    created() {
        this.productId = this.$route.query.productId;
        this.cQuestion.productId = this.$route.query.productId;
    },
    methods: {
        //下载历史翻译 -- 文档翻译
        downloadHistoryFile(item, fileSource) {
            let fileType = item[fileSource].substring(
                item[fileSource].lastIndexOf(".") + 1
            );
            if (fileType != "txt") {
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                iframe.style.height = 0;
                iframe.src = item[fileSource];
                document.body.appendChild(iframe);
                setTimeout(() => {
                    iframe.remove();
                }, 5 * 60 * 1000);
            } else {
                //txt文件下载
                const link = document.createElement("a");
                fetch(item[fileSource])
                    .then((res) => res.blob())
                    .then((blob) => {
                        link.href = URL.createObjectURL(blob);
                        link.download = "";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    });
            }
        },
        //复制历史翻译 -- 复制文字
        copyHistorytext(event, id) {
            let that = this;
            let clipboard = null;
            clipboard = new Clipboard("#" + id);
            clipboard.on("success", () => {
                that.$message.success("复制成功！");
                clipboard.destroy();
            });
            clipboard.on("error", () => {
                that.$message.success("复制失败！");
                clipboard.destroy();
            });
        },
        //容器赋高度并滚动到顶部
        setContainerStyles(containerIndex) {
            this.$nextTick(function () {
                var historyListBoxDoc =
                    document.getElementsByClassName("historyListBox");
                var menkTextDocList =
                    document.getElementsByClassName("hisMenkText");
                var mkDocList = document.getElementsByClassName("mkBox");
                if (menkTextDocList.length > 0) {
                    for (var i = 0; i < menkTextDocList.length; i++) {
                        mkDocList[i].style.height =
                            menkTextDocList[i].scrollHeight + 20 + "px";
                    }
                    historyListBoxDoc[0].scrollTop = (containerIndex - 1) * 28;
                }
            });
        },
        //显示隐藏历史记录列表Item
        toggleHistoryItem(key, historyType, containerIndex) {
            if (historyType == 0) {
                for (var i = 0; i < this.historyTextList.length; i++) {
                    var textFirstKey = Object.keys(this.historyTextList[i])[0];
                    if (key == textFirstKey) {
                        this.historyTextList[i][textFirstKey].isActive =
                            !this.historyTextList[i][textFirstKey].isActive;
                    } else {
                        this.historyTextList[i][textFirstKey].isActive = false;
                    }
                }
                this.setContainerStyles(containerIndex);
            } else {
                for (var j = 0; j < this.historyFileList.length; j++) {
                    var fileFirstKey = Object.keys(this.historyFileList[j])[0];
                    if (key == fileFirstKey) {
                        this.historyFileList[j][fileFirstKey].isActive =
                            !this.historyFileList[j][fileFirstKey].isActive;
                    } else {
                        this.historyFileList[j][fileFirstKey].isActive = false;
                    }
                }
            }
        },
        //获取历史翻译记录
        feachTranslateHisroty() {
            getTranslateHisroty().then((res) => {
                this.historyTextList = [];
                this.historyFileList = [];
                if (res.success) {
                    var feachData = res.body.data;
                    for (var key in feachData) {
                        var textArray = [];
                        var fileArray = [];
                        for (var i = 0; i < feachData[key].length; i++) {
                            if (feachData[key][i].fileTrans) {
                                fileArray.push(feachData[key][i]);
                            } else {
                                textArray.push(feachData[key][i]);
                            }
                        }
                        if (textArray.length > 0) {
                            this.historyTextList.push({
                                [key]: {
                                    isActive: false,
                                    dataList: textArray,
                                },
                            });
                        }
                        if (fileArray.length > 0) {
                            this.historyFileList.push({
                                [key]: {
                                    isActive: false,
                                    dataList: fileArray,
                                },
                            });
                        }
                    }
                    var textFirstKey = Object.keys(this.historyTextList[0])[0];
                    var fileFirstKey = Object.keys(this.historyFileList[0])[0];
                    this.historyTextList[0][textFirstKey].isActive = true;
                    this.historyFileList[0][fileFirstKey].isActive = true;
                }
            });
        },
        textHighlight(index, type) {
            this.widthNum = 0;
            this.heightNum = 0;
            var h = this.JQ("#zhFileList").height();
            var w = this.JQ("#mwFileList").width();
            this.JQ("#zhFileList").find("p").eq(index).addClass("hover");
            this.JQ("#mwFileList").find("p").eq(index).addClass("hover");
            if (index != 0) {
                for (var i = 0; i < index + 1; i++) {
                    this.widthNum += this.JQ("#mwFileList")
                        .find("p")
                        .eq(i)
                        .width();
                    this.heightNum += this.JQ("#zhFileList")
                        .find("p")
                        .eq(i)
                        .height();
                }
                if (type == "mw") {
                    if (this.widthNum - w > 0) {
                        this.JQ("#zhFileList").scrollTop(this.heightNum - h);
                    }
                } else {
                    if (this.widthNum - w > 0) {
                        this.JQ("#mwFileList").scrollLeft(this.widthNum - w);
                    }
                }
            } else {
                if (type == "mw") {
                    this.JQ("#zhFileList").scrollTop(0);
                } else {
                    this.JQ("#mwFileList").scrollLeft(0);
                }
            }
        },
        textDarken(index) {
            this.JQ("#zhFileList").find("p").eq(index).removeClass("hover");
            this.JQ("#mwFileList").find("p").eq(index).removeClass("hover");
        },
        goFileTrans() {
            this.loading = true;
            translateDoct(
                this.from,
                this.to,
                this.fielCustomPath,
                this.productId
            ).then((res) => {
                if (res.success) {
                    if (this.from == "zh") {
                        Object.keys(res.body.data).forEach((item) => {
                            this.cFileTextList.push(item);
                            this.mFileTextList.push(res.body.data[item]);
                            this.zhFileText += item + "\r\n";
                            this.mFileText += res.body.data[item] + "\r\n";
                        });
                    } else {
                        Object.keys(res.body.data).forEach((item) => {
                            this.mFileTextList.push(item);
                            this.cFileTextList.push(res.body.data[item]);
                            this.mFileText += item + "\r\n";
                            this.zhFileText += res.body.data[item] + "\r\n";
                        });
                    }
                    this.isFileName = false;
                    this.loading = false;
                } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            });
        },
        //文档上传
        uploadFile(data) {
            this.fileName = data.file.name;
            this.isFileUpload = true;
            let fileType = data.file.name.split(".")[1];
            this.getBase64(data.file).then((res) => {
                let typeText = res.substring(0, 128);
                let beginNum = typeText.indexOf("/");
                let endNum = typeText.indexOf(";");
                let replaceText = typeText.substring(beginNum + 1, endNum);
                let trueText = typeText.replace(replaceText, fileType);
                this.fielCustomPath = trueText + res.substring(128);
                this.mFileTextList = [];
                this.cFileTextList = [];
                this.mFileText = "";
                this.zhFileText = "";
                this.isFileName = true;
            });
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                let filePath = "";
                reader.readAsDataURL(file);
                reader.onload = function () {
                    filePath = reader.result;
                };
                reader.onerror = function (error) {
                    reject(error);
                };
                reader.onloadend = function () {
                    resolve(filePath);
                };
            });
        },
        submitQestion(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.disabled = true;
                    if (this.languagesType == "zh") {
                        this.cQuestion.fromLang = "zh";
                        this.cQuestion.toLang = "mw";
                    } else {
                        this.cQuestion.fromLang = "mw";
                        this.cQuestion.toLang = "zh";
                    }
                    translateFeedback(this.cQuestion).then((res) => {
                        if (res.success) {
                            this.$message({
                                message: "建议提交成功",
                                type: "success",
                                duration: 1000,
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                type: "warning",
                                duration: 1000,
                            });
                        }
                        setTimeout(() => {
                            this.disabled = false;
                            this.isShow = false;
                        }, 2000);
                    });
                } else {
                    return false;
                }
            });
        },
        hideQuestion() {
            this.isShow = false;
            if (this.languagesType == "zh") {
                this.$refs["cQuestion"].resetFields();
            } else {
                this.$refs["mQuestion"].resetFields();
            }
        },
        copytext(type) {
            let that = this;
            let clipboard = null;
            if (type == "zh") {
                clipboard = new Clipboard("#zh");
            } else if (type == "mw") {
                clipboard = new Clipboard("#mw");
            } else if (type == "zhf") {
                clipboard = new Clipboard("#zhf");
            } else {
                clipboard = new Clipboard("#mwf");
            }
            clipboard.on("success", () => {
                that.$message.success("复制成功！");
                clipboard.destroy();
            });
            clipboard.on("error", () => {
                that.$message.success("复制失败！");
                clipboard.destroy();
            });
        },
        showQuestin(type) {
            this.languagesType = type;
            this.isShow = true;
        },
        goTrans() {
            let content = this.left == 0 ? this.cText : this.mText;
            if (content.length == 0) {
                this.$message({
                    type: "warning",
                    message: "请输入要翻译的内容！",
                });
            } else {
                if (this.left != 0) {
                    if (isMgl(this.mText)) {
                        this.loading = true;
                        this.enterTranslate(
                            this.from,
                            this.to,
                            content,
                            this.productId
                        );
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请输入国家标准编码蒙古文字！",
                        });
                        return false;
                    }
                } else {
                    this.loading = true;
                    this.enterTranslate(
                        this.from,
                        this.to,
                        content,
                        this.productId
                    );
                }
            }
        },
        enterTranslate(from, to, content, productId) {
            translate(from, to, content, productId).then((res) => {
                if (res.success) {
                    if (this.left == 0) {
                        this.mText = res.body.data;
                    } else {
                        this.cText = res.body.data;
                    }
                    this.loading = false;
                } else {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                    this.loading = false;
                }
            });
        },
        changeType() {
            this.left = this.left == 0 ? "50%" : "0";
            this.right = this.right == 0 ? "50%" : "0";
            this.from = this.left == 0 ? "zh" : "mw";
            this.languagesType = this.left == 0 ? "zh" : "mw";
            this.to = this.left == 0 ? "mw" : "zh";
            this.cText = "";
            this.mText = "";
            this.nowNumc = 0;
            this.nowNumm = 0;
            this.fileName = "";
            this.isFileName = false;
            this.cFileTextList = [];
            this.mFileTextList = [];
        },
        clearText(type) {
            if (type == "cText") {
                this.nowNumc = 0;
                this.cText = "";
            } else if (type == "mText") {
                this.nowNumm = 0;
                this.mText = "";
            }
        },
    },
    watch: {
        historyType(type){
            if(type==0){
                this.setContainerStyles();
            }
        },
        showHistory(boolean) {
            if (boolean) {
                this.feachTranslateHisroty();
                this.setContainerStyles(0);
            }
        },
        "mQuestion.destContent"(value) {
            if (value.length == 100) {
                this.$message({
                    message: "建议译文最大长度是100个字符！",
                    type: "warning",
                    duration: 5000,
                });
            }
        },
        "mQuestion.origContent"(value) {
            if (value.length == 100) {
                this.$message({
                    message: "原文最大长度是100个字符！",
                    type: "warning",
                    duration: 5000,
                });
            }
        },
        "cQuestion.destContent"(value) {
            if (value.length == 100) {
                this.$message({
                    message: "建议译文最大长度是100个字符！",
                    type: "warning",
                    duration: 5000,
                });
            }
        },
        "cQuestion.origContent"(value) {
            if (value.length == 100) {
                this.$message({
                    message: "原文最大长度是100个字符！",
                    type: "warning",
                    duration: 5000,
                });
            }
        },
        cText(value) {
            this.nowNumc = value.length;
            if (value.length == this.maxlength) {
                this.$message({
                    message: `翻译内容最大长度是${this.maxlength}个字符！`,
                    type: "warning",
                    duration: 5000,
                });
            }
        },
        mText(value) {
            this.nowNumm = value.length;
            if (value.length == this.maxlength) {
                this.$message({
                    message: `翻译内容最大长度是${this.maxlength}个字符！`,
                    type: "warning",
                    duration: 5000,
                });
            }
        },
    },
};
</script>
<style>
.uploadDoc .el-upload--text {
    display: flex;
}
.uploadDoc .changeImg {
    color: #252b3a !important;
}
.translateContent .el-textarea.is-disabled .el-textarea__inner {
    background: #fafafa;
    color: #252b3a;
}
.translateContent .h100 textarea {
    height: 100%;
    font-size: 16px;
    color: #252b3a;
    border: 0 !important;
    padding: 20px 0 !important;
}
.menk textarea {
    text-align: justify;
    -webkit-text-orientation: sideways-right;
    text-orientation: sideways-right;
    display: table-column;
    font-family: "Menksoft2012";
    -webkit-writing-mode: vertical-lr !important;
    writing-mode: vertical-lr !important;
    font-size: 22px !important;
}
.pleft34 textarea {
    background: #fafafa;
}
.pright34 textarea {
    background: #fff;
}

.cQuestionBox .el-select {
    width: 142px;
    margin-left: 20px;
}
.cQuestionBox .el-select .el-input__inner {
    height: 28px;
    line-height: 28px;
    border: 0;
    color: #252b3a;
}
.cQuestionBox .el-select .el-input__icon {
    line-height: 28px;
    color: #252b3a;
}
.cQuestion .h260 .el-form-item__content,
.cQuestion .h260 .el-textarea,
.cQuestion .h260 .el-textarea__inner {
    height: 200px;
}
.cQuestion .el-form-item__label {
    line-height: 28px;
    padding: 0;
}
.mQuestion .el-form-item__label {
    font-size: 14px;
    color: #252b3a;
    line-height: 28px;
    padding: 0;
}
.mQuestion .el-form-item {
    margin-bottom: 12px;
}
.mQuestion .el-col-11,
.mQuestion .el-col-11 .el-form-item,
.mQuestion .el-col-11 .el-textarea,
.mQuestion .el-col-11 .el-textarea__inner {
    height: 100%;
}
.mQuestion .el-col-11 .el-form-item__content {
    height: 242px;
}
</style>

<style lang="less" scoped>
.originalText .menkText {
    line-height: 24px !important;
    height: 360px;
    margin: 0 auto;
    width: 428px;
    font-size: 20px;
    line-height: 32px;
    -webkit-column-width: 360px;
    -webkit-column-gap: 20px;
    -webkit-column-fill: auto;
    -webkit-column-rule: 0px outset #ececec;
    -moz-column-width: 360px;
    -moz-column-gap: 20px;
    -moz-column-fill: auto;
    -moz-column-rule: 0px outset #ececec;
    column-width: 360px;
    column-gap: 20px;
    column-fill: auto;
    column-rule: 0px outset #ececec;
}
.originalText.zh {
    font-size: 14px;
    color: #353535;
}
.originalText {
    margin-bottom: 10px;
    padding: 10px;
    line-height: 24px;
    color: #353535;
    background: #f0f5ff;
}
.originalTextTop {
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    > h6 {
        font-size: 14px;
        color: #353535;
    }
    > div {
        height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        color: #4cbcab;
        cursor: pointer;
        i {
            margin-right: 2px;
        }
    }
}
.historyDetails {
    padding-left: 10px;
}
.historyItemDownload {
    display: flex;
    flex-direction: row;
    > div {
        width: 70px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        color: #353535;
        margin-left: 12px;
        cursor: pointer;
        p {
            margin-left: 6px;
            text-decoration: underline;
        }
    }
}
.historyItemTime {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        margin-left: 10px;
        margin-right: 8px;
        font-size: 14px;
        color: #797a7d;
    }
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        i {
            margin: 0 4px;
        }
        p {
            font-size: 12px;
            width: 20px;
            height: 20px;
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
        p.mw {
            color: #ff8c1c;
            border: solid 1px #ff8c1c;
        }
        p.zh {
            color: #214597;
            border: solid 1px #214597;
        }
    }
}
.historyItemTimeDownload {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.historyItemContent {
    margin-top: 16px;
    position: relative;
    min-height: 40px;
    .historyItemSpot {
        position: absolute;
        left: -4px;
        top: 16px;
        width: 8px;
        height: 8px;
        background: #ff8610;
        border-radius: 50%;
        border: solid 2px #fff;
    }
}
.textHistoryDate {
    height: 20px;
    width: 112px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0 10px 10px 0;
    font-size: 14px;
    color: #fff;
    background: #ff8610;
    cursor: pointer;
    p {
        margin-right: 6px;
    }
}

.historyItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}
.historyListBox {
    margin: 0 0 0 18px;
    height: 100%;
    overflow-y: auto;
    border-left: solid 1px #ff8610;
    padding-right: 18px;
}
.historyListBox::-webkit-scrollbar {
    width: 6px;
}
.historyListBox::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 3px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    background: #535353;
}
.historyListBox::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background: #ededed;
}
#tHistoryListBox {
    margin-top: 20px;
    height: calc(100% - 76px);
}
.tHistoryTas {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
        margin-left: 6px;
        width: 82px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        color: #c3c3c3;
        text-align: center;
        border: solid 1px #b9b9b9;
        cursor: pointer;
    }
    p.active {
        color: #fff;
        border-color: #1b4094;
        background: #1b4094;
    }
}
.tHistoryModuleTitle {
    display: flex;
    flex-direction: row;
    img {
        height: 30px;
    }
    div {
        margin-left: 6px;
        display: flex;
        flex-direction: column;
        h4 {
            font-size: 18px;
            color: #353535;
        }
        p {
            font-size: 12px;
            color: #bebebe;
        }
    }
}
.tHistoryTasBox {
    margin: 0 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 36px;
    border-bottom: solid 1px #e5e5e5;
}
.tHistoryBtn.show {
    right: 474px;
}
.tHistoryBtn {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 1001;
    margin-top: -87.5px;
    width: 44px;
    height: 175px;
    cursor: pointer;
    .tHistoryBtnImg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .tHistoryBtnContent {
        position: absolute;
        top: 50%;
        right: 0;
        width: 28px;
        height: 124px;
        margin-top: -62px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 15px;
        p {
            writing-mode: vertical-lr;
        }
        img {
            margin-top: 6px;
            width: 11px;
            height: 7px;
        }
    }
}

.tHistoryContent {
    padding: 26px 0 0;
    width: 474px;
    height: 100%;
    background: #fff;
    border-left: solid 1px #1b4094;
}
.tHistoryBox {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.4);
}

.fileTranslateHeader {
    width: 100%;
    height: 48px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.fileTranslateBox {
    height: calc(100% - 120px);
}
.filePromptText {
    height: 42px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    align-items: center;
    span {
        color: #ff1b3b;
    }
}
.flieName {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #333;
    white-space: nowrap;
    i {
        margin-right: 12px;
        font-size: 24px;
    }
}
.menkTextBox {
    padding: 12px;
    height: calc(100% - 42px);
    width: 96%;
    margin: 0 12px;
    overflow-y: hidden;
    overflow-x: auto;
    -o-writing-mode: vertical-lr;
    -moz-writing-mode: vertical-lr;
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    -webkit-writing-mode: tb-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: tb-lr;
    text-orientation: sideways-right;
    -webkit-text-orientation: sideways-right;
    word-break: keep-all;
    p {
        font-size: 18px;
        color: #252b3a;
        line-height: 30px;
        font-family: "Menksoft2012";
    }
    p.hover {
        background: #fff100;
    }
}

.menkTextBox::-webkit-scrollbar {
    height: 10px;
}
.menkTextBox::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
}
.menkTextBox::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
}
.fileTextBox {
    height: 100%;
    padding: 16px;
    overflow-y: auto;
    p {
        font-size: 14px;
        color: #252b3a;
        line-height: 30px;
    }
    p.hover {
        background: #fff100;
    }
}
.fileTextBox::-webkit-scrollbar {
    width: 10px;
}
.fileTextBox::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
}
.fileTextBox::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
}
.h290 {
    height: 290px;
}
.h260 {
    height: 260px;
}
.cQuestionFormBox {
    flex: 1;
}
.cQuestionTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 34px;
    margin-bottom: 16px;
    > p {
        font-size: 18px;
        font-family: "思源黑体";
        font-weight: bold;
        color: #252b3a;
    }
    > span {
        font-size: 14px;
        font-family: "思源黑体";
        color: #b0b0b0;
        margin-left: 16px;
    }
    > .el-icon-close {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
        font-size: 20px;
        color: #b0b0b0;
        cursor: pointer;
    }
}
.cQuestionBox {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.questionNewsBox {
    padding: 14px 20px;
    width: 560px;
    height: 490px;
    background: #fff;
}
.questionSubmitBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
}
.backImg {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 99;
    margin-left: -0.5px;
    width: 1px;
    height: 100%;
    background: #e5e5e5;
}
.back1 {
    background: #fff;
}
.back2 {
    background: #fafafa;
}
.menk {
    -webkit-writing-mode: vertical-lr;
    -webkit-text-orientation: sideways-right;
    writing-mode: vertical-lr;
    text-orientation: sideways-right;
}
.operationBox {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    div {
        height: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        i {
            font-size: 20px;
            font-weight: bold;
            color: #252b3a;
            margin: 0 10px;
            cursor: pointer;
        }
        span {
            font-size: 14px;
            color: #252b3a;
        }
    }
    .num {
        color: #6f7583;
    }
    .rightIcon {
        width: 100%;
        justify-content: flex-end;
        i {
            color: #a0a0a0;
            font-weight: normal;
        }
    }
}
.pright34 {
    padding-right: 34px;
    padding-left: 12px;
}
.pleft34 {
    padding-left: 34px;
    padding-right: 12px;
    background: #fafafa;
}
.textBox {
    height: calc(100% - 42px);
    position: relative;
    .el-icon-close {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 99;
        font-size: 22px;
        cursor: pointer;
    }
}
.bleft1 {
    border-left: solid 1px #e5e5e5;
}
.translate-text-box {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
}
.fanyiImg {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
    margin: -31.5px 0 0 -31.5px;
    cursor: pointer;
}
.translateContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
}
.applicationBtnBox {
    align-items: center;
    height: 48px;
    line-height: 48px;
    border-bottom: solid 1px #e5e5e5;
    text-align: center;
    p {
        display: inline-block;
        font-size: 16px;
        color: #252b3a;
        width: 120px;
    }
    img {
        cursor: pointer;
        margin: 0 256px;
        vertical-align: middle;
    }
}
.translateBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: solid 1px #e5e5e5;
    background: #fff;
    border-radius: 8px;
}
.contentBox {
    height: 100%;
    padding: 60px 0;
    position: relative;
}
</style>
