<template>
    <div id="header">
        <div class="logo-container h100 left" v-if="isShow">
            <img src="~assets/images/logo.png" alt="" />
        </div>
        <div class="w12 h100 header-nav-login">
            <div class="logo-nav-container h100">
                <div class="logo-box" v-if="!isShow">
                    <img src="~assets/images/logo.png" alt="" />
                </div>
                <div class="nav-container h100" v-if="showModular">
                    <tar-bar>
                        <tar-bar-item path="/home">
                            <span slot="item-text">首页</span>
                        </tar-bar-item>
                        <tar-bar-item path="/product" :hasSecond="true">
                            <span slot="item-text">产品中心</span>
                            <i class="el-icon-arrow-down" slot="item-icon"></i>
                            <div slot="item-second-nav">
                                <product-bar>
                                    <product-bar-item
                                        path="/product-details"
                                        v-for="item in productList"
                                        :key="item.id"
                                        :productId="item.id"
                                    >
                                        <p slot="name">{{ item.name }}</p>
                                    </product-bar-item>
                                </product-bar>
                            </div>
                        </tar-bar-item>
                        <tar-bar-item path="/download">
                            <span slot="item-text">下载</span>
                        </tar-bar-item>
                        <tar-bar-item path="/college">
                            <span slot="item-text">蒙科立学院</span>
                        </tar-bar-item>
                        <tar-bar-item path="/case">
                            <span slot="item-text">客户案例</span>
                        </tar-bar-item>
                        <tar-bar-item
                            path="/about"
                            :hasSecond="true"
                            width="1200px"
                            :left="aboutLeft"
                        >
                            <span slot="item-text">关于我们</span>
                            <i class="el-icon-arrow-down" slot="item-icon"></i>
                            <About slot="item-second-nav" />
                        </tar-bar-item>
                    </tar-bar>
                </div>
            </div>
            <div class="user-container h100" v-if="!userInfo && showModular">
                <!-- <iframe
                    id="myframe"
                    scrolling="no"
                    src="https://tianqiapi.com/api.php?style=tw&skin=pear"
                    frameborder="0"
                    width="340"
                    height="70"
                    allowtransparency="true"
                    @mouseenter="changeIframeHeight(390)"
                    @mouseout="changeIframeHeight(70)"
                ></iframe> -->
                <p @click="handleRegister">
                    <img src="~assets/images/registerImg.png" alt="">
                    <em>用户注册</em>
                </p>
                <span>|</span>
                <p @click="handleLogin">
                    <img src="~assets/images/loginBtn.png" alt="" />
                    <em>登录</em>
                </p>
            </div>
            <el-dropdown
                @command="handleCommand"
                v-if="userInfo && showModular"
            >
                <span class="el-dropdown-link">
                    <div class="header_msg">
                        <img
                            :src="
                                userInfo.headimgurl
                                    ? userInfo.headimgurl
                                    : headimgurl
                            "
                            alt="用户头像"
                            class="userImg"
                        />
                        <div class="userName">{{ userInfo.memberName }}</div>
                    </div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        icon="el-icon-user-solid"
                        command="/personal"
                    >
                        个人中心
                    </el-dropdown-item>
                    <el-dropdown-item
                        icon="el-icon-bell"
                        command="/personal/news"
                    >
                        消息中心
                    </el-dropdown-item>
                    <el-dropdown-item
                        align="center"
                        command="out"
                        class="loginOut"
                        @mouseenter.native="hover = true"
                        @mouseleave.native="hover = false"
                    >
                        <img
                            src="~assets/images/loginOut.svg"
                            v-show="!hover"
                            alt=""
                        />
                        <img
                            src="~assets/images/loginOuta.svg"
                            v-show="hover"
                            alt=""
                        />
                        退出登录
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
import VueEvent from "@/VueEvent.js";
import { PcCookie, Key } from "@/utils/cookie";
import { getProductsNav } from "api/product";
import TarBar from "components/common/tarbar/TarBar";
import TarBarItem from "components/common/tarbar/TarBarItem";
import ProductBar from "components/common/productbar/ProductBar";
import ProductBarItem from "components/common/productbar/ProductBarItem";
import About from "components/contents/about";
export default {
    inject: ["reload"],
    components: {
        TarBar,
        TarBarItem,
        ProductBar,
        ProductBarItem,
        About,
    },
    computed: {
        userInfo() {
            return PcCookie.get(Key.userInfoKey)
                ? JSON.parse(PcCookie.get(Key.userInfoKey))
                : null;
        },
    },
    data() {
        return {
            windowWidth: document.documentElement.clientWidth,
            isShow: true, //两个logo切换
            hover: false, //用户下拉框切换
            isLogin: false, //是否登录
            showModular: true, //是否显示导航及头部登录、注册按钮
            headimgurl: require("@/assets/images/userHeadImg.png"),
            aboutLeft: "",
            productList: [],
            token: PcCookie.get(Key.tokenKey),
            memberType: PcCookie.get(Key.memberTypeKey),
        };
    },
    created() {
        if (this.windowWidth > 1600) {
            this.isShow = true;
            this.aboutLeft = "-304px";
        } else {
            this.isShow = false;
            this.aboutLeft = "-455px";
        }
        this.fetchProductNav();
    },
    mounted() {
        let that = this;
        window.addEventListener("resize", function () {
            that.windowWidth = document.body.offsetWidth;
            if (that.windowWidth > 1600) {
                that.isShow = true;
                that.aboutLeft = "-304px";
            } else {
                that.isShow = false;
                that.aboutLeft = "-455px";
            }
        });
        VueEvent.$on("showModular", (data) => {
            this.showModular = data;
        });
        VueEvent.$on("changeHeadImg", () => {
            this.reload();
        });
    },
    methods: {
        changeIframeHeight(height){
            document.getElementById("myframe").height=height;
        },
        handleRegister() {
            this.$router.push({
                path: "/register",
            });
        },
        enterAttestation() {
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再进行企业认证！",
                    type: "warning",
                });
            } else {
                this.$router.push("/attestation");
            }
        },
        handleLogin() {
            this.$router.push({
                path: "/login",
                query: { redirect: this.$router.currentRoute.fullPath },
            });
        },
        handleCommand(command) {
            if (command === "/personal") {
                this.$router.push(command);
            } else if (command === "/personal/news") {
                this.$router.push(command);
            } else {
                this.$store.dispatch("UserLogout").then((res) => {
                    const { success } = res;
                    if (success) {
                        VueEvent.$emit("logout");
                        this.$router.push("/");
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                });
                // .catch((error) => {
                //     this.$message({
                //         message: error+'6555',
                //         type: "error",
                //     });
                // });
            }
        },
        fetchProductNav() {
            getProductsNav().then((res) => {
                if (res.success) {
                    this.productList = res.body.data;
                }
            });
        },
    },
};
</script>

<style>
#header iframe {
    padding: 0 5px;
    background: #fff;

}
.register-dropdown-item i {
    color: #ffba4d !important;
}
.register-dropdown-item img {
    margin-right: 6px;
    height: 14px;
}
.register-dropdown-item {
    display: flex;
    align-items: center;
}
.register-dropdown .el-dropdown-link {
    font-size: 14px;
    color: #252b3a;
}
.register-dropdown .el-dropdown-link img {
    margin: 0 4px 0 0;
}
.register-dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.loginOut img {
    margin-right: 5px;
}
.loginOut {
    padding-left: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.header-nav-login .el-dropdown-menu__item:focus,
.header-nav-login .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #1b4094;
    color: #fff;
}
.header-nav-login .el-dropdown-menu {
    top: 60px !important;
}
</style>
<style scoped>
.hasAttestation {
    width: 120px;
    position: absolute;
    right: 140px;
}
.attestation {
    margin-right: 12px;
}
.attestation span {
    margin-left: 4px !important;
}
.userName {
    flex: 1;
    font-size: 14px;
    color: #252b3a;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.userImg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: solid 1px #dadada;
    margin-right: 8px;
}
.header_msg {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 71px;
    box-sizing: content-box;
}
.user-container p i {
    font-size: 16px;
    color: #ffba4d;
}
.user-container em {
    margin-left: 4px;
    font-style: normal;
    align-items: center;
    display: flex;
}
.user-container span {
    margin: 0 22px;
    align-items: center;
    display: flex;
}
.user-container p {
    height: 100%;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.user-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}
.nav-container {
    display: flex;
    flex-direction: row;
}
.logo-box {
    margin: 0 20px;
}
.logo-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.header-nav-login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}
.logo-container {
    position: absolute;
    top: 0;
    left: 60px;
    display: flex;
    align-items: center;
}
#header {
    width: 100%;
    position: relative;
    z-index: 99;
    height: 73px;
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
}
</style>
