<template>
    <div class="searchBox">
        <div class="course_nav">
            <div class="course_nav_box">
                <div class="type">类型</div>
                <div class="subject_list">
                    <p
                        v-for="(item, index) in typeList"
                        :key="index"
                        :class="{ active: typeIndex === index }"
                    >
                        <span @click="searchTypeQery(item, index, 'type')">
                            {{ item.label }}
                        </span>
                    </p>
                </div>
            </div>
            <div class="course_nav_box">
                <div class="type">产品</div>
                <div class="subject_list">
                    <p
                        v-for="(item, index) in productList"
                        :key="item.productId"
                        :class="{ active: productIndex === index }"
                    >
                        <span @click="searchTypeQery(item, index, 'product')">
                            {{ item.productName }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="searchContent">
            <div class="sortBOx">
                <p>综合排序</p>
                <!-- <div class="sortText">最新</div>
                <div class="sortIcon">
                    <i  class="el-icon-caret-top"></i>
                    <i  class="el-icon-caret-bottom"></i>
                </div> -->
            </div>
            <div class="searchGroup">
                <el-input
                    placeholder="搜索您感兴趣的视频"
                    v-model="searchInput"
                    class="input-with-select"
                >
                    <el-button
                        slot="append"
                        icon="el-icon-search"
                        @click="searchInputQery"
                    ></el-button>
                </el-input>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        productInfos: {
            type: Array,
            default() {
                return [];
            },
        },
        types: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            searchInput: "",
            typeIndex: 0,
            typeId: null,
            typeList: [
                {
                    value: "",
                    label: "全部",
                },
            ],
            productIndex: 0,
            productId: null,
            productList: [
                {
                    productId: "",
                    productName: "全部",
                },
            ],
        };
    },
    created() {
        this.productList = this.productList.concat(
            this.productInfos
        );
        this.typeList = this.typeList.concat(
            this.types
        );
    },
    methods: {
        //输入框查询
        searchInputQery() {
            this.typeIndex = 0
            this.typeId = ''
            this.productIndex = 0
            this.productId = ''
            this.$emit("searchInputQery", this.searchInput);
        },
        //类型查询
        searchTypeQery(item, index, type) {
            if (type === "type") {
                this.typeIndex = index;
                this.typeId = item.value;
            } else {
                this.productIndex = index;
                this.productId = item.productId;
            }
            const queryData = {
                typeId: this.typeId,
                productId: this.productId,
            };
            this.$emit("searchTypeQery", queryData);
            //往父级传事件，page的current设为1，并请求数据
        },
    },
};
</script>
<style>
.searchGroup .el-input__inner {
    height: 30px;
    line-height: 30px;
    background: transparent;
}
</style>
<style lang="less" scoped>
.searchGroup {
    width: 406px;
    height: 100%;
    display: flex;
    align-items: center;
}

.sortBOx {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    > p {
        margin-right: 90px;
        color: #252b3a;
    }
    .sortText {
        color: #252b3a;
    }
    .sortIcon {
        margin-left: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        i {
            color: #8e8e8e;
            font-size: 12px;
            cursor: pointer;
        }
        .el-icon-caret-bottom {
            margin-top: -5px;
        }
        .el-icon-caret-top {
            margin-top: 2px;
        }
        i:hover {
            color: #007cf3;
        }
    }
}
.searchContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding-left: 20px;
    height: 44px;
    border-bottom: solid 1px #dfe4eb;
}
.subject_list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    p {
        width: 154px;
        font-size: 14px;
        line-height: 34px;
        color: #252b3a;
        span {
            cursor: pointer;
        }
    }
    p.active {
        color: #007cf3;
        span{
            background: #c9e2fa;
            padding:6px 10px;
            border-radius: 20px;
        }
    }
}
.course_nav_box {
    padding: 20px;
    display: flex;
    flex-direction: row;
    min-height: 74px;
    background: #eaedf2;
    margin-bottom: 1px;
    .type {
        margin-right: 50px;
        width: 86px;
        height: 28px;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        color: #fff;
        background: #2f5d97;
        border-radius: 17px;
        font-weight: bold;
    }
    .all {
        margin-right: 30px;
        width: 86px;
        height: 34px;
        font-size: 16px;
        line-height: 34px;
        text-align: center;
        border-radius: 17px;
        color: #252b3a;
    }
    .all.active {
        font-weight: bold;
        color: #007cf3;
    }
}
</style>
