<template>
    <div class=" main-container" style="background:#f3f5f8;overflow:hidden">
        <div class="w12 fontBox">
            <div class="font-nav">
                <div class="font-nav-li" @click="activeIndex = 0" :class="{'active': activeIndex == 0}">
                    <span></span>
                    <p>Windows 字体安装</p>
                </div>
                <div class="font-nav-li" @click="activeIndex = 1" :class="{'active': activeIndex == 1}">
                    <span></span>
                    <p>Android字体嵌入方法</p>
                </div>
                <div class="font-nav-li border0" @click="activeIndex = 2" :class="{'active': activeIndex == 2}">
                    <span></span>
                    <p>Linux安装蒙古文字体教程</p>
                </div>
            </div>
            <div class="list-news-box" v-if="activeIndex == 0">
                <div class="guide-step-title">
                    <p>Windows 字体安装指南</p>
                    <span>Windows Vista/7/8/10</span>
                </div>
                <div class="exanple">
                    <p>Windows 7 为例：</p>
                    <div>
                        <p>1、双击字体文件，打开字体查看器</p>
                        <img src="~assets/images/product/openTypeImg1.jpg" alt="">
                    </div>
                    <div>
                        <p>2、点击“安装”，安装字体；或，右击字体文件点击“安装”，安装字体</p>
                        <img src="~assets/images/product/openTypeImg2.jpg" alt="">
                    </div>
                </div>
            </div>
            <div class="list-news-box" v-if="activeIndex == 1">
                <div class="guide-step-title">
                    <p>安卓应用开发中自定义字体嵌入方法</p>
                </div>
                <div class="exanple">
                    <p>Typeface使用TTF字体文件设置字体：</p>
                    <div>
                        <p>
                            在程序项目中放入ttf字体文件，在程序代码中使用Typeface设置字体。具体步骤如下：a)
                            在assets目录下新建fonts目录，把ttf字体文件放到这（如下图）。
                        </p>
                        <img src="~assets/images/product/openTypeImg3.jpg" alt="">
                    </div>
                    <div>
                        <p>
                            a) 程序中调用：AssetManager mgr = getAssets(); //得到AssetManager
                            Typeface typeFaceHawang = Typeface.createFromAsset(mgr, "fonts/MHW8101.ttf");//根据路径得到Typeface
                            textView.setTypeface(typeFaceHawang); //设置字体textView.setText("
                            <span class="mkText">        \r\n       "</span>
                            );//测试字体
                        </p>
                        <img src="~assets/images/product/openTypeImg4.jpg" alt="">
                    </div>
                </div>
            </div>
            <div class="list-news-box" v-if="activeIndex == 2">
                <div class="guide-step-title">
                    <p>Linux安装蒙古文字体教程</p>
                </div>
                <div class="exanple">
                    <p>Linux Mint 17.2为例：</p>
                    <div>
                        <p>
                            第一步：安装字体使用字体查看器打开字体文件。点击“安装”安装字体。
                        </p>
                        <img src="~assets/images/product/openTypeImg5.jpg" alt="">
                        <img src="~assets/images/product/openTypeImg6.jpg" alt="">
                        <img src="~assets/images/product/openTypeImg7.jpg" alt="">
                        <img src="~assets/images/product/openTypeImg8.jpg" alt="">
                    </div>
                    <div>
                        <p>
                            第二步：使用字体新建空白文档。
                        </p>
                        <img src="~assets/images/product/openTypeImg9.jpg" alt="">
                        <span style="margin-left:66px">点击“编辑->首选项”</span>
                        <img src="~assets/images/product/openTypeImg10.jpg" alt="">
                        <span style="margin-left:66px">点击“字体和颜色->编辑器字体”</span>
                        <img src="~assets/images/product/openTypeImg11.jpg" alt="">
                        <span style="margin-left:66px">选择刚才安装的字体，点击确定</span>
                        <img src="~assets/images/product/openTypeImg12.jpg" alt="">
                        <span style="margin-left:66px">关闭所有窗口，设置完成</span>
                        <img src="~assets/images/product/openTypeImg13.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeIndex:0,
        }
    }
}
</script>
<style lang="less" scoped>
.exanple{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    color: #252b3a;
    >p{
        font-size: 16px;
    }
    >div{
        margin-top: 10px;
        margin-left:140px ;
        margin-bottom: 20px;
        img{
            display: block;
            margin: 10px auto;
            max-width: 100%;
        }
        .mkText{
            font-family: 'Menksoft2012';
            font-size: 18px;
        }
    }
}
.guide-step-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        font-size: 16px;
        color: #252b3a;
        line-height: 32px;
    }
    span{
        font-size: 14px;
        color: #252b3a;
    }
}
.border0{
    border: 0 !important;
}
.fontBox{
    margin-top: 98px;
    overflow: hidden;
    .font-nav{
        float: left;
        width: 254px;
        padding: 0 10px;
        border-right: solid 1px #dadada;
        .font-nav-li{
            cursor: pointer;
            padding: 0 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 65px;
            font-style: 14px;
            color: #252b3a;
            border-bottom: solid 1px #d2d2d2;
            span{
                margin-right: 14px;
                width: 8px;
                height: 8px;
                background: #dfe9ff;
                border-radius: 50%;
            }
        }
        .font-nav-li.active{
            color: #1b4094;
            background: -webkit-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: -o-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: -moz-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: linear-gradient(to right, #f3f5f8, #cfdcfa);
            span{
                background: #1b4094;
            }
        }
    }
    .list-news-box{
        padding: 10px 20px 20px;
        margin-left: 292px;
        background: #fff;
    }
}
</style>
