<template>
    <div class="main-container" style="background: #f7f8fa">
        <div class="w12 forgetBody h100">
            <h3>忘记密码</h3>
            <div class="forgetBodyInner" >
                <p class="forget-txt-mod">
                    如果您的密码已忘记，请输入注册时的手机号前往重设登录密码。
                </p>
                <div class="formBox">
                    <el-form ref="phoneForm" :model="phoneForm" :rules="rules">
                        <el-row class="emailRow">
                            <span
                                class="absolute"
                                :class="{
                                    'is-focus': isFocus,
                                    'is-error': !validate,
                                }"
                                @click="toggleLabel"
                                :style="{ top: top }"
                                >手机号</span
                            >
                            <el-form-item prop="phone">
                                <el-input
                                    ref="phone"
                                    v-model="phoneForm.phone"
                                    :disabled="disabled"
                                    @focus="isFocus = true"
                                    @blur="isFocus = false"
                                ></el-input>
                            </el-form-item>
                            <el-row
                        :gutter="10"
                        type="flex"
                        align="middle"
                        v-if="isRegister && isRules"
                    >
                        <el-col :span="17">
                            <el-form-item prop="code">
                                <el-input
                                    placeholder="请输入验证码"
                                    v-model="phoneForm.code"
                                    :disabled="disabled"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" class="phoneCodeBtnBox">
                            <el-button
                                style="margin-bottom:22px;"
                                @click.prevent="getMessageCode"
                                :disabled="tDisabled"
                                >{{
                                    tDisabled ? timeCount + "S" : "获取验证码"
                                }}</el-button
                            >
                        </el-col>
                    </el-row>
                    <JcRange  status="ruleForm.status" :isRegister="isRegister" :successFun="onMpanelSuccess" :errorFun="onMpanelError" v-if="!isRules"></JcRange>
                            <el-form-item>
                                <el-button
                                    type="primary"
                                    style="width: 100%"
                                    :disabled="btnDisabled"
                                    @click="enterResetPass('phoneForm')"
                                    >下一步</el-button
                                >
                            </el-form-item>
                        </el-row>
                    </el-form>
                    <el-row >
                        <el-divider><span>其他登录方式</span></el-divider>
                    </el-row>
                    <el-row type="flex" justify="space-around" style="margin-top:20px;" >
                        <div class="felxRow pointer" @click="enterLogin">
                            <img src="~assets/images/login/phoneImg1.png" alt="" >
                            <p>手机短信登录</p>
                        </div>
                        <div class="felxRow pointer" @click="enterLogin">
                            <img src="~assets/images/login/WeChatImg.png" alt="" >
                            <p>微信扫码登录</p>
                        </div>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { isvalidPhone } from "@/utils/validate.js";
import { beforeEncrypt } from '@/utils/encryptData.js'
import {  checkPhone, getCode } from "@/api/register";
import { getPublicKey } from "api/perCheck"
import { checkForgetCode } from "api/auth"
import JcRange from "@/components/common/jcRange/JcRange.vue";
export default {
    components:{JcRange},
    data() {
        var validPhone = (rule, value, callback) => {
            if (value === "") {
                this.validate = false;
                this.top = "12px";
                callback(new Error("请输入手机号码"));
            } else if (!isvalidPhone(value)) {
                this.validate = false;
                this.top = "-8px";
                callback(new Error("请填写正确的手机号码"));
            } else {
                let formStr = this.phoneForm.phone;
                const phoneForm = beforeEncrypt(
                    16,
                    this.publicKey,
                    this.rsKey,
                    formStr
                );
                checkPhone(
                    phoneForm.formDes,
                    phoneForm.rsKey,
                    phoneForm.secretKey
                )
                    .then((response) => {
                        if (!response.success) {
                            this.isRegister = true;
                            this.validate = true;
                            this.top = "-8px";
                            callback();
                        } else {
                            this.validate = false;
                            this.top = "-8px";
                            this.isRegister = false;
                            this.$message({
                                type:'error',
                                message:"手机号未注册！"
                            })
                            callback();
                        }
                    })
                    .catch((err) => {
                        alert(err);
                    });
            }
        };
        var validCode = (rule, value, callback) => {
            if (value === "" || value.length != 6) {
                callback(new Error("请输入6位短信验证码"));
            }else {
                let formStr = JSON.stringify(this.phoneForm)
                const phoneNews = beforeEncrypt(16, this.publicKey, this.rsKey, formStr)
                checkForgetCode(phoneNews.formDes,phoneNews.rsKey,phoneNews.secretKey).then(res => {
                    if(res.success){
                        callback();
                    }else{
                        callback(new Error(res.msg));
                    }
                })
            }
        };
        return {
            rules: {
                phone: [
                    { required: true, validator: validPhone, trigger: "blur" },
                ],
                code: [
                    { required: true, validator: validCode,  trigget: "blur" },
                ],
            },
            phoneForm: {
                phone: "",
                code:""
            },
            email:'',
            disabled: false,
            isFocus: false,
            validate: true,
            top: "",
            isRegister:false,
            timer: null,
            timeCount: "",
            tDisabled: false,
            publicKey: "",
            rsKey: "",
            isRules:false,
            btnDisabled:false
        };
    },
    created(){
        this.getKey();
    },
    methods: {
        enterResetPass(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(!this.isRules){
                        this.$message({
                            type:'warning',
                            message:'请滑动滑块进行验证，方可进行下一步'
                        })
                        return false;
                    }else{
                        this.disabled = true;
                        this.btnDisabled = true;
                        this.$router.push({
                            path:'/reset-password',
                            query:{
                                phone:this.phoneForm.phone,
                                code:this.phoneForm.code
                            }
                        })
                    }

                } else {
                    this.disabled = false;
                    return false;
                }
            });
        },
        toggleLabel() {
            this.isFocus = !this.isFocus;
            if (this.isFocus) {
                this.$refs["phone"].focus();
            } else {
                this.$refs["phone"].blur();
            }
        },
        enterLogin() {
            this.$router.push("/login");
        },
        //获取手机验证码
        getMessageCode() {
            let formStr = this.phoneForm.phone;
            const phoneForm = beforeEncrypt(
                16,
                this.publicKey,
                this.rsKey,
                formStr
            );
            getCode(
                phoneForm.formDes,
                phoneForm.rsKey,
                phoneForm.secretKey
            ).then((res) => {
                this.tDisabled = true;
                if (res.success) {
                    this.countDown();
                } else {
                    this.tDisabled = true;
                    this.timeCount = 60;
                    this.countDown();
                }
                this.$message({
                    type: res.success ? "success" : "warning",
                    message: res.msg,
                });
            });
        },
        //倒计时60秒
        countDown() {
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.timeCount = TIME_COUNT;
                this.tDisabled = true;
                this.timer = setInterval(() => {
                    if (this.timeCount > 0 && this.timeCount <= TIME_COUNT) {
                        this.timeCount--;
                    } else {
                        this.tDisabled = false;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        },
        onMpanelError(){
            this.isRules = false;
        },
        onMpanelSuccess(){
            this.isRules = true;
        },
        getKey() {
            getPublicKey().then((res) => {
                if (res.success) {
                    const { body } = res;
                    this.publicKey = body.data.publicKey;
                    this.rsKey = body.data.redisKey;
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.phoneCodeBtnBox{
    display: flex;
    justify-content: flex-end;
}
.felxRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 42px;
    img{
        height: 32px;
    }
    p{
        margin: 0 8px;
        font-size: 14px;
        color: #a0a0a0;
    }
}
.tooltipBox {
    margin: 0 auto;
    width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    img{
        margin-top: 20px;
    }
    h3{
        margin: 36px 0;
        font-size: 20px;
        color:#333;
    }
    p{
        font-size: 14px;
        color:#333;
        line-height: 24px;
    }
}
.formBox .el-form {
    margin: 60px 0 0;
}
.otherLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 24px;
    font-size: 14px;
    color: #333;
    p {
        font-size: 16px;
        line-height: 32px;
    }
    span {
        cursor: pointer;
    }
}
.is-focus {
    top: -8px !important;
    font-size: 12px !important;
    color: #00a4ff !important;
}
.is-error {
    font-size: 12px !important;
    color: #e54545 !important;
}
.absolute {
    position: absolute;
    left: 13px;
    top: 12px;
    z-index: 3;
    height: 16px;
    line-height: 16px;
    padding: 0 2px;
    font-size: 14px;
    color: #606266;
    background: #fff;
}
.emailRow {
    position: relative;
}
.formBox {
    width: 480px;
    margin: 0 auto;
}
.forget-txt-mod {
    font-size: 14px;
    line-height: 24px;
    margin: 40px 0 32px;
    text-align: center;
    color: #333;
}
.forgetBody {
    height: calc(100vh - 260px);
    padding: 60px 0 40px;
    display: flex;
    flex-direction: column;
}
.forgetBodyInner {
    width: 100%;
    flex: 1;
    margin: 0 auto;
    max-width: 980px;
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(3, 27, 78, 0.5);
    border: 0;
    margin-top: 32px;
    padding-top: 40px;
}
h3 {
    text-align: center;
    font-size: 26px;
    line-height: 40px;
    font-weight: 400;
}
</style>
