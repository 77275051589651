<template>
    <div id="activityBox">
        <div class="activityBox">
            <img src="~assets/images/redPacketBackImg.png" class="redPacketBackImg" alt="">
        </div>
        <div class="activity-content">
            <img src="~assets/images/redPacketTitleImg.png" class="redPacketTitleImg" alt="">
            <div class="text">活动时间：2022年2月15日08:30 - 2022年2月17日08:30</div>
        </div>
        <div class="redPacketGif" v-if="isClikDown">
            <img src="~assets/images/redPacket.gif" alt="">
        </div>
        <div class="guImgBig">
            <img src="~assets/images/guImg.png"  alt="">
        </div>
        <div class="guImg">
            <img src="~assets/images/guImg1.png"  alt="" v-show="isClik">
            <img src="~assets/images/guImg2.png"  alt="" v-show="!isClik">
        </div>
        <div class="second" v-if="isClikDown">{{num}}</div>
        <div class="redPacketBtn">
            <img src="~assets/images/redPacketBtn.png" id="redPacketBtn" @mousedown="click"  @mouseup="click1" alt="">
        </div>
        <img src="~assets/images/redPacketGz.png" class="redPacketGz" alt="" @click="enterRules">
        <img src="~assets/images/redpacketFx.png" id="shareInput" :data-clipboard-text="shareHref" class="redPacketFx" alt="" @click="enterShare">
        <div id="redPacketShowBox" v-if="hasMoney">
            <div class="redPacketShowBox">
                <div class="redPakectTitle">{{money == 0 ? message : "恭喜获得击鼓福利"}}</div>
                <div class="moneyBox" v-if="money!=0" style="z-index:9">
                    <p>¥</p>
                    <span>{{money}}</span>
                    <div>
                        <em>现金红包</em>
                        <i>(仅限平台使用)</i>
                    </div>
                </div>
                <div class="moneyBox" v-if="money!=0">
                    <img src="~assets/images/repacketHave.png" alt="">
                </div>
                <div class="moneyBox" v-if="money==0">
                    <img src="~assets/images/repacketNone.png" class="repacketNone" alt="">
                </div>
                <img src="~assets/images/redPacketShowImg.png" class="redPacketShowImg" alt="">
                <div class="entterPersonal" @click="enterPersonal"  v-if="money!=0">点击查看</div>
                <div class="entterPersonal" @click="enterIndex" v-if="money==0">返回首页</div>
            </div>
        </div>
    </div>
</template>
<script>
import {robRedPacket, saveRedPacketShare,getActivityStatus} from 'api/activity'
import { PcCookie, Key } from "@/utils/cookie";
export default{
    data(){
        return {
            isActivity:false,
            isClik:false,
            isClikDown:false,
            hasMoney:false,
            money:0,
            num:5,
            payTimer:null,
            message:'',
            shareHref:'',
            token: PcCookie.get(Key.tokenKey),
            isFirst:true
        }
    },
    mounted(){
        document.getElementById('footer').style.display = 'none'
        document.getElementById('header').style.position = 'fixed'
    },
    beforeDestroy(){
        document.getElementById('footer').style.display = 'block'
        document.getElementById('header').style.position = 'relative'
        document.getElementById('header').style.zIndex = '99'
    },
    created(){
        let shareCode = this.$route.query.code;
        if(shareCode != undefined || shareCode != null){
            PcCookie.set(Key.shareCodeKey,shareCode)
        }
    },
    methods:{
        enterIndex(){
            this.$router.push('/home')
        },
        enterPersonal(){
            this.$router.push('/personal/core')
        },
        enterRules(){
            this.$router.push({
                path:'/home-activity-details',
                query:{
                    activityId:'c84622c22906412bb52387d0ad02f426'
                }
            })
        },
        enterShare(){
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再分享！",
                    type: "warning",
                });
            } else {
                saveRedPacketShare('c84622c22906412bb52387d0ad02f426').then(res => {
                    if(res.success){
                        this.shareHref = res.body.data.linkUrl
                        this.copyJSON(this.shareHref)
                    }
                })
            }
        },
        async copyJSON(text) {
            let that = this;
            let target = document.createElement('input')
            target.value = text
            document.body.appendChild(target)
            target.select()
            try {
                await document.execCommand('Copy')
                that.$message.success("活动分享路径复制成功！");
            } catch {
                that.$message.success("活动分享路径复制失败，请重新分享！");
            }
        },
        click1(){
            document.getElementById('redPacketBtn').classList.remove("click");
        },
        click(){
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再使用！",
                    type: "warning",
                });
            } else {
                if(this.isFirst){
                    getActivityStatus('c84622c22906412bb52387d0ad02f426').then(res=> {
                        if(res.body.isStart){
                            this.isFirst = false
                            this.timej()
                            this.isClik = !this.isClik
                            this.isClikDown = true
                            document.getElementById('redPacketBtn').classList.add("click");
                        }else{
                            this.$message({
                                type:'warning',
                                message:'活动未开始，敬请期待...'
                            })
                            return false
                        }
                    })
                }else{
                    this.isFirst = false
                    this.timej()
                    this.isClik = !this.isClik
                    this.isClikDown = true
                    document.getElementById('redPacketBtn').classList.add("click");
                }
            }
        },
        timej(){
            if(!this.isClikDown){
                const that = this
                that.payTimer = window.setInterval(function () {
                    that.num--;
                    if (that.num == 0) {
                        window.clearInterval(that.payTimer);
                        that.isClikDown = false
                        that.num = 5
                        robRedPacket('c84622c22906412bb52387d0ad02f426').then(res => {
                            if(res.success){
                                that.money = res.body.robbedRedPacket
                            }else{
                                that.money = 0
                                that.message = res.msg
                            }
                            that.hasMoney = true
                            return false
                        })
                    }
                }, 1000);
            }
        }
    },
    watch:{
        hasMoney(value){
            if(value){
                document.getElementById('header').style.zIndex = '0'
            }else{
                document.getElementById('header').style.zIndex = '99'
            }
        }
    }
}
</script>
<style lang="less" scoped>
@vw:19.2vw;
@vh:10.8vh;
#redPacketShowBox{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0, .7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .redPacketShowBox{
        height: 700/@vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .redPacketShowImg{
            height: 574/@vh;
        }
        .entterPersonal{
            width: 366/@vw;
            height: 66/@vh;
            line-height: 66/@vh;
            text-align: center;
            color: #fff;
            font-size: 22/@vh;
            background: #f80628;
            margin-top: 26/@vh;
            border-radius: 33/@vh;
            cursor: pointer;
        }
        .redPakectTitle{
            position: absolute;
            top: 250/@vh;
            width: 100%;
            height: 44/@vh;
            font-size: 36/@vh;
            color: chocolate;
            text-align: center;
        }
        .moneyBox{
            position: absolute;
            top: 370/@vh;
            width: 100%;
            height: 120/@vh;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: #fff;
            p{
                margin-top: 34/@vh;
                font-size: 25/@vh;
            }
            span{
                margin: 0 12/@vw;
                font-size: 80/@vh;
                font-weight: bold;
            }
            div{
                display: flex;
                flex-direction: column;
                em{
                   font-size: 20/@vh;
                   font-style: normal;
                   margin-bottom: 6/@vh;
                }
                i{
                    font-style: normal;
                    font-size: 12/@vh;
                }
            }
            .repacketNone{
                height: 143/@vh;
            }
        }
    }
}
#activityBox{
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: calc(100% - 73px);
    .activityBox{
        width: 100%;
        height: 100%;
        position: absolute;
        .redPacketBackImg{
            height: 100%;
            width: 1920/@vw;
        }
    }
    .activity-content{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: center;
        .redPacketTitleImg{
            margin-top: 80/@vh;
            height: 199/@vh;
        }
        .text{
            font-size: 16px;
            font-family: '思源黑体';
            color: #fce29e;
        }

    }
    .guImgBig{
        position: absolute;
        z-index: 9;
        bottom: 154/@vh;
        left: 0;
        height: 531/@vh;
        width: 100%;
        display: flex;
        justify-content: center;
        img{
            height: 531/@vh;
        }
    }
    .guImg{
        position: absolute;
        z-index: 9;
        bottom: 374/@vh;
        left: 0;
        height: 303/@vh;
        width: 100%;
        display: flex;
        justify-content: center;
        img{
            height: 290/@vh;
            margin-top: 1.1vh;
            margin-right: 0.5vw;
        }
    }
    .redPacketBtn{
        position: absolute;
        z-index: 9;
        bottom: 120/@vh;
        left: 0;
        height: 73/@vh;
        width: 100%;
        display: flex;
        justify-content: center;
        img{
            height: 73/@vh;
            cursor: pointer;

        }
        img.click{
            -moz-transform:scale(0.95);
            -webkit-transform:scale(0.95);
            -o-transform:scale(0.95);
        }
        img.click1{
            -moz-transform:scaleX(1);
            -webkit-transform:scaleX(1);
            -o-transform:scaleX(1);
        }
    }
    .redPacketGif{
        position: absolute;
        z-index: 9;
        bottom:-8vh;
        left: 0;
        height: 90vh;
        width: 100%;
        display: flex;
        justify-content: center;
        img{
            height: 780/@vh;
        }
    }
    .second{
        position: absolute;
        z-index: 9;
        bottom: 374/@vh;
        left: 0;
        height: 303/@vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #810201;
    }
    .redPacketGz{
        position: absolute;
        right: 320/@vw;
        bottom: 576/@vh;
        z-index: 9;
        height: 118/@vh;
        cursor: pointer;
    }
    .redPacketFx{
        position: absolute;
        right: 320/@vw;
        bottom: 434/@vh;
        z-index: 9;
        height: 143/@vh;
        cursor: pointer;
    }
}
</style>
