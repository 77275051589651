import { request } from '@/utils/request'
//获取通知公告列表
export function getNoticeList(current, size) {
    return request({
        url: `/api/v1/ctnotice/noticeInfo/noticelist`,
        method: 'get',
        params: {
            pageNo: current,
            pageSize: size
        }
    });
}

//获取通知公告详情
export function getNoticeDetail(noticeID) {
    return request({
        url: `/api/v1/ctnotice/noticeInfo/noticedetail`,
        method: 'get',
        params: {
            noticeID
        }
    });
}
