<template>
    <div id="loginBox">
        <div class="loginBack">
            <img
                src="~assets/images/login/bgImg.jpg"
                v-if="isIE"
                class="bgImg"
                alt=""
            />
            <vue-core-video-player
                v-if="!isIE"
                :src="bg"
                :controls="false"
                :loop="true"
                :autoplay="true"
                :muted="true"
            ></vue-core-video-player>
        </div>
        <div class="loginContainer">
            <div class="login-tabs-box">
                <span
                    v-for="(item, index) in titleList"
                    :key="index"
                    class="pointer"
                    :class="{ left: tabsIndex == index }"
                    @click="changeTabs(index)"
                >
                    {{ item.text }}
                </span>
            </div>
            <!--用户名、密码登录-->
            <div class="loginFormBox" v-if="tabsIndex == 0">
                <el-form
                    ref="passForm"
                    :model="passForm"
                    :rules="rules"
                    key="passForm"
                    label-width="52px"
                    label-position="left"
                >
                    <el-form-item prop="username">
                        <span slot="label">
                            <img
                                src="~assets/images/login/phoneImg.png"
                                alt=""
                            />
                        </span>
                        <el-input
                            placeholder="请输入用户名/手机号"
                            v-model="passForm.username"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <span slot="label">
                            <img
                                src="~assets/images/login/passImg.png"
                                alt=""
                            />
                        </span>
                        <el-input
                            placeholder="请输入密码"
                            type="password"
                            v-model="passForm.password"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item class="messageLogin">
                        <JcRange  status="ruleForm.status" :isRegister="isfinish" :successFun="onMpanelSuccess1" :errorFun="onMpanelError1" ></JcRange>
                    </el-form-item>
                    <el-form-item class="messageLogin">
                        <el-button
                            type="primary"
                            :disabled="disabled"
                            @click="namePwdLogin('passForm')"
                            >登录</el-button
                        >
                    </el-form-item>
                </el-form>

                <div class="other-tool-box">
                    <!-- <span class="pointer" @click="handleRegister">注册</span>
                    <el-divider direction="vertical"></el-divider> -->
                    <span @click="forgetPassword" class="pointer"
                        >忘记密码</span
                    >
                </div>
            </div>
            <!--短信登录-->
            <div class="loginFormBox" v-else>
                <el-form
                    ref="messageForm"
                    :model="messageForm"
                    key="messageForm"
                    label-width="52px"
                    label-position="left"
                    :rules="phoneRules"
                >
                    <el-form-item prop="phone">
                        <span slot="label">
                            <img
                                src="~assets/images/login/phoneImg.png"
                                alt=""
                            />
                        </span>
                        <el-input
                            placeholder="请输入手机号"
                            v-model="messageForm.phone"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                    <el-row
                        :gutter="10"
                        type="flex"
                        align="middle"
                        class="messageRow"
                        v-if="isRegister && isRules"
                    >
                        <el-col :span="17">
                            <el-form-item prop="code">
                                <span slot="label">
                                    <img
                                        src="~assets/images/login/passImg.png"
                                        alt=""
                                    />
                                </span>
                                <el-input
                                    placeholder="请输入验证码"
                                    v-model="messageForm.code"
                                    :disabled="disabled"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" class="phoneCodeBtnBox">
                            <el-button
                                size="small "
                                class="phoneCodeBtn"
                                @click.prevent="getMessageCode"
                                :disabled="tDisabled"
                                >{{
                                    tDisabled ? timeCount + "S" : "获取验证码"
                                }}</el-button
                            >
                        </el-col>
                    </el-row>
                    <JcRange  status="ruleForm.status" :isRegister="isRegister" :successFun="onMpanelSuccess" :errorFun="onMpanelError" v-if="!isRules"></JcRange>
                    <el-form-item class="messageLogin">
                        <el-button type="primary" :disabled="disabled" @click="phoneNumLogin('messageForm')"
                            >登录</el-button
                        >
                    </el-form-item>
                </el-form>
                <div class="statement">
                    <p>
                        同意并接受
                        <span class="agreement pointer" @click="openClause"
                            >《服务条款》</span
                        >
                        <em>和</em>
                        <span class="agreement pointer" @click="openPrivacy"
                            >《隐私权政策》</span
                        >
                    </p>
                </div>
                <div class="other-tool-box" style="margin:0;">
                    <!-- <span class="pointer" @click="handleRegister">注册</span>
                    <el-divider direction="vertical"></el-divider> -->
                    <span @click="forgetPassword" class="pointer"
                        >忘记密码</span
                    >
                </div>
            </div>
            <!--二维码微信登录-->
            <el-row style="margin-top: 30px">
                <el-divider><span>第三方账号登录</span></el-divider>
            </el-row>
            <el-row type="flex" justify="center" style="margin-top: 20px">
                <img
                    src="~assets/images/login/WeChatImg.png"
                    alt=""
                    @click="showWeChatImg"
                />
            </el-row>
        </div>
        <el-dialog
            :visible.sync="visible"
            @opened="wxopen"
            width="360px"
            class="wechat-login-box"
        >
            <iframe
                id="iframeContain"
                name="iframeContain"
                seamless
                scrolling="yes"
                sandbox="allow-scripts allow-top-navigation allow-same-origin"
                :src="iframeURL"
                >
            </iframe>
        </el-dialog>
    </div>
</template>
<script>
import VueEvent from "@/VueEvent.js";
import { beforeEncrypt } from '@/utils/encryptData.js'
import { getQrCode } from "api/weChatRegister";
import { getPublicKey } from "api/perCheck"
import { isvalidPhone } from "@/utils/validate.js";
import JcRange from "@/components/common/jcRange/JcRange.vue";
import {  checkPhone, getCode } from "@/api/register";
export default {
    inject: ["reload"],
    components:{JcRange},
    computed: {
        isIE() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                return true;
            } else {
                return false;
            }
        },
    },
    data() {
        var validPhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号码"));
            } else if (!isvalidPhone(value)) {
                callback(new Error("请填写正确的手机号码"));
            } else {
                let formStr = this.messageForm.phone;
                const phoneForm = beforeEncrypt(
                    16,
                    this.publicKey,
                    this.rsKey,
                    formStr
                );
                checkPhone(
                    phoneForm.formDes,
                    phoneForm.rsKey,
                    phoneForm.secretKey
                )
                    .then((response) => {
                        if (!response.success) {
                            this.isRegister = true;
                            callback();
                        } else {
                            this.isRegister = false;
                            this.$message({
                                type:'error',
                                message:"手机号未注册！"
                            })
                            callback();
                        }
                    })
                    .catch((err) => {
                        alert(err);
                    });
            }
        };
        var validCode = (rule, value, callback) => {
            if (value === "" || value.length != 6) {
                callback(new Error("请输入6位短信验证码"));
            }else {
                callback()
            }
        };
        return {
            bg: require("@/assets/images/login/BG.mp4"),
            bgImg: require("@/assets/images/login/bgImg.jpg"),
            showBack: "",
            titleList: [
                {
                    id: 0,
                    text: "账号密码登录",
                },
                {
                    id: 1,
                    text: "短信快捷登录",
                },
            ],
            tabsIndex: 1,
            passForm: {
                username: "",
                password: "",
            },
            messageForm: {
                phone: "",
                code: "",
            },
            //用户名和密码为非空 true
            isfinish:false,
            //用户名密码登录时，滑块验证
            isDrag:false,
            //校验手机号是否注册
            isRegister:false,
            visible: false,
            disabled: false,
            redirect: "",
            iframeURL:'',
            timer: null,
            timeCount: "",
            tDisabled: false,
            publicKey: "",
            rsKey: "",
            isRules:false,
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户名/手机号",
                        trigget: "blur",
                    },
                ],
                password: [
                    { required: true, message: "请输入密码", trigget: "blur" },
                ],
            },
            phoneRules:{
                phone: [
                    { required: true, validator: validPhone, trigger: "blur" },
                ],
                code: [
                    { required: true, validator: validCode,  trigget: "blur" },
                ],
            }
        };
    },
    created() {
        //如  路由路径是登录、注册、微信完善信息 ——> 路由重定向是跳到首页
        const redirect = this.$route.query.redirect;
        if (
            redirect == undefined ||
            redirect == "/login" ||
            redirect == "/register" ||
            redirect == "/weChat-register"
        ) {
            this.redirect = "/home";
        } else {
            this.redirect = redirect;
        }
        this.getKey();
    },
    methods: {
        phoneNumLogin(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let formStr = JSON.stringify(this.messageForm)
                    const loginNews = beforeEncrypt(16, this.publicKey, this.rsKey, formStr)
                    this.$store.dispatch("PhoneLogin", loginNews).then((res) => {
                        const { msg, success } = res;
                        if (success) {
                            this.$router.push(this.redirect);
                            VueEvent.$emit("login");
                        } else {
                            this.$message({
                                message: msg,
                                type: "error",
                            });
                            this.disabled = false;
                        }
                    })
                }else{
                    this.disabled = false;
                    return false;
                }
            })
        },
        //获取手机验证码
        getMessageCode() {
            let formStr = this.messageForm.phone;
            const phoneForm = beforeEncrypt(
                16,
                this.publicKey,
                this.rsKey,
                formStr
            );
            getCode(
                phoneForm.formDes,
                phoneForm.rsKey,
                phoneForm.secretKey
            ).then((res) => {
                this.tDisabled = true;
                if (res.success) {
                    this.countDown();
                } else {
                    this.tDisabled = true;
                    this.timeCount = 60;
                    this.countDown();
                }
                this.$message({
                    type: res.success ? "success" : "warning",
                    message: res.msg,
                });
            });
        },
        //倒计时60秒
        countDown() {
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.timeCount = TIME_COUNT;
                this.tDisabled = true;
                this.timer = setInterval(() => {
                    if (this.timeCount > 0 && this.timeCount <= TIME_COUNT) {
                        this.timeCount--;
                    } else {
                        this.tDisabled = false;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        },
        onMpanelError(){
            setTimeout(()=>{
                this.isRules = false;
            },2000)
        },
        onMpanelSuccess(){
            setTimeout(()=>{
                this.isRules = true;
            },2000)
        },
        onMpanelError1(){
            this.isDrag = false;
        },
        onMpanelSuccess1(){
            this.isDrag = true;
        },
        openClause() {
            this.isClause = true;
            let routeData = this.$router.resolve({ path: "/clause" });
            window.open(routeData.href, "_blank");
        },
        openPrivacy() {
            this.isPrivacy = true;
            let routeData = this.$router.resolve({ path: "/privacy" });
            window.open(routeData.href, "_blank");
        },
        //用户名密码登录
        namePwdLogin(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.isDrag){
                        let formStr = JSON.stringify(this.passForm)
                        const loginNews = beforeEncrypt(16, this.publicKey, this.rsKey, formStr)
                        this.$store.dispatch("UserLogin", loginNews).then((res) => {
                            const { msg, success } = res;
                            if (success) {
                                this.$router.push(this.redirect);
                                VueEvent.$emit("login");
                            } else {
                                this.$message({
                                    message: msg,
                                    type: "error",
                                });
                                this.disabled = false;
                                this.isDrag = false;
                                this.isfinish = false;
                            }
                        });
                    }else{
                        this.$message({
                            type:'warning',
                            message:'请滑动滑块进行验证，验证成功后方可登录。'
                        })
                    }

                } else {
                    this.disabled = false;
                    return false;
                }
            });
        },
        //去注册
        handleRegister() {
            this.$router.push("/register");
        },
        forgetPassword() {
            this.$router.push("/forget-password");
        },
        //密码登录、短信登录切换
        changeTabs(index) {
            this.tabsIndex = index;
            if(index == 0){
                if(!this.$refs["passForm"]){
                    return false
                }else{
                    this.$refs["passForm"].resetFields();
                }

            }else if(index == 1){
                if(!this.$refs["messageForm"]){
                    return false
                }else{
                    this.$refs["messageForm"].resetFields();
                }
            }
        },
        //微信二维码弹框显示
        showWeChatImg() {
            if (!this.disabled) {
                this.visible = true;
            }
        },
        //生成二维码
        wxCreate() {
            const redirectUrl = this.$route.query.redirect;
            getQrCode(redirectUrl).then(res => {
                this.iframeURL = res.body.data.authUrl;
                this.state = res.body.data.state;
                this.visible = true;
            })
        },
        //校验是否扫码
        async wxopen() {
            //生成二维码
            this.wxCreate();
        },
        getKey() {
            getPublicKey().then((res) => {
                if (res.success) {
                    const { body } = res;
                    this.publicKey = body.data.publicKey;
                    this.rsKey = body.data.redisKey;
                }
            });
        },
    },
    beforeDestroy() {
        this.obj = null;
    },
    watch:{
        'passForm.username'(value){
            if(value.length != 0){
                if(this.passForm.password.length !=0 ){
                    this.isfinish = true;
                }
            }else{
                this.isfinish = false;
            }
        },
        'passForm.password'(value){
            if(value.length != 0){
                if(this.passForm.username.length !=0 ){
                    this.isfinish = true;
                }
            }else{
                this.isfinish = false;
            }
        }
    }
};
</script>
<style>
#loginBox .el-dialog__body {
    padding: 0 !important;
}
.wechat-login-box .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<style lang="less">
.phoneCodeBtnBox{
    text-align: right;
}
.phoneCodeBtn{
    width: 92px;
}
.checkRegister{
    margin-bottom: 30px;
    height: 40px;
    background: red;
}
#iframeContain{
    width: 320px;
    height: 400px;
    padding: 15px 0 15px;
    background: #fff;
    box-sizing: content-box;
    border: 0;
}
.iframeBox{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background: rgba(0,0,0,.6);
}
.bgImg {
    width: 100%;
    height: 100%;
}
#qrCode {
    width: 300px;
    margin: 0 auto;
}
.loginBack video {
    object-fit: cover;
}
.loginFormBox .el-form-item__label {
    font-size: 25px;
    color: #6f7583;
}
.loginFormBox .el-form-item {
    margin-bottom: 30px;
    border-bottom: solid 1px #e6e7e8;
}
.loginFormBox .el-input__inner {
    border: 0;
}
.messageRow .el-form-item {
    margin: 0;
    border-bottom: 0;
}
.messageRow {
    margin-bottom: 30px;
    border-bottom: solid 1px #e6e7e8;
}
.messageRow .el-col-7 {
    padding: 0;
    p {
        text-align: right;
        color: #3858a1;
        font-size: 15px;
        font-weight: bold;
    }
}
.messageLogin {
    margin: 0 !important;
}
.messageLogin .el-form-item__content {
    margin: 0 !important;
}
.messageLogin .el-form-item__content .el-button {
    background: #1b4094;
    border-color: #1b4094;
    width: 100%;
}
.loginContainer .el-divider span {
    font-size: 14px;
    color: #ababac;
}
.other-tool-box {
    font-size: 12px;
    color: #a0a0a0;
    text-align: right;
    margin-top: 12px;
}
</style>
<style lang="less" scoped>
#loginBox {
    position: relative;
    overflow: hidden;
    height: 820px;
}
.loginBack {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.statement {
    display: flex;
    align-items: center;
    height: 54px;
    font-size: 14px;
    color: #a0a0a0;
    em{
        font-style: normal;
    }
    span {
        color: #ff3535;
    }
}
.login-tabs-box {
    height: 150px;
    line-height: 150px;
    span {
        margin-top: 6px;
        display: inline-block;
        font-size: 16px;
        color: #6f7583;
    }
    span.left {
        margin-top: 0;
        margin-right: 16px;
        font-weight: bold;
        font-size: 25px;
        color: #252b3a;
    }
}
.loginContainer {
    position: absolute;
    left: 50%;
    top: 96px;
    margin-left: -283px;
    z-index: 4;
    padding: 0 74px;
    width: 566px;
    height: 628px;
    background: #fff;
    box-shadow: 0 2px 12px 0 #74a5ea;
}
.main-container {
    padding: 60px 0;
}
</style>
