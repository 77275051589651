<template>
    <div class="main-container" style="background: #f3f5f8">
        <!--
            二维码盒子
            三分钟倒计时
            提示：请在三分钟之内进行支付
        -->
        <div class="codeImgBox" v-if="disabled == true">
            <div class="payImgBox">
                <p>
                    三分钟倒计时: <em>00:{{ minute }}:{{ second }}</em>
                </p>
                <img :src="payImg" alt="" class="payImg" />
                <span>提示：请在三分钟之内进行支付</span>
            </div>
        </div>

        <div class="w12 overflowh">
            <div class="details-crumbs">
                <p class="bar2"></p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>产品中心</el-breadcrumb-item>
                    <el-breadcrumb-item>订单支付</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="order-box">
                <div class="order-title-box">
                    <h3>{{ productName }}</h3>
                    <p>—</p>
                    <span>产品服务购买</span>
                </div>
                <div class="order-content-box">
                    <div class="order-type-box">
                        <div class="order-label">类型：</div>
                        <div class="flex1 order-type-text">
                            <p>产品服务</p>
                        </div>
                    </div>
                    <div class="order-specs-box" v-if="hasNews">
                        <div class="order-label">规格：</div>
                        <div class="order-specs-type-box flex1">
                            <div
                                class="order-specs-type pointer"
                                v-for="i in 4"
                                :key="i"
                                :class="{ active: activeIndex == i }"
                                @click="choseSpecs(i, packageList[i])"
                            >
                                <div class="title">
                                    {{ packageList[i].name }}
                                </div>
                                <div class="flex1 order-news">
                                    <div class="order-price">
                                        <span>¥</span>
                                        <h3>{{ packageList[i].price }}</h3>
                                    </div>
                                    <div class="order-tips">
                                        <p>
                                            规格：{{
                                                packageList[i].field1 / 8000
                                            }}页
                                        </p>
                                        <p>
                                            有效时长：{{
                                                efficientTime(
                                                    packageList[i].efficientTime
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <img
                                    src="~assets/images/product/buyImg.png"
                                    alt=""
                                    class="buyImg"
                                    v-if="activeIndex === i"
                                />
                            </div>
                        </div>
                    </div>
                    <div id="order-pay-box" class="mtop128">
                        <div class="pay_type_box">
                            <div
                                class="pay_type pointer"
                                @click="changePayMode('2')"
                            >
                                <p
                                    :class="{
                                        activeType: orderInfoVo.payMode == '2',
                                    }"
                                ></p>
                                <img
                                    src="~assets/images/product/zfbImg.png"
                                    alt=""
                                />
                                <span>支付宝支付</span>
                            </div>
                            <div
                                class="pay_type pointer"
                                @click="changePayMode('1')"
                            >
                                <p
                                    :class="{
                                        activeType: orderInfoVo.payMode == '1',
                                    }"
                                ></p>
                                <img
                                    src="~assets/images/product/wxImg.png"
                                    alt=""
                                />
                                <span>微信支付</span>
                            </div>
                        </div>
                        <div class="discountBox" v-if="moneyObj.id != ''">
                            <div class="discountTitle" @click="changeDiscont">
                                使用红包/礼品卡/抵用
                                <i :class="isDiscount ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                            </div>
                            <div class="discontRedPacket" v-if="isDiscount">
                                <div class="discountImgBox"  @click="choseRedPacket">
                                    <img src="~assets/images/redPackImg1.png" alt="" v-show="!choseDis">
                                    <img src="~assets/images/redPackImg.png" alt="" v-show="choseDis">
                                    <div class="discountNews">
                                        <p>¥ {{moneyObj.amount}}</p>
                                        <span  :class="{'active':choseDis}">仅限平台使用</span>
                                        <em  :class="{'active':choseDis}">有效期至：{{moneyObj.deadline}}</em>
                                    </div>
                                </div>
                                <p>*红包一次性抵扣，不可拆分使用。</p>
                            </div>
                        </div>
                        <div class="order-payment-price">
                            <p>应交金额：</p>
                            <h3>¥{{ showPrice * orderInfoVo.buyCount }}</h3>
                        </div>
                        <button
                            class="paymentBtn pointer"
                            @click="orderPayment"
                            :disabled="disabled"
                        >
                            立即支付
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getProductDetails } from "api/product";
import { transactions, queryOrder, getAmount } from "api/order";
export default {
    computed: {
        efficientTime() {
            return function (type) {
                let retrunType = "";
                if (type == 1) {
                    retrunType = "24个小时";
                } else {
                    retrunType = type + "天";
                }
                return retrunType;
            };
        },
        showPrice() {
            if (this.activeIndex != -1) {
                this.packageList.forEach((item, index) => {
                    if (index == this.activeIndex) {
                        if(this.choseDis){
                            let priceNum = Math.floor((item.price*this.orderInfoVo.buyCount - this.moneyObj.amount)*100)/100
                            if(priceNum<=0){
                                this.payPrice = 0
                            }else{
                                this.payPrice = priceNum
                            }
                        }else{
                            this.payPrice = item.price*this.orderInfoVo.buyCount;
                        }
                    }
                });
            }
            return this.payPrice;
        },
        second: function () {
            return this.num(this.seconds);
        },

        minute: function () {
            return this.num(this.minMinutes);
        },
    },
    data() {
        return {
            activeIndex: -1,
            productName: "",
            packageList: [],
            payPrice: 0,
            payMode: null,
            orderInfoVo: {
                product: {
                    id: "",
                },
                productPaymentMode: {
                    id: "",
                },
                payMode: "",
                buyCount: 1,
                promotionRecordId:''
            },
            payImg: "",
            disabled: false,
            orderId: "",
            seconds: 0,
            minMinutes: 3,
            timer: null,
            hasNews: false,
            isDiscount:false,
            choseDis:false,
            moneyObj:{
                amount:0,
                deadline:'',
                id:''
            }
        };
    },
    created() {
        this.productId = this.$route.query.productId;
        this.orderInfoVo.product.id = this.$route.query.productId;
        if (this.$route.query.packageType) {
            this.activeIndex = parseInt(this.$route.query.packageType);
        }
        this.fetchData(this.productId);
        this.fetchAmount();
    },
    methods: {
        choseRedPacket(){
            if (this.activeIndex == -1) {
                this.$message({
                    message: "请选择购买规格",
                    type: "warning",
                });
            }else{
                this.choseDis = !this.choseDis
                this.orderInfoVo.promotionRecordId = this.choseDis ? this.moneyObj.id : '';
            }
        },
        changeDiscont(){
            this.isDiscount = !this.isDiscount;
        },
        orderPayment() {
            if (this.activeIndex == -1) {
                this.$message({
                    message: "请选择购买规格",
                    type: "warning",
                });
            } else if (this.orderInfoVo.payMode == "") {
                this.$message({
                    message: "请选择付款方式！",
                    type: "warning",
                });
            } else {
                //如果时次卡
                //月、季、年
                let type = "";
                if (this.activeIndex == 0) {
                    type = "times";
                } else {
                    type = "days";
                }
                transactions(this.orderInfoVo, type).then((res) => {
                    if (res.success && res.errorCode == "2000") {
                        const qrcode = res.body.data.orderInfo.qrcode
                        if(qrcode != undefined){
                            this.payImg = res.body.data.orderInfo.qrcode;
                            this.orderId = res.body.data.orderInfo.id;
                            this.Countdown(res.body.data.orderInfo.id);
                            this.disabled = true;
                        }else{
                            this.disabled = false;
                            this.payImg = "";
                            this.$router.push("/product-order-success");
                        }

                    } else if (res.errorCode == "3003") {
                        this.disabled = false;
                        this.$message({
                            message: res.msg,
                            type: "warning",
                        });
                    }
                });
            }
        },
        changePayMode(payMode) {
            this.orderInfoVo.payMode = payMode;
        },
        choseSpecs(index, item) {
            this.activeIndex = index;
            this.orderInfoVo.productPaymentMode.id = item.id;
        },
        payment() {
            this.$router.push("/product-order-success");
        },
        num(n) {
            return n < 10 ? "0" + n : "" + n;
        },
        Countdown(id) {
            var _this = this;
            _this.timer = window.setInterval(function () {
                if (_this.seconds === 0 && _this.minMinutes !== 0) {
                    _this.seconds = 59;
                    _this.minMinutes -= 1;
                } else if (_this.minMinutes === 0 && _this.seconds === 0) {
                    _this.seconds = 0;
                    window.clearInterval(_this.timer);
                    _this.disabled = false;
                    _this.payImg = "";
                } else {
                    _this.seconds -= 1;
                }
                queryOrder(id).then((res) => {
                    if (res.success) {
                        if (res.body.data.status == "3") {
                            window.clearInterval(_this.timer);
                            _this.disabled = false;
                            _this.payImg = "";
                            _this.$router.push("/product-order-success");
                        }
                    }
                });
            }, 1000);
        },
        fetchData(id) {
            getProductDetails(id).then((res) => {
                if (res.success) {
                    this.productName = res.body.data.productInfo.name;
                    this.packageList =
                        res.body.data.productInfo.productPaymentRuleList;
                    this.orderInfoVo.productPaymentMode.id =
                        this.activeIndex != -1
                            ? this.packageList[this.activeIndex].id
                            : -1;
                    this.hasNews = true;
                }
            });
        },
        fetchAmount(){
            getAmount().then(res => {
                if(res.success){
                    if(res.body.data.length>0){
                        this.moneyObj = res.body.data[0];
                    }
                }
            })
        }
    },
    watch: {
        second: {
            handler(newVal) {
                this.num(newVal);
            },
        },
        minute: {
            handler(newVal) {
                this.num(newVal);
            },
        },
    },
    beforeDestroy() {
        if (this.timer) {
            //如果定时器还在运行 或者直接关闭，不用判断
            clearInterval(this.timer); //关闭
        }
    },
};
</script>
<style lang="less">
.order-specs-type-box {
    flex-wrap: wrap;
}
.el-input-number--small {
    line-height: 20px;
}
.el-input-number__decrease,
.el-input-number__increase {
    height: 18px;
}
.el-input--small .el-input__inner {
    height: 20px;
    line-height: 20px;
}
.el-input-number--small .el-input__inner {
    padding: 0 20px !important;
}
.el-input-number--small {
    width: 100px;
    margin-top: 10px;
}
</style>
<style lang="less" scoped>
.discountImgBox{
    width: 220px;
    height: 115px;
    position: relative;
    margin: 4px auto;
    cursor: pointer;
    img{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    .discountNews{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p{
            font-size: 30px;
            font-weight: bold;
            color: #fff ;
        }
        span{
            width: 140px;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            color: #fff ;
            text-align: center;
            background: #b5b5b5;
        }
        span.active{
            color: #ed3c27;
            background: #ffe7af;
        }
        em{
            margin-top: 12px;
            font-size: 12px;
            color: #8d8d8d;
            font-style: normal;
        }
        em.active{
            color: #971a0b;
        }
    }
}
.discountBox{
    padding: 12px;
    width: 336px;
    border: solid 1px #ccc;
    font-size: 14px;
    color: #3b3b3b;
    .discountTitle{
        line-height: 24px;
        cursor: pointer;

    }
    .discontRedPacket{
        border-top: solid 1px #3b3b3b;
        padding: 6px 0;
        text-align: center;
        >p{
            color: crimson;
        }
    }
}
.codeImgBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.payImgBox {
    width: 370px;
    height: 444px;
    border: solid 1px #252b3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    img {
        border: solid 1px #ccc;
        width: 236px;
    }
    p {
        margin: 16px 0;
        font-size: 22px;
        color: #252b3a;
        em {
            color: #ff8610;
            font-style: normal;
        }
    }
    span {
        font-size: 16px;
        color: #252b3a;
        margin: 20px 0;
    }
}
</style>
