<template>
    <div
        id="applicationBox"
        style="background: #f3f5f8"
        v-loading="loading"
        element-loading-text="编码转换中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
    >
        <div class="w12 h100">
            <div class="conversionBox">
                <div class="conversionTitleBox">
                    <p>自动识别</p>
                    <p>转换结果</p>
                </div>
                <div class="conversion-handle-box">
                    <div class="c-trueBtn" @click="conversion">
                        自动
                        <br />
                        转换
                    </div>
                    <div class="conversion-news-box bright1">
                        <div class="code-type-box">
                            <el-form
                                ref="form"
                                :model="form"
                                label-width="80px"
                            >
                                <el-form-item label="编码类型">
                                    <el-select
                                        v-model="form.from"
                                        placeholder=""
                                        disabled="disabled"
                                    >
                                        <el-option
                                            v-for="item in fromOpttions"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="mglTxtBox menkTitle">
                            <i
                                class="el-icon-close"
                                v-if="form.content.length > 0"
                                @click="clear"
                            ></i>
                            <p class="codeTypeBox">
                                {{ fromTitle }}
                            </p>
                            <el-input
                                type="textarea"
                                v-model="form.content"
                                placeholder="    ......"
                            ></el-input>
                        </div>
                        <el-row type="flex" justify="end" class="elRowBox">
                            <el-tooltip
                                class="item pointer"
                                effect="dark"
                                content="复制转换原始文字"
                                placement="top"
                                v-if="form.content.length != 0"
                            >
                                <i
                                    id="original"
                                    class="el-icon-document-copy"
                                    :data-clipboard-text="form.content"
                                    @click="copytext('original')"
                                ></i>
                            </el-tooltip>
                        </el-row>
                    </div>
                    <div class="conversion-news-box bleft1">
                        <div class="code-type-box">
                            <el-form
                                ref="form"
                                :model="form"
                                label-width="80px"
                            >
                                <el-form-item label="编码类型">
                                    <el-select
                                        v-model="form.to"
                                        @change="handleCodeType"
                                        :disabled="disabled"
                                        placeholder=""
                                    >
                                        <el-option
                                            v-for="item in toOptions"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="mglTxtBox menkTitle">
                            <p class="codeTypeBox">
                                {{ toTitle }}
                            </p>
                            <el-input
                                type="textarea"
                                v-model="toContent"
                                disabled="disabled"
                            ></el-input>
                        </div>
                        <el-row type="flex" justify="end" class="elRowBox">
                            <el-tooltip
                                v-if="toContent.length != 0"
                                class="item pointer"
                                effect="dark"
                                content="复制转换结果文字"
                                placement="top"
                            >
                                <i
                                    id="result"
                                    class="el-icon-document-copy"
                                    :data-clipboard-text="toContent"
                                    @click="copytext('result')"
                                ></i>
                            </el-tooltip>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Clipboard from "clipboard";
import { isMgl, isMkMgl } from "utils/fontCodeType";
import { codeConvert } from "api/product";
export default {
    data() {
        return {
            productId: "",
            disabled: true,
            loading: false,
            fromOpttions: [
                {
                    id: "",
                    name: "",
                },
            ],
            toOptions: [],
            form: {
                from: "",
                content: ``,
                to: "",
                productId: "",
            },
            toContent: "",
            fromTitle: "……",
            toTitle: "……",
        };
    },
    created() {
        this.productId = this.$route.query.productId;
    },
    methods: {
        clear() {
            this.form.content = "";
            this.form.from = "";
            this.form.to = "";
            this.fromTitle = "……";
            this.toTitle = "……";
            this.toContent = "";
            this.disabled = true;
        },
        copytext(type) {
            let that = this;
            let clipboard = null;
            if (type == "original") {
                clipboard = new Clipboard("#original");
            } else {
                clipboard = new Clipboard("#result");
            }
            clipboard.on("success", () => {
                that.$message.success("复制成功！");
                clipboard.destroy();
            });
            clipboard.on("error", () => {
                that.$message.success("复制失败！");
                clipboard.destroy();
            });
        },
        handleCodeType(val) {
            this.form.to = val;
        },
        conversion() {
            this.loading = true;
            if (this.form.content.length == 0) {
                this.$message({
                    type: "warning",
                    message: "请输入内容！",
                });
                this.loading = false;
                this.disabled = true;
                return false;
            } else {
                //是否是蒙古文字
                if (!isMkMgl(this.form.content) && !isMgl(this.form.content)) {
                    this.$message({
                        type: "error",
                        message: "请输入蒙古文！",
                    });
                    this.loading = false;
                    this.disabled = true;
                    return false;
                } else {
                    let list = [
                        {
                            id: "__MENK",
                            name: "蒙科立传统蒙古文编码",
                        },
                        {
                            id: "__StdEx",
                            name: "传统蒙古文编码“共享工程”",
                        },
                    ];
                    let list1 = [
                        {
                            id: "__GB",
                            name: "传统蒙古文编码国家标准",
                        },
                        {
                            id: "__StdEx",
                            name: "传统蒙古文编码“共享工程”",
                        },
                    ];
                    this.form.from = isMgl(this.form.content)
                        ? "__GB"
                        : "__MENK";
                    this.fromTitle = isMgl(this.form.content)
                        ? "    "
                        : "    ";

                    this.fromOpttions[0]["id"] = isMgl(this.form.content)
                        ? "__GB"
                        : "__MENK";
                    this.fromOpttions[0]["name"] = isMgl(this.form.content)
                        ? "传统蒙古文编码国家标准"
                        : "蒙科立传统蒙古文编码";
                    this.hasOptions = true;
                    this.toOptions = isMgl(this.form.content) ? list : list1;
                    if (this.form.to.length == 0) {
                        this.form.to = isMgl(this.form.content)
                            ? "__MENK"
                            : "__GB";
                        this.toTitle = isMgl(this.form.content)
                            ? "    "
                            : "    ";
                    } else {
                        if (this.form.to == "__StdEx") {
                            this.toTitle =
                                "     ";
                        } else if (this.form.to == "__GB") {
                            this.toTitle =
                                "    ";
                        } else {
                            this.toTitle =
                                "    ";
                        }
                    }
                    codeConvert(this.form, this.productId).then((res) => {
                        if (res.success && res.errorCode == "2000") {
                            this.toContent = res.body.data;
                            this.loading = false;
                            this.disabled = false;
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                            });
                            this.loading = false;
                            this.disabled = true;
                            this.form.content = "";
                        }
                    });
                }
            }
        },
    },
};
</script>
<style>
.elRowBox {
    margin-top: 10px;
    height: 14px;
}
.conversion-news-box .el-select {
    width: 100%;
}
.conversion-handle-box .el-textarea {
    width: 100%;
    height: 100%;
}
.conversion-handle-box .el-textarea.is-disabled .el-textarea__inner {
    background: #fafafa;
}
.conversion-handle-box .el-textarea textarea {
    height: 100%;
    width: 92%;
    color: #252b3a !important;
    font-size: 18px !important;
    font-family: "MenkGarqag" !important;
    border: none;
    background: none;
    writing-mode: tb-lr !important;
    -webkit-writing-mode: vertical-lr !important;
    -webkit-text-orientation: sideways-right !important;
    writing-mode: vertical-lr !important;
    text-orientation: sideways-right !important;
    display: table-column;
    word-break: keep-all;
    text-align: justify;
}
</style>
<style lang="less" scoped>
.mglTxtBox {
    flex: 1;
    position: relative;
    > .el-icon-close {
        position: absolute;
        right: -16px;
        top: -10px;
        z-index: 99;
        cursor: pointer;
    }
    .codeTypeBox {
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        color: #1b4094;
        border-right: solid 1px #ccc;
    }
}
.c-trueBtn {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 101;
    margin: -33px 0 0 -33px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    background: #1c4392;
    font-size: 16px;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
}
.bright1 {
    border-right: solid 1px #fff;
    padding-right: 52px !important;
}
.bleft1 {
    border-left: solid 1px #e5e5e5;
    background: #fafafa;
    padding-left: 52px !important;
}
.conversion-news-box {
    display: flex;
    flex-direction: column;
    width: calc(50% - 1px);
    height: 100%;
    padding: 16px;
}
.conversion-handle-box {
    display: flex;
    flex-direction: row;
    height: calc(100% - 46px);
    border: solid 1px #e5e5e5;
    border-radius: 12px;
    background: #ffffff;
    position: relative;
}
.conversionTitleBox {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: bold;
    font-family: "思源黑体";
    color: #252b3a;
    height: 46px;
    p {
        width: 50%;
        padding-left: 16px;
    }
}
.conversionBox {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    height: 100%;
}
#applicationBox {
    min-height: 500px;
}
</style>
