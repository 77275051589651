<template>
    <div class="main-container" style="background:#f3f5f8">
        <Banner/>
        <List/>
    </div>
</template>
<script>
import Banner from 'views/routerViews/Case/Index/Banner'
import List from 'views/routerViews/Case/Index/List'
export default {
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙古文OCR，机器翻译，固什词典，wps蒙古文专版，蒙古文校对，编码转换",
            },
        ],
    },
    components:{ Banner, List }
}
</script>
