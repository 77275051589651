<template>
    <div class="package-container">
        <div class="w12 h100">
            <div class="package-title">
                <p class="menkTitle"> <br/>  <br/> <br/></p>
                <span>产品服务套餐</span>
            </div>
            <div class="package-list-box">
                <div class="package-li-box" v-for="(item,index) in packageList" :key="item.id">
                    <img src="~assets/images/product/hotImg.png" alt="" class="hotImg" v-if="index == 0 || index == 1 || index == 2">
                    <div class="package-type">
                        <p v-if="index == 0 && singleBuy == '1'">{{item.price}}元</p>
                        <span>{{item.name}}</span>
                    </div>
                    <div class="package-rule-box">
                        <div class="package-rule-news-box">
                            <p>规格：</p>
                            <div>
                                <em>{{item.field1}}</em>
                                <p>个字符</p>
                            </div>
                        </div>
                        <div class="package-rule-news-box">
                            <p>有效时长：</p>
                            <div>
                                <em>{{efficientTime(item.efficientTime)}}</em>
                                <p>有效</p>
                            </div>
                        </div>
                    </div>
                    <div class="package-price-box">
                        <p>{{item.price}}</p>
                        <span>元/{{item.field2}}</span>
                    </div>
                    <div class="package-btn-box" @click="enterPay(index)">立即选购</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie"
export default {
    props:{
        packageList:{
            type:Array,
            default() {
                return []
            }
        },
        name:{
            type:String,
            default:''
        },
        productId:{
            type:String,
            default:''
        },
        singleBuy:{
            type:String,
            default:''
        }
    },
    computed:{
        efficientTime() {
            return function (type) {
                let retrunType = "";
                if(type == 1){
                    retrunType = '24个小时'
                }else{
                    retrunType = type + '天'
                }
                return retrunType;
            };
        },
    },
    data() {
        return {
            token: PcCookie.get(Key.tokenKey),
        }
    },
    methods: {
        enterPay(index){
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再下载！",
                    type: "warning",
                });
            } else {
                this.$router.push({
                    path:'/product-order',
                    query:{
                        productId:this.productId,
                        packageType: index
                    }
                })
            }
        }
    }
}
</script>
<style lang="less" scoped>
.package-btn-box{
    width: 110px;
    height: 36px;
    font-size: 14px;
    color: #fff;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    background: #3c7fff;
}
.package-price-box{
    padding-left: 20px;
    width: 198px;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: solid 1px #eeeeee;
    p{
        font-size: 25px;
        color: #ff6701;
        margin-right: 4px;
    }
    span{
        font-size: 12px;
        color: #252b3a;
    }
}
.package-rule-news-box{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    >p{
        font-size: 16px;
        color: #252b3a;
    }
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 214px;
        height: 56px;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 0 6px 0 #c3d3ef;
        em{
            font-size: 25px;
            color: #252b3a;
            font-weight: bold;
        }
        p{
            margin-left: 6px;
            font-size: 14px;
            color: #252b3a;
            font-weight: bold;
            font-style: italic;
        }
    }
}
.package-rule-box{
    width: 640px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 20px;
}
.package-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 226px;
    height: 100%;
    background: -webkit-linear-gradient(#ebf1fb, #f5f6f9);
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(#ebf1fb, #f5f6f9);
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(#ebf1fb, #f5f6f9);
    /* Firefox 3.6 - 15 */
    background: linear-gradient(#ebf1fb, #f5f6f9);
    p{
        font-size: 35px;
        color: #ff6701;
        font-weight: bold;
        margin-right: 6px;
    }
    span{
        color: #252b3a;
        font-size: 20px;
        font-weight: bold;
    }
}
.hotImg{
    position: absolute;
    left: -7px;
    top:  -7px;
    z-index: 99;
}
.package-li-box{
    height: 102px;
    border: solid 1px #e6e6e6;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fcfdfd;
    font-family: '思源黑体';
}
.package-li-box:hover{
    border: solid 1px #3c7fff;
}
.package-li-box:hover .package-btn-box{
    background: #ff6701;
}
.package-li-box.active{
    border: solid 1px #3c7fff;
}
.package-li-box.active .package-btn-box{
    background: #ff6701;
}
.package-list-box{
    padding: 0 7px;
}
.package-container{
    padding-bottom: 62px;
    background: #fff;
    .package-title{
        padding: 33px 0;
        font-size: 35px;
        color: #252b3a;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        p{
            margin-right: 12px;
        }
    }
    .package-list{
        height: 376px;
    }
}
</style>
