<template>
    <div class="scene-item h100" :style="{'width':width}">
        <el-card class="box-card h100" :body-style="{ padding: '0px',height: '100%' }">
            <div class="contentBox h100">
                <div class="pictureBox" :style="{'top':top,'bottom':bottom}">
                    <slot name="sceneImg"></slot>
                </div>
                <i :class="iconClass" :style="{'top':iconTop}"></i>
                <div class="textBox" :style="{'top':bottom,'bottom':top}">
                    <slot name="title"></slot>
                    <slot name="text"></slot>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    props:{
        width: {
            type: String,
            default: '286px'
        },
        top: {
            type: String,
            default: ''
        },
        bottom:{
            type: String,
            default: ''
        },
        img:{
            type: String,
            default: ''
        }
    },
    data() {
        return {
            icClass: ''
        }
    },
    computed:{
        iconClass() {
            let icClass = this.top == '' ? 'el-icon-caret-bottom' : 'el-icon-caret-top'
            return icClass
        },
        iconTop() {
            let iTop = this.top == '' ? '173px' : '250px'
            return iTop
        }
    },
    created() {
    }
}
</script>
<style>
    .scene-item .box-card i{
        position: absolute;
        left: 50%;
        z-index: 9;
        margin-left: -12px;
        font-size: 24px;
        color: #fff;
    }
    .scene-item .box-card .title{
        font-size: 18px;
        line-height: 36px;
        font-weight: 600;
        color: #252b3a;
    }
    .scene-item .box-card .sceneImg{
        width: 100%;
        height: 100%;
    }
    .scene-item .box-card .text{
        width: 100%;
        height: 84px;
        font-size: 14px;
        line-height: 28px;
        color: #252b3a;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
</style>
<style lang="less" scoped>

.contentBox{
    position: relative;
    .pictureBox{
        width: 100%;
        height: 266px;
        position: absolute;
        left: 0;
    }
    .textBox{
        position: absolute;
        left: 0;
        right: 0;
        padding: 32px 20px;
        height: 182px;
        
    }
}

</style>