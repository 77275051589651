import { render, staticRenderFns } from "./ProductBarItem.vue?vue&type=template&id=64bb2fa2&scoped=true&"
import script from "./ProductBarItem.vue?vue&type=script&lang=js&"
export * from "./ProductBarItem.vue?vue&type=script&lang=js&"
import style0 from "./ProductBarItem.vue?vue&type=style&index=0&id=64bb2fa2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64bb2fa2",
  null
  
)

export default component.exports