<template>
    <div id="header">
        <div class="logo-container h100 left" v-if="isShow">
            <img src="~assets/images/logo.png" alt="" />
        </div>
        <div class="w12 h100 header-nav-login">
            <div class="logo-nav-container h100">
                <div class="logo-box" v-if="!isShow">
                    <img src="~assets/images/logo.png" alt="" />
                </div>
                <div class="nav-container h100" v-if="showModular">
                    <tar-bar>
                        <tar-bar-item path="/home">
                            <span slot="item-text">首页</span>
                        </tar-bar-item>
                        <tar-bar-item  v-for="item in productList" :path="item.routerPath" :key="item.id" :productId="item.id">
                            <span slot="item-text">{{item.name}}</span>
                        </tar-bar-item>
                        <tar-bar-item path="/app-convert-mongolia">
                            <span slot="item-text">新旧蒙文转换</span>
                        </tar-bar-item>
                    </tar-bar>
                </div>
            </div>
            <div class="user-container h100" v-if="!userInfo && showModular">
                <!-- <iframe
                    id="myframe"
                    scrolling="no"
                    src="https://tianqiapi.com/api.php?style=tw&skin=pear"
                    frameborder="0"
                    width="340"
                    height="70"
                    allowtransparency="true"
                    @mouseenter="changeIframeHeight(390)"
                    @mouseout="changeIframeHeight(70)"
                ></iframe> -->
                <p @click="handleRegister">
                    <img src="~assets/images/registerImg.png" alt="">
                    <em>用户注册</em>
                </p>
                <span>|</span>
                <p @click="handleLogin">
                    <img src="~assets/images/loginBtn.png" alt="" />
                    <em>登录</em>
                </p>
            </div>
            <el-dropdown
                @command="handleCommand"
                v-if="userInfo && showModular"
            >
                <span class="el-dropdown-link">
                    <div class="header_msg">
                        <img
                            :src="
                                userInfo.headimgurl
                                    ? userInfo.headimgurl
                                    : headimgurl
                            "
                            alt="用户头像"
                            class="userImg"
                        />
                        <div class="userName">{{ userInfo.memberName }}</div>
                    </div>
                </span>
                <el-dropdown-menu slot="dropdown" class="headerDropdown">
                    <el-dropdown-item
                        icon="el-icon-user-solid"
                        command="/personal"
                    >
                        个人中心
                    </el-dropdown-item>
                    <el-dropdown-item
                        icon="el-icon-bell"
                        command="/personal/news"
                    >
                        消息中心
                    </el-dropdown-item>
                    <el-dropdown-item
                        align="center"
                        command="out"
                        class="loginOut"
                        @mouseenter.native="hover = true"
                        @mouseleave.native="hover = false"
                    >
                        <img
                            src="~assets/images/loginOut.svg"
                            v-show="!hover"
                            alt=""
                        />
                        <img
                            src="~assets/images/loginOuta.svg"
                            v-show="hover"
                            alt=""
                        />
                        退出登录
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
import VueEvent from "@/VueEvent.js";
import { PcCookie, Key } from "@/utils/cookie";
import TarBar from "components/common/application/tarbar/TarBar";
import TarBarItem from "components/common/application/tarbar/TarBarItem";
export default {
    inject: ["reload"],
    components: {
        TarBar,
        TarBarItem,
    },
    props:{
        productList:{
            type:Array,
            default() {
                return []
            }
        }
    },
    computed: {
        userInfo() {
            return PcCookie.get(Key.userInfoKey)
                ? JSON.parse(PcCookie.get(Key.userInfoKey))
                : null;
        },
    },
    data() {
        return {
            windowWidth: document.documentElement.clientWidth,
            isShow: true, //两个logo切换
            hover: false, //用户下拉框切换
            isLogin: false, //是否登录
            showModular: true, //是否显示导航及头部登录、注册按钮
            headimgurl: require("@/assets/images/userHeadImg.png"),
            aboutLeft: "",
        };
    },
    created() {
        if (this.windowWidth > 1600) {
            this.isShow = true;
            this.aboutLeft = "-304px";
        } else {
            this.isShow = false;
            this.aboutLeft = "-455px";
        }
    },
    mounted() {
        let that = this;
        window.addEventListener("resize", function () {
            that.windowWidth = document.body.offsetWidth;
            if (that.windowWidth > 1600) {
                that.isShow = true;
                that.aboutLeft = "-304px";
            } else {
                that.isShow = false;
                that.aboutLeft = "-455px";
            }
        });
    },
    methods: {
        handleRegister() {
            this.$router.push({
                path: "/register",
            });
        },
        handleLogin() {
            this.$router.push({
                path: "/login",
                query: { redirect: this.$router.currentRoute.fullPath },
            });
        },
        handleCommand(command) {
            if (command === "/personal") {
                this.$router.push(command);
            } else if (command === "/personal/news") {
                this.$router.push(command);
            } else {
                this.$store.dispatch("UserLogout").then((res) => {
                    const { success } = res;
                    if (success) {
                        VueEvent.$emit("logout");
                        this.$router.push("/");
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                })
            }
        },
    },
};
</script>

<style>
.loginOut img {
    margin-right: 5px;
}
.loginOut {
    padding-left: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.headerDropdown .el-dropdown-menu__item:focus,
.headerDropdown .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #1b4094;
    color: #fff;
}
.headerDropdown .el-dropdown-menu {
    top: 60px !important;
}
</style>
<style scoped>
.userName {
    flex: 1;
    font-size: 14px;
    color: #252b3a;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.userImg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: solid 1px #dadada;
    margin-right: 8px;
}
.header_msg {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 140px;
    height: 71px;
    box-sizing: content-box;
}
.user-container p i {
    font-size: 16px;
    color: #ffba4d;
}
.user-container em {
    margin-left: 4px;
    font-style: normal;
    align-items: center;
    display: flex;
}
.user-container span {
    margin: 0 22px;
    align-items: center;
    display: flex;
}
.user-container p {
    height: 100%;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.user-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}
.nav-container {
    display: flex;
    flex-direction: row;
}
/* .logo-box img {
    height: 45px;
    width: 112px;
} */
.logo-box {
    margin: 0 20px;
}
.logo-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.header-nav-login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
/* .logo-container img {
    height: 45px;
    width: 112px;
} */
.logo-container {
    position: absolute;
    top: 0;
    left: 60px;
    display: flex;
    align-items: center;
}
#header {
    position: relative;
    height: 73px;
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
}
</style>
