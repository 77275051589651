<template>
    <div class="contactUs">
        <div class="w12 h100">
            <div class="title">
                <p class="menkTitle"><br/> <br/></p>
                <span>联系我们</span>
            </div>
            <div class="contact-content">
                <div class="contact-text h100">
                    <img src="~assets/images/contactImg1.png" alt="" />
                    <p>联系电话</p>
                    <p>0471-6929536 (服务中心)</p>
                </div>
                <div class="contact-text h100">
                    <img src="~assets/images/contactImg2.png" alt="" />
                    <p>电子邮箱</p>
                    <img src="~assets/images/aboutEmailImg.png" style="margin-top:2px" alt="">
                </div>
                <div class="contact-text h100">
                    <img src="~assets/images/contactImg3.png" alt="" />
                    <p>QQ</p>
                    <p>391059028</p>
                </div>
                <div class="contact-text h100">
                    <img src="~assets/images/contactImg4.png" alt="" />
                    <img src="~assets/images/codeImg.png" alt="" />
                </div>
            </div>
            <div class="contactUsMap">
                <baidu-map
                    :center="center"
                    :zoom="zoom"
                    @ready="handler"
                    class="bMap"
                >
                    <bm-marker
                        :position="{ lng: 111.776614, lat: 40.849223 }"
                        :dragging="true"
                        animation="BMAP_ANIMATION_BOUNCE"
                    >
                        <bm-label
                            content="呼和浩特市赛罕区机场高速路128号<br>低碳大厦7层 010011"
                            :labelStyle="{
                                color: 'back',
                                fontSize: '16px',
                                border: 0,
                                padding: '10px 20px',
                            }"
                            :offset="{ width: -90, height: -80 }"
                        />
                    </bm-marker>
                </baidu-map>
            </div>
            <div class="workTime">
                办公时间：周一至周五 08:30-11:50 13:00-17:30
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            center: { lng: 111.776614, lat: 40.849223 },
            zoom: 20,
            show: true,
            qrCode: {
                id: 0,
                bigImg: require("@/assets/images/codeImg.png"),
            },
        };
    },
    methods: {
        handler() {
            this.center.lng = 111.776614;
            this.center.lat = 40.849223;
            this.zoom = 16;
        },
    },
};
</script>
<style>
.anchorBL,
.BMap_cpyCtrl {
    display: none !important;
}
.bMap {
    width: 100%;
    height: 232px;
}
</style>
<style lang="less" scoped>
.workTime {
    font-size: 14px;
    color: #252b3a;
    line-height: 48px;
    text-align: center;
}
.contactUsMap {
    margin-top: 20px;
    height: 232px;
}
.contactUs {
    height: 686px;
    .title {
        padding-top: 60px;
        height: 170px;
        font-size: 32px;
        color: #252b3a;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        p{
            margin-right: 12px;
        }
    }
    .contact-content {
        height: 126px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .contact-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                margin-bottom: 20px;
            }
            p {
                font-size: 14px;
                color: #262626;
                line-height: 22px;
            }
        }
    }
}
</style>
