<template>
    <div class="advantageItem" :style="{'width':width}">
        <slot name="icon"></slot>
        <div class="advatage-content">
            <slot name="title"></slot>
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        width: {
            type: String,
            default: '386px'
        }
    }
}
</script>
<style lang="less" scoped>
.advatage-content{
    height: 100%;
    width: 282px;
    color: #252b3a;
    .title{
        font-size: 18px;
        line-height: 48px;
        font-weight: 600;
    }
    .content{
        font-size: 14px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
}
.advantageItem{
    padding: 26px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    background: #fff;
}
</style>