<template>
    <div class="c-news">
        <div class="title" @click.stop="enterList">
            <slot name="title"></slot>
        </div>
        <slot name="list"></slot>
    </div>
</template>
<script>
export default {
    props: {
        path: {
            type: String,
            default: "",
        },
        newType: {
            type: String,
            default: "",
        },
    },
    methods: {
        enterList() {
            // if(this.newType !='' && this.newType=='activity'){
            //     this.$message({
            //         message: '敬请期待...',
            //         type: 'warning'
            //     });
            //     // this.$router.push({
            //     //     path: this.path,
            //     //     query: {
            //     //         newsType: this.newType,
            //     //     },
            //     // });
            // }else{
            //     this.$router.push(this.path);
            // }
            this.$router.push({
                path: this.path,
                query: {
                    newsType: this.newType,
                },
            });
        },
    },
};
</script>
<style lang="less" scoped>
.c-news {
    padding: 36px 0;
    width: 232px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 33px;
        border-bottom: solid 1px #e6e6e6;
        margin-bottom: 10px;
        > p {
            color: #1b4094;
            font-size: 16px;
        }
    }
}
</style>
