<template>
    <div class="main-container" style="background: #f7f8fa">
        <div class="w12 forgetBody h100">
            <h3>设置新密码</h3>
            <div class="forgetBodyInner">
                <p class="forget-txt-mod">
                    请输入新密码，以进行密码重设
                </p>
                <div class="formBox">
                    <el-form ref="form" :model="form" :rules="rules">
                        <el-form-item prop="password">
                            <el-input
                                v-model="form.password"
                                :disabled="disabled"
                                placeholder="新密码"
                                type="password"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="comfirePass">
                            <el-input
                                v-model="form.comfirePass"
                                :disabled="disabled"
                                placeholder="确认新密码"
                                type="password"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                type="primary"
                                style="width: 100%"
                                :disabled="disabled"
                                @click="resetPassWord('form')"
                                >确认修改</el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updatePassword } from "api/auth";
import { isvalidPass } from "@/utils/validate.js";
import { getPublicKey } from "api/perCheck";
import { beforeEncrypt } from '@/utils/encryptData.js'
export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else if (!isvalidPass(value)) {
                callback(
                    new Error(
                        "密码以字母开头 长度在6~12之间 只能包含字母、数字和下划线"
                    )
                );
            } else {
                callback();
            }
        };
        var validatecomfirePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入确认密码"));
            } else if (value !== this.form.password) {
                callback(new Error("两次输入密码不一致！"));
            } else {
                callback();
            }
        };
        return {
            rules: {
                password: [
                    {
                        required: true,
                        validator: validatePass,
                        trigget: "blur",
                    },
                ],
                comfirePass: [
                    {
                        required: true,
                        validator: validatecomfirePass,
                        trigget: "blur",
                    },
                ],
            },
            form: {
                password: "",
                comfirePass: "",
                code: null,
                phone: null,
            },
            disabled: false,

            publicKey: "",
            rsKey: "",
        };
    },
    created() {
        this.disabled = this.$route.query.code ? false : true;
        this.form.code = this.$route.query.code ? this.$route.query.code : null;
        this.form.phone = this.$route.query.phone ? this.$route.query.phone : null;
        this.getKey();
    },
    methods: {
        resetPassWord(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.disabled = true;
                    let formStr = JSON.stringify(this.form)
                    const passNews = beforeEncrypt(16, this.publicKey, this.rsKey, formStr)
                    updatePassword(passNews.formDes,passNews.rsKey,passNews.secretKey).then(res => {
                        const { success, msg } = res;
                        if(success){
                            this.$message({
                                message: msg,
                                type: "success",
                                duration:1500
                            });
                            setTimeout( ()=>{
                                this.$router.push('/login')
                            },2000)
                        }else{
                            this.$message({
                                message: msg,
                                type: "warning"
                            });
                            this.disabled = false;
                        }
                    })
                }else{
                    this.$message({
                        message: "请输入密码！",
                        type: "warning",
                    });
                    this.disabled = false;
                    return false;
                }
            })
        },
        getKey() {
            getPublicKey().then((res) => {
                if (res.success) {
                    const { body } = res;
                    this.publicKey = body.data.publicKey;
                    this.rsKey = body.data.redisKey;
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.formBox .el-form {
    margin: 60px 0;
}
.otherLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 24px;
    font-size: 14px;
    color: #333;
    p {
        font-size: 16px;
        line-height: 32px;
    }
    span {
        cursor: pointer;
    }
}
.is-focus {
    top: -8px !important;
    font-size: 12px !important;
    color: #00a4ff !important;
}
.is-error {
    font-size: 12px !important;
    color: #e54545 !important;
}
.absolute {
    position: absolute;
    left: 13px;
    top: 12px;
    z-index: 3;
    height: 16px;
    line-height: 16px;
    padding: 0 2px;
    font-size: 14px;
    color: #606266;
    background: #fff;
}
.emailRow {
    position: relative;
}
.formBox {
    width: 480px;
    margin: 0 auto;
}
.forget-txt-mod {
    font-size: 14px;
    line-height: 24px;
    margin: 40px 0 32px;
    text-align: center;
    color: #333;
}
.forgetBody {
    height: calc(100vh - 260px);
    padding: 60px 0 40px;
    display: flex;
    flex-direction: column;
}
.forgetBodyInner {
    width: 100%;
    flex: 1;
    margin: 0 auto;
    max-width: 980px;
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(3, 27, 78, 0.5);
    border: 0;
    margin-top: 32px;
    padding-top: 40px;
}
h3 {
    text-align: center;
    font-size: 26px;
    line-height: 40px;
    font-weight: 400;
}
</style>
