import { request } from '@/utils/chatRequest'
//每次请求带个时间戳——缓存不一样
export function checkWeChatLogin(state, rsKey, secretKey) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/memberInfo/login/checkLogin?timestamp=${timestamp}`,
        method: 'get',
        params: {
            state,
            rsKey,
            secretKey
        }
    });
}
//是否有注册账户
export function isExistAccount(data, rsKey, secretKey) {
    return request({
        url: `/api/v1/memberInfo/register/isExistAccount`,
        method: 'post',
        data: {
            data,
            rsKey,
            secretKey
        }
    });
}
//邮箱验证码
export function sendEmailCode(data, rsKey, secretKey) {
    return request({
        url: `/api/v1/memberInfo/login/bindAccount/sendMail`,
        method: 'post',
        data: {
            data,
            rsKey,
            secretKey
        }
    });
}
//微信绑定账号
export function bindAccount(data, rsKey, secretKey) {
    return request({
        url: '/api/v1/memberInfo/register/bindAccount',
        method: 'post',
        data: {
            data,
            rsKey,
            secretKey
        }
    });
}
//微信完善信息
export function information(data, rsKey, secretKey) {
    return request({
        url: '/api/v1/memberInfo/register/perfect/information',
        method: 'post',
        data: {
            data,
            rsKey,
            secretKey
        }
    });
}


//获取微信唯一验证码
export function getWeChatStatus() {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/memberInfo/login/getStatus?timestamp=${timestamp}`,
        method: 'get',
    });
}

//获取微信二维码
export function getQrCode(redirectUrl) {
    return request({
        url: `/api/v1/oauth/main/render/wechat`,
        method: 'get',
        params:{
            redirectUrl
        }
    });
}

//获取QQ授权
export function getQqQrCode(redirectUrl) {
    return request({
        url: `/api/v1/oauth/main/render/qq`,
        method: 'get',
        params:{
            redirectUrl
        }
    });
}


//用uuId获取用户信息
export function getUserInfo(data, rsKey, secretKey) {
    return request({
        url: `/api/v1/oauth/getToken`,
        method: 'post',
        data:{
            data,
            rsKey,
            secretKey
        }
    });
}
