<template>
    <div class="container">
        <div class="noticeListBox">
            <div class="notice-item pointer" v-for="item in noticeList" :key="item.id" @click="enterDetails(item)">
                <div class="title ellipsis">
                    {{ item.title }}
                </div>
                <div class="content" v-html="unEscapeHTML(item.content)"></div>
                <div class="date">
                    <i class="el-icon-time"></i>
                    <span>
                        {{item.createDate}}
                    </span>
                </div>
            </div>
        </div>
        <el-row type="flex" justify="center">
            <el-pagination
                 @current-change="handleCurrentChange"
                :current-page="page.current"
                :page-size="page.size"
                :total="page.total"
                layout="prev, pager, next"
            >
            </el-pagination>
        </el-row>
    </div>
</template>
<script>
import { getNoticeList } from "api/notice"
export default {
    data() {
        return {
            page:{
                total:0,
                pageNo:1,
                pageSize:6
            },
            noticeList:[],
            hasNotice:false
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        handleCurrentChange(current) {
            this.page.pageNo = current
            this.fetchData()
        },
        enterDetails(item) {
            this.$router.push({
                path:'/home-notice-details',
                query:{
                    noticeId:item.id,
                }
            });
        },
        fetchData() {
            getNoticeList(
                this.page.pageNo,
                this.page.pageSize
            ).then((res) => {
                if (res.success && res.errorCode === '2000') {
                    this.noticeList = res.body.data.rows;
                    this.page.total = res.body.data.total
                    this.hasNotice = true
                }
            });
        },
        unEscapeHTML(html) {
            html= "" +html;
            return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'");
        },
    }
}
</script>
<style lang="less" scoped>
.notice-item {
    display: flex;
    flex-direction: column;
    height: 132px;
    padding-top: 20px;
    border-bottom: dashed 1px #7d7d7d;
    .title{
        font-size: 16px;
        color: #252b3a;
        line-height: 32px;
    }
    .content{
        margin-top: 10px;
        font-size: 14px;
        color: #6f7583;
        line-height: 20px;
        height: 40px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .date{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 18px;
        margin: 6px 0;
        i{
            margin-right: 4px;
        }
        i,span{
            font-size: 12px;
            color: #323846;
        }
    }
}
.noticeListBox{
    padding: 0 35px;
    margin-bottom: 35px;
}

</style>
