<template>
    <div id="product-bar" class="product-list">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "ProductBar",
};
</script>
<style scoped>
.product-list {
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;
}
</style>
