<template>
    <div class="contentBox">
        <div class="w12">
            <div class="title-crumbs-box">
                <div class="title">新闻资讯</div>
                <div class="crumbs">
                    <p class="bar2"></p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>新闻资讯</el-breadcrumb-item>
                        <el-breadcrumb-item>{{showTypeText}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div class="news-content-box">
                <div class="news-router">
                    <p class="pointer" @click="enterList('article',newsListPath)" :class="{'active': queryType == 'article'}">最新资讯</p>
                    <p class="pointer" @click="enterList('notice',noticeListPath)" :class="{'active': queryType == 'notice'}">通知公告</p>
                    <p class="pointer" @click="enterList('activity',activityListPath)" :class="{'active': queryType == 'activity'}">最新活动</p>
                </div>
                <div class="news-list">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueEvent from "@/VueEvent.js";
export default {
    data() {
        return {
            newsListPath:'/home-news-list',
            noticeListPath:'/home-notice-list',
            activityListPath:'/home-activity-list',
            showTypeText:'',
            queryType:''
        }
    },
    mounted() {
        VueEvent.$on("changeRoute", (data) => {
            this.queryType = data.newsType
            this.getBreadcrumb(data.newsType)
        });
    },
    created() {
        this.queryType = this.$route.query.newsType
        this.getBreadcrumb(this.queryType)
    },
    methods: {
        enterList(newsType, path) {
            this.$router.push({
                path:path,
                query:{
                    newsType:newsType
                }
            })
        },
        getBreadcrumb(queryType) {
            if(queryType == 'article') {
                this.showTypeText = '最新新闻'
            }else if(queryType == 'notice') {
                this.showTypeText = '通知公告'
            }else{
                this.showTypeText = '最新活动'
            }
        }
    }
}
</script>
<style lang="less" scoped>
.news-content-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .news-router{
        width: 228px;
        padding: 0 40px;
        height: 450px;
        background: #fff;
        >p{
            margin-top: 30px;
            padding: 0 3px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #000000;
            border-bottom: solid 1px #dcdcdc;
        }
        >p.active{
            color: #1b4094;
            border-bottom: solid 1px #1b4094;
        }
    }
    .news-list{
        padding: 12px 0;
        width: 950px;
        background: #fff;
    }
}
.contentBox{
    background: #f3f5f8;
    padding-bottom: 92px;
    .w12{
        position: relative;
        .title-crumbs-box{
            height: 52px;
            position: absolute;
            left: 0;
            top: -52px;
            right: 0;
            z-index: 12;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .title{
                padding: 0 20px;
                width: 228px;
                height: 100%;
                line-height: 52px;
                font-size: 20px;
                color: #d6d7d9;
                background: rgba(0, 0, 0, .7);
            }
            .crumbs{
                width: 950px;
                padding: 5px 16px 0;
                height: 100%;
                border-bottom: solid 1px #eeeeee;
                background: #fff;
                display: flex;
                flex-direction: row;
                align-items: center;
                .bar2{
                    height: 16px;
                    width: 2px;
                    background: #6f7583;
                    margin-right: 5px;
                }
            }
        }
    }
}
</style>
