<template>
    <div class="main-container">
        <Banner />
        <List />
    </div>
</template>
<script>
import Banner from "views/routerViews/About/Recruit/Banner";
import List from "views/routerViews/About/Recruit/List";
export default {
    components: { Banner, List },
};
</script>