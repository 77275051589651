<template>
    <div
        id="applicationBox"
        v-loading="loading"
        :element-loading-text="loadingTitle"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
    >
        <div class="h100 pContainer">
            <div class="p-back-box" v-if="showImgList">
                <div class="h100 p-c-box" >
                    <div class="pc-c-select">
                        <el-select v-model="lsitValue" @change="handeleSelect">
                            <el-option
                            v-for="item in list"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.Name">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="p-img-list" v-if="list[choseIndex].backImages.length>0">
                        <div v-for="(item,index) in list[choseIndex].backImages" :key="index" class="p-img-li" @click="choseImg(item.IMG)">
                            <img :src="item.IMG" alt="">
                        </div>
                    </div>
                    <div class="p-img-list" v-else>
                        <el-row type="flex" justify="center" class="aCenter">暂无数据...</el-row>
                    </div>
                </div>
            </div>
            <div class="p-img-show pointer"  v-show="!showImgList && hasImgList" @click="showImgList = true">
                <i class="el-icon-d-arrow-left"></i>
            </div>
            <div class="p-img-show1 pointer"  v-show="showImgList && hasImgList"  @click="showImgList = false">
                <i class="el-icon-d-arrow-right"></i>
            </div>
            <div class="p-iframe-box">
                <div class="effector h100" style="padding:0;">
                    <iframe src="" style="height:100%;border:0;" width="660" id="htmlAndImg" frameborder="1"></iframe>
                </div>
            </div>
            <div class="p-upload-news-box">
                <div class="p-upload-news-left">
                    <div class="p-upload-news-l-top">
                        <div class="p-news-group">
                            <p class="p-news-group-lable menkTitle"></p>
                            <div class="p-news-group-input-box">
                                <TitleEditor @titleChange="titleChange"/>
                            </div>
                        </div>
                    </div>
                    <div class="p-upload-news-l-bottom">
                        <el-row class="h100">
                            <el-col :span="12" class="h100">
                                <div class="p-news-group">
                                    <p class="p-news-group-lable menkTitle"></p>
                                    <div class="p-textarea-box menk">
                                        <el-input
                                            type="textarea"
                                            v-model="author"
                                            maxlength="100"
                                            :disabled="loading"
                                        ></el-input>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="12"  class="h100">
                                <div class="p-news-group">
                                    <p class="p-news-group-lable menkTitle"></p>
                                    <div class="p-textarea-box menk">
                                        <el-input
                                            type="textarea"
                                            v-model="date"
                                            maxlength="100"
                                            :disabled="loading"
                                        ></el-input>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="p-upload-news-middle">
                    <ContentEditor @contentChange="contentChange" :clearText="clearText"/>
                </div>
                <div class="p-upload-news-right">
                    <div class="p-upload-news-r-t">
                        <p class="menkTitle" @click="cleanStyle">  </p>
                        <p class="menkTitle" @click="saveNews"></p>
                    </div>
                    <div class="p-upload-news-r-b">
                        <div class="p-upload-fbl">
                            <el-popover
                                placement="bottom"
                                width="312"
                                trigger="hover">
                                <div>
                                    1、生成图片默认分辨率为：640×950<br/>
                                    2、当选择为2倍时，分辨率为：1280×1900，图片大小乘以二（如：500kb×2），以此类推，倍数越大清晰度越高，随之图片大小也会翻倍<br/>
                                    3、背景图片的大小也会影响整个生成出来的图片大小<br/>
                                    4、根据自己要求选择分辨率倍数
                                </div>
                                <i class="el-icon-question" slot="reference"></i>
                            </el-popover>
                            <!-- <i class="el-icon-question"></i> -->
                            <p>图<br/>片<br/>分<br/>辨<br/>率<br/>选<br/>择</p>
                            <el-select v-model="fbl" style="width:50px">
                                <el-option
                                v-for="item in fblList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <span class="menkTitle" @click="downloadImg"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TitleEditor from 'components/common/editor/title'
import ContentEditor from 'components/common/editor/editor'
import { getAllBackImages, getAllTaxonomies, savePictureNews, IPageToImage } from 'api/product'
export default {
    components:{ TitleEditor, ContentEditor },
    data() {
        return {
            loading:false,
            loadingTitle:'',
            fbl:"1",
            fblList:[
                {
                    id:"1",
                    name:'1倍'
                },
                {
                    id:"2",
                    name:'2倍'
                },
                {
                    id:"3",
                    name:'3倍'
                },
                {
                    id:"4",
                    name:'4倍'
                },
                {
                    id:"5",
                    name:'5倍'
                }
            ],
            title:'',
            author:'',
            date:'',
            content:'',
            lsitValue:'内蒙古自治区政府',
            list:[],
            choseIndex:0,
            //显示隐藏图片集合
            showImgList:false,
            //是否有背景图
            hasImgList:false,
            //背景图路径
            imgSrc:'',
            //是否已经保存信息
            isSave:false,
            //清除样式
            clearText:'',
            isIeWeb:false,
        };
    },
    created() {
        let userAgent = navigator.userAgent;
        if (
            (userAgent.indexOf("Trident") > -1 &&
                userAgent.indexOf("rv:11.0")) ||
            (userAgent.indexOf("compatible") > -1 &&
                userAgent.indexOf("MSIE") > -1)
        ) {
            this.loading = true
            this.$notify({
                message:`该产品功能请谷歌浏览器、360极速浏览器使用！`,
                type: 'warning',
                duration:0
            });
        }
        else {
            this.isIeWeb = false;
        }
        this.fetchTypeList()
    },
    methods: {
        //下载图片
        downloadImg(){
            if(!this.isSave){
                this.$message({
                    type:"warning",
                    message:'请先保存内容信息，再下载图片文档！'
                })
                return false
            }else{
                this.loadingTitle = '图片文档下载中...'
                this.loading = true
                const formData = {
                    url: `${process.env.VUE_APP_SERVICE_URL1}Api/Content`,
                    imgSrc:  this.imgSrc,
                    iresSize:  this.fbl
                }
                IPageToImage(formData).then(res => {
                    if(!res){
                        this.$message.error('下载失败，请重新操作！')
                        return false
                    }
                    var a = document.createElement('a');
                    let serverUrl =  process.env.ENV == 'development' ?  'http://192.168.10.150:8098/' : 'https://www.mklai.cn'
                    a.href = `${serverUrl}${res}`;
                    a.id='exppub';
                    document.body.appendChild(a);
                    var alink = document.getElementById('exppub');
                    alink.click();
                    alink.parentNode.removeChild(a);
                    this.loading = false
                    this.loadingTitle = ''
                })
            }

        },
        //保存信息
        saveNews(){
            if(this.author != '' || this.date != '' || this.title != '' || this.content != ''){
                this.loadingTitle = '信息保存中...'
                this.loading = true
                const formData = {
                    "title":this.title,
                    "content": this.content,
                    "zuozhe": this.author,
                    "datetime": this.date,
                }
                savePictureNews(formData).then(() => {
                    document.getElementById("htmlAndImg").src = "/Api/Content";
                    this.loading = false
                    this.loadingTitle = ''
                    this.isSave = true
                })
            }else{
                this.$message({
                    type:'warning',
                    message:'请先输入标题、作者、日期、内容等信息！'
                })
                this.isSave = false
            }
        },
        //清除内容样式
        cleanStyle(){
            let str = this.content
            str = str.replace(/<\/?[^>]*>/g, '');
            str = str.replace(/(\n)/g, '')
            str = str.replace(/\s+style="[^"]*"/g, '')
            str = str.replace(/&nbsp;/gi, '')
            str = '<p>'+str+'</p>'
            this.content = str
            this.clearText = str
        },
        //选中底图
        choseImg(Img){
            this.showImgList = false
            if(this.author != '' || this.date != '' || this.title != '' || this.content != ''){
                document.getElementById("htmlAndImg").contentWindow.document.body.children[0].children[0].style.backgroundImage = "url(" + Img + ")";
                this.imgSrc = Img
            }else{
                this.$message({
                    type:'warning',
                    message:'请先输入标题、作者、日期、内容等信息！'
                })
            }
        },
        //切换城市
        handeleSelect(val){
            this.list.forEach((item,index) => {
                if(val == item.Name){
                    this.choseIndex = index
                }
            })
        },
        fetchTypeList(){
            getAllTaxonomies().then(res => {
                if(res.success){
                    res.data.forEach((item, index) => {
                        if(index !=0){
                            this.list.push(item)
                        }
                    })
                    this.fetchImgList()
                }
            })
        },
        fetchImgList() {
            getAllBackImages().then(res => {
                if(res.success){
                    this.list.forEach(item => {
                        res.data.forEach(itemData => {
                            if(item.ID == itemData.Taxonomy_Id){
                                item.backImages.push({
                                    IMG:itemData.Url,
                                    ID:itemData.ID
                                })
                            }
                        })
                    })
                    this.hasImgList = true
                    this.imgSrc = this.list[0].backImages[0].IMG
                }
            })
        },
        //监听内容
        contentChange(data){
            this.content = data
        },
        //监听标题
        titleChange(data){
            this.title = data
        },
    },
};
</script>
<style>
.p-news-group-input-box .el-input__inner{
    height: 30px;
    line-height: 30px;
}
.p-news-group-input-box .el-input__icon{
    line-height: 30px;
}
.p-news-group-input-box .el-select .el-input__inner{
    padding-right: 24px;
}
.p-upload-news-box .el-textarea, .p-upload-news-box .el-textarea textarea{
    height: 100%;
}
.menk textarea {
    text-align: justify;
    -webkit-text-orientation: sideways-right;
    text-orientation: sideways-right;
    display: table-column;
    -webkit-writing-mode: vertical-lr !important;
    writing-mode: vertical-lr !important;
    font-size: 18px !important;
    font-family: 'Menksoft2012';
}
.p-textarea-box .el-textarea__inner{
    padding: 5px 14px;
}
.p-upload-fbl .el-select{
    margin: 0 4px;
}
.p-upload-fbl .el-select .el-input__inner{
    padding: 4px 4px 4px 6px;
    line-height: 30px;
    height: 30px;
}
.p-upload-fbl .el-input__suffix{
    right: 0;
}
.p-upload-fbl .el-input__icon{
    line-height: 30px;
}
.pc-c-select .el-select{
    width: 100%;
}
</style>
<style lang="less" scoped>
.aCenter{
    align-items: center;
    color: #505050;
}
.p-img-show1{
    width: 22px;
    height: 74px;
    line-height: 74px;
    text-align: center;
    position: absolute;
    right: 300px;
    top: 50%;
    margin-top: -37px;
    z-index: 99;
    font-size: 18px;
    color: #fff;
    border-radius: 6px 0 0 6px;
    background: #004b9f;
}
.p-img-show{
    width: 22px;
    height: 74px;
    line-height: 74px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -37px;
    z-index: 99;
    font-size: 18px;
    color: #fff;
    border-radius: 6px 0 0 6px;
    background: #004b9f;
}
.pc-c-select{
    margin: 0 16px;
}
.p-img-li{
    width:110px;
    height:200px;
    margin:10px 0;
    border:solid 1px #ccc;
    cursor: pointer;
    img{
        width: 100%;
        height: 100%;
    }
}
.p-img-list{
    padding: 0 6px;
    margin-top: 14px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow-y: auto;
}
.p-c-box{

    display: flex;
    justify-content: center;
    flex-direction: column;
}
.p-back-box{
    padding: 20px 0;
    height: 96%;
    position: absolute;
    right: 0;
    top: 2%;
    width: 300px;
    border: solid 1px #ccc;
    background: #fff;
    z-index: 999;
    box-shadow: 0px 0px 12px rgba(0,0,0, .5);
}
.pContainer{
    position: relative;
}
.p-upload-fbl{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #707070;
    i{
        font-size: 20px;
        cursor: pointer;
    }
    i:hover{
        color: #004b9f;
    }
    p{
        margin: 4px 0;
        width: 16px;
        text-align: center;
        font-size: 14px;
    }
    span.menkTitle{
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 144px;
        background: #004b9f;
        font-size: 20px !important;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
    }
}
.p-upload-news-r-b{
    padding: 14px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.p-upload-news-r-t{
    padding: 14px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    >p{
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        padding: 16px 0;
        width: 36px;
        font-size: 20px !important;
        color: #004b9f !important;
        border: solid 1px #004b9f;
        cursor: pointer;
    }
}

.p-upload-news-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60px;
    height: 100%;
    border: solid 1px #ccc;
    border-right: 0;
    border-left: 0;
    background: #f5f5f5;
}
.p-upload-news-middle{
    margin-left: 4px;
    width: calc(100% - 60px - 194px);
    box-sizing: border-box;
}
.p-textarea-box{
    width: 57px;
    height: 100%;
}
.menk {
    -webkit-writing-mode: vertical-lr;
    -webkit-text-orientation: sideways-right;
    writing-mode: vertical-lr;
    text-orientation: sideways-right;
}
.p-news-group-title{
    flex: 1;
}
.mb10{
    margin-bottom: 10px;
}
.p-news-group-input-box{
    width: 154px;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
.p-news-group-lable{
    display: flex;
    align-items: center;
    height: 100%;
    width: 40px;
    color: #252b3a;
    font-size: 20px !important;
}
.p-news-group{
    display: flex;
    flex-direction: row;
    height: 100%;
}
.p-upload-news-l-top{
    height: 56%;
}
.p-upload-news-l-bottom{
    margin-top: 14px;
    height: calc(44% - 14px);
}
.p-upload-news-left{
    width: 194px;
    height: 100%;
}
.p-iframe-box{
    float: right;
    width: 662px;
    height: 100%;
    border: solid 1px #ccc;
}
.p-upload-news-box{
    margin-right: 662px;
    height: 100%;
    display: flex;
    flex-direction: row;
}
#applicationBox{
    padding:10px 0;
    min-height: 726px;
    background: #ffffff;
}
</style>



