<template>
    <div id="footer">
        <div class="footerNavBox">
            <span @click="enterNav('/home')">首页</span>
            <el-divider direction="vertical"></el-divider>
            <span @mouseenter="itemHover" @mouseleave="itemLeave">
                产品中心
                <i class="el-icon-arrow-up" slot="item-icon"></i>
                <div class="secondNav" v-if="showSecond">
                    <product-bar>
                        <product-bar-item
                            path="/product-details"
                            v-for="item in productList"
                            :key="item.id"
                            :productId="item.id"
                        >
                            <p slot="name">{{ item.name }}</p>
                        </product-bar-item>
                    </product-bar>
                </div>
            </span>
            <el-divider direction="vertical"></el-divider>
            <span  @click="enterNav('/download')">下载</span>
            <el-divider direction="vertical"></el-divider>
            <span  @click="enterNav('/case')">客户案例</span>
            <el-divider direction="vertical"></el-divider>
            <span  @click="enterNav('/about')">关于我们</span>
        </div>
        <div class="footerNews">
            <span>蒙公网安备15010502000755号</span>
            <span>蒙ICP备16000343号</span>
            <span>蒙ISP-20090006</span>
        </div>
    </div>
</template>
<script>
import ProductBar from "components/common/application/productbar/ProductBar";
import ProductBarItem from "components/common/application/productbar/ProductBarItem";
import { getProductsNav } from "api/product";
export default {
    components: {
        ProductBar,
        ProductBarItem,
    },
    data() {
        return {
            productList: [],
            showSecond:false
        };
    },
    created() {
        this.fetchProductNav();
    },
    methods: {
        enterNav(path){
            let routeData = this.$router.resolve({
                path: path,
            });
            window.open(routeData.href, "_blank");
        },
        itemHover() {
            this.showSecond = true;
        },
        itemLeave() {
            this.showSecond = false;
        },
        fetchProductNav() {
            getProductsNav().then((res) => {
                if (res.success) {
                    this.productList = res.body.data;
                }
            });
        },
    },
};
</script>
<style>
.footerNavBox .el-divider {
    margin: 0 20px;
    width: 1px;
    background: #6f7583;
}
</style>
<style lang="less" scoped>
.footerNews {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: #ffffff;
    > span {
        padding: 0 22px;
        font-size: 14px;
        color: #6f7583;
        cursor: pointer;
    }
}
.footerNavBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 76px;
    background: #fafafa;
    > span {
        padding: 0 22px;
        font-size: 14px;
        color: #6f7583;
        cursor: pointer;
        height: 76px;
        line-height: 76px;
        position: relative;
        .secondNav {
            position: absolute;
            bottom: 76px;
            left: -200px;
            width: 520px;
            background: #ffffff;
            z-index: 999;
            border: solid 1px #e6e6e6;
            box-shadow: -3px 4px 5px 0 rgb(102 128 3.95%), -2px 2px 0 0 #fff;
        }
    }
}
#footer {
    height: 136px;
}
</style>
