<template>
    <div class="main-container" style="background: #f3f5f8">
        <div class="w12 overflowh" v-if="hasNews">
            <div class="details-crumbs">
                <p class="bar2"></p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/about' }"
                        >关于我们</el-breadcrumb-item
                    >
                    <el-breadcrumb-item  :to="{ path: '/about-recruit' }">加入我们</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="details-content-box">
                <div class="details-title">
                    招聘信息
                    <span>发布时间：{{details.updateDate}}</span>
                </div>
                <div class="recruitBox">
                    <div class="recruitTitle">
                        <div class="recruit-position">
                            <p>招聘部门：</p>
                            <span>{{details.department.name}}</span>
                        </div>
                    </div>
                     <div class="recruitTitle">
                        <div class="recruit-position">
                            <p>招聘岗位：</p>
                            <span>{{details.duties}}</span>
                        </div>
                    </div>
                     <div class="recruitTitle">
                        <div class="recruit-position">
                            <p>岗位薪资：</p>
                            <span>{{details.salary}}</span>
                        </div>
                    </div>
                    <div class="details-notice">
                        <p>岗位要求：</p>
                        <div class="recruitNews"  v-html="unEscapeHTML(details.recruitdesc)"></div>
                    </div>
                    <div class="details-notice">
                        <p>部门描述：</p>
                        <div class="recruitNews"  v-html="unEscapeHTML(details.departdesc)"></div>
                    </div>
                    <div class="details-notice">
                        <p>工作地点：</p>
                        <div class="recruitNews">{{details.locale}}</div>
                    </div>
                    <div class="details-notice">
                        <p>简历投递邮箱：</p>
                        <div class="recruitNews">{{details.field1}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getRecruitdetails } from "api/recruit";
export default {
    data() {
        return {
            details: {},
            recruitId: "",
            hasNews: false,
        };
    },
    created() {
        this.recruitId = this.$route.query.recruitId
        this.fetchData(this.recruitId)
    },
    methods: {
        unEscapeHTML(html) {
            html = "" + html;
            return html
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&amp;/g, "&")
                .replace(/&quot;/g, '"')
                .replace(/&apos;/g, "'");
        },
        fetchData(id) {
            getRecruitdetails(id).then((res) => {
                if (res.success && res.errorCode === "2000") {
                    if(res.errorCode === '2000'){
                        this.details = res.body.data
                        this.hasNews = true
                    }
                } else {
                    this.$message({
                        type: "danger",
                        message: res.msg,
                    });
                    this.hasNews = false
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.recruitBox{
    width: 800px;
    margin: 16px auto;
}
.details-notice{
    margin: 6px 0;
    display: flex;
    flex-direction: row;
    >p{
        width: 106px;
        font-size: 15px;
        color: #252b3a;
        margin-right: 16px;
        font-weight: bold;
        line-height: 36px;
    }
    >div{
        flex: 1;
        padding: 10px 0;
    }
}
.recruitTitle{
    display: flex;
    flex-direction: column;
    line-height: 36px;
    height: 36px;
    .recruit-position{
        display: flex;
        flex-direction: row;
        margin-right: 30px;
        p{
            width: 106px;
            font-size: 15px;
            color: #252b3a;
            margin-right: 16px;
            font-weight: bold;
        }
        span{
            font-size: 15px;
            color: #252b3a;
            font-weight: bold;
        }
    }
}
.details-title {
    line-height: 42px;
    font-size: 25px;
    color: #252b3a;
    text-align: center;
    border-bottom: dashed 1px #ccc;
    position: relative;
    span{
        position: absolute;
        right: 30px;
        top: 40px;
        float: right;
        font-size: 14px;
        line-height: 16px;
    }
}
.details-content-box {
    margin: 20px 0;
    padding: 14px 20px;
}
</style>
