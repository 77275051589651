import { request } from '@/utils/request'
//用户下单 —— 单次或月、季、年
export function transactions(orderInfoVo,type,invoice,isInvoice=0) {
    return request({
        url: `/api/v1/orderInfo/transactions/${type}`,
        method: 'post',
        data:{
            ...orderInfoVo,
            invoice,
            isInvoice
        }
    });
}

//订单是否支付查询 —— 轮询
export function queryOrder(orderId) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/orderInfo/order/query?timestamp=${timestamp}`,
        method: 'get',
        params: {
            orderId
        }
    });
}

//订单详情
export function getOrderDetails(orderId) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/orderInfo/order/detail?timestamp=${timestamp}`,
        method: 'get',
        params: {
            orderId
        }
    });
}

//立即下单

export function orderPayment(id,payMode,promotionRecordId) {
    return request({
        url: '/api/v1/orderInfo/payment',
        method: 'post',
        data: {
            id,
            payMode,
            promotionRecordId
        }
    });
}

//获取红包余额
export function getAmount() {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/orderInfo/getAmount?timestamp=${timestamp}`,
        method: 'get'
    });
}
