<template>
    <div class="homeCollegecontainer">
        <img src="~assets/images/collegeImg1.png" alt="" class="collegeImg1" :class="{'right0': show}">
        <div class="w12 h100 collegeBox">
           <div class="college-title">
               与蒙科立开发者共享技术、商业创新
           </div>
           <div class="college-introduce">
               <div class="cmName">
                    <span class="menkTitle"> <br/></span>
                    <p>蒙科立学院</p>
                </div>
               <span>汇聚精品内容，云集技术大咖，关注产品成长，让热爱从这里开始</span>
               <em class="pointer" @click="enterDetails">进入蒙科立学院</em>
           </div>
           <div class="college-modular-box w12">
               <div class="h100 college-modular" @mouseenter="itemHover($event)" @mouseleave="itemleave($event)">
                   <div class="college-modular-img college-modular-img1" ></div>
                   <h4>在线学习中心</h4>
                   <p>为广大用户搭建的线上视频学习平台</p>
                   <div class="backBlue pointer collegeBtn" @click="enterLearn">进入学习</div>
               </div>
               <div class="h100 college-modular" @mouseenter="itemHover($event)" @mouseleave="itemleave($event)">
                   <div class="college-modular-img college-modular-img2" ></div>
                   <h4>客户服务中心</h4>
                   <p>提供全面系统的产品售后服务和技术支持</p>
                   <div class="backOrange pointer collegeBtn" @click="enterService">点击查看</div>
               </div>
               <div class="h100 college-modular" @mouseenter="itemHover($event)" @mouseleave="itemleave($event)">
                   <div class="college-modular-img college-modular-img3"> </div>
                   <h4>校企合作学习中心</h4>
                   <p>为高校老师及学生搭建的学习平台</p>
                   <div class="backGreen pointer collegeBtn" @click="enterCooperation">敬请期待</div>
               </div>
           </div>
           <img src="~assets/images/collegeImg.png" alt="" class="collegeImg">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            windowWidth: document.documentElement.clientWidth,
            show: false,
        };
    },
    mounted() {
        let that = this;
        window.addEventListener("resize", function () {
            that.windowWidth = document.body.offsetWidth;
            if (that.windowWidth <= 1500) {
                that.show = true;
            } else {
                that.show = false;
            }
        });
    },
    methods: {
        enterCooperation() {
            this.$message({
                message: "该功能尚未上线，敬请期待...",
                type: "warning",
            });
        },
        enterService() {
            this.$message({
                message: "该功能尚未上线，敬请期待...",
                type: "warning",
            });
        },
        enterLearn() {
            this.$router.push("/college-course");
        },
        enterDetails() {
            this.$router.push("/college");
        },
        itemHover(event) {
            event.currentTarget.children[0].classList.remove("college-enter");
            event.currentTarget.children[0].classList.add("college-leave");
        },
        itemleave(event) {
            event.currentTarget.children[0].classList.remove("college-leave");
            event.currentTarget.children[0].classList.add("college-enter");
        },
    },
};
</script>
<style lang="less" scoped>
@vw: 19.2vw;
.college-modular-img {
    width: 97px;
    height: 97px;
}
.college-modular {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h4 {
        margin-top: 4px;
        font-size: 18px;
        color: #252b3a;
    }
    p {
        margin-top: 7px;
        font-size: 14px;
        color: #252b3a;
    }
    .collegeBtn {
        margin-top: 30px;
        padding: 10px 32px;
        font-size: 16px;
        color: #fff;
    }
}
.college-modular-box {
    position: absolute;
    left: 0;
    top: 496px;
    height: 262px;
    z-index: 99;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.college-introduce {
    position: absolute;
    z-index: 10;
    margin-top: 62px;
    height: 176px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    .cmName {
        display: flex;
        flex-direction: row;
        p {
            font-size: 35px;
        }
        span {
            font-size: 26px !important;
            margin-right: 12px;
        }
    }
    > span {
        font-size: 16px;
    }
    em {
        width: 176px;
        padding: 16px 0;
        font-size: 16px;
        text-align: center;
        border: solid 1px #fff;
        font-style: normal;
    }
}
.college-title {
    padding-top: 56px;
    height: 166px;
    line-height: 110px;
    font-size: 35px;
    color: #fff;
    text-align: center;
}
.collegeImg {
    position: absolute;
    right: 26px;
    top: 192px;
    z-index: 9;
    width: 700px;
}
.collegeBox {
    position: relative;
}
.right0 {
    right: 0 !important;
}
.collegeImg1 {
    position: absolute;
    right: 302 / @vw;
    top: 282px;
    width: 312 / @vw;
}
.homeCollegecontainer {
    position: relative;
    height: 808px;
    background: #42424c;
}
</style>
