<template>
    <div id="tab-bar">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TarBar",
};
</script>



<style scoped>
#tab-bar {
    display: flex;
    width: 100%;
}
</style>
