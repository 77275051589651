<template>
    <div class="college-questions">
        <div class="w12">
            <div class="module-title">
                <div class="title">
                    <img src="~assets/images/questionsImg.png" alt="" />
                    <p>常见问题解答</p>
                </div>
            </div>
            <div class="questionBox">
                <div class="softwareBox" v-if="hasNews">
                    <div class="question-type-box" @click="enterList">
                        <div class="question-type">
                            <div>
                                <p>软件问题</p>
                                <img
                                    src="~assets/images/softwareImg.png"
                                    alt=""
                                />
                            </div>
                            <p>提供软件配置指导以及协助故障排除。</p>
                        </div>
                        <i class="el-icon-right pointer"></i>
                    </div>
                    <div class="question-list-box">
                        <div class="question-list">
                            <div class="titleBox">
                                <p>{{ productNavList[0].name }}</p>
                                <span class="pointer" @click="enterList(productNavList[0])">更多>></span>
                            </div>
                            <div v-if="questionList.length > 0">
                                <div
                                    class="question-li ellipsis pointer"
                                    v-for="(item, index) in questionList"
                                    :key="index"
                                    @click="enterDetails(item)"
                                >
                                    <em>{{ index + 1 }}.</em>
                                    {{ item.question }}
                                </div>
                            </div>
                            <div v-else class="noMore">暂无数据</div>
                        </div>
                        <p class="bar1"></p>
                        <div class="question-list">
                            <div class="titleBox">
                                <p>{{ productNavList[1].name }}</p>
                                <span class="pointer"  @click="enterList(productNavList[1])">更多>></span>
                            </div>
                            <div v-if="questionList1.length > 0">
                                <div
                                    class="question-li ellipsis pointer"
                                    v-for="(item, index) in questionList1"
                                    :key="index"
                                    @click="enterDetails(item)"
                                >
                                    <em>{{ index + 1 }}.</em>
                                    {{ item.question }}
                                </div>
                            </div>
                            <div v-else class="noMore">暂无数据</div>
                        </div>
                    </div>
                </div>
                <div class="otherQuestion">
                    <div class="question-type-box" @click="enterFeedback">
                        <div class="question-type">
                            <div>
                                <p>其他问题</p>
                                <img
                                    src="~assets/images/otherQuestionImg.png"
                                    alt=""
                                />
                            </div>
                            <p>提前规避风险，快速响应问题</p>
                        </div>
                        <i class="el-icon-right pointer"></i>
                    </div>
                    <img src="~assets/images/kfImg.jpg" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getProductsNav, getQuestions } from "api/product";
import { PcCookie, Key } from "@/utils/cookie";
export default {
    data() {
        return {
            page: {
                size: 6,
                total: 0,
                current: 1,
            },
            productNavList: [],
            productId: "",
            questionList: [],
            questionList1: [],
            hasNews:false,
            token: PcCookie.get(Key.tokenKey),
        };
    },
    created() {
        this.getListata();
    },
    methods: {
        enterDetails(item) {
            this.$router.push({
                path:'/college-problem-details',
                query:{
                    questionId:item.id
                }
            })
        },
        enterList(list) {
            this.$router.push({
                path:"/college-problem",
                query:{
                    productName: list.name,
                    productId: list.id
                }
            });
        },
        enterFeedback() {
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再使用！",
                    type: "warning",
                });
            } else {
                this.$router.push("/college-feedback");
            }
        },
        getListata() {
            const that = this;
            getProductsNav().then((res) => {
                if (res.success) {
                    const data = res.body.data;
                    for (var i = 0; i < 2; i++) {
                        this.productNavList.push(data[i]);
                        that.getQuestionsData(
                            data[i].id,
                            that.page.current,
                            that.page.size,
                            i
                        );
                    }
                }
            });
        },
        getQuestionsData(productId, current, size, i) {
            getQuestions(productId, current, size).then((res) => {
                if (res.success) {
                    if (i == 0) {
                        this.questionList = res.body.data.list;
                    } else {
                        this.questionList1 = res.body.data.list;
                    }
                    this.hasNews = true
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.noMore{
    height: 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #252b3a;
}
.otherQuestion {
    width: 286px;
    height: 100%;
}
.question-list-box {
    height: 244px;
    border: solid 1px #e6e6e6;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .question-list {
        padding: 0 15px;
        width: 440px;
        height: 100%;
        .titleBox {
            height: 58px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: #7e8492;
            margin-bottom: 8px;
            span {
                font-size: 14px;
                color: #252b3a;
            }
        }
        .question-li {
            height: 26px;
            font-size: 14px;
            color: #252b3a;
            em{
                font-style:normal
            }
        }
    }
    .bar1 {
        width: 1px;
        height: 202px;
        background: #e6e6e6;
    }
}
.question-type-box {
    padding: 0 12px 0 18px;
    height: 70px;
    background: #1b4094;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    color: #fff;
    .question-type {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        div {
            display: flex;
            flex-direction: row;
            font-size: 18px;
            height: 32px;
            align-items: center;
            img {
                margin-left: 10px;
            }
        }
        > p {
            font-size: 14px;
        }
    }
    > i {
        font-size: 20px;
        color: #fff;
        margin-bottom: 10px;
    }
}
.questionBox {
    padding-bottom: 100px;
    height: 416px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .softwareBox {
        height: 100%;
        width: 892px;
    }
}
.module-title {
    margin-top: 36px;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        p {
            margin-left: 12px;
            font-size: 18px;
            color: #252b3a;
            font-weight: bold;
        }
    }
    .more {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
    }
}
</style>
