import { request } from '@/utils/request'
//获取招聘部门列表
export function getDeptNameList(id) {
    return request({
        url: `/api/v1/recruitInfo/getDeptName`,
        method: 'get',
        params: {
            id
        }
    });
}
//获取招聘列表
export function getRecruitList(deptId, current, size) {
    return request({
        url: `/api/v1/recruitInfo/detail`,
        method: 'post',
        data: {
            deptId,
            pageNo: current,
            pageSize: size,
        }
    });
}

//获取招聘详情
export function getRecruitdetails(id) {
    return request({
        url: `/api/v1/recruitInfo/getOne`,
        method: 'get',
        params: {
            id
        }
    });
}
