<template>
    <div class="ArchiveContainer" id="ArchiveContainer">
        <div class="ArchiveSideLinks" :style="{'left':location}">
            <h4 class="ArchiveName">蒙科立输入法</h4>
            <div class="ArchiveSideLink" :class="{'active': leftActiveIndex == 0}" @click="leftActiveIndex=0">
                <p></p>
                <span>蒙科立输入法使用说明</span>
            </div>
            <div class="ArchiveSideLink" :class="{'active': leftActiveIndex == 1}" @click="leftActiveIndex=1">
                <p></p>
                <span>版本日志</span>
            </div>
        </div>
        <div class="ArchiveContent">
            <div class="ArchiveCrumbs">
                <p>{{fromPath}}</p>
                <i class="el-icon-arrow-right"></i>
                <span>蒙科立输入法</span>
            </div>
            <div class="ArchiveTitleBox">
                <h4 v-if="leftActiveIndex == 0">蒙科立输入法使用说明</h4>
                <h4 v-if="leftActiveIndex == 1">蒙科立输入法版本日志</h4>
                <div class="ArchiveUpdateTime">
                    <span>更新时间：2022-06 - 09  09:33</span>
                </div>
            </div>
            <div class="html-content" v-if="leftActiveIndex == 0">
                <div class="section" id="srfSection1">
                    <h4 class="html-content-title">输入法入门</h4>
                    <div class="html-details-content">
                        <h5 class="html-content-title-small" id="srf11">1.输入法安装</h5>
                        <div>
                            <p>双击MenkIME安装包</p>
                            <img src="~assets/images/archive/1.png" class="smallImg" alt="">
                            <p>出现以下界面，鼠标单击</p>
                            <img src="~assets/images/archive/2.png"  class="smallImg" alt="">
                            <p>进行安装。</p>
                            <img src="~assets/images/archive/3.png" alt="">
                            <h6>等待进度条</h6>
                            <img src="~assets/images/archive/4.png" alt="">
                            <p>进度条到达100%时鼠标单击</p>
                            <img src="~assets/images/archive/5.png"  class="smallImg" alt="">
                            <p>即可安装成功。</p>
                            <img src="~assets/images/archive/6.png" alt="">
                        </div>
                        <h5 class="html-content-title-small" id="srf12">2.输入法卸载</h5>
                        <div>
                            <p>在应用中心选择输入法进行卸载，选择卸载后出现以下界面点击</p>
                            <img src="~assets/images/archive/7.png" class="smallImg" alt="">
                            <p>等待进度条。</p>
                            <img src="~assets/images/archive/8.png"  alt="">
                            <p>等待进度条到达100%后鼠标点击</p>
                            <img src="~assets/images/archive/5.png" alt="">
                            <p>即可卸载成功。</p>
                            <img src="~assets/images/archive/9.png" alt="">
                        </div>
                        <h5 class="html-content-title-small" id="srf13">3.输入法切换</h5>
                        <div>
                            <p>输入法安装完成后切换输入法（按住CTRL+SHIFT键进行切换）显示</p>
                            <img src="~assets/images/archive/10.png" class="smallImg" alt="">
                            <p>可在文档中编辑蒙文。</p>
                        </div>
                        <h5 class="html-content-title-small" id="srf14">4.输入法键盘布局</h5>
                        <div>
                            <img src="~assets/images/archive/11.png"  alt="">
                        </div>
                        <h5 class="html-content-title-small"  id="srf15">5.翻页选词</h5>
                        <div>
                            <p>输入法默认翻页“-”为向前翻页“+”为向后翻页（下方键盘布局红框标注），
                                找到所需词后，按其对应数字或使用鼠标选择，使用空格键导入默认输
                                入输入法当前页面第一个词。</p>
                            <img src="~assets/images/archive/12.png"  alt="">
                            <br>
                            <img src="~assets/images/archive/13.png"  alt="">
                        </div>
                    </div>
                </div>
                <div class="section" id="srfSection2">
                    <h4 class="html-content-title">输入法界面</h4>
                    <div class="html-details-content">
                        <h5 class="html-content-title-small" id="srf21">1.编码转换</h5>
                        <div>
                            <p>默认情况下输入法为以下状态，为传统蒙古文国家标准编码</p>
                            <img src="~assets/images/archive/14.png" alt="">
                            <p>使用鼠标点击红框文字或点击SHIFT+空格键切换为传统蒙古文蒙科立编码</p>
                            <img src="~assets/images/archive/15.png"  class="smallImg" alt="">
                        </div>
                        <h5 class="html-content-title-small" id="srf22">2.设置</h5>
                        <div>
                            <img src="~assets/images/archive/16.png" alt="">
                            <img src="~assets/images/archive/17.png"  alt="">
                        </div>
                        <h5 class="html-content-title-small" id="srf23">3.软键盘</h5>
                        <div>
                            <p>点击输入法键盘标志，默认为PC键盘（对应上方输入法键盘布局图）。</p>
                            <img src="~assets/images/archive/18.png" alt="">
                            <p>软键盘有六种文种可选择，按照以下图片步骤选择需要的软键盘即可，默认情况下
                                虚拟键盘会在右下角显示，如不需要鼠标点击下图中虚拟键盘右上方红框标志即
                                可隐藏，隐藏后敲击虚拟键盘对应的文种即可。</p>
                            <img src="~assets/images/archive/19.png" alt="">
                            <p>隐藏虚拟键盘显示页面。</p>
                            <img src="~assets/images/archive/20.png" alt="">
                            <p>西里尔文键盘</p>
                            <img src="~assets/images/archive/21.png" alt="">
                            <p>希腊文键盘</p>
                            <img src="~assets/images/archive/22.png" alt="">
                            <p>俄文键盘</p>
                            <img src="~assets/images/archive/23.png" alt="">
                            <p>拼音</p>
                            <img src="~assets/images/archive/24.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="section" id="srfSection3">
                    <h4 class="html-content-title">输入法规则</h4>
                    <div class="html-details-content">
                        <h5 class="html-content-title-small" id="srf31">1.基本输入法</h5>
                        <div>
                            <p>在输入框中输入所需文字所有的字母</p>
                            <img src="~assets/images/archive/25.png" alt="">
                        </div>
                        <h5 class="html-content-title-small" id="srf32">2.高效输入法</h5>
                        <div>
                            <p>输入每个音节的首字母、其余字母可以省略。如输入蒙古文词只需要输入 ngdgr ，
                                候选框中便出现该词，如出现位置比较靠后，在 n 后补充 i ，即可将该词
                                提升到第一个位置（点击键盘左右箭头可以使光标在字母中间滑动）以此类推
                                ，可通过补充每个音节首字母后的其余字母，直至需要的词出现为止或出
                                现在候选框靠前位置。</p>
                            <img src="~assets/images/archive/26.png" alt="">
                            <img src="~assets/images/archive/27.png"  alt="">
                        </div>
                        <h5 class="html-content-title-small" id="srf33">3.双词根词输入法</h5>
                        <div>
                            <p>需要写入地名或者人名时遇到双词根词时在第一个字写完后点击下方键盘布局图中红框标注的键盘再写第二个词即可连接且正确显示</p>
                            <img src="~assets/images/archive/28.png" alt="">
                            <img src="~assets/images/archive/29.png" alt="">
                        </div>
                        <h5 class="html-content-title-small" id="srf34">4.携带姓氏或书名中的“·”</h5>
                        <div>
                            <p>文字中添加图中所需点时按SHIFT+2即可（下方键盘布局中红框标注）</p>
                            <img src="~assets/images/archive/30.png" alt="">
                            <img src="~assets/images/archive/31.png" alt="">
                        </div>
                        <h5 class="html-content-title-small" id="srf35">5.快速输入英文</h5>
                        <div>
                            <p>除了“ Shift ”键切换以外，蒙科立输入法也支持回车输入英文。在输入较
                                短的英文时使用能省去切换到英文状态下的麻烦。具体使用方法是：输入英文
                                ，直接敲回车即可。</p>
                        </div>
                        <h5 class="html-content-title-small" id="srf36">6.附加成分（ Tinyilgal ）的输入规则</h5>
                        <div>
                            <p>所有的附加成分（ Tinyilgal ）都安排在数字键上。具体安排请看上方键盘布局图
                                输入一个蒙古文词再输入附加成分时会按蒙古文语法规则正确显示相应的附加成分，因
                                此用户不必担心附加成分会产生语法规则出错。输入所需的候选词对应的数字，即可输入
                                该多变体附加成分，直接敲下空格即可输入第一个附加成分。</p>
                            <img src="~assets/images/archive/32.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="html-content" v-if="leftActiveIndex == 1">
                <div class="section" id="bbrz">
                    <h4 class="html-content-title">版本日志</h4>
                    <div class="html-details-content">
                        <h5 class="html-content-title-small">1.基于QT的全新图形框架，界面更丰富美观。</h5>
                        <h5 class="html-content-title-small">2.自动升级，及时体验新版本。</h5>
                        <h5 class="html-content-title-small">3.支持蒙科立旧编码，国家编制编码，共享工程标准（民委）等三种方法输入。</h5>
                        <h5 class="html-content-title-small">4.全新软件盘，支持输入多个文种。（持续更新）</h5>
                        <h5 class="html-content-title-small">5.支持双元音输入。（例如：双ii有两种编码组合）</h5>
                        <h5 class="html-content-title-small">6.新增多个常用标点符号。</h5>
                        <h5 class="html-content-title-small">7.方便的特殊控制符输入。~ 波浪键</h5>
                        <div>
                            <img src="~assets/images/archive/34.png" alt="">
                        </div>
                        <h5 class="html-content-title-small">8.候选框显示位置优化。</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="ArchiveAnchorsBox" v-if="leftActiveIndex == 0"  :style="{'right':location,'display': (screenWidth > 1200 ? 'block':'none')}">
            <div class="ArchiveAnchor ">
                <a  href="#srfSection1" class="ArchiveAnchorFisrt">输入法入门</a>
                <div class="ArchiveAnchor-item active" @click="activeChange($event)">
                    <a href="#srf11">1.输入法安装</a>
                </div>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf12">2.输入法卸载</a>
                </div>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf13">3.输入法切换</a>
                </div>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf14">4.输入法键盘布局</a>
                </div>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf15">5.翻页选词</a>
                </div>
            </div>
            <div class="ArchiveAnchor">
                <a  href="#srfSection2" class="ArchiveAnchorFisrt">输入法界面</a>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf21">1.编码转换</a>
                </div>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf22">2.设置</a>
                </div>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf23">3.软键盘</a>
                </div>
            </div>
            <div class="ArchiveAnchor ">
                <a href="#srfSection3" class="ArchiveAnchorFisrt">输入法规则</a>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf31">1.基本输入法</a>
                </div>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf32">2.高效输入法</a>
                </div>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf33">3.双词根词输入法</a>
                </div>
                 <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf34">4.携带姓氏或书名中的“·”</a>
                </div>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf35">5.快速输入英文</a>
                </div>
                <div class="ArchiveAnchor-item" @click="activeChange($event)">
                    <a href="#srf36">6.附加成分（ Tinyilgal ）的输入规则</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            leftActiveIndex:0,
            fromPath:'',
            screenWidth: window.innerWidth,
            location:'',
        }
    },
    mounted(){
        const that = this;
        this.fromPath = this.$route.query.fromPath;
        this.setContainerLocation(this.screenWidth)
        window.addEventListener("resize", () => {
            this.screenWidth = window.innerWidth;
            this.setContainerLocation(this.screenWidth);
        });
        let html = document.getElementsByTagName("html")[0];
        let footer = document.getElementById("footer");
        footer.style.display = 'none';
        html.style.overflowY = 'hidden';
        const root = document.getElementById('ArchiveContainer')
        root.addEventListener('scroll', function (e) {
            var scrollTop = e.target.scrollTop;
            that.JQ(".ArchiveAnchor-item").each(function(index,item){
                item.classList.remove('active');
            })
            if(scrollTop<1580){
                that.JQ(".ArchiveAnchor-item").eq(0).addClass("active");
            }else if(scrollTop<2600){
                that.JQ(".ArchiveAnchor-item").eq(1).addClass("active");
            }else if(scrollTop<2750){
                that.JQ(".ArchiveAnchor-item").eq(2).addClass("active");
            }else if(scrollTop<3100){
                that.JQ(".ArchiveAnchor-item").eq(3).addClass("active");
            }else if(scrollTop<3800){
                that.JQ(".ArchiveAnchor-item").eq(4).addClass("active");
            }else if(scrollTop<4400){
                that.JQ(".ArchiveAnchor-item").eq(5).addClass("active");
            }else if(scrollTop<5100){
                that.JQ(".ArchiveAnchor-item").eq(6).addClass("active");
            }else if(scrollTop<7600){
                that.JQ(".ArchiveAnchor-item").eq(7).addClass("active");
            }else if(scrollTop<8100){
                that.JQ(".ArchiveAnchor-item").eq(8).addClass("active");
            }else if(scrollTop<9000){
                that.JQ(".ArchiveAnchor-item").eq(9).addClass("active");
            }else if(scrollTop<9900){
                that.JQ(".ArchiveAnchor-item").eq(10).addClass("active");
            }else if(scrollTop<10410){
                that.JQ(".ArchiveAnchor-item").eq(11).addClass("active");
            }else{
                that.JQ(".ArchiveAnchor-item").eq(12).addClass("active");
            }
        }, true)
    },
    methods:{
        setContainerLocation(windowWidth){
            if(windowWidth>1200){
                this.location = (windowWidth-1200)/2 + 'px';
            }else{
                this.location = '0px';
            }
        },
        activeChange(e){
            this.JQ(".ArchiveAnchor-item").each(function(index,item){
                item.classList.remove('active');
            })
            if(e.target.nodeName == 'A'){
                e.target.parentNode.classList.add('active');
            }else if(e.target.nodeName == 'DIV'){
                e.target.classList.add('active');
            }
        }
    },
    beforeDestroy(){
        let footer = document.getElementById("footer");
        footer.style.display = 'block';
        let html = document.getElementsByTagName("html")[0];
        html.style.overflowY = 'auto';
    },
}
</script>

<style lang="less" scoped>
.ArchiveAnchorsBox{
    position: fixed;
    right: 0;
    top: 93px;
    padding-left: 20px;
    width: 192px;
    height: 100%;
    overflow-y: auto;
    .ArchiveAnchor{
        .ArchiveAnchorFisrt{
            font-weight: bold;
            color: #252b3a;
            font-size: 14px;
            line-height: 22px;
            padding: 5px 10px;
            border-left: solid 1px #d2d2d2;
        }
    }
    .ArchiveAnchor-item{
        border-left: solid 1px #d2d2d2;
        padding: 5px 10px;
        position: relative;
        a{
            display: block;
            font-size: 14px;
            line-height: 20px;
            color: #252b3a;
        }
    }
    .ArchiveAnchor-item::before{
        left: -6px;
        margin-top: -6px;
        width: 11px;
        height: 11px;
        border-width: 1px;
        opacity: 0;
    }
    .ArchiveAnchor-item::after{
        left: -3px;
        margin-top: -3px;
        width: 5px;
        height: 5px;
        border-width: 2px;

    }
    .ArchiveAnchor-item::after,.ArchiveAnchor-item::before{
        content: '';
        position: absolute;
        top: 15px;
        border: solid #f5f5f6 1px;
        border-radius: 50%;
        background-color: white;
    }
    .ArchiveAnchor-item.active{
        a{
            color: #2a5edd;
        }
    }
    .ArchiveAnchor-item.active::before,.ArchiveAnchor-item.active::after{
        border-color: #2a5edd;
        opacity: 1;
    }
    .ArchiveAnchor-item.active::after{
        background: #2a5edd;
    }
}
.html-details-content{
    div{
        font-size: 16px;
        color: #252b3a;
        line-height: 32px;
        p,img{
            display: inline-block;
        }
        h6{
            font-size: 16px;
            color: #252b3a;
            line-height: 32px;
            font-weight: normal;
        }
        .smallImg{
            display: inline-block;
            margin:0 6px;
        }
        img{
            display: block;
            margin: 6px auto;
            max-width: 100%;
        }
    }
}
.html-content-title-small{
    line-height: 24px;
    font-size: 18px;
    font-weight: bold;
    color: #252b3a;
    margin: 12px 0;
}
.html-content-title{
    line-height: 58px;
    font-size: 23px;
    font-weight: bold;
    color: #1b4094;
}
.ArchiveTitleBox{
    margin-top: 16px;
    border-bottom: solid 1px #d2d2d2;
    h4{
        font-size: 26px;
        font-weight: normal;
        color: #252b3a;
    }
    .ArchiveUpdateTime{
        span{
            font-size: 12px;
            line-height: 42px;
            color: #838589;
        }
    }
}
.ArchiveCrumbs{
    height: 32px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #252b3a;
    p:hover{
        color: #1b4094;
    }
    p{
        margin-right: 4px;
        cursor: pointer;
    }
    i{
        font-size: 18px;
    }
    span{
        margin: 0 4px;
    }
}
.ArchiveContent{
    margin: 0 auto;
    width: 1200px;
    padding: 0 192px 0 260px;
    flex: 1;
}

.ArchiveSideLink{
    min-height: 66px;
    padding: 20px 16px;
    border-bottom: solid 1px #d2d2d2;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    p{
        margin-top: 6px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #b3c0e0;
    }
    span{
        flex: 1;
        margin-left: 8px;
        font-size: 14px;
        line-height: 22px;
        color: #252b3a;
    }
}
.ArchiveSideLink.active{
    background: -webkit-linear-gradient(to right, #f1f5ff, #c1d4ff);
    background: -o-linear-gradient(to right, #f1f5ff, #c1d4ff);
    background: -moz-linear-gradient(to right, #f1f5ff, #c1d4ff);
    background: linear-gradient(to right, #f1f5ff, #c1d4ff);
    p{
        background: #1b4094;
    }
    span{
        color: #21479c;
    }
}
.ArchiveName{
    padding: 25px 0 25px 14px;
    font-size: 18px;
    font-weight: bold;
    color: #252b3a;
    border-bottom: solid 1px #d2d2d2;
}
.ArchiveSideLinks{
    position: fixed;
    top: 93px;
    width: 240px;
    height: 100%;
    border-right: solid 1px #dadada;
    overflow-y: auto;
    background: #f1f5ff;
}
.ArchiveContainer{
    margin: 20px auto 0;
    height: calc(100vh - 93px);
    overflow-y: auto;
}
.ArchiveContainer::-webkit-scrollbar {
  width: 8px; /* 纵向滚动条的宽 */
}
.ArchiveContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #EBEBEB;
}
</style>
