<template>
    <div class="main-container" style="background: #f3f5f8">
        <div class="w12 overflowh" v-if="hasNews">
            <div class="details-crumbs">
                <p class="bar2"></p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item :to="{ path: '/college-problem'}">常见问题</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="details-content-box">
                <div class="details-title">{{details.productQuestionInfo.title}}</div>
                <div
                    class="ditails-content"
                    v-html="unEscapeHTML(details.productQuestionInfo.answer)"
                ></div>
                <div class="details-context-box">
                    <div class="pointer" @click="enterDetails(preArticle.id)">
                        <p>·</p>
                        <span class="ellipsis">{{ preArticle.title }}</span>
                    </div>
                    <div class="pointer" @click="enterDetails(nextArticle.id)">
                        <p>·</p>
                        <span class="ellipsis">{{ nextArticle.title }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getQuestionDetails } from "api/product";
export default {
    data() {
        return {
            details: {},
            preArticle: {},
            nextArticle: {},
            questionId: "",
            newsType:'',
            hasNews: false,
            renewal:''
        };
    },
    created() {
        this.questionId = this.$route.query.questionId
        this.fetchData(this.questionId)
    },
    methods: {
        enterDetails(id) {
            if (id != "") {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
                this.fetchData(id);
            }
        },
        unEscapeHTML(html) {
            html = "" + html;
            return html
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&amp;/g, "&")
                .replace(/&quot;/g, '"')
                .replace(/&apos;/g, "'");
        },
        fetchData(id) {
            getQuestionDetails(id).then(res => {
                if (res.success && res.errorCode === "2000") {
                    this.details = res.body.data
                    this.preArticle = res.body.data.preQuestion
                    this.nextArticle = res.body.data.nextQuestion
                    this.hasNews = true
                }
            })
        },
    },
};
</script>
<style lang="less" scoped>
.ditails-content {
    padding: 26px 0;
    font-size: 16px;
    line-height: 32px;
    color: #8f8f8f;
    border-bottom: dashed 1px #3b3b3b;
}
.details_news_left p {
    margin-right: 40px;
}
.details_news_left {
    display: flex;
}
.details_news {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 82px;
    font-size: 14px;
    color: #3b3b3b;
    border-bottom: solid 5px #8f8f8f;
}
.details_title {
    line-height: 42px;
    font-size: 30px;
    font-weight: bold;
    color: #000;
    text-align: center;
}
.details-content-box {
    margin: 20px 0;
    padding: 14px 20px;
}
</style>
