<template>
    <div class="order-news-box">
        <div class="order-box h100">
            <div class="core-title">
                <p>我的订单</p>
                <span class="pointer" @click="enterOrder">
                    <i class="el-icon-arrow-right"></i>
                </span>
            </div>
            <div class="order-list-box">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="全部订单" name="0"></el-tab-pane>
                    <el-tab-pane label="待支付" name="1"></el-tab-pane>
                    <el-tab-pane label="已支付" name="2"></el-tab-pane>
                    <el-tab-pane label="已取消" name="3"></el-tab-pane>
                </el-tabs>
                <div class="order-list" v-if="page.total != 0">
                    <div
                        class="order-item"
                        v-for="item in orderList"
                        :key="item.id"
                    >
                        <img :src="item.product.logo" alt="" />
                        <div class="order-content">
                            <div class="order-content">
                                <h3>{{ item.product.name }}</h3>
                                <p v-if="item.payMode == '2'">
                                    在线支付/支付宝 {{ item.createDate }}
                                </p>
                                <p v-if="item.payMode == '1'">
                                    在线支付/微信支付 {{ item.createDate }}
                                </p>
                            </div>
                        </div>
                        <div
                            class="order-type"
                            :class="orderTypeClass(item.status)"
                        >
                            {{ orderTypeName(item.status) }}
                        </div>
                        <div class="details pointer">
                            <span @click="enterOrderDetails(item.id)"
                                >查看详情</span
                            >
                            <span
                                v-if="item.status == '0'"
                                class="pointer"
                                @click="cancel(item.id)"
                                >取消订单</span
                            >
                        </div>
                    </div>
                </div>
                <div class="noneOrder" v-if="page.total == 0">
                    <p>暂无订单记录</p>
                </div>
            </div>
        </div>
        <div class="news-box h100">
            <div class="news-top-box">
                <div class="core-title">
                    <p>消息中心</p>
                    <span class="pointer" @click="enterNews">
                        <i class="el-icon-arrow-right"></i>
                    </span>
                </div>
                <div class="news-list" v-if="hasNotice">
                    <div
                        class="news-item pointer"
                        v-for="item in noticeList"
                        :key="item.id"
                        @click="enterDetails(item)"
                    >
                        <img
                            src="~assets/images/personal/newsLeftImg.png"
                            alt=""
                        />
                        <p class="ellipsis">{{ item.title }}</p>
                    </div>
                </div>
                <div class="noMore" v-else>暂无记录</div>
            </div>
            <div class="news-bottom-box">
                <img src="~assets/images/temporary/11.jpg" alt="" />
            </div>
        </div>
        <el-dialog
            :title="newsTitle"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose"
        >
            <span>{{ newsContent }}</span>
        </el-dialog>
    </div>
</template>
<script>
import {
    getOrderList,
    orderCancel,
    getPersonalNotice,
    readNotice,
} from "api/personal";
export default {
    inject: ["reload"],
    computed: {
        orderTypeClass() {
            return function (status) {
                let className = "";
                if (status == "3") {
                    className = "success";
                } else if (status == "0") {
                    className = "danger";
                }else if(status == '1' || status == '5' ) {
                    className = 'warning'
                }
                return className;
            };
        },
        orderTypeName() {
            return function (status) {
                let name = "";
                if (status == "3") {
                    name = "已完成";
                } else if (status == "0") {
                    name = "待支付";
                } else if (status == "1") {
                    name = "已取消";
                }else if(status == "5"){
                    name = "待审核";
                }
                return name;
            };
        },
    },
    data() {
        return {
            activeName: 0,
            page: {
                current: 1,
                size: 3,
                total: null,
            },
            status: "",
            orderList: [],
            hasNews: false,
            noticePage: {
                current: 1,
                size: 4,
                total: null,
            },
            noticeList: [],
            hasNotice: false,
            dialogVisible: false,
            newsTitle: "",
            newsContent: "",
        };
    },
    created() {
        this.getList();
        this.getNoticeLists();
    },
    methods: {
        enterOrder() {
            this.$router.push("/personal/order");
        },
        enterNews() {
            this.$router.push("/personal/news");
        },
        enterDetails(item) {
            this.newsTitle = item.title;
            this.newsContent = item.content;
            this.dialogVisible = true;
            if (item.readFlag === "0") {
                readNotice(item.id).then((res) => {
                    const { success } = res;
                    if (success) {
                        item.readFlag = "1";
                    }
                });
            }
        },
        handleClose() {
            this.dialogVisible = false;
        },
        getNoticeLists() {
            getPersonalNotice(
                this.noticePage.current,
                this.noticePage.size
            ).then((res) => {
                const { success, body, msg } = res;
                if (success) {
                    if (body.data.count == 0) {
                        this.hasNotice = false;
                    } else {
                        this.page.total = body.data.count;
                        this.noticeList = body.data.list;
                        this.hasNotice = true;
                    }
                } else {
                    this.$message({
                        message: msg,
                        type: "error",
                    });
                }
            });
        },
        enterOrderDetails(id) {
            this.$router.push({
                path: "/order-details",
                query: {
                    orderId: id,
                },
            });
        },
        cancel(id) {
            this.$confirm("确定要取消这条订单记录吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    orderCancel(id).then((res) => {
                        if (res.errorCode === "2000") {
                            this.$message({
                                message: res.msg,
                                type: "success",
                            });
                            this.status = "";
                            this.getList();
                        } else {
                            this.$message({
                                message: res.msg,
                                type: "warning",
                            });
                        }
                    });
                })
                .catch(() => {});
        },
        handleClick(tab) {
            if (tab.name === "0") {
                this.status = "";
            } else if (tab.name === "1") {
                this.status = "0";
            } else if (tab.name === "2") {
                this.status = "3";
            } else if (tab.name === "3") {
                this.status = "1";
            } else {
                this.status = "2";
            }
            this.getList();
        },
        getList() {
            getOrderList(this.status, this.page.current, this.page.size).then(
                (res) => {
                    if (res.errorCode === "2000") {
                        this.orderList = res.body.data.rows;
                        this.page.total = res.body.data.total;
                    }
                }
            );
        },
    },
};
</script>
<style>
.order-box .el-tabs__nav .el-tabs__item {
    height: 26px;
    line-height: 26px;
    color: #6f7583;
}
.order-box .el-tabs__nav .el-tabs__item.is-active {
    color: #1b4094;
}
.order-box .el-tabs__active-bar {
    background: #1b4094 !important;
}
</style>
<style lang="less" scoped>
.noMore {
    height: calc(100% - 72px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #ccc;
    font-size: 14px;
    color: #6f7583;
}
.noneOrder {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #6f7583;
}
.news-bottom-box {
    margin-top: 20px;
    height: 88px;
    img {
        width: 100%;
        height: 100%;
    }
}
.details {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #151515;
    font-size: 12px;
    span {
        margin: 4px 0;
    }
}
.order-type {
    margin-bottom: 10px;
}
.order-type.warning {
    color: #b21f27;
}
.order-type.danger {
    color: #ff8e09;
}
.order-type.success {
    color: #1fb226;
}
.order-type {
    width: 86px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #6f7583;
}
.order-content {
    width: 324px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
        font-size: 14px;
        color: #252b3a;
    }
    p {
        font-size: 12px;
        color: #6f7583;
    }
}
.order-item {
    margin-bottom: 20px;
    height: 38px;
    display: flex;
    flex-direction: row;
    > img {
        width: 38px;
        height: 38px;
        margin-right: 20px;
    }
}
.news-item {
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
        margin-right: 8px;
    }
    p {
        width: 228px;
        font-size: 12px;
        color: #252b3a;
    }
}
.news-top-box {
    padding: 0 16px;
    height: 160px;
    background: #fff;
}
.news-box {
    width: 286px;
}
.order-list-box {
    height: 212px;
}
.order-box {
    padding: 0 16px;
    width: 612px;
    background: #fff;
}
.order-news-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    height: 268px;
}
</style>

