<template>
    <div class="c-news-list">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "AboutBar",
};
</script>



<style scoped>
.c-news-list {
    flex: 1;
    font-size: 14px;
    text-align: left;
    color: #b7b7b7;
    line-height: 32px;
}
</style>
