<template>
    <div class=" main-container" style="background:#f3f5f8;overflow:hidden">
        <div class="w12 fontBox">
            <div class="font-nav">
                <div class="font-nav-li" @click="activeIndex = 0" :class="{'active': activeIndex == 0}">
                    <span></span>
                    <p>OS  X安装蒙古文字体教程</p>
                </div>
                <div class="font-nav-li border0" @click="activeIndex = 1" :class="{'active': activeIndex == 1}">
                    <span></span>
                    <p>iOS嵌入蒙古文字体教程</p>
                </div>
            </div>
            <div class="list-news-box" v-if="activeIndex == 0">
                <div class="guide-step-title">
                    <p>OS X安装蒙古文字体教程</p>
                </div>
                <div class="exanple">
                    <p>第一步：使用字体册打开字体文件</p>
                    <div>
                        <img src="~assets/images/product/aatImg1.jpg" alt="">
                        <span style="margin-left:66px">点击“安装字体”</span>
                        <img src="~assets/images/product/aatImg2.jpg" alt="">
                        <span style="margin-left:66px">安装完成后在字体册->用户中显示刚才安装的字体</span>
                        <img src="~assets/images/product/aatImg3.jpg" alt="">
                    </div>
                </div>
                <div class="exanple">
                    <p>第二步：使用字体新建文本编辑器。点击“字体->显示字体”</p>
                    <div>
                        <img src="~assets/images/product/aatImg4.jpg" alt="">
                        <span style="margin-left:66px">选字体集里的所有字体，在所有字体里面找刚在安装的蒙古文字体</span>
                        <img src="~assets/images/product/aatImg5.jpg" alt="">
                        <span style="margin-left:66px">关闭窗口，设置完成</span>
                    </div>
                </div>
            </div>
            <div class="list-news-box" v-if="activeIndex == 1">
                <div class="guide-step-title">
                    <p>iOS嵌入蒙古文字体教程</p>
                </div>
                <div class="exanple">
                    <p>第一步：将字体库文件拖入项目中</p>
                    <div>
                        <img src="~assets/images/product/aatImg6.jpg" alt="">
                    </div>
                </div>
                <div class="exanple">
                    <p>第二步：修改Info.plist:添加Fonts provided by application属性,设值为字体体文件名.</p>
                    <div>
                        <img src="~assets/images/product/aatImg7.jpg" alt="">
                    </div>
                </div>
                <div class="exanple">
                    <p>第三步：打开布局文件Main.storyboard,拖一个UILabel控件到界面里,修改UILabel的字体属性Font -> Custom, Font Family -> MenkAAT.</p>
                    <div>
                        <img src="~assets/images/product/aatImg8.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeIndex:0,
        }
    }
}
</script>
<style lang="less" scoped>
.exanple{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    color: #252b3a;
    >p{
        font-size: 16px;
    }
    >div{
        margin-top: 10px;
        margin-left:140px ;
        margin-bottom: 20px;
        img{
            display: block;
            margin: 10px auto;
            max-width: 100%;
        }
        .mkText{
            font-family: 'Menksoft2012';
            font-size: 18px;
        }
    }
}
.guide-step-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        font-size: 16px;
        color: #252b3a;
        line-height: 32px;
    }
    span{
        font-size: 14px;
        color: #252b3a;
    }
}
.border0{
    border: 0 !important;
}
.fontBox{
    margin-top: 98px;
    overflow: hidden;
    .font-nav{
        float: left;
        width: 254px;
        padding: 0 10px;
        border-right: solid 1px #dadada;
        .font-nav-li{
            cursor: pointer;
            padding: 0 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 65px;
            font-style: 14px;
            color: #252b3a;
            border-bottom: solid 1px #d2d2d2;
            span{
                margin-right: 14px;
                width: 8px;
                height: 8px;
                background: #dfe9ff;
                border-radius: 50%;
            }
        }
        .font-nav-li.active{
            color: #1b4094;
            background: -webkit-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: -o-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: -moz-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: linear-gradient(to right, #f3f5f8, #cfdcfa);
            span{
                background: #1b4094;
            }
        }
    }
    .list-news-box{
        padding: 10px 20px 20px;
        margin-left: 292px;
        background: #fff;
    }
}
</style>
