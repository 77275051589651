<template>
    <div class="userInfoBox">
        <img
            src="~assets/images/personal/userInfoBackImg.png"
            alt=""
            class="userInfoBackImg"
        />
        <div class="userInfoContent">
            <div class="user-img-name">
                <img
                    :src="
                        userInfo.headimgurl ? userInfo.headimgurl : headimgurl
                    "
                    class="userImg"
                />
                <div class="user-name">
                    <p class="name" v-if="userDetails.memberType == '0'">{{ userInfo.memberName }}</p>
                    <p class="name" style="font-size:14px">
                        用户类型：{{userDetails.memberType == '1' ? '企业用户' : '个人用户'}}
                        <em class="attestation" @click="etnerAttestation" v-if="memberType == '0'">企业认证</em>
                    </p>
                    <div class="modify pointer" @click="editUserInfo" v-if="userDetails.memberType == '0'">
                        <img
                            src="~assets/images/personal/modifyImg.png"
                            alt=""
                        />
                        <span>修改个人信息</span>
                    </div>
                </div>
            </div>
            <div class="accountNewsBox">
                <div class="accountNews">
                    <div class="a-news">
                        <p>会员等级：</p>
                        <span>
                            {{ userDetails.memberGrade }}
                            <img
                                v-if=" userDetails.memberGrade != '普通用户' "
                                src="~assets/images/personal/diamonds.png"
                            />
                        </span>

                    </div>
                    <div class="a-news">
                        <p>绑定手机：</p>
                        <span>{{ phoneNum(userDetails.phone) }}</span>
                    </div>
                    <div class="a-news">
                        <p>绑定邮箱：</p>
                        <span>{{ userDetails.email }}</span>
                    </div>
                    <div class="a-news">
                        <p>账户余额：</p>
                        <span>{{ userDetails.balance }}元 <span  v-if="userDetails.balance != 0">(截止：{{userDetails.deadline.trim().split(" ")[0]}})</span> </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import { getInformation } from "api/personal";
export default {
    computed: {
        userInfo() {
            return PcCookie.get(Key.userInfoKey)
                ? JSON.parse(PcCookie.get(Key.userInfoKey))
                : null;
        },
        phoneNum() {
            return function(phone){
                var reg = /^(\d{3})\d{4}(\d{4})$/
                phone = phone.replace(reg, "$1****$2")
                return phone
            }
        }
    },
    data() {
        return {
            userDetails: {
                phone: "",
                email: "",
                memberGrade: "",
                memberType:"",
                balance:0,
                deadline:''
            },
            headimgurl: require("@/assets/images/userHeadImg.png"),
            memberType: PcCookie.get(Key.memberTypeKey),
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        etnerAttestation(){
            this.$router.push('/attestation')
        },
        fetchData() {
            getInformation().then((res) => {
                const { body, success } = res;
                if (success) {
                    this.userDetails.phone = body.data.memberInfo.phone;
                    this.userDetails.email = body.data.memberInfo.email;
                    this.userDetails.memberType = body.data.memberInfo.memberType;
                    this.userDetails.memberGrade =
                        body.data.memberInfo.memberGrade;
                    this.userDetails.balance = body.data.promotionRecs.length>0  ? body.data.promotionRecs[0].amount : 0;
                    this.userDetails.deadline = body.data.promotionRecs.length>0 ?  body.data.promotionRecs[0].deadline : '';

                }
            });
        },
        editUserInfo() {
            this.$router.push('/personal/information')
        }
    },
};
</script>
<style lang="less" scoped>
.attestation{
    display: inline-block;
    width: 88px;
    height: 22px;
    line-height: 22px;
    font-size: 13px;
    font-style: normal;
    color: #fff;
    text-align: center;
    margin-left: 10px;
    margin-top: 4px;
    background: #ff8610;
    cursor: pointer;
}
.userImg {
    width: 112px;
    height: 112px;
    border-radius: 50%;
}
.accountNewsBox {
    width: 290px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .a-news {
        display: flex;
        flex-direction: row;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        color: #fff;
        span {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
                margin-left: 12px;
            }
        }
    }
    .binding {
        margin-top: 58px;
        font-size: 14px;
        width: 82px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #ffe1c3;
        background: #ff8610;
    }
}
.user-name {
    margin-left: 12px;
    width: 260px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name {
        font-size: 18px;
        color: #fff;
        margin-bottom: 16px;
    }
    .modify {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 24px;
        img {
            margin-right: 6px;
        }
        span {
            font-size: 12px;
            color: #fff;
        }
    }
}
.user-img-name {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.userInfoBox {
    padding: 0 66px;
    height: 140px;
    position: relative;
    .userInfoContent {
        position: absolute;
        left: 66px;
        top: 0;
        bottom: 0;
        right: 66px;
        z-index: 4;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .userInfoBackImg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
    }
}
</style>
