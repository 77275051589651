<template>
    <div class="serve-buy-box">
        <div class="mine-serve h100">
            <div class="core-title">
                <p>我的服务</p>
                <span class="pointer" @click="enterServe">
                    <i class="el-icon-arrow-right"></i>
                </span>
            </div>
            <div class="noMore" v-if="!hasService">
                暂无记录
            </div>
            <div class="mine-serve-content" v-if="hasService">
                <div class="mine-serve-news h100" v-for="(item) in serviceList" :key="item.id">
                    <img :src="item.product.baseMap" alt="" class="serveImg">
                    <div class="serve-news-text h100">
                        <div class="serve-type-box">
                            <img :src="item.product.logo" alt="">
                            <div>
                                <h3>{{item.product.name}}</h3>
                                <!-- <span>{{item.order.actualAmount}}元{{item.order.productPaymentMode.name}}产品服务购买</span> -->
                            </div>
                        </div>
                        <div class="pament">
                            <h3>您已付费</h3>
                            <span>到期时间：{{item.expiryDate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mine-buy h00">
            <div class="core-title">
                <p>购买记录</p>
                <span class="pointer" @click="enterPru">
                    <i class="el-icon-arrow-right"></i>
                </span>
            </div>
            <div class="noMore" v-if="!hasPru">
                暂无记录
            </div>
            <div  v-if="hasPru">
                <div class="buy-item pointer" v-for="item in purList" :key="item.id">
                    <div class="buy-title ">
                        <span></span>
                        <em class="ellipsis">{{item.product.name}}</em>
                    </div>
                    <div class="buy-text textRight">
                        版本号：{{item.product.version}}
                    </div>
                    <div class="buy-text textRight">
                        购买时间：{{item.effectiveDate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getMineService, getMinePurchase } from 'api/personal'
export default {
    data() {
        return {
            page:{
                size: 2,
                total: null,
                current: 1
            },
            serviceList:[],
            purList:[],
            hasService:false,
            hasPru:false
        }
    },
    created () {
        this.getList()
        this.getPurList()
    },
    methods:{
        enterServe(){
            this.$router.push('/personal/serve')
        },
        enterPru(){
            this.$router.push('/personal/purchase')
        },
        getPurList() {
            getMinePurchase(this.page.current, this.page.size).then(res => {
                if(res.errorCode == '2000'){
                    this.purList = res.body.data.rows
                    this.page.total = res.body.data.total
                    if(this.page.total != 0){
                        this.hasPru = true
                    }else{
                        this.hasPru = false
                    }
                }
            })
        },
        getList(){
            getMineService(this.page.current, this.page.size).then(res => {
                if(res.errorCode == '2000'){
                    this.serviceList = res.body.data.rows
                    this.page.total = res.body.data.total
                    if(this.page.total != 0){
                        this.hasService = true
                    }else{
                        this.hasService = false
                    }
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
.wathet{
    background: #eef9ff;
}
.pink{
    background: #ffeeee;
}
.noMore{
    height: calc(100% - 72px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #ccc;
    font-size: 14px;
    color: #6f7583;
}
.buy-text{
    font-size: 12px;
    line-height: 18px;
    color: #252b3a;
}
.buy-item{
    padding-right: 20px;
    margin-bottom: 14px;
    height: 66px;
    border-bottom: dashed 1px #8e8e8e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    line-height: 18px;
    color: #252b3a;
    .buy-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            margin-right: 4px;
            width: 4px;
            height: 4px;
            background: #8e8e8e;
            border-radius: 50%;
        }
        em{
            width: 226px;
            font-style: normal;
        }
    }
}
.pament{
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3{
        font-size: 14px;
        color: #252b3a;
    }
    span{
        margin-top: 6px;
        font-size: 12px;
        color: #6f7583;
    }
}
.serve-type-box{
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img{
        margin-right: 4px;
        height: 36px;
    }
    >div{
        h3{
            font-size: 16px;
            color: #252b3a;
        }
        span{
            font-size: 12px;
            color: #6f7583;
        }
    }
}
.mine-serve-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 146px;
    .mine-serve-news{
        width: 278px;
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        .serveImg{
            position: absolute;
            left: 0;
            top: 0;
            z-index: 6;
            width: 100%;
            height: 100%;
            border-radius: 6px;
        }
        .serve-news-text{
            position: absolute;
            left: 16px;
            top: 0;
            right: 16px;
            bottom: 0;
            z-index: 8;
            display: flex;
            flex-direction: column;
        }
    }
}
.mine-buy{
    width: 286px;
    padding: 0 16px;
    background: #fff;
}
.mine-serve{
    padding: 0 16px;
    width: 612px;
    background: #fff;
}
.serve-buy-box {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 224px;
}
</style>
