<template>
    <div class="tabItem" :class="{ active: isActive }" @click="itemClick">
        <p class="tab_bar"></p>
        <div class="tab_news">
            <div class="imgBox" v-if="!isActive">
                <slot name="item-img"></slot>
            </div>
            <div class="imgBox" v-else>
                <slot name="item-active"></slot>
            </div>
            <slot name="item-name"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "PerTabItem",
    props: {
        path: {
            type: String,
        },
    },
    data() {
        return {};
    },
    methods: {
        itemClick() {
            this.$router.push(this.path);
            document.documentElement.scrollTop = 0;
        },
    },
    computed: {
        isActive() {
            return this.$route.path.indexOf(this.path) !== -1;
        },
    },
};
</script>

<style scoped>
.active .tab_bar {
    background: #1b4094;
}
.active {
    background: #fff;
}
.tab_news p {
    height: 24px;
    line-height: 28px;
    font-size: 14px;
    color: #3b3b3b;
}
.tab_news img {
    margin-right: 18px;
    margin-top: 6px;
}
.tab_news {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.tab_bar {
    position: absolute;
    left: 0;
    top: 14px;
    width: 5px;
    height: 42px;
    background: #fff;
}
.tabItem {
    position: relative;
    padding-left: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
    cursor: pointer;
}
</style>
