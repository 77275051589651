<template>
    <div class="h100 serve-box overflowh">
        <div class="serve-title">我的服务</div>
        <div v-if="hasNews">
            <div class="serve-list">
                <div class="mine-serve-news h100" v-for="(item) in serviceList" :key="item.id">
                    <img :src="item.product.baseMap" alt="" class="serveImg">
                    <div class="serve-news-text h100">
                        <div class="serve-type-box">
                            <img :src="item.product.logo" alt="">
                            <div>
                                <h3>{{item.product.name}}</h3>
                                <!-- <span>{{item.order.actualAmount}}元{{item.order.productPaymentMode}}产品服务购买</span> -->
                            </div>
                        </div>
                        <div class="pament">
                            <h3>您已付费</h3>
                            <span>到期时间：{{item.expiryDate}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <el-row type="flex" justify="center" style="margin-top:16px;">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="page.current"
                    :page-size="page.size"
                    :total="page.total"
                    layout="prev, pager, next"
                >
                </el-pagination>
            </el-row>
        </div>
        <div class="noneNews" v-else>
            <img src="~assets/images/personal/nonImg.png" alt="">
            <div class="promptText">
                <p>
                    暂时没有购买服务
                    <!-- ，<span class="pointer">快去选购吧 >></span> -->
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { getMineService } from 'api/personal'
export default {
    data() {
        return {
            page:{
                size: 9,
                total: null,
                current: 1
            },
            serviceList:[],
            hasNews:false
        }
    },
    created () {
        this.getList()
    },
    methods: {
        getList(){
            getMineService(this.page.current, this.page.size).then(res => {
                if(res.errorCode == '2000'){
                    this.serviceList = res.body.data.rows
                    this.page.total = res.body.data.total
                    if(this.page.total != 0){
                        this.hasNews = true
                    }else{
                        this.hasNews = false
                    }
                }
            })
        },
        handleCurrentChange(current) {
            this.page.current = current
            this.getList()
        }
    }
}
</script>
<style lang="less" scoped>
.wathet{
    background: #eef9ff;
}
.pink{
    background: #ffeeee;
}
.pament{
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3{
        font-size: 14px;
        color: #252b3a;
    }
    span{
        margin-top: 6px;
        font-size: 12px;
        color: #6f7583;
    }
}
.serve-type-box{
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img{
        margin-right: 4px;
        height: 36px;
    }
    >div{
        h3{
            font-size: 16px;
            color: #252b3a;
        }
        span{
            font-size: 12px;
            color: #6f7583;
        }
    }
}
.mine-serve-news{
    margin: 0 7px 24px;
    height: 146px;
    width: 278px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    .serveImg{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 6;
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }
    .serve-news-text{
        position: absolute;
        left: 16px;
        top: 0;
        right: 16px;
        bottom: 0;
        z-index: 8;
        display: flex;
        flex-direction: column;
    }
}
.serve-list{
    display: flex;
    flex-wrap: wrap;
}
.serve-title{
    font-size: 18px;
    color: #252b3a;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
}
.serve-box {
    padding: 0 20px;
    background: #fff;
}
</style>
