import { request } from '@/utils/request'
//获取新闻资讯列表
export function getArticleList(current, size) {
    return request({
        url: `/api/v1/ctArticle/article/getArticle`,
        method: 'get',
        params: {
            pageNo: current,
            pageSize: size
        }
    });
}

//获取新闻资讯详情
export function getArticleDetail(articleId) {
    return request({
        url: `/api/v1/ctArticle/article/detail`,
        method: 'get',
        params: {
            articleId
        }
    });
}

//更新新闻资讯文章点击量
export function updataArticleRedNum(id) {
    return request({
        url: `/api/v1/ctArticle/article/renewal`,
        method: 'get',
        params: {
            id
        }
    });
}
