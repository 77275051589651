<template>
    <div
        class="dictionaryBox"
        v-loading="loading"
        element-loading-text="努力工作中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
    >
        <div class="whiteBox absoluteBox"></div>
        <div class="huiBox absoluteBox"></div>
        <div class="w12 h100">
            <!--蒙文 ——> 汉文-->
            <div class="mongoliaBox" v-if="type == 'mw'">
                <div class="mongoliaLeftBox">
                    <img
                        src="~assets/images/product/dictionaryImg.png"
                        alt=""
                        class="dictionaryImg"
                    />
                    <div class="dictionarySearch">
                        <el-dropdown @command="handleCommand"  trigger="click">
                            <span class="el-dropdown-link">
                                蒙文<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="mw">蒙文</el-dropdown-item>
                                <el-dropdown-item command="zh">汉文</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <div class="d-search-text">
                            <el-input
                                class="menk"
                                type="textarea"
                                v-model="searchText"
                                maxlength="50"
                                :disabled="disabled"
                            ></el-input>
                        </div>
                        <div class="d-serach-btn">
                            <i class="el-icon-search"></i>
                        </div>
                    </div>
                </div>
                <div class="mongoliaRightBox">
                    <div class="d-originalText">
                        <p class="menkTitle"> </p>
                    </div>
                    <div class="d-bar1"></div>
                    <div class="d-list-box">
                        <div class="d-change-btn-box">
                            <p class="active">传统蒙古文</p>
                            <p>西里尔语</p>
                        </div>
                        <div class="d-list-news">
                            <div
                                class="d-li-box"
                                v-for="index in 99"
                                :key="index"
                                @mouseenter="mkItemHover($event)"
                                @mouseleave="mkItemleave($event)"
                            >
                                <span>{{ index }}</span>
                                <div class="">
                                    <p class="menkText"> </p>
                                    <em></em>
                                    <h4>中国</h4>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="复制文字"
                                        placement="bottom"
                                    >
                                        <i
                                            class="el-icon-document-copy"
                                        ></i>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="d-total-box">
                            蒙科立固什词典为您整理出10条相关词
                        </div>
                    </div>
                </div>
            </div>
            <!--汉文 ——> 蒙文-->
            <div class="zhBox" v-if="type == 'zh'">
                <div class="d-zh-newsBox">
                    <div class="d-zh-search-box">
                        <img src="~assets/images/product/dictionaryImg1.png" alt="">
                        <div style="margin-top: 15px;width:548px;">
                            <el-input placeholder="请输入内容" v-model="zhInput" class="input-with-select">
                                <el-select v-model="type" slot="prepend" placeholder="请选择">
                                    <el-option label="汉文" value="zh"></el-option>
                                    <el-option label="蒙古文" value="mw"></el-option>
                                </el-select>
                                <el-button slot="append" icon="el-icon-search"></el-button>
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="zhRightBox">
                    <div class="d-originalText">
                        <p style="text-align: center;font-weight: bold;">中国</p>
                    </div>
                    <div class="d-bar1"></div>
                    <div class="d-zhlist-box">
                        <div class="d-change-btn-box">
                            <p class="active">传统蒙古文</p>
                            <p>西里尔语</p>
                        </div>
                        <div class="d-list-news">
                            <div
                                class="d-li-box"
                                v-for="index in 99"
                                :key="index"
                                @mouseenter="mkItemHover($event)"
                                @mouseleave="mkItemleave($event)"
                            >
                                <span>{{ index }}</span>
                                <div class="">
                                    <h4>中国</h4>
                                    <em></em>
                                    <p class="menkText"> </p>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="复制文字"
                                        placement="bottom"
                                    >
                                        <i
                                            class="el-icon-document-copy"
                                        ></i>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="d-total-box">
                            蒙科立固什词典为您整理出10条相关词
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: false,
            searchText: "",
            type:'zh',
            zhInput:'',
            disabled:false
        };
    },
    methods:{
        handleCommand(command) {
            this.type = command
        },
        mkItemHover(event){
            event.currentTarget.classList.add("active");
        },
        mkItemleave(event) {
            event.currentTarget.classList.remove("active");
        },
    }
};
</script>
<style>
.d-zh-search-box .el-select .el-input {
    width: 90px;
}
.d-search-text .el-textarea {
    height: 100%;
    border: 0 !important;
    overflow: hidden;
}
.d-search-text .el-textarea__inner {
    height: 2000px;
    border: 0 !important;
}
.menk textarea {
    text-align: justify;
    -webkit-text-orientation: sideways-right;
    text-orientation: sideways-right;
    display: table-column;
    font-family: "Menksoft2012";
    color: #252b3a !important;
    -webkit-writing-mode: vertical-lr !important;
    writing-mode: vertical-lr !important;
    font-size: 22px !important;
    font-weight: bold;
}
.menk {
    -webkit-writing-mode: vertical-lr;
    -webkit-text-orientation: sideways-right;
    writing-mode: vertical-lr;
    text-orientation: sideways-right;
}
</style>
<style lang="less" scoped>
.d-zh-search-box{
    margin-top: 198px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.d-zh-newsBox{
    width: 50%;
    height: 100%;
}
.zhBox{
    height: 100%;
    display: flex;
    flex-direction: row;
}
.d-total-box {
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    color: #a3a6ae;
}
.d-li-box {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    > span {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #2789f6;
        border-radius: 50%;
        font-size: 14px;
        color: #2789f6;
        margin: 0 13px 12px;
    }
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #2789f6;
        font-size: 18px !important;
        p {
            font-size: 20px !important;
            font-weight: bold !important;
        }
        em {
            width: 2px;
            height: 36px;
            margin: 36px 0;
            background: #2789f6;
        }
        h4 {
            width: 20px;
            font-size: 18px;
        }
        i {
            cursor: pointer;
            margin: 12px 0;
            font-weight: bold;
            color: #1b4094;
        }
    }
}
.d-li-box.active {
    > span {
        border: solid 1px #1b4094;
        border-radius: 50%;
        font-size: 14px;
        color: #fff;
        background: #1b4094;
    }
    > div {
        p {
            font-size: 24px !important;
            font-weight: bold !important;
            color: #516dad;
        }
        em {
            background: #516dad;
        }
        h4 {
            font-weight: bold;
            color: #252b3a;
        }
    }
}
.d-list-news::-webkit-scrollbar {
    height: 10px;
}
.d-list-news::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
}
.d-list-news::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
}
.d-list-news {
    display: flex;
    flex-wrap: nowrap;
    height: calc(100% - 60px);
    overflow-x: auto;
    overflow-y: hidden;
    .menkTitle {
        height: 100%;
        background: red;
    }
}
.d-change-btn-box {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    p {
        width: 116px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        color: #a7a6a6;
        border: solid 1px #d1d1d1;
        cursor: pointer;
        margin-left: 6px;
    }
    p.active {
        color: #1b66ba;
        border-color: #2789f6;
    }
}
.d-list-box {
    width: 800px;
    height: 94%;
    display: flex;
    flex-direction: column;
}
.d-zhlist-box{
    width: 552px;
    height: 94%;
    display: flex;
    flex-direction: column;
}
.d-bar1 {
    border-right: dashed 1px #252b3a;
    height: 530px;
}
.d-originalText {
    display: flex;
    justify-content: center;
    width: 46px;
    height: 100%;
    padding: 32px 0;
    p {
        font-size: 25px !important;
        color: #252b3a;
    }
}
.mongoliaRightBox, .zhRightBox {
    padding-top: 76px;
    padding-bottom: 26px;
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
    min-width: 0;
    background: #f3f5f8;
}
.d-serach-btn {
    height: 48px;
    border-top: solid 1px #d1d1d1;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        color: #d1d1d1;
        font-size: 26px;
        cursor: pointer;
    }
}
.d-search-text {
    height: 486px;
    padding: 26px 0;
}
.el-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 54px;
    border-bottom: solid 1px #d1d1d1;
    font-size: 14px;
    color: #7b808d;
    cursor: pointer;
    position: relative;
}
.mongoliaLeftBox {
    padding-top: 76px;
    width: 354px;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    .dictionarySearch {
        margin-left: 40px;
        width: 68px;
        height: 590px;
        border: solid 1px #d1d1d1;
        box-shadow: 0px 0px 8px #ccc;
        position: relative;
    }
}
.mongoliaBox {
    height: 100%;
    display: flex;
    flex-direction: row;
}
.dictionaryBox {
    position: relative;
    height: 900px ;
    > .w12 {
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 99;
        margin-left: -600px;
    }
}
.huiBox {
    right: 0;
    background: #f3f5f8;
}
.whiteBox {
    left: 0;
    background: #fff;
}
.absoluteBox {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
}
</style>
