<template>
    <div class="course-item pointer" @click="enterDetails">
        <div class="course-cover-paly">
            <slot name="coverImg"></slot>
            <div class="palyBox">
                <i class="el-icon-video-play"></i>
            </div>
        </div>
        <div class="course-news-box">
            <slot name="title"></slot>
            <div class="time-learnNum">
                <div>
                    <i class="el-icon-time"></i>
                    <slot name="time"></slot>
                </div>
                <div>
                    <i class="el-icon-user"></i>
                    <slot name="num"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        courseId:{
            type:String,
            default:''
        },
        id:{
            type:String,
            default:''
        },
        productId:{
            type:String,
            default:''
        },
    },
    methods: {
        enterDetails() {
            this.$router.push({
                path:'/college-course-details',
                query:{
                    courseId:this.courseId,
                    id:this.id,
                    productId:this.productId
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.course-news-box{

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    border-radius: 0 0 6px 6px;
    .news-title{
        height: 16px;
        font-size: 14px;
        color: #252b3a;
        margin-bottom: 16px;
        font-weight: normal;
    }
    .time-learnNum{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 26px;
        font-size: 12px;
        color: #818181;
        i{
            margin-right: 6px;
        }
    }
}
.course-cover-paly{
    position: relative;
    height: 176px;
    border-radius: 6px 6px 0 0;
    .coverImg{
        width: 100%;
        height: 100%;
        border-radius: 6px 6px 0 0;
    }
    .palyBox{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 6px 6px 0 0;
        background: rgba(0, 0, 0, .5);
        i{
            color: #fff;
            font-size: 40px;
        }
    }
}
.course-item{
    display: flex;
    flex-direction: column;
    width: 288px;
    height: 252px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 3px 8px 0 #dcdee1;
    margin:15px 6px;
}
</style>
