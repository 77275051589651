<template>
    <div id="product-advantage">
        <div class="w12 h100">
            <div class="title">
                <p class="menkTitle">   <br/></p>
                <span>产品优势</span>
            </div>
            <div class="advatage-list">
                <advantage-box>
                    <advantage-item v-for="item in advantage" :key="item.id">
                        <img slot="icon" :src="item.imgUrl" alt="" >
                        <div class="title ellipsis" slot="title">{{item.name}}</div>
                        <div class="content" slot="content">{{item.substance}}</div>
                    </advantage-item>
                </advantage-box>
            </div>
        </div>
    </div>
</template>
<script>
import AdvantageBox from 'components/common/advantage/AdvantageBox'
import AdvantageItem from 'components/common/advantage/AdvantageItem'
export default {
    components: {AdvantageBox, AdvantageItem},
    props:{
        advantage:{
            type:Array,
            default() {
                return []
            }
        }
    }
}
</script>
<style lang="less" scoped>
#product-advantage{
    height: 420px;
    background: #f3f5f8;
    .w12{
        >.title{
            height: 166px;
            padding-top: 66px;
            font-size: 35px;
            color: #252b3a;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            p{
                margin: 0 12px;
            }
        }
        .advatage-list{
            height: 162px;
        }
    }
}
</style>
