<template>
    <div class="main-container" style="background: #f3f5f8">
        <!--
            二维码盒子
            三分钟倒计时
            提示：请在三分钟之内进行支付
        -->
        <div class="codeImgBox" v-if="disabled == true">
            <div class="payImgBox">
                <p>
                    三分钟倒计时: <em>00:{{ minute }}:{{ second }}</em>
                </p>
                <img :src="payImg" alt="" class="payImg" />
                <span>提示：请在三分钟之内进行支付</span>
            </div>
        </div>

        <div class="w12 overflowh">
            <div class="details-crumbs">
                <p class="bar2"></p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{ productName }}</el-breadcrumb-item>
                    <el-breadcrumb-item>订单支付</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="order-box">
                <div class="order-title-box">
                    <h3>{{ productName }}</h3>
                    <p>—</p>
                    <span>产品服务购买</span>
                </div>
                <div class="order-content-box">
                    <div class="order-type-box">
                        <div class="order-label">类型：</div>
                        <div class="flex1 order-type-text">
                            <p>产品服务</p>
                        </div>
                    </div>
                    <div class="order-specs-box">
                        <div class="order-label">规格：</div>
                        <div class="order-specs-type-box flex1">
                            <div
                                class="order-specs-type pointer"
                                v-for="(item, index) in packageList"
                                :key="index"
                                :class="{ active: activeIndex == index }"
                                @click="choseSpecs(index, item)"
                            >
                                <div class="title">
                                    {{ item.name }}
                                </div>
                                <div class="flex1 order-news">
                                    <div class="order-price">
                                        <span>¥</span>
                                        <h3>{{ item.price }}</h3>
                                    </div>
                                    <div class="order-tips" v-if="index == 0">
                                        <p>仅限1个账号使用</p>
                                        <p>7款蒙科立AI助手，1天2000字符</p>
                                        <p>超值体验</p>
                                    </div>
                                    <div class="order-tips" v-if="index == 1">
                                        <p>2个账号使用</p>
                                        <p>7款蒙科立AI助手，1年500万字符</p>
                                        <p>尊享体验</p>
                                    </div>
                                    <div class="order-tips" v-if="index == 2">
                                        <p>5个账号使用</p>
                                        <p>7款蒙科立AI助手，1年无限量，极致</p>
                                        <p>畅想体验</p>
                                    </div>
                                    <el-input-number
                                        v-model="orderInfoVo.buyCount"
                                        :min="1"
                                        :max="10000"
                                        size="small"
                                        v-if="index === 0 && singleBuy == '1'"
                                        :disabled="disabled"
                                    ></el-input-number>
                                </div>
                                <img
                                    src="~assets/images/product/buyImg.png"
                                    alt=""
                                    class="buyImg"
                                    v-if="activeIndex === index"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="invoiceContainer">
                        <div class="pay_title">
                            发票信息<span
                                style="
                                    margin-left: 10px;
                                    font-size: 14px;
                                    color: #818181;
                                "
                                >*开企业抬头发票须填写纳税人识别号，以免影响报销</span
                            >
                        </div>
                        <div class="invoice_box">
                            <p>开具发票</p>
                            <div class="invoice_news">
                                <div class="goodsNews">
                                    <p>电子发票</p>
                                    <em>—</em>
                                    <span>单位</span>
                                </div>
                            </div>
                            <el-button
                                type="info"
                                round
                                size="mini"
                                @click="changeInvoice"
                                >添加发票信息</el-button
                            >
                        </div>
                    </div>
                    <div id="order-pay-box" class="mtop128">
                        <div class="pay_type_box">
                            <div
                                class="pay_type pointer"
                                @click="changePayMode('3')"
                            >
                                <p
                                    :class="{
                                        activeType: orderInfoVo.payMode == '3',
                                    }"
                                ></p>
                                <img
                                    src="~assets/images/product/xxzfImg.png"
                                    alt=""
                                />
                                <span>线下支付</span>
                            </div>
                            <div
                                class="pay_type pointer"
                                @click="changePayMode('2')"
                            >
                                <p
                                    :class="{
                                        activeType: orderInfoVo.payMode == '2',
                                    }"
                                ></p>
                                <img
                                    src="~assets/images/product/zfbImg.png"
                                    alt=""
                                />
                                <span>支付宝支付</span>
                            </div>
                            <div
                                class="pay_type pointer"
                                @click="changePayMode('1')"
                            >
                                <p
                                    :class="{
                                        activeType: orderInfoVo.payMode == '1',
                                    }"
                                ></p>
                                <img
                                    src="~assets/images/product/wxImg.png"
                                    alt=""
                                />
                                <span>微信支付</span>
                            </div>
                        </div>
                        <div class="discountBox" v-if="moneyObj.id != ''">
                            <div class="discountTitle" @click="changeDiscont">
                                使用红包/礼品卡/抵用
                                <i
                                    :class="
                                        isDiscount
                                            ? 'el-icon-arrow-up'
                                            : 'el-icon-arrow-down'
                                    "
                                ></i>
                            </div>
                            <div class="discontRedPacket" v-if="isDiscount">
                                <div
                                    class="discountImgBox"
                                    @click="choseRedPacket"
                                >
                                    <img
                                        src="~assets/images/redPackImg1.png"
                                        alt=""
                                        v-show="!choseDis"
                                    />
                                    <img
                                        src="~assets/images/redPackImg.png"
                                        alt=""
                                        v-show="choseDis"
                                    />
                                    <div class="discountNews">
                                        <p>¥ {{ moneyObj.amount }}</p>
                                        <span :class="{ active: choseDis }"
                                            >仅限平台使用</span
                                        >
                                        <em :class="{ active: choseDis }"
                                            >有效期至：{{
                                                moneyObj.deadline
                                            }}</em
                                        >
                                    </div>
                                </div>
                                <p>*红包一次性抵扣，不可拆分使用。</p>
                            </div>
                        </div>
                        <div class="order-payment-price">
                            <p>应交金额：</p>
                            <h3>¥{{ showPrice }}</h3>
                        </div>
                        <button
                            class="paymentBtn pointer"
                            @click="orderPayment"
                            :disabled="disabled"
                            v-if="orderInfoVo.payMode != '3'"
                        >
                            立即支付
                        </button>
                        <button
                            class="paymentBtn pointer"
                            @click="orderPayment"
                            :disabled="disabled"
                            v-else
                        >
                            确认下单
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="tipsBox" v-if="isShow" @click="isShow = false;">
            <div>
                <h4>公对公打款信息：</h4>
                <p>户名：内蒙古蒙科立蒙古文化股份有限公司</p>
                <p>电话： 0471-6921827</p>
                <p>开户行：招商银行股份有限公司呼和浩特分行营业部</p>
                <p>账号：999014873010869</p>
                <p>
                    注：提交订单并打款后，请尽快与客服联系确认打款！我们确认收到款后，会及时为您开通相应服务。
                </p>
            </div>
        </div>
        <Invoice
            :visible="visible"
            :remoteClose="remoteClose"
            @incoiceDetails="incoiceDetails"
            :invoiceInfo="invoiceInfo"
        />
    </div>
</template>
<script>
import Invoice from "components/common/invoice";
import { getProductDetails } from "api/product";
import { transactions, queryOrder, getAmount } from "api/order";
export default {
    components: { Invoice },
    computed: {
        efficientTime() {
            return function (type) {
                let retrunType = "";
                if (type == 1) {
                    retrunType = "24个小时";
                } else {
                    retrunType = type + "天";
                }
                return retrunType;
            };
        },
        showPrice() {
            if (this.activeIndex != -1) {
                this.packageList.forEach((item, index) => {
                    if (index == this.activeIndex) {
                        if (this.choseDis) {
                            let priceNum =
                                Math.floor(
                                    (item.price * this.orderInfoVo.buyCount -
                                        this.moneyObj.amount) *
                                        100
                                ) / 100;
                            if (priceNum <= 0) {
                                this.payPrice = 0;
                            } else {
                                this.payPrice = priceNum;
                            }
                        } else {
                            this.payPrice =
                                item.price * this.orderInfoVo.buyCount;
                        }
                    }
                });
            }
            return this.payPrice;
        },
        second: function () {
            return this.num(this.seconds);
        },

        minute: function () {
            return this.num(this.minMinutes);
        },
    },
    data() {
        return {
            activeIndex: -1,
            productName: "",
            packageList: [],
            payPrice: 0,
            orderInfoVo: {
                product: {
                    id: "",
                },
                productPaymentMode: {
                    id: "",
                },
                payMode: "",
                buyCount: 1,
                promotionRecordId: "",
                cardType: "3",
            },
            payImg: "",
            disabled: false,
            orderId: "",
            seconds: 0,
            minMinutes: 3,
            timer: null,
            singleBuy: "",
            isDiscount: false,
            choseDis: false,
            moneyObj: {
                amount: 0,
                deadline: "",
                id: "",
            },
            visible: false,
            isShow: false,
            invoiceInfo: {
                titleName: "",
                phoneNumber: "",
                taxNumber: "",
                titleType: "",
            },
        };
    },
    created() {
        this.productId = this.$route.query.productId;
        this.orderInfoVo.product.id = this.$route.query.productId;
        if (this.$route.query.packageType) {
            this.activeIndex = parseInt(this.$route.query.packageType);
        }
        this.fetchData(this.productId);
        this.fetchAmount();
    },
    methods: {
        //发票组件传回的发票信息
        incoiceDetails(data) {
            if (data.status === 0) {
                this.invoiceInfo.titleName = data.userName;
                this.invoiceInfo.phoneNumber = data.phone;
            } else {
                this.invoiceInfo.titleName = data.companyName;
                this.invoiceInfo.phoneNumber = data.companyPhone;
                this.invoiceInfo.taxNumber = data.invoiceNum;
            }
            this.invoiceInfo.titleType = data.status;
        },
        //显示发票模块
        changeInvoice() {
            this.visible = true;
        },
        //关闭发票模块
        remoteClose() {
            this.visible = false;
        },
        choseRedPacket() {
            if (this.activeIndex == -1) {
                this.$message({
                    message: "请选择购买规格",
                    type: "warning",
                });
            } else {
                this.choseDis = !this.choseDis;
                this.orderInfoVo.promotionRecordId = this.choseDis
                    ? this.moneyObj.id
                    : "";
            }
        },
        changeDiscont() {
            this.isDiscount = !this.isDiscount;
        },
        orderPayment() {
            if (this.activeIndex == -1) {
                this.$message({
                    message: "请选择购买规格",
                    type: "warning",
                });
            } else if (this.orderInfoVo.payMode == "") {
                this.$message({
                    message: "请选择付款方式！",
                    type: "warning",
                });
            } else {
                //如果时次卡
                //月、季、年
                let type = "";
                if (this.activeIndex == 0 && this.singleBuy == "1") {
                    type = "times";
                } else {
                    type = "days";
                }
                transactions(this.orderInfoVo, type, this.invoiceInfo, 1).then(
                    (res) => {
                        if (res.success && res.errorCode == "2000") {
                            //1. 线下支付 -- 不进行付款状态轮询 ，弹出下单成功的提示信息，并跳转到个人中心 -- 我的订单
                            //2. 线上支付
                            if(this.orderInfoVo.payMode == '3'){
                                this.$message({
                                    type:'success',
                                    message:'下单成功，请尽快与客服联系确认打款！我们确认收到款后，会及时为您开通相应服务。',
                                    duration:2000
                                })
                                setTimeout(()=>{
                                    this.$router.push('/personal/order');
                                },3000)

                            }else{
                                //qrcode '' 或 有
                                const qrcode = res.body.data.orderInfo.qrcode;
                                if (qrcode != undefined) {
                                    this.payImg = res.body.data.orderInfo.qrcode;
                                    this.orderId = res.body.data.orderInfo.id;
                                    this.Countdown(res.body.data.orderInfo.id);
                                    this.disabled = true;
                                } else {
                                    this.disabled = false;
                                    this.payImg = "";
                                    this.$router.push("/product-order-success");
                                }
                            }

                        } else if (res.errorCode == "3003") {
                            this.disabled = false;
                            this.$message({
                                message: res.msg,
                                type: "warning",
                            });
                        }
                    }
                );
            }
        },
        changePayMode(payMode) {
            if (payMode == "3") {
                this.isShow = true;
            }
            this.orderInfoVo.payMode = payMode;
        },
        choseSpecs(index, item) {
            this.activeIndex = index;
            this.orderInfoVo.productPaymentMode.id = item.id;
        },
        payment() {
            this.$router.push("/product-order-success");
        },
        num(n) {
            return n < 10 ? "0" + n : "" + n;
        },
        Countdown(id) {
            var _this = this;
            _this.timer = window.setInterval(function () {
                if (_this.seconds === 0 && _this.minMinutes !== 0) {
                    _this.seconds = 59;
                    _this.minMinutes -= 1;
                } else if (_this.minMinutes === 0 && _this.seconds === 0) {
                    _this.seconds = 0;
                    window.clearInterval(_this.timer);
                    _this.disabled = false;
                    _this.payImg = "";
                } else {
                    _this.seconds -= 1;
                }
                queryOrder(id).then((res) => {
                    if (res.success) {
                        if (res.body.data.status == "3") {
                            window.clearInterval(_this.timer);
                            _this.disabled = false;
                            _this.payImg = "";
                            _this.$router.push("/product-order-success");
                        }
                    }
                });
            }, 1000);
        },
        fetchData(id) {
            getProductDetails(id).then((res) => {
                if (res.success) {
                    this.singleBuy = res.body.data.productInfo.singleBuy;
                    this.productName = res.body.data.productInfo.name;
                    this.packageList =
                        res.body.data.productInfo.productPaymentRuleList;
                    this.orderInfoVo.productPaymentMode.id =
                        this.activeIndex != -1
                            ? this.packageList[this.activeIndex].id
                            : "";
                }
            });
        },
        fetchAmount() {
            getAmount().then((res) => {
                if (res.success) {
                    if (res.body.data.length > 0) {
                        this.moneyObj = res.body.data[0];
                    }
                }
            });
        },
    },
    watch: {
        second: {
            handler(newVal) {
                this.num(newVal);
            },
        },
        minute: {
            handler(newVal) {
                this.num(newVal);
            },
        },
    },
    beforeDestroy() {
        if (this.timer) {
            //如果定时器还在运行 或者直接关闭，不用判断
            clearInterval(this.timer); //关闭
        }
    },
};
</script>
<style lang="less">
.order-specs-type {
    height: 180px;
}
.order-tips {
    width: 212px;
}
.order-specs-type-box {
    justify-content: space-between;
}
.order-specs-type {
    width: 300px;
}
.order-specs-type .title {
    font-size: 18px;
}
.order-specs-type-box {
    flex-wrap: wrap;
}
.el-input-number--small {
    line-height: 20px;
}
.el-input-number__decrease,
.el-input-number__increase {
    height: 18px;
}
.el-input--small .el-input__inner {
    height: 20px;
    line-height: 20px;
}
.el-input-number--small .el-input__inner {
    padding: 0 20px !important;
}
.el-input-number--small {
    width: 100px;
    margin-top: 10px;
}
</style>
<style lang="less" scoped>
.goodsNews span {
    flex: 1;
}
.goodsNews em {
    margin: 0 20px;
}
.goodsNews p {
    margin: 0 !important;
}
.goodsNews {
    display: flex;
    flex-direction: row;
    margin: 0 12px;
    line-height: 32px;
}
.invoice_news {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 16px;
}
.invoice_box p {
    margin-right: 32px;
}
.invoice_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    height: 66px;
    font-size: 16px;
    color: #3b3b3b;
}
.pay_title {
    padding-left: 24px;
    height: 38px;
    line-height: 38px;
    font-size: 20px;
    color: #000;
    border-bottom: solid 1px #dadada;
}
.invoiceContainer {
    margin-top: 30px;
}

.tipsBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1002;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    div {
        padding: 10px 0;
        width: 360px;
        background: #fff;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        color: #3b3b3b;
        h4{
            padding:  0 10px;
            line-height: 32px;
            border-bottom: solid 1px #ccc;
        }
        p {
            line-height: 26px;
            padding: 0 10px;
            font-size: 14px;
        }
    }
}
.discountImgBox {
    width: 220px;
    height: 115px;
    position: relative;
    margin: 4px auto;
    cursor: pointer;
    img {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    .discountNews {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            font-size: 30px;
            font-weight: bold;
            color: #fff;
        }
        span {
            width: 140px;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            background: #b5b5b5;
        }
        span.active {
            color: #ed3c27;
            background: #ffe7af;
        }
        em {
            margin-top: 12px;
            font-size: 12px;
            color: #8d8d8d;
            font-style: normal;
        }
        em.active {
            color: #971a0b;
        }
    }
}
.discountBox {
    padding: 12px;
    width: 336px;
    border: solid 1px #ccc;
    font-size: 14px;
    color: #3b3b3b;
    .discountTitle {
        line-height: 24px;
        cursor: pointer;
    }
    .discontRedPacket {
        border-top: solid 1px #3b3b3b;
        padding: 6px 0;
        text-align: center;
        > p {
            color: crimson;
        }
    }
}
.codeImgBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.payImgBox {
    width: 370px;
    height: 444px;
    border: solid 1px #252b3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    img {
        border: solid 1px #ccc;
        width: 236px;
    }
    p {
        margin: 16px 0;
        font-size: 22px;
        color: #252b3a;
        em {
            color: #ff8610;
            font-style: normal;
        }
    }
    span {
        font-size: 16px;
        color: #252b3a;
        margin: 20px 0;
    }
}
</style>
