<template>
    <div class="main-container" style="background:#f3f5f8;">
        <Banner/>
        <Recommend/>
        <Questions/>
    </div>
</template>
<script>
import Banner from 'views/routerViews/College/Index/Banner'
import Recommend from 'views/routerViews/College/Index/Recommend'
import Questions from 'views/routerViews/College/Index/Questions'
export default {
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙古文WPS2002，蒙古文字体，蒙科立学院，蒙科立整词输入法，蒙古文WPS2002，语音识别",
            },
        ],
    },
    components:{Banner, Recommend, Questions}
}
</script>
