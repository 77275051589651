<template>
    <div class="orcBox h100">
        <div class="listBox"  v-if="page.total != 0">
            <div class="list-top-box">
                <p class="time">产品名称</p>
                <p class="type">购买终端数量</p>
                <p class="passText">安装方式</p>
                <p class="resultText">服务状态</p>
                <p class="resultText">序列号</p>
            </div>
            <div class="comServiceListBox">
                <div class="li-box" v-for="item in list" :key="item.id">
                    <p class="time">{{ item.product.name }}</p>
                    <p class="type">
                        {{ item.purchaseNum}}
                    </p>
                    <p class="passText">
                        {{item.installType == '0' ? '现场安装' : '远程指导'}}
                    </p>
                    <p class="resultText">
                    {{item.status == '0' ? '受理中' : '已完成'}}
                    </p>
                    <p class="resultText">
                        {{item.serialNum}}
                    </p>
                </div>
                <el-row type="flex" justify="center" style="margin-top: 40px">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="page.current"
                        :page-size="page.size"
                        :total="page.total"
                        layout="prev, pager, next"
                    >
                    </el-pagination>
                </el-row>
            </div>
        </div>
        <div class="noneNews" v-if="page.total == 0">
            <img src="~assets/images/personal/nonImg.png" alt="">
            <div class="promptText">
                <p>暂无记录</p>
            </div>
        </div>
    </div>
</template>
<script>
import { gainCompanyService } from 'api/personal'
export default {
    data() {
        return {
            page:{
                size: 10,
                total: null,
                current: 1
            },
            list:[],
            hasList:false
        }
    },
    created() {
        this.getList()
    },
    methods:{
        getList() {
            gainCompanyService(this.page.current, this.page.size).then(res => {
                if(res.errorCode == '2000'){
                    this.list = res.body.data.page.list;
                    this.page.total = res.body.data.page.count;
                    if(this.page.total != 0){
                        this.hasList = true
                    }else{
                        this.hasList = false
                    }
                }
            })
        },
        handleCurrentChange(current) {
            this.page.current = current
            this.getList()
        }
    }
}
</script>
<style lang="less" scoped>
.comServiceListBox{
    flex: 1;
    overflow-y: auto;
}
.li-box {
    min-height: 42px;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border-bottom: dashed 1px #ccc;
    color: #909399;
    .time {
        width: 167px;
    }
    .type {
        width: 167px;
    }
    .passText {
        width: 167px;
    }
    .resultText {
        width: 167px;
    }
    > p {
        span {
            color: #1b4094;
            margin-left: 10px;
            padding-left: 10px;
            border-left: 1px solid rgb(139, 135, 135);
            cursor: pointer;
        }
        em {
            color: #1b4094;
            cursor: pointer;
            font-style: normal;
        }
    }
}
.list-top-box {
    margin-bottom: 20px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: bolder;
    color: #909399;
    text-align: center;
    border-bottom: solid 2px #ccc;
    .time {
        width: 167px;
    }
    .type {
        width: 167px;
    }
    .passText {
        width: 167px;
    }
    .resultText {
        width: 167px;
    }
}
.listBox {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.filenewsBox {
    display: flex;
    flex-direction: row;
    span {
        margin-left: 12px;
        white-space: nowrap;
        cursor: pointer;
        color: #1b4094;
    }
}
.orcBox {
    padding: 0 42px;
    background: #fff;
}
</style>
