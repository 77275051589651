import axios from 'axios'
import { Message } from 'element-ui'
export function request(config) {
    const instance = new axios.create({
        baseURL: '',
        timeout: 60000
    });
    instance.interceptors.request.use(config => {
        return config;
    }, error => {
        console.log(error)
    })

    instance.interceptors.response.use(res => {
        if (res.status !== 200) {
            Message({
                message: '服务器内部错误',
                type: 'warning'
            })
        } else {
            return res.data
        }
    }, error => {
        console.log(error)
    })

    return instance(config)
}
