const proJectList = [
    {
        id:'project01',
        partyAname:'内蒙古北疆交通设计咨询有限公司',
        partyALogo:require("@/assets/images/case/logoImg1.png"),
        projectName:'业务信息化系统',
        projectTypeImg:require("@/assets/images/case/img1.png"),
        projectsurvey:`
            为创建知识型企业、打造学习型职工，随着业务精细化管理的不断深入，企业对信息化的
            应用需求也逐渐增长。有了众多精细化管理业务系统的建设和运行，大大提高了企业的信
            息化水平，在这种情况下实现内部业务流程无纸化办公是公司在当前信息化部
            署实施的重点。
        `,
        khIntroduction:`
            内蒙古北疆交通设计咨询有限公司是隶属于内蒙古公路交通投资发展有限公司的全资子公司。
            营业务包括工程勘察设计(包含公路工程、交通工程、建筑工程、养护工程等)、可行性研究报
            告编制、安全性评估报告的编制、测绘服务、工程管理服务(工程监理服务、项目管理服务)、
            工程招标代理、工程咨询、实验、检测服务、软件开发、交通工程研究服务;交通科研技术及
            产品的推广应用;交通信息化软硬件研发。
        `,
        challengesText:`
            <span>随着公司日益壮大，及主营业务的飞速发展。企业对信息化管理不断增长的应用需求，出现了
            内部业务无法快速流转，日常工作需求不能及时得到满足；北疆设计院提出了如下需求：<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            深化内部业务流程的过程中摸索出业务相关的精细化管理业务流，有效的调动了职工积极性、提高了企业活力。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            业务信息化系统主要为设计院内部各部门业务工作标准化、流程化，提高工作效率，实现工作职责可追溯性，达到技术上自主可控的效果。
            </span>
        `,
        programmeText:`
            蒙科立服务团队通过对客户业务需求的分析，将其业务采用业务信息化系统解决方案体系及相应产品。
            系统基于当下最流行的B/S架构进行设计，结合实际业务需求将系统分为10大模块，分别为：基础支
            撑平台、业务工作台、个人中心、流程管理子系统、任务书管理、电子签章管理、安评报告管理、
            方案设计及预算管理、统计分析以及在线学习等模块。基于业务信息化系统，结合北疆设计院应用
            需求，通过与硬件厂商合作，实现了企业进行数字化运营的诉求。
        `,
        khProfit:`
            <span><em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            企业级、专业的业务信息化系统平台、专属设备托管、和当前生产系统的混合云落地方案<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            提供数据备份和恢复功能使，在由于系统的错误或其他原因引起系统的数据丢失或系统的数据被破坏时，能够及时恢复和还原数据。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            7*24小时技术支持和运维服务<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            按需弹性服务方式
            </span>
        `,
        caseType:[
            {
                id:0,
                text:'交通运输'
            }
        ],
        imgList:[
            {
                id:0,
                imgUrl:require("@/assets/images/case/p1img1.png")
            },
            {
                id:1,
                imgUrl:require("@/assets/images/case/p1img2.png")
            },
        ]
    },
    {
        id:'project02',
        partyAname:'内蒙古交科路桥建设有限公司',
        partyALogo:require("@/assets/images/case/logoImg2.png"),
        projectName:'农民工实名管理系统',
        projectTypeImg:require("@/assets/images/case/img2.png"),
        projectsurvey:`
            农民工实名管理系统主要解决了公路交通、建设施工等领域开放式施工现场的农民
            工劳务人员的实名登记、安全培训、考试、档案管理、考勤管理、劳务人员在岗实名核查等棘手问题。
        `,
        khIntroduction:`
            内蒙古交科路桥建设有限公司于2001年08月02日成立。主营业务包括公路工程施工总承包；公路路面工
            程专业承包；公路路基工程专业承包；市政公用工程施工总承包；桥梁工程专业承包；仓储服务
            （不含易燃、易爆、危险品）；园林绿化工程施工；机械租赁；沥青、改性沥青、乳化沥青的销售
            ；公路交通工程（公路安全设施分项）专业承包；建筑工程施工总承包等。
        `,
        challengesText:`
            <span>建设单位现场施工监管部门，为解决极容易引起劳资纠纷的问题提出以下需求：<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            建筑工地人工杂乱、安监部门很难监督施工人员的工作量以及工作效率低下<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            人员管理困难、工人考勤无法量化统计、工资与工时对不上，工人的利益得不到保障<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            劳务人员在岗实名核查，统计入库问题<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            线下安全知识培训普及难<br/>
            </span>
        `,
        programmeText:`
            系统建设单位现场施工监管部门解决建筑工地人工杂乱、安监部门很难监督施工人员的工作量以及工作效率、
            人员管理困难、工人考勤无法量化统计、工资与工时对不上、工人的利益得不到保障等问题，
            从而有效解决了极容易引起劳资纠纷的问题。
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            系统配套提供了人脸识别考勤机功能，集人脸识别+考勤+体温检测功能与一体，同时可通过移动设备下现场进行人员实
            名核定及是否脱岗的核查工作；测温模块，测温精准、快速、无接触为工地防疫测温提供高效检测及管理工具。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            实名登记我们采用了集人像采集、人像核验、身份证自动读取为一体的精密设备，从而可快速、高效的完成工人的实名
            认证信息采集入库工作；为保障农民工朋友的自身利益，系统提供个人银行卡识别功能，同个人档案一起收集，
            发放薪资用于核实自身账号信息。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            本系统提供线上学习课件、视频等内容供用工单位针对农民工进行安全教育培训，同时还满足了用工单位组织的培
            训考试（现场打印试卷、答卷、判卷），培训记录和成绩归档。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            用工单位可使用“农民工掌中宝”App下现场随机抽查农民工在岗情况，通过人脸核验可读取劳务人员的实名信息，实时查看所辖区的人员工作状态。<br/>
        `,
        khProfit:`
            <span><em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            弹性伸缩，灵活应对业务发展需求，按需使用减少成本。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            具备快速部署和搭建高可用方案的条件，以及数据安全保护。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            获得售前和售后技术支持团队的服务，提供解决方案的建议。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            按需弹性服务方式
            </span>
        `,
        caseType:[
            {
                id:0,
                text:'交通运输'
            }
        ],
        imgList:[
            {
                id:0,
                imgUrl:require("@/assets/images/case/p2img1.png")
            },
            {
                id:1,
                imgUrl:require("@/assets/images/case/p2img2.png")
            },
            {
                id:2,
                imgUrl:require("@/assets/images/case/p2img3.png")
            }
        ]
    },
    {
        id:'project03',
        partyAname:'内蒙古建筑职业技术学院',
        partyALogo:require("@/assets/images/case/logoImg3.png"),
        projectName:'网络培训及考试系统',
        projectTypeImg:require("@/assets/images/case/img3.png"),
        projectsurvey:`
            随着计算机信息化日新月异的发展，计算机网络发挥了越来越大的作用。如何更合理、
            更高效地将计算机网络信息的高效性应用到我们教育教学学习考试上，一直是我们持续关注、
            研发的主题和目标。
        `,
        khIntroduction:`
            内蒙古建筑职业技术学院坐落于内蒙古自治区首府呼和浩特市，是内蒙古自治区独立设置的建筑
            类高等院校，是国家示范性高等职业院校，国家优质高等职业院校，国家“双高计划”高水平专
            业群建设单位，全国住房和城乡建设职业教育教学指导委员会委员单位，全国高职高专土建类
            行指委市政工程专指委主任委员单位，自治区高等教育学会高职高专教育研究会理事长单位，
            自治区高校毕业生就业工作促进会理事长单位。
        `,
        challengesText:`
            <span>为提高培训项目工作的效率，促进高等院校教学方法、教学手段的整体改革，提升高校人才培养的质量提出了如下需求：<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            传统的纸质考试需要人员集中，专门安排考场，往来时间的损耗、考试组织费用等等，成本非常高、<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            传统的纸质考试出卷、排版、印刷、交卷、批改、成绩登记、统计查询等工作比较繁琐，耗时耗力<br/>
            </span>
        `,
        programmeText:`
            <em style="text-indent:28px;font-style: normal;">
                视频课程学习模块，基于专业或课程内容进行多级分类，进行选择学习。在选择对应课程进行学习前必须先缴费，否则无法学习
            </em><br/>
            <em style="text-indent:28px;font-style: normal;">
                开启课程学习，每5分钟暂停一次，需用户点击鼠标或触摸解除暂停继续学习。
            </em><br/>
            <em style="text-indent:28px;font-style: normal;">
                课程播放可调节音量，并自动保存进度（意外关闭等再次登录学习自动打开当前位置），课程全部播放完毕，记录完成本节课程学习。
            </em><br/>
            <em style="text-indent:28px;font-style: normal;">
                未学习的课程及单节中的进度不得快进跳过，已经学习过的课程与单节内容中的部分，可以返回重播或快进。
            </em><br/>
            <em style="text-indent:28px;font-style: normal;">
                课程学习原则：首次，也就是没有播放学习过的课程目录及内部课程节点，不得跨越快进等操作。已经完成的课程，可任意选取观看。
            </em><br/>
            <em style="text-indent:28px;font-style: normal;">
                记录每个学员学习的数据记录，包括用户名称、学习课程、完成状态、学习时长，模拟次数、学完章节数、最近学习时间等；支持根据关键字进行记录的检索。
            </em><br/>
            <em style="text-indent:28px;font-style: normal;">
                学员参加正式考试需进行实名身份核验，正式开考前，检测用户当前设备是否摄像头且是否可用，正式开考前根据考试规则设置，
                提前让用户做好一系列准备，例如保证摄像头可以清晰捕捉到用户正脸，未达标不允许进入考试。
            </em><br/>
            <em style="text-indent:28px;font-style: normal;">
                正式考试中：
            </em><br/>
            <em style="text-indent:28px;font-style: normal;">
                <em style="text-indent:28px;font-style: normal;">
                    1、疑似作弊检测记录：在规定的时间定时抓拍画面，将抓拍画面均记录在本次考试的异常记录中，可由人工再次判定处理。
                </em>
            </em><br/>
            <em style="text-indent:28px;font-style: normal;">
                <em style="text-indent:28px;font-style: normal;">
                    2、人像核对，进入考试前对屏幕前的用户进行人像核对，如发现人像与注册报名时人像不吻合，不允许进入考试，无法参加考试。
                </em>
            </em><br/>
            <em style="text-indent:28px;font-style: normal;">
                <em style="text-indent:28px;font-style: normal;">
                    3、系统完整的提供了试卷自动判分、题库、考试规则设定、考试记录、自动随机组卷、人工组卷、线上考试、证书打印
                    、课程购买、订单管理、统计分析等功能，此系统线上培训与考试一体化解决方案。
                </em>
            </em><br/>
        `,
        khProfit:`
            <span>通过对网络培训及考试系统的引入，解决了内建职院对教学改革的需求；<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            通过网络培训及考试系统的引入，为学生提供了在线培训、在线报名，在线考试的便利，老师可以实时更新课件及视频。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            在整个过程中，蒙科立的客户经理、架构、研发及技术支持为内建职院提供全方位的服务，保障内建职院项目高效运营。<br/>
            </span>
        `,
        caseType:[
            {
                id:0,
                text:'教育'
            },
            {
                id:1,
                text:'科技互联网'
            }
        ],
        imgList:[
            {
                id:0,
                imgUrl:require("@/assets/images/case/p3img1.png")
            },
            {
                id:1,
                imgUrl:require("@/assets/images/case/p3img2.gif")
            },
        ]
    },
    {
        id:'project04',
        partyAname:'内蒙古北疆交通设计咨询有限公司',
        partyALogo:require("@/assets/images/case/logoImg1.png"),
        projectName:'内蒙古公路交通信息化平台',
        projectTypeImg:require("@/assets/images/case/img4.jpg"),
        projectsurvey:`
            该软件系统的建设主要为解决公路建设项目前期基础信息采集工作存在不规范、不统一、
            信息不对称、沟通不畅、重复劳动等问题，满足公司信息化平台建设以及在建项目资料归
            档需求，实现总公司所辖各路段技术信息标准化管理，便于项目后期管养建设过程中能够
            快速精确查找所需资料，节省文档交流成本，提高工作效率。
        `,
        khIntroduction:`
            内蒙古北疆交通设计咨询有限公司是隶属于内蒙古公路交通投资发展有限公司的全资子公司。
            营业务包括工程勘察设计(包含公路工程、交通工程、建筑工程、养护工程等)、可行性研究
            报告编制、安全性评估报告的编制、测绘服务、工程管理服务(工程监理服务、项目管理服务)、
            工程招标代理、工程咨询、实验、检测服务、软件开发、交通工程研究服务;交通科研技术及产
            品的推广应用;交通信息化软硬件研发。
        `,
        challengesText:`
            <span>勘察数据信息化管理平台，建立服务于横、纵向工作的沟通联系，实现信息及时传递与共享，提出如下需求<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            所有分公司、项目办工作，都将在项目信息化管理平台上部署完成<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            实现电子化办公，总公司所辖项目的前期支撑件、可研、初步设计、施工图设计、竣工验收、检测养护等相关资料收集整理，实现电子化并录入平台进行存档。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            实现信息及时传递与共享，标准化的管理、及时更新的各类数据，各个部门资源信息的共享。<br/>
            </span>
        `,
        programmeText:`
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            项目信息化平台利用模块化设计思想建设基础平台，实现用户统一分配、统一管理的目的；将公路新建、养护项目的前期支撑件资
            料做信息化处理，统一管理、统一存储，实现在线调阅、检索、归档等目的；建设项目管理、项目资料资源库建设、模板机制
            、支持多中资源上传模式，包括zip压缩包、文件夹上传、单文件上传等，系统自动进行文件切分及重命名，一件上传。模板
            管理中可定制多套项目资料模板结构，在上传资料的过程中可任意引用已启用的模板，快速生成资料文件目录结构，同时模
            板也支持克隆功能。项目分为拟建项目、在建项目、已建项目三类，系统提供GIS地图集成模式，将所有辖区负责的公路路段
            以地图线路的形式展示，可直观的在地图查阅某一条具体线路的详细资料，包括项目概况、项目所有电子资料文献及图纸；
            项目资源库建设功能中系统使用了超大文件上传技术，可在浏览器端一次性上传单个文件在8GB以上的文件；另外系统通过
            上传的资源目录结构及类型，自动形成项目总目录、分目录及子目录共三级目录，贴近用户线下办公需求。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            勘察数据采集信息化管理系统利用模块化设计思想建设基础平台，实现用户统一分配、统一管理的目的；外业采集流程进行标准处理，
            建设工单子系统，通过系统派单、领单、提交审批等，项目管理均以该信息库为主要依据，覆盖全区12盟市的道路交通均在GIS地
            图上绘制并分盟市展示，快速定位项目坐标；将采集数据进行线上上报，流程标准化、任务可跟踪。通过移动终端提升公路、
            桥梁项目前期全过程外业数据采集规范及标准化，最终形成全区国省干线公路项目的全生命周期数据库，建立服务于横、纵向工
            作的沟通联系，实现信息及时传递与共享。将外业勘察路线、重要控制点、重要构造物等的地理位置、重要信息一次录入手机等移动端，
            配合手机GPS导航使勘察过程准确高效。将项目信息录入，可在高清卫星、二维地图上全面、直观的展示项目全貌。利用手机移
            动终端设备随时定位记录调查点的信息，并将信息上传服务器。提高调查效率、高效指导业内方案的拟定。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            外业通APP软件基于开放的Android系统进行开放，解决交通行业外业作业工作危险、方式陈旧、数据不及时等诸多业务痛点；该软件结合了
            移动互联网技术、空间定位技术等，在外业采集流程上进行优化处理，外业勘察人员手持高精度移动终端现场定位，根据后端管
            理系统预设线路图，精准指示到达目标桩号进行数据采集工作，系统提供外业小组在线接单、小组人员分配、角色认定、线上填报
            外业采集指标数据项，从而进行信息化、标准化处理，外业人员填报数据可线上进行数据审批、流转，解决了以往必须来回奔波返
            回公司进行汇报的弊端，大大节省了人力、物力和精力，数据及时共享，系统支持在二维地图及卫星地图模式，在线绘制线路图及
            标注采集点，支持道路交通行业2大类（新建、养护）11小类标准化数据采集标准，包括交安、路基、路面、排水防护、桥涵、隧道、
            社会调度、其他等；采集数据进行线上上报，流程标准化、任务可跟踪，实现信息及时传递与共享。<br/>
        `,
        khProfit:`
            <span>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            三款软件极简接入即可发挥强大功能，团队反复检验与打磨，让访问和使用更具稳定性、兼容性和流畅性。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            应用安全、环境安全等多维度全面保护客户端软件自身安全和客户端数据传输安全。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            提供数据备份和恢复功能使，在由于系统的错误或其他原因引起系统的数据丢失或系统的数据被破坏时，能够及时恢复和还原数据。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            7*24小时技术支持和运维服务<br/>
            </span>
        `,
        caseType:[
            {
                id:0,
                text:'交通运输'
            }
        ],
        imgList:[
            {
                id:0,
                imgUrl:require("@/assets/images/case/p4img1.jpg")
            },
            {
                id:1,
                imgUrl:require("@/assets/images/case/p4img2.jpg")
            },
            {
                id:2,
                imgUrl:require("@/assets/images/case/p4img3.jpg")
            },
            {
                id:3,
                imgUrl:require("@/assets/images/case/p4img4.jpg")
            },
        ]
    },
    {
        id:'project05',
        partyAname:'内蒙古自治区非物质文化遗产保护中心',
        partyALogo:require("@/assets/images/case/logoImg5.png"),
        projectName:'内蒙古蒙古族非物质文化遗产数据库建设',
        projectTypeImg:require("@/assets/images/case/img5.png"),
        projectsurvey:`
        《内蒙古蒙古族非物质文化遗产数据库建设——民间文学类数据库》建设的社会价值在于非物质文化遗产的保护、
        传承、创新发展首次运用了新信息传播技术手段，面向全世界传播蒙古族优秀的非物质文化遗产，这具有
        极其重要的意义。特别是保障少数民族文化安全、信息安全、边疆安全、西部少数民族社会的稳定均具有无
        法用经济效益计算和衡量的巨大社会效益。
        `,
        khIntroduction:`
        内蒙古自治区非物质文化遗产保护中心属于自治区文化厅直属处级事业单位，中心主要承担自治区非物质文化遗产保护工作的具体组织实施和联络交流。
        `,
        challengesText:`
            <span>内蒙古蒙古族非物质文化遗产数据库建设旨在为内蒙古蒙古族非遗保护工作提供蒙古族非物质文化遗产项目的资源支持，
            通过整合10个非遗代表性项目民间文学、 传统音乐、 传统舞蹈、 曲艺 、 传统戏剧、 传统体育游艺与杂技、 传统美术、
            传统技艺、 传统医药、 民俗的资源，来帮助内蒙古蒙古族非遗传承人群强基础、拓眼界、增学养，增强文化自信，提高专业
            技术能力和可持续发展能力，提升非遗保护传承水平。同时也丰富参与院校的学术和科研积累，完善相关学科体系建设，更好地发挥文化传承创新功能。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            解决非物质文化遗产数据库中海量资源的蒙、汉检索功能<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            长篇文学集中展示的问题<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            资源分类及长篇文学集中展示的问题<br/>
            </span>
        `,
        programmeText:`
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            在数据库中完成不少于三个大项目的全套数据库建设工作，在每一个具体的项目中，分别设立四个相对独立并有所交叉的单元，
            分别为曲目（包括曲谱、唱词、录音、录像及相关简介）；传承人（简介、照片、申报片、申报文本）；出版物
            （唱词集、专辑、论文、专著、报道）；专项活动（比赛、演出、研讨会、田野调查、培训）等。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            非物质文化遗产数据库中海量资源的蒙、汉检索功能，让用户能够第一时间快速定位到所需要的资源。在解决这
            一关键问题中，项目组采用了两种解决方法：一是通过设定模糊搜索和高级定位搜索两种方式，
            使检索功能能够适用于各种需求类型的检索用户；二是通过总库搜索和各子库单独搜索的方式递
            减和缩小检索范围，使检索用记能够根据个人需要快速定位所找的资源。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            在建设民间文学类数据库的过程中，着重解决长篇幅的民间文学作品在数据库中集中展示的问题以及海量非遗资源的分类录入时穷尽对象、
            理清逻辑关系等问题。如在录入长篇乌力格尔《嘎达梅林》等项目时，由于篇幅极大，对其内容录入及前台展现等问题给予了集中解决。<br/>
        `,
        khProfit:`
            <span>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            《内蒙古蒙古族非物质文化遗产数据库建设——民间文学类数据库》建设的社会价值在于非物质文化遗产的保护、传承、创新发展首次运
            用了新信息传播技术手段，面向全世界传播蒙古族优秀的非物质文化遗产，这具有极其重要的意义。特别是保障少数民族文化安全、
            信息安全、边疆安全、西部少数民族社会的稳定均具有无法用经济效益计算和衡量的巨大社会效益。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            通过本项目的实施，大大提升了内蒙古自治区非物质文化遗产保护中心人员使用蒙文蒙语开展各项工作，同时培养了能够熟练运用计算机知识，
            操作技术比较稳定的双语干部队伍；软件技术研发方面有效培养了蒙古语授课计算机专业人才数名。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            落实党的十九大提出的加强文化遗产保护传承，推动中华优秀传统文化创造性转化、创新性发展的要求，进一步扩大实施范围，覆盖更多非
            遗门类，惠及更多传承人群，推动非遗的当代实践，提高非遗保护传承水平。进一<br/>
            </span>
        `,
        caseType:[
            {
                id:0,
                text:'政务'
            }
        ],
        imgList:[
            {
                id:0,
                imgUrl:require("@/assets/images/case/p5img1.jpg")
            },
            {
                id:1,
                imgUrl:require("@/assets/images/case/p5img2.jpg")
            },
            {
                id:2,
                imgUrl:require("@/assets/images/case/p5img3.jpg")
            },
            {
                id:3,
                imgUrl:require("@/assets/images/case/p5img4.jpg")
            },
        ]
    },
    {
        id:'project06',
        partyAname:'内蒙古大学蒙古语学院',
        partyALogo:require("@/assets/images/case/logoImg6.png"),
        projectName:'《江格尔》声像图文数据库',
        projectTypeImg:require("@/assets/images/case/img6.png"),
        projectsurvey:`
        随着全球化趋势的增强，经济和社会的急剧变迁，《江格尔》的生存、保护和发展也遇到了新的情况和问题，
        形势十分严峻。著名的民间艺人有的已经过世，在世的也都已经年届高龄，面临着“人亡歌息”的危险。
        因此《江格尔》声像图文数据库项目建立是对《江格尔》传承人和资料的抢救和保护工作，必须抓紧，
        以使这部宝贵的史诗长唱于世间。
        `,
        khIntroduction:`
        内蒙古大学蒙古语言文学系成立于1957年，蒙古语言文学专业经过50多年的建设，在教学科研水平和师资
        队伍综合实力方面在国内高校同类专业中位居领先，在国际上位居先进地位。以培养蒙古语言文学专业人
        才为目的，在奖学金设置、免试推荐研究生等方面享有优惠政策；“文理综合”方向是应信息时代人才需求，
        联手内蒙古大学计算机学院培养掌握蒙古语言文学和计算机综合知识的蒙古文信息处理应用型人才为目的
        ，面向蒙授文理科招生的应用型专业方向。
        `,
        challengesText:`
            <span>《江格尔》声像图文数据库，主要以推进新时代民族团结进步创建工作，深化信息技术，将民族文化得到全面推广和应用提出以下需求：<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            体现民族特色，和江格尔故事特色。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            蒙古文的显示需求和特点，操作和阅读习惯符合蒙古文的习惯。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            要实现对蒙古文献内容扫描，对多媒体数字信息存储。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            OCR扫描技术需要支持多语种。<br/>
            </span>
        `,
        programmeText:`
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            《江格尔》声像图文数据库后台管理系统方便了研究人员对文献资料的管理工作，标准化的人员管理进一步加强了数据库信息的安全保障；系
            统支持OCR扫描技术，支持多语种，包括汉语、英文、德文，其中新蒙文、图瓦、卡尔梅克、阿尔泰文的扫描，大大提升了资料搜集和录入的工
            作效率；系统可切换蒙古文专版，版面设计尊重传统蒙古文的书写排列方式和蒙古文阅读习惯。
        `,
        khProfit:`
            <span>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            《江格尔》声像图文数据库系统前台和后台管理系统极简接入即可发挥强大功能，团队反复检验与打磨，让访问和使用更具稳定性、兼容性和流畅性。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            应用安全、环境安全等多维度全面保护客户端软件自身安全和数据传输安全。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            7*24小时技术支持和运维服务<br/>
            </span>
        `,
        caseType:[
            {
                id:0,
                text:'政务'
            }
        ],
        imgList:[
            {
                id:0,
                imgUrl:require("@/assets/images/case/p6img1.jpg")
            },
            {
                id:1,
                imgUrl:require("@/assets/images/case/p6img2.png")
            },
        ]
    },
    {
        id:'project07',
        partyAname:'人民法院电子音像出版社',
        partyALogo:require("@/assets/images/case/logoImg7.png"),
        projectName:'中国审判法律应用支持系统（蒙古文版）“法信蒙古文版”',
        projectTypeImg:require("@/assets/images/case/img7.png"),
        projectsurvey:`
        为深入贯彻落实十九大对于全面依法治国提出的新要求，更好地服务以审判为中心的诉讼制度改革，
        更好的服务民族地区法治建设发展，促进法治理念传播与法治文化建设，力争将“法信”蒙古文版打
        造成致力于为全国少数民族法官、当事人以及少数民族群众使用本民族语言开展司法审判，参与诉讼
        服务，交流审判经验，汇聚审判资源的综合性法律知识服务平台。
        `,
        khIntroduction:`
        人民法院电子音像出版社是高人民法院主管、人民法院出版社主办的全民所有制企业，经营范围为法律资讯服务、
        法律数字产品、法律数据库的研发与营销；电影、电视剧、电视栏目和专题片的策划发行；电子音像制品的制作出版；
        相关培训及活动的策划组织等。主要业务是为全国司法系统、法律行业及社会公众提供各种法律信息查询的产品及服务
        、法律应用及教学服务和法制影视的制作、推广服务。未来的人民法院电子音像出版社将以法院系统信息资源为依托
        ，在已有的渠道和经验基础上，利用先进的网络数字和多媒体新媒体技术，发展成为权威、专业、独具品牌影响力
            的法律产品、资讯和多媒体服务的供应商。
        `,
        challengesText:`
            <span>为深入贯彻落实十九大对于全面依法治国提出的新要求，更好地服务以审判为中心的诉讼制度改革，更好的服
            务民族地区法治建设发展，促进法治理念传播与法治文化建设，力争将“法信”蒙古文版打造成致力于为全国
            少数民族法官、当事人以及少数民族群众使用本民族语言开展司法审判，参与诉讼服务，交流审判经验，汇
            聚审判资源的综合性法律知识服务平台。
            </span>
        `,
        programmeText:`
        中国审判法律应用支持系统（蒙古文版）暨“法信蒙古文版”是全国法院系统首个少数民族语言文字法律数据库。
        以蒙古语言文字为基础，深度融合法律知识服务与案例大数据的数字化平台。平台主要包括9大资源库和33个子库，
        总字数达300余万字。分别有法律文书库、裁判文书库、司法案例库、法律观点库、法律图书库、法律文献库、参考
        文献库、党建专栏、法院动态等模块。给各地蒙古族群众和相关单位提供蒙古文法律资源平台，促进法律知识的普及等。
        `,
        khProfit:`
            <span>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            积极实现全国使用民族语言文字的地区法治建设中的功能性意义，从而取得显著的社会效益。<br/>
            <em style="display: inline-block;width: 5px;height: 5px;background: #1b4094;border-radius: 50%;font-style: normal;"></em>
            “法信蒙古文版”更好地发挥自身优势，不断加强在法治宣传教育中的作用，着力铸牢中华民族共同体意识，不断开创民族团结进步事业新局面。<br/>
            </span>
        `,
        caseType:[
            {
                id:0,
                text:'政务'
            }
        ],
        imgList:[
            {
                id:0,
                imgUrl:require("@/assets/images/case/p7img1.jpg")
            },
            {
                id:1,
                imgUrl:require("@/assets/images/case/p7img2.jpg")
            },
            {
                id:2,
                imgUrl:require("@/assets/images/case/p7img3.jpg")
            },
            {
                id:3,
                imgUrl:require("@/assets/images/case/p7img4.png")
            },
            {
                id:4,
                imgUrl:require("@/assets/images/case/p7img5.png")
            },
            {
                id:5,
                imgUrl:require("@/assets/images/case/p7img6.png")
            },
            {
                id:6,
                imgUrl:require("@/assets/images/case/p7img7.png")
            },
        ]
    }
]
export default proJectList
