<template>
    <div class="main-container aboutBox">
        <Banner />
        <Introduction />
        <History />
        <Honor />
        <Contact />
    </div>
</template>
<script>
import Banner from "views/routerViews/About/Index/Banner";
import Introduction from "views/routerViews/About/Index/Introduction";
import History from "views/routerViews/About/Index/History";
import Honor from "views/routerViews/About/Index/Honor";
import Contact from "views/routerViews/About/Index/Contact";
export default {
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙科立，蒙科立综合服务，蒙科立输入法，蒙古文AI人工智能",
            },
        ],
    },
    components: {
        Banner,
        Introduction,
        History,
        Honor,
        Contact,
    },
};
</script>
