import { login, phoneLogin, logout} from '@/api/auth'
import {getUserInfo} from '@/api/weChatRegister'
import { PcCookie, Key } from '@/utils/cookie'
import { desDecryptData } from '@/utils/encryptData'
const state = {
    userInfo: PcCookie.get(Key.userInfoKey) ? PcCookie.get(Key.userInfoKey) : null,
    token: PcCookie.get(Key.tokenKey) ? PcCookie.get(Key.tokenKey) : null,
    weChatInfo: PcCookie.get(Key.weChatInfoKey) ? PcCookie.get(Key.weChatInfoKey) : null,
    public: PcCookie.get(Key.publicKey) ? PcCookie.get(Key.publicKey) : null,
    memberType: PcCookie.get(Key.memberTypeKey) ? PcCookie.get(Key.memberTypeKey) : null,
}
const mutations = {
    SET_USER_STATE(state, data) {
        const {memberInfo, token, publicKey} = data
        const userInfo = {
            "memberName": memberInfo.memberName ? memberInfo.memberName : memberInfo.phone,
            "headimgurl": memberInfo.headimgurl,
            "userId": memberInfo.id
        }
        state.userInfo = JSON.stringify(userInfo)
        state.token = token
        state.public = publicKey
        state.memberType = memberInfo.memberType
        PcCookie.set(Key.userInfoKey, JSON.stringify(userInfo))
        PcCookie.set(Key.tokenKey, token)
        PcCookie.set(Key.publicKey, publicKey)
        PcCookie.set(Key.memberTypeKey, memberInfo.memberType)
    },
    RESET_USER_STATE(state) {
        state.userInfo = null
        state.token = null
        state.public = null
        state.memberType = null
        PcCookie.remove(Key.userInfoKey)
        PcCookie.remove(Key.tokenKey)
        PcCookie.remove(Key.publicKey)
        PcCookie.remove(Key.memberTypeKey)
    },
}
// 定义行为
const actions = {
    //用户名密码登录
    UserLogin({ commit }, loginNews) {
        return new Promise((resolve, reject) => {
            login(loginNews.formDes,loginNews.rsKey,loginNews.secretKey).then(response => {
                const data = response.body.data.data
                const responseData = desDecryptData( data, loginNews.keys )
                if(responseData.success){
                    commit('SET_USER_STATE', responseData.body.data)
                }
                resolve(responseData)
            }).catch(error => {
                commit('RESET_USER_STATE')
                reject(error)
            })
        })
    },
    //手机号登录
    PhoneLogin({ commit }, loginNews) {
        return new Promise((resolve, reject) => {
            phoneLogin(loginNews.formDes,loginNews.rsKey,loginNews.secretKey).then(response => {
                const data = response.body.data.data
                const responseData = desDecryptData( data, loginNews.keys )
                if(responseData.success){
                    commit('SET_USER_STATE', responseData.body.data)
                }
                resolve(responseData)
            }).catch(error => {
                commit('RESET_USER_STATE')
                reject(error)
            })
        })
    },
    //登出
    UserLogout({ state, commit }) {
        return new Promise((resolve, reject) => {
            const userId = JSON.parse(state.userInfo).userId
            logout(userId).then((response) => {
                if (response.success) {
                    commit('RESET_USER_STATE')
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    //token失效重新登录
    UserLoginAgain({ commit }) {
        commit('RESET_USER_STATE')
    },
    //微信扫码登录
    WeChatLogin({ commit },weChatNews) {
        return new Promise((resolve, reject) => {
            getUserInfo(
                weChatNews.formDes,
                weChatNews.rsKey,
                weChatNews.secretKey
            ).then((response) => {
                const data = response.body.data.data
                const responseData = desDecryptData( data, weChatNews.keys )
                if(responseData.success){
                    commit('SET_USER_STATE', responseData.body.data)
                }
                resolve(responseData)
            }).catch(error => {
                commit('RESET_USER_STATE')
                reject(error)
            })
        })
    },
}
export default { state, mutations, actions }
