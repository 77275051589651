<template>
    <div class="newsBox h100">
        <div class="news-title">消息中心</div>
        <div v-if="hasNews">
            <div class="news-list">
                <div class="item_box" v-for="item in newsList" :key="item.id">
                    <img
                        src="~assets/images/personal/notice.png"
                        alt=""
                        class="noticeImg"
                    />
                    <div class="item_news">
                        <p>
                            {{ item.title }}
                            <span v-if="item.readFlag === '0'">(未读)</span>
                        </p>
                        <div>
                            <span>{{ showType(item.type) }} | {{ item.updateDate }}</span>
                            <em @click="enterDetails(item)">查看详情 >></em>
                        </div>
                    </div>
                    <div class="deleteNews pointer" @click="handleDelete(item)">删除</div>
                </div>
            </div>
            <el-row type="flex" justify="center" style="margin-top: 40px" >
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="page.current"
                    :page-size="page.size"
                    :total="page.total"
                    layout="prev, pager, next"
                >
                </el-pagination>
            </el-row>
        </div>
        <div class="noneNews" v-else>
            <img src="~assets/images/personal/nonImg.png" alt="" />
            <div class="promptText">
                <p>暂时没有消息</p>
            </div>
        </div>
        <el-dialog
            :title="newsTitle"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose"
        >
            <span>{{ newsContent }}</span>
        </el-dialog>
    </div>
</template>
<script>
import { getPersonalNotice, readNotice, delNotice } from "api/personal";
export default {
    computed:{
        showType() {
            return function(type){
                let stype = ''
                if(type == '1'){
                    stype = '系统通知'
                }else if(type == '2') {
                    stype = '账户通知'
                }
                return stype
            }
        }
    },
    data() {
        return {
            page: {
                size: 6,
                total: 0,
                current: 1,
            },
            newsList: [],
            hasNews: true,
            dialogVisible:false,
            newsTitle:'',
            newsContent:''
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        handleDelete(item) {
            this.$confirm('确定要删除这条信息记录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delNotice(item.id).then(res => {
                    if(res.errorCode === '2000'){
                        this.page.current = 1;
                        this.fetchData();
                    }
                })
            }).catch(() => {

            });
        },
        enterDetails(item) {
            this.newsTitle = item.title;
            this.newsContent = item.content;
            this.dialogVisible = true;
            if(item.readFlag === '0'){
                readNotice(item.id).then((res) => {
                    const { success } = res;
                    if (success) {
                        item.readFlag = "1";
                    }
                });
            }

        },
        fetchData() {
            getPersonalNotice(this.page.current, this.page.size).then((res) => {
                const { success, body, msg } = res;
                if (success) {
                    if (body.data.count == 0) {
                        this.hasNews = false;
                    } else {
                        this.page.total = body.data.count;
                        this.newsList = body.data.list;
                        this.hasNews = true;
                    }
                } else {
                    this.$message({
                        message: msg,
                        type: "error",
                    });
                }
            });
        },
        handleCurrentChange(current) {
            this.page.current = current;
            this.fetchData();
        },
        handleClose(){
            this.dialogVisible = false;
        }
    },
};
</script>
<style lang="less" scoped>
.deleteNews{
    font-size: 16px;
    color: #b21f27;
}
.noticeImg {
    width: 40px;
    height: 40px;
}
.noRead p,
.noRead span,
.noRead em {
    font-weight: bold;
}
.item_news em {
    margin-left: 12px;
    font-style: normal;
    font-size: 14px;
    color: #b21f27;
    cursor: pointer;
}
.item_news span {
    font-size: 14px;
    color: #a1a1a1;
}
.item_news p {
    font-size: 16px;
    line-height: 24px;
    color: #101010;
}
.item_news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    height: 100%;
}
.item_box img {
    margin-right: 12px;
}
.item_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 0;
    padding: 0 26px;
    height: 74px;
    border: solid 1px #e2e2e2;
}
.news-title {
    font-size: 18px;
    color: #252b3a;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
}
.newsBox {
    padding: 0 40px;
    background: #fff;
}
</style>
