<template>
    <div class="listBox">
        <div class="w12">
            <div class="typeBox">
                <p>职位类别</p>
                <div class="typeList">
                    <div class="typeItem pointer"  :class="{'active': choseIndex == 0}" @click="changeType('',0)">
                        <i class="el-icon-caret-right"></i>
                        <p>全部</p>
                    </div>
                    <div class="typeItem pointer pleft20" v-for="(item,index) in deptList" :key="item.id" @click="changeType(item,index)" :class="{'active': choseIndex == index+1}">
                        <p>{{item.name}}</p>
                    </div>
                </div>
            </div>
            <div class="list" v-if="recruitList.length != 0">
                <div class="item pointer" v-for="item in recruitList" :key="item.id" @click="enterDetails(item)">
                    <p class="title">{{item.duties}}</p>
                    <div class="addressBox">
                        <p>{{item.locale}}</p>
                        <span>|</span>
                        <p>{{item.department.name}}</p>
                        <span>|</span>
                        <p>{{item.type == '2' ? '校园招聘' : '社会招聘'}}</p>
                    </div>
                    <div class="textBox">
                        部门介绍：<span v-html="unEscapeHTML(item.departdesc)"></span>
                    </div>
                </div>
            </div>
            <div class="noMoreList" v-else>
                <img src="~assets/images/personal/nonImg.png" alt="">
                <div class="promptText">
                    <p>暂无信息</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getRecruitList, getDeptNameList } from 'api/recruit'
export default {
    data() {
        return {
            recruitPage:{
                total:0,
                pageNo:1,
                pageSize:1000
            },
            choseIndex:0,
            deptId:"",
            recruitList: [],
            deptList:[]
        }
    },
    created() {
        this.fetchRecruit()
        this.getDeptList()
    },
    methods: {
        changeType(item,index) {
            this.deptId = item.id ? item.id : ''
            this.choseIndex = item.id ? index + 1 : 0
            this.fetchRecruit()
        },
        enterDetails(item) {
            this.$router.push({
                path: '/about-recruit-details',
                query: { recruitId: item.id },
            });
        },
        getDeptList(){
            getDeptNameList().then(res => {
                if(res.errorCode == '2000'){
                    this.deptList = res.body.data
                }
            })
        },
        fetchRecruit(){
            getRecruitList(this.deptId, this.recruitPage.pageNo,this.recruitPage.pageSize).then(res => {
                if(res.errorCode == '2000'){
                    this.recruitList = res.body.data.list
                }
            })
        },
        unEscapeHTML(html) {
            html = "" + html;
            return html
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&amp;/g, "&")
                .replace(/&quot;/g, '"')
                .replace(/&apos;/g, "'");
        },
    }
}
</script>
<style lang="less" scoped>
.typeList {
    padding-left: 20px;
}
.pleft20{
    padding-left: 20px;
}
.typeItem {
    margin: 12px 0;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    color: #363636;
}
.typeItem.active{
    color: rgb(27, 64, 148);
}
.item {
    margin: 6px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    height: 180px;
    justify-content: center;
    .title {
        font-weight: bold;
        font-size: 25px;
        color: #000000;
    }
    .addressBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 58px;
        color: #939393;
        p {
            font-size: 16px;
        }
        span {
            margin: 0 12px;
            font-size: 12px;
        }
    }
    .textBox {
        height: 44px;
        font-size: 14px;
        line-height: 22px;
        color: #939393;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}
.item:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.listBox {
    margin: 100px 0;
    .w12 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .typeBox {
            width: 238px;
            > p {
                font-size: 18px;
                color: #000;
                font-weight: bold;
            }
        }
        .list {
            padding-left: 60px;
            width: 952px;
            min-height: 400px;
            border-left: solid 1px #dadada;
        }
        .noMoreList{
            width: 952px;
            min-height: 400px;
            border: solid 1px #dadada;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .promptText {
                position: absolute;
                top: 260px;
                left: 0;
                z-index: 9;
                width: 100%;
                height: 42px;
                text-align: center;
                font-size: 16px;
                color: #252b3a;
            }
        }
    }
}
</style>
