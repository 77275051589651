<template>
    <div class="home-information">
        <div class="w12 h100">
            <div class="home-information-title">
                <p class="menkTitle">  <br/><br/></p>
                新闻资讯
            </div>
            <div class="home-information-content">
                <div class="pictureNews-list h100" v-if="choseId === 0 && hasArticle">
                    <div
                        class="h100 pictureNews-li pointer"
                        @click="enterDetails(articlePictureNews,'article')"
                    >
                        <img
                            :src="articlePictureNews.imageUrl"
                            class="pictureNews-li-img"
                            alt=""
                        />
                        <div class="pictureNews-li-text">
                            <p class="ellipsis">{{ articlePictureNews.title }}</p>
                            <span class="ellipsis" v-html="unEscapeHTML(articlePictureNews.summary)"></span>
                        </div>
                    </div>
                </div>
                <div class="pictureNews-list h100" v-if="choseId === 1 && hasNotice">
                    <div
                        class="h100 pictureNews-li pointer"
                        @click="enterDetails(noticePictureNews, 'notice')"
                    >
                        <img
                            :src="noticePictureNews.attachment"
                            class="pictureNews-li-img"
                            alt=""
                            style="height:100%;"
                        />
                    </div>
                </div>
                <div class="information-text-list h100">
                    <div class="content_btn_box">
                        <div class="btn_box">
                            <div
                                class="btn"
                                v-for="(item, index) in btnList"
                                :class="{ btn_active: choseId === index }"
                                :key="index"
                                @click="changeType(index)"
                            >
                                {{ item.text }}
                            </div>
                        </div>
                        <div class="more_box">
                            <div
                                class="news_more"
                                v-if="choseId === 0"
                                @click="enterList('article')"
                            >
                                + 更多
                            </div>
                            <div class="news_more" v-else @click="enterList('notice')">
                                + 更多
                            </div>
                        </div>
                    </div>
                    <div class="listBox" v-if="choseId === 0 && hasArticle">
                        <div
                            class="information-text-li pointer"
                            v-for="(item, index) in articleList"
                            :key="index"
                            @click="enterDetails(item,'article')"
                            @mouseover="hoverNews(item,'article',index)"
                            :class="{'activeNews' : choseNewsIndex == index}"
                        >
                            <div>
                                <div class="dateBox h100">
                                    <p>{{getMonth(item.createDate)}}/{{getDay(item.createDate)}}</p>
                                    <span>{{getYear(item.createDate)}}年</span>
                                </div>
                                <div class="contentBox h100">
                                    <p class="ellipsis">
                                        {{ item.title }}
                                    </p>
                                    <span v-html="unEscapeHTML(item.summary)"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="listBox" v-if="choseId === 1 && hasNotice">
                        <div
                            class="information-text-li pointer"
                            v-for="(item, index) in noticeList"
                            :key="index"
                            @click="enterDetails(item,'notice')"
                            @mouseover="hoverNews(item,'notice',index)"
                            :class="{'activeNews' : choseNews1Index == index}"
                        >
                            <div>
                                <div class="dateBox h100">
                                    <p>{{getMonth(item.createDate)}}/{{getDay(item.createDate)}}</p>
                                    <span>{{getYear(item.createDate)}}年</span>
                                </div>
                                <div class="contentBox h100">
                                    <p class="ellipsis">
                                        {{ item.title }}
                                    </p>
                                    <span v-html="unEscapeHTML(item.content)"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getArticleList } from "api/article";
import { getNoticeList } from "api/notice"
export default {
    computed:{
        getYear(){
            return function(str) {
                str = str.substring(0,4)
                return str
            }
        },
        getMonth(){
            return function(str) {
                str = str.substring(5,7)
                return str
            }
        },
        getDay(){
            return function(str) {
                str = str.substring(8,10)
                return str
            }
        },
    },
    data() {
        return {
            choseId: 0,
            newsId: 0,
            btnList: [
                {
                    id: 0,
                    text: "最新新闻",
                    title: "article",
                },
                {
                    id: 1,
                    text: "通知公告",
                    title: "notice",
                },
            ],
            hasArticle:false,
            articlePictureNews:{},
            articleList: [],
            choseNewsIndex:0,
            choseNews1Index:0,
            hasNotice:false,
            noticePictureNews:{},
            noticeList:[],
            page:{
                pageNo:1,
                pageSize:3
            }
        };
    },
    created() {
        this.fetchArticleData()
        this.fetchNoticeData()
    },
    methods: {
        changeType(index) {
            this.choseId = index;
        },
        enterList(type) {
            if(type == 'article'){
                this.$router.push({
                    path:"/home-news-list",
                    query:{
                        newsType:type
                    }
                });
            }else{
                this.$router.push({
                    path:"/home-notice-list",
                    query:{
                        newsType:type
                    }
                });
            }
        },
        enterDetails(item, type) {
            if(type == 'article'){
                this.$router.push({
                    path:'/home-article-details',
                    query:{
                        articleId:item.id,
                    }
                });
            }else{
                this.$router.push({
                    path:'/home-notice-details',
                    query:{
                        noticeId:item.id,
                    }
                });
            }
        },
        hoverNews(item,type,index) {
            if(type === 'article'){
                this.choseNewsIndex = index
                this.articlePictureNews = item
            }else{
                this.choseNews1Index = index
                this.noticePictureNews = item
            }
        },
        fetchArticleData() {
            getArticleList(
                this.page.pageNo,
                this.page.pageSize
            ).then((res) => {
                if (res.success && res.errorCode === '2000') {
                    this.articleList = res.body.data.list;
                    this.articlePictureNews = res.body.data.list[0];
                    this.hasArticle = true
                }
            });
        },
        fetchNoticeData() {
            getNoticeList(
                this.page.pageNo,
                this.page.pageSize
            ).then((res) => {
                if (res.success && res.errorCode === '2000') {
                    this.noticeList = res.body.data.rows;
                    this.noticePictureNews = res.body.data.rows[0];
                    this.hasNotice = true
                }
            });
        },
        unEscapeHTML(html) {
            html= "" +html;
            return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'");
        },
    },
};
</script>
<style lang="less" scoped>
.listBox{
    margin-top: 7px;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.content_btn_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.btn_box {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
.btn {
    width: 98px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #1b4394;
    margin-right: 12px;
    border: solid 1px #1b4394;
}
.btn_active {
    background: #1b4394;
    color: #fff;
}
.news_more {
    font-size: 14px;
    color: #1b4394;
    cursor: pointer;
}
.information-text-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 28px;
    > p {
        color: #76777b;
        font-size: 20px;
        text-align: right;
    }
    .information-text-li.activeNews{
        border: solid 1px #1b4394;
        .dateBox {
            border-right: solid 1px #1b4394;
            p {
                color: #1b4394;
            }
            span {
                color: #1b4394;
            }
        }
        .contentBox {
            p {
                color: #1b4394;
            }
            span {
                color: #1b4394;
            }
        }
    }
    .information-text-li {
        margin-top: 8px;
        padding: 0 26px;
        height: 120px;
        border: solid 1px #e6e6e6;
        display: flex;
        align-items: center;
        > div {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 70px;
            .dateBox {
                width: 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-right: solid 1px #e6e6e6;
                p {
                    font-size: 20px;
                    color: #252b3a;
                    margin-bottom: 10px;
                    font-weight: bold;
                }
                span {
                    font-size: 14px;
                    color: #76777b;
                }
            }
            .contentBox {
                width: 410px;
                margin-left: 18px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                p {
                    font-size: 16px;
                    color: #252b3a;
                    font-weight: bold;
                    margin-bottom: 8px;
                }
                span {
                    font-size: 14px;
                    color: #252b3a;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    max-height: 34px;
                }
            }
        }
    }
}
.pictureNews-li {
    box-shadow: 0 3px 10px 0 #dae0ed;
    .pictureNews-li-img {
        width: 100%;
        height: 346px;
    }
    .pictureNews-li-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 74px;
        padding: 0 16px;
        p {
            font-size: 18px;
            color: #252b3a;
            font-weight: bold;
            margin-bottom: 8px;
        }
        span {
            font-size: 14px;
            color: #252b3a;
        }
    }
}
.pictureNews-list {
    width: 610px;
    background: #fff;
}
.home-information-content {
    height: 420px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.home-information-title {
    height: 100px;
    font-size: 30px;
    color: #252b3a;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    p{
        margin-right: 12px;
    }
}
.home-information {
    padding: 44px 0 100px;
    height: 686px;
    background: #fff;
    overflow: hidden;
}
</style>
