<template>
    <div class="container w12">
        <div class="title">法律声明及隐私权政策</div>
        <div class="time">生效日期：2021年12月</div>
        <div class="ordinaryText">提示条款</div>
        <div class="ordinaryText">
            您的信任对我们非常重要，我们深知用户信息安全的重要性，我们将按照法律法规要求，
            采取安全保护措施，保护您的用户信息安全可控。鉴此，蒙科立（或简称“我们”）制定
            本《法律声明及隐私权政策》（以下或简称“声明和政策”）并提醒您：在使用蒙科立综
            合服务网站的各项服务前，请您务必仔细阅读并透彻理解本《法律声明和隐私权政策》，
            在确认充分理解并同意后方使用相关产品和服务。一旦您开始使用蒙科立服务，将被视为
            对本声明和政策内容的接受和认可。
        </div>
        <div class="ordinaryText indentText">
            如对本声明和政策内容有任何疑问、意见或建议，
            您可通过我们的咨询或建议提交平台<span @click="enterProposal" class="pointer">（https://www.mklai.cn/）</span>或客服电话（0471-6929536）与我们联系。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            定义
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            蒙科立综合服务网站：指蒙科立综合服务官网（域名为：<span  @click="enterMklai"  class="pointer">https://www.mklai.cn</span>）。
        </div>
        <div class="ordinaryText indentText">
            用户信息：用户信息是指您注册蒙科立综合服务网站账户，使用蒙科立的服务以及蒙科立为了向您提供服务，您提交的或者
            蒙科立收集的用户会员类信息，如姓名、联系方式等，具体详见隐私权政策第二条所述的信息。
        </div>
        <div class="ordinaryText indentText">
           业务数据：不同于用户信息，是指蒙科立的用户利用蒙科立的服务上传、下载、分发等通过蒙科立的技术服务处理的数据。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
           法律声明
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            一、权利归属
        </div>
        <div class="ordinaryText indentText">
            1.1. 蒙科立综合服务网站的 Logo 、“蒙科立”、“ mklai”等文字、图形及其组合，以及蒙科立综合服务网站的其他标识、徽记、
            蒙科立服务的名称等为蒙科立及其关联公司在中国和其他国家的注册商标。未经蒙科立书面授权，任何人不得以任何方式展示、
            使用或做其他处理（包括但不限于复制、传播、展示、镜像、上传、下载），也不得向他人表明您有权展示、使用或做其他处理。
        </div>
        <div class="ordinaryText indentText">
            1.2. 蒙科立综合服务网站所有的产品、服务、技术与所有程序（以下或简称“技术服务”）的知识产权均归属于蒙科立或归其权利人所有。
        </div>
        <div class="ordinaryText indentText">
            1.3. 除非蒙科立另行声明，蒙科立拥有蒙科立在网站内发布文档等信息（包括但不限于文字、图形、图片、照片、音频、视频、
            图标、色彩、版面设计、电子文档）的所有权利（包括但不限于版权、商标权、专利权、商业秘密和其他所有相关权利）。
            未经蒙科立许可，任何人不得擅自使用如上内容（包括但不限于通过程序或设备监视、复制、转播、展示、镜像、上传、
            下载蒙科立网站内的任何内容）。被授权浏览、复制、打印和传播属于蒙科立网站内信息内容的，该等内容都不得用于商
            业目的且所有信息内容及其任何部分的使用都必须包括此权利声明。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            二、知识产权保护
        </div>
        <div class="ordinaryText indentText">
            我们尊重知识产权，反对并打击侵犯知识产权的行为。任何组织或个人认为蒙科立网站的网页（含<span>www.mklai.cn</span>网页内容以及客户端页面）
            内容（如转载文章、产品中心发布的商品信息等）可能侵犯其合法权益的，可以向蒙科立提出书面权利通知，蒙科立将在收到知识产权权利
            人合格通知后依法尽快处理。
        </div>
        <div class="ordinaryText indentText">
            隐私权政策
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            蒙科立（以下或称为“我们”）尊重并保护用户信息，并且将以高度勤勉和审慎的义务对待这些信息。在您使用了蒙科立网站提供的服务时，
            我们将按照本隐私权政策收集、处理及披露您的信息。我们希望通过本隐私权政策向您清晰地介绍我们对您信息的处理方式，因此我们建
            议您完整地阅读本隐私权政策，以帮助您了解维护自己隐私权的方式。
        </div>
        <div class="ordinaryText indentText">
            如果您有任何疑问、意见或建议，请通过蒙科立提供的各种联系方式与我们联系。
        </div>
        <div class="ordinaryText indentText2">
            一、本协议的适用范围
        </div>
        <div class="ordinaryText indentText2">
            二、我们如何收集和使用您的用户信息
        </div>
        <div class="ordinaryText indentText2">
            三、我们如何保护和保存您的用户信息
        </div>

        <div class="ordinaryText indentText2">
           四、未成年人用户信息的特别约定
        </div>
        <div class="ordinaryText indentText">
           一、本协议的适用范围
        </div>
        <div class="ordinaryText indentText">
            1.1. 本隐私权政策适用于蒙科立网站所有服务。服务包括向您提供页面浏览、网站登录服务，以及通过蒙科立网站向您提供的技术服务。
        </div>
        <div class="ordinaryText indentText">
            1.2. 本隐私权政策不适用于其他第三方向您提供的服务。例如，产品中心的服务商依托我们向您提供服务时，您向服务商提供的信息不适用本隐私权政策。
        </div>
        <div class="ordinaryText indentText">
            1.3. 需要特别说明的是，作为蒙科立的用户，若您利用蒙科立的技术服务，为您的用户再行提供服务，因您的业务数据属于您所有，您应当另行与您的用户约定隐私权政策。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            二、我们如何收集和使用您的用户信息
        </div>
        <div class="ordinaryText indentText">
            （一）我们收集您的用户信息的情形
        </div>
        <div class="ordinaryText indentText">
            2.1. 帮助您成为我们的会员
        </div>
        <div class="ordinaryText indentText">
            2.1.1. 当您在蒙科立网站创建账户时，您须向我们提供会员名，设置、确认您的登录密码，提供您在中华人民共和国境内手机号码。
            您提交的手机号码用于您注册、登录、绑定账户、密码找回时接受验证码，并且作为您与蒙科立指定的联系方式之一，用来接受相
            关业务通知（如新品上线、服务变更等），并且蒙科立可通过此联系方式向您营销推广、宣传产品、发送业务通知（含账单）或与
            您进行业务沟通。
        </div>
        <div class="ordinaryText indentText">
            2.1.2. 在您注册完成蒙科立账户后，您可以在账户“基本资料”一栏中继续填写您的信息。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            三、我们如何保护和保存您的用户信息
        </div>
        <div class="ordinaryText indentText">
            3.1. 蒙科立非常重视您的信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的用户信息。防止用
            户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们对蒙科立综合服务网站提供 HTTPS 协议安全浏览方
            式；我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控
            制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。
        </div>
        <div class="ordinaryText indentText">
            3.2. 我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本政策所述目的所需的期限内保留您的用户
            信息，除非受到法律的允许。超出上述用户信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。
        </div>
        <div class="ordinaryText indentText">
            3.3. 请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、
            技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        </div>
        <div class="ordinaryText indentText">
            3.4. 在不幸发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的
            影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、
            信函、电话、推送通知等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。
        </div>
        <div class="ordinaryText indentText">
            3.5. 同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。
        </div>
        <div class="ordinaryText indentText">
            3.6. 我们将收集到的您的用户信息存放在中华人民共和国境内，如在符合适用法律规定的情形下因业务需要向境外传输个人信息的
            ，我们会事先征得您的同意，并向您告知用户信息出境的目的、接收方、安全保障措施、安全风险等情况。
        </div>
        <div class="ordinaryText indentText">
            3.7. 如出现蒙科立产品和服务停止运营的情形，我们会采取合理措施保护您用户信息安全，包括及时停止继续收集用户信息的活动
            ；停止运营的通知将以逐一送达或公告的形式通知用户；并对所持有的个人信息进行删除或匿名化处理等。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            四、未成年人用户信息的特别约定
        </div>
        <div class="ordinaryText indentText">
            4.1. 我们主要面向成人提供产品和服务。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权政策，
            并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
        </div>
        <div class="ordinaryText indentText">
            4.2. 对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、
            父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
        </div>
    </div>
</template>
<script>
export default {
    methods:{
        enterProposal(){
            window.open('https://www.mklai.cn/', '_blank')
        },
        enterMklai(){
            window.open('https://www.mklai.cn', '_blank')
        }
    }
};
</script>
<style lang="less" scoped>
.indentText2{
    text-indent: 64px;
}
.indentText {
    text-indent: 32px;
}
.ordinaryText {
    font-size: 16px;
    color: #333;
    line-height: 32px;
    min-height: 32px;
    p {
        margin-bottom: 10px;
    }
    span{
        color: #1b4094;
    }
}
.time {
    font-size: 20px;
    text-align: right;
}
.title {
    font-size: 20px;
    font-weight: 400;
    line-height: 46px;
    text-align: center;
}
.container {
    padding: 20px;
    background: #fff;
}
</style>
