<template>
    <div
        class="download-college"
        :style="{ background: backColor }"
        v-if="hasNews"
    >
        <div class="w12 collegeBox">
            <div class="c-title-box">
                <p>蒙科立学院</p>
                <span>为您解决软产品使用的所有问题</span>
            </div>
            <div class="menk-question-box">
                <div
                    class="course-li pointer"
                    v-for="item in collegeList"
                    :key="item.id"
                    @click="enterDetails(item)"
                >
                    <div class="course-img">
                        <img :src="item.coursewareVideo.cover" alt="" />
                        <p class="ellipsis">{{ item.coursewareVideo.name }}</p>
                    </div>
                    <div class="course-nwes">
                        <p class="ellipsis">{{ item.courseInfo.name }}</p>
                        <div class="course-content">
                            <div>
                                <i class="el-icon-time"></i>
                                <span>{{
                                    showTime(item.coursewareVideo.totalDuration)
                                }}</span>
                            </div>
                            <div>
                                <i class="el-icon-user-solid"></i>
                                <span>{{ item.studyCount }}人学习</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="more" @click="enterLisr">
                更多视频帮助 <i class="el-icon-right"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { feachVideoList } from "api/college";
export default {
    props: {
        backColor: {
            type: String,
            default: "",
        },
    },
    computed: {
        showTime() {
            return function (tiem) {
                let minute = parseInt(tiem / 60);
                let second = tiem % 60;
                return minute + "分" + second + "秒";
            };
        },
    },
    data() {
        return {
            searchObj: {
                productId: "",
                type: "0",
                name: "",
            },
            page: {
                size: 3,
                total: 0,
                current: 1,
            },
            collegeList: [],
            hasNews: false,
        };
    },
    created() {
        this.searchObj.productId = this.$route.query.productId;
        this.fetchData();
    },
    methods: {
        enterDetails(item) {
            const query = {
                courseId: item.courseInfo.id,
                id: item.id,
                productId: item.courseInfo.productId,
            };
            this.$emit("reloadCourse", query);
        },
        enterLisr() {
            this.$router.push("/college-course");
        },
        fetchData() {
            feachVideoList(
                this.searchObj,
                this.page.current,
                this.page.size
            ).then((res) => {
                if (res.success) {
                    this.collegeList = res.body.data.rows;
                    this.page.total = res.body.data.total;
                    this.hasNews = this.page.total == 0 ? false : true;
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.more {
    margin: 30px 0 60px;
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    color: #161616;
    font-family: "宋体";
}
.menk-question-box {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .course-li {
        width: 382px;
        height: 198px;
        border: solid 1px #dcdee1;
        background: #fff;
        .course-nwes {
            padding: 0 15px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            .course-content {
                font-size: 12px;
                color: #818181;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
            > p {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 16px;
                color: #252b3a;
            }
        }
        .course-img {
            height: 138px;
            position: relative;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
            }
            p {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 4;
                width: 100%;
                height: 138px;
                line-height: 138px;
                font-size: 25px;
                color: #fff;
                font-weight: bold;
                text-align: center;
            }
        }
    }
}
.c-title-box {
    padding-top: 103px;
    height: 166px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #252b3a;
    p {
        font-size: 35px;
    }
    span {
        font-size: 16px;
    }
}
.collegeBox {
    display: flex;
    flex-direction: column;
}
.download-college {
    min-height: 200px;
}
</style>
