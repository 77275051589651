import { request } from '@/utils/request'
//获取热蒙产品推荐
export function getRecommendProduct(current, size) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productInfo/recommend?timestamp=${timestamp}`,
        method: 'get',
        params: {
            pageNo: current,
            pageSize: size
        }
    });
}

//获取产品详情
export function getProductDetails(id) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productInfo/detail?timestamp=${timestamp}`,
        method: 'get',
        params: {
            id
        }
    });
}

//获取产品常见问题列表
export function getQuestions(productId,current,size) {
    return request({
        url: '/api/v1/productQuestionInfo/getQuestions',
        method: 'post',
        data:{
            productId,
            pageNo: current,
            pageSize: size
        }
    });
}

//获取产品导航列表
export function getProductsNav() {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productInfo/getProducts?timestamp=${timestamp}`,
        method: 'get',
    });
}

//获取产品常见问题详情
export function getQuestionDetails(id) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productQuestionInfo/detail?timestamp=${timestamp}`,
        method: 'get',
        params: {
            id
        }
    });
}

//可以下载的产品
export function getDownloadProduct() {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productInfo/needDownloadProduct?timestamp=${timestamp}`,
        method: 'get',

    });
}

//更新产品下载量
export function updateDownloadNum(id) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productInfo/recordClickNum?timestamp=${timestamp}`,
        method: 'get',
        params: {
            id
        }
    });
}


//获取反馈一键类型
export function getSuggestType() {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productQuestionInfo/getSuggestType?timestamp=${timestamp}`,
        method: 'get',
    });
}
//提交意见反馈
export function saveQuestion(proposal) {
    return request({
        url: `/api/v1/productQuestionInfo/saveQuestion`,
        method: 'post',
        data: {
            ...proposal
        }
    });
}

//机器翻译
export function translate(from, to , content, productId) {
    return request({
        url: `/api/v1/ai/trans/cmt?productId=${productId}`,
        method: 'post',
        data: {
            from,
            to ,
            content
        }
    });
}
export function translateDoct(from, to , filePath, productId) {
    return request({
        url: `/api/v1/ai/trans/doct?productId=${productId}`,
        method: 'post',
        data: {
            from,
            to ,
            filePath,
            productId
        }
    });
}

//机器翻译反馈问题
export function translateFeedback(form) {
    return request({
        url: `/api/v1/ai/trans/feedback`,
        method: 'post',
        data: {
            ...form
        }
    });
}

//机器翻译历史记录

export function getTranslateHisroty() {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/ai/trans/his?timestamp=${timestamp}`,
        method: 'get',
    });
}

//ocr单张识别
export function socrweb(filePath, productId) {
    return request({
        url: `/api/v1/ai/ocr/socr`,
        method: 'post',
        data: {
            filePath,
            productId
        }
    });
}

// 实时付费获取ocr识别结果
export function socrlt(rltKey) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/ai/ocr/socrlt?timestamp=${timestamp}`,
        method: 'get',
        params: {
            rltKey
        }
    });
}

//ocr批量识别
export function entrustOCRList(productId) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productBatch/entrustOCR?timestamp=${timestamp}`,
        method: 'get',
        params: {
            productId
        }
    });
}


//查看font下载详情
export function getFontDetails(productId) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productDownloadInfo/gainLoadPro/detail?timestamp=${timestamp}`,
        method: 'get',
        params: {
            productId
        }
    });
}


//语音识别
export function recordeAf(filePath, productId) {
    return request({
        url: `/api/v1/ai/asr/af`,
        method: 'post',
        data: {
            filePath,
            productId
        }
    })
}


//编码转换
export function codeConvert(form, productId) {
    return request({
        url: `/api/v1/mts/convert`,
        method: 'post',
        data: {
            ...form,
            productId
        }
    })
}

//新旧蒙文转换
export function codeMkConvert(from, to,content,productId='') {
    return request({
        url: `/api/v1/ces/cnvt`,
        method: 'post',
        data: {
            from,
            to,
            content,
            productId
        }
    })
}

//蒙古文校对
export function menkProof(form, productId) {
    return request({
        url: `/api/v1/mts/proof`,
        method: 'post',
        data: {
            ...form,
            productId
        }
    })
}

//语音合成
export function synthesis(content, productId, speaker) {
    return request({
        url: `/api/v1/ai/tts/smpl`,
        method: 'post',
        data: {
            content,
            productId,
            speaker
        }
    })
}


//获取图文生成背景图片接口
export function getAllBackImages() {
    return request({
        url: `/Api/GetAllBackImages`,
        method: 'get',
    })
}
//获取图文生成背景图片分类接口
export function getAllTaxonomies() {
    return request({
        url: `/Api/GetAllTaxonomies`,
        method: 'get',
    })
}

//保存图片信息——标题、作者、日期、内容
export function savePictureNews(data) {
    return request({
        url: `/Api/SaveContent`,
        method: 'post',
        data:{
            ...data
        }
    })
}

//生成图片
export function IPageToImage(data) {
    return request({
        url: `/Api/IPageToImage`,
        method: 'post',
        data:{
            ...data
        }
    })
}


//wps企业下单
export function savRecord(installType,productId,purchaseNum) {
    return request({
        url: `/api/v1/productInfo/company/savRecord`,
        method: 'post',
        data:{
            installType,
            productId,
            purchaseNum
        }
    })
}
