<template>
    <div id="SceneBox" class="SceneBox h100">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "ProductBar",
};
</script>
<style scoped>
.SceneBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
</style>
