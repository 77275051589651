<template>
    <div class="fontDetailsContainer">
        <img src="~assets/images/font/1.jpg" alt="" srcset="" v-if="activeIndex == 0">
        <img src="~assets/images/font/2.jpg" alt="" srcset="" v-if="activeIndex == 1">
        <img src="~assets/images/font/3.jpg" alt="" srcset="" v-if="activeIndex == 2">
        <img src="~assets/images/font/4.jpg" alt="" srcset="" v-if="activeIndex == 3">
        <img src="~assets/images/font/5.jpg" alt="" srcset="" v-if="activeIndex == 4">
        <img src="~assets/images/font/6.jpg" alt="" srcset="" v-if="activeIndex == 5">
        <img src="~assets/images/font/7.jpg" alt="" srcset="" v-if="activeIndex == 6">
    </div>
</template>

<script>
export default {
    data(){
        return{
            activeIndex:null
        }
    },
    created(){
        this.activeIndex = this.$route.query.index;
    }
}
</script>

<style lang="less" scoped>
.fontDetailsContainer{
    overflow: hidden;
    img{
        max-width: 100%;
    }
}
</style>
