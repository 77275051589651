import Vue from 'vue'
import VueEvent from '@/VueEvent.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import BaiduMap from 'vue-baidu-map'
import VueCoreVideoPlayer from 'vue-core-video-player'
import clipboard from 'clipboard'
import SlideVerify from 'vue-monoplasty-slide-verify';
import MetaInfo from 'vue-meta-info';
import 'animate.css';
Vue.use(MetaInfo);
Vue.use(ElementUI)
Vue.use(BaiduMap, {
    ak: 'EUxOlA1rk6DGe3NElwtWMBSW874EnNGD'
})
Vue.use(VueCoreVideoPlayer, {
    lang: 'zh-CN'
})
Vue.use(SlideVerify)
Vue.prototype.clipboard = clipboard
router.beforeEach((to, from, next) => {
    if (to.path === '/product') {
        next({ path: '/' })
    } else if (to.path === '/login' || to.path === '/register' || to.path === '/forget-password' || to.path === '/weChat-register' ) {
        VueEvent.$emit('showModular', false);
        next()
    }else if(to.path === '/home-news-list' || to.path === '/home-notice-list' || to.path === '/home-activity-list' ) {
        VueEvent.$emit('changeRoute', to.query);
        next()
    }else {
        VueEvent.$emit('showModular', true);
        next()
    }
})
router.afterEach(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
})
Vue.prototype.JQ = $
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
    mounted () {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app')
