import { request } from '@/utils/request'
//注册
export function register(data, rsKey, secretKey) {
    return request({
        url: '/api/v1/memberInfo/register',
        method: 'post',
        data: {
            data,
            rsKey,
            secretKey
        }
    });
}
//获取手机验证码
export function getCode(data, rsKey, secretKey) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/memberInfo/login/getCode?timestamp=${timestamp}`,
        method: 'post',
        data: {
            data, rsKey, secretKey
        }
    });
}
//用户名查询
export function checkMemberName(data, rsKey, secretKey) {
    return request({
        url: `/api/v1/memberInfo/register/checkMemberName`,
        method: 'post',
        data: {
            data,
            rsKey,
            secretKey
        }
    });
}
//手机号查询
export function checkPhone(data, rsKey, secretKey) {
    return request({
        url: `/api/v1/memberInfo/register/checkPhone`,
        method: 'post',
        data: {
            data,
            rsKey,
            secretKey
        }
    });
}
//邮箱查询
export function checkEmail(data, rsKey, secretKey) {
    return request({
        url: `/api/v1/memberInfo/register/checkEmail`,
        method: 'post',
        data: {
            data,
            rsKey,
            secretKey
        }
    });
}
//激活账户
export function sendMail(email, memberName) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/memberInfo/login/sendMail?timestamp=${timestamp}`,
        method: 'get',
        params: {
            email,
            memberName
        }
    });
}
//企业社会信用代码查询
export function checkCreditCode(data, rsKey, secretKey) {
    return request({
        url: `/api/v1/memberInfo/register/checkCreditCode`,
        method: 'post',
        data: {
            data,
            rsKey,
            secretKey
        }
    });
}
