import { request } from '@/utils/request'
//获取活动列表
export function getActivityList(current, size) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/activity/getActivity?timestamp=${timestamp}`,
        method: 'get',
        params: {
            pageNo: current,
            pageSize: size
        }
    });
}
//获取活动详情
export function getActivityDetail(id) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/activity/detail?timestamp=${timestamp}`,
        method: 'get',
        params: {
            id
        }
    });
}

//抢红包
export function robRedPacket(activityId) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/activity/robRedPacket?timestamp=${timestamp}`,
        method: 'get',
        params: {
            activityId
        }
    });
}
//红包分享点击数
export function saveRedPacketShare(activityId) {
    return request({
        url: `/api/v1/activity/saveShare`,
        method: 'get',
        params:{
            activityId
        }
    });
}


//红包活动状态
export function getActivityStatus(activityId) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/activity/activityStatus?timestamp=${timestamp}`,
        method: 'get',
        params: {
            activityId
        }
    });
}
