import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { PcCookie, Key } from '@/utils/cookie'

export function request(config) {
    const token = store.state.auth.token
    const instance = new axios.create({
        baseURL: '',
        timeout: 1200000
    });
    instance.interceptors.request.use(config => {
        config.headers['token'] = token
        return config;
    }, error => {
        console.log(error)
    })

    instance.interceptors.response.use(res => {
        if (res.status !== 200) {
            Message({
                message: '服务器内部错误',
                type: 'warning'
            })
        } else if (res.data.errorCode == '3001') {
            Message({
                message: res.data.msg,
                type: 'warning'
            })
            setTimeout(()=>{
                store.dispatch("UserLoginAgain")
                const redirect = router.history.current.fullPath
                router.push({
                    path: '/login',
                    query: { redirect: redirect }
                })
            },1000)
        }else {
            if(res.headers.reftoken){
                PcCookie.set(Key.tokenKey, res.headers.reftoken)
            }
            if(res.headers.refpublickey){
                PcCookie.set(Key.publicKey, res.headers.refpublickey)
            }
            //如果headers里有refToken 用refToken 替换cookie里的token
            return res.data
        }
    }, error => {
        console.log(error)
    })

    return instance(config)
}
