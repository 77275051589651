<template>
    <div class="puestionBox">
        <div class="w12 h100">
            <div class="puestion-title">
                <p class="menkTitle"><br/> <br/></p>
                <span>常见问题</span>
            </div>
            <div class="question-list">
                <div
                    class="question-li"
                    v-for="(item, index) in questionList"
                    :key="item.id"
                    :class="{ active: item.isOpen }"
                >
                    <div class="question-title">
                        <div class="closeBtn right">
                            <i
                                class="el-icon-close pointer"
                                v-if="item.isOpen"
                                @click="toggleContent(item)"
                            ></i>
                            <i
                                class="el-icon-plus pointer"
                                v-else
                                @click="toggleContent(item)"
                            ></i>
                        </div>
                        <div
                            class="question-title-text pointer"
                            @click="enterDetails(item)"
                        >
                            {{ index + 1 }}.{{ item.title }}
                        </div>
                    </div>
                    <div class="question-content" v-if="item.isOpen">
                        {{ item.answer }}
                    </div>
                </div>
            </div>
            <div class="moreQuestion">
                更多问题请查看
                <span class="pointer" @click="question">常见问题</span>，也可在
                <span class="pointer" @click="enterOtherQuestion"
                    >其他服务</span
                >
                中提出建议和反馈 。
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        questionList: {
            type: Array,
            default() {
                return [];
            },
        },
        name:{
            type:String,
            default:''
        },
        productId:{
            type:String,
            default:''
        },
    },
    data() {
        return {};
    },
    methods: {
        enterOtherQuestion() {
            this.$router.push("/college-feedback");
        },
        enterDetails(item) {
            this.$router.push({
                path:'/college-problem-details',
                query:{
                    questionId:item.id
                }
            })
        },
        question() {
            this.$router.push({
                path:"/college-problem",
                query:{
                    productName: this.name,
                    productId: this.productId
                }
            });
        },
        toggleContent(item) {
            this.questionList.forEach((li) => {
                if (item != li) {
                    li.isOpen = false;
                }
            });
            item.isOpen = !item.isOpen;
            this.$forceUpdate();
        },
    },
};
</script>
<style lang="less" scoped>
.moreQuestion {
    font-size: 14px;
    text-align: center;
    margin: 56px 0;
    span {
        color: #6b7cb0;
        margin: 0 12px;
    }
}
.question-li {
    margin-bottom: 18px;
    padding: 0 22px;
    color: #252b3a;
    .question-title {
        font-size: 18px;
        margin-bottom: 12px;
        .question-title-text {
            line-height: 30px;
            margin-right: 36px;
            font-weight: 600;
        }
        .closeBtn {
            font-size: 24px;
            line-height: 30px;
            color: #a4aab3;
        }
    }
    .question-content {
        font-size: 16px;
        line-height: 24px;
    }
}
.question-li.active {
    padding: 22px;
    background: -webkit-linear-gradient(#edf3fc, #fff); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(#edf3fc, #fff); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(#edf3fc, #fff); /* Firefox 3.6 - 15 */
    background: linear-gradient(#edf3fc, #fff); /* 标准的语法（必须放在最后） */
    box-shadow: 0 2px 12px 0 rgba(234, 235, 238, 0.8);
}
.puestionBox {
    overflow: hidden;
    background: #f3f5f8;
    .puestion-title {
        padding-top: 32px;
        margin-bottom: 20px;
        font-size: 35px;
        color: #252b3a;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        p{
            margin: 0 12px;
        }
    }
}
</style>
