<template>
    <div
        class="main-container"
        style="background: rgb(243, 245, 248); padding-bottom: 40px"
    >
        <div class="codeImgBox" v-if="disabled == true">
            <div class="payImgBox">
                <p>
                    三分钟倒计时: <em>00:{{ minute }}:{{ second }}</em>
                </p>
                <img :src="payImg" alt="" class="payImg" />
                <span>提示：请在三分钟之内进行支付</span>
            </div>
        </div>
        <div class="w12" v-if="hasNews">
            <div class="details-crumbs">
                <p class="bar2"></p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/personal' }"
                        >个人中心</el-breadcrumb-item
                    >
                    <el-breadcrumb-item :to="{ path: '/personal/order' }"
                        >我的订单</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>订单详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="title">订单详情</div>
            <div class="order-type min-el-step">
                <div class="order_type_title">订单状态</div>
                <div class="order_news">
                    <div class="order_news_status">
                        <div class="order_num">订单编号：{{ orderNumber }}</div>
                        <div
                            class="order_status"
                            :class="orderTypeClass(details.status)"
                        >
                            {{ orderTypeName(details.status) }}
                        </div>
                    </div>
                    <div class="order-bz">
                        <el-steps :active="active" align-center>
                            <el-step
                                title="提交订单"
                                :description="details.createDate"
                            ></el-step>
                            <el-step
                                :title="title"
                                :description="description2"
                            ></el-step>
                            <el-step
                                title="完成"
                                :description="description2"
                            ></el-step>
                        </el-steps>
                    </div>
                </div>
            </div>
            <div class="order_xnwes1">
                <div class="order_xnwes_item">
                    <div class="order_bt">买家信息</div>
                    <div class="order_xnwes_text">
                        <!-- <div>
                            <p>顾客姓名</p>
                            <span>：</span>
                            <span>{{ details.member.memberName }}</span>
                        </div> -->
                        <div>
                            <p>电话</p>
                            <span>：</span>
                            <span>{{ details.member.phone }}</span>
                        </div>
                        <div>
                            <p>邮箱</p>
                            <span>：</span>
                            <span>{{ details.member.email }}</span>
                        </div>
                    </div>
                </div>
                <div class="order_xnwes_item blr1">
                    <div class="order_bt">付款信息</div>
                    <div class="order_xnwes_text">
                        <div>
                            <p>付款方式：</p>
                            <span v-if="details.payMode != '3'">在线支付</span>
                            <span v-else>线下支付</span>
                        </div>
                        <div v-if="details.status != '5'">
                            <p>付款时间：</p>
                            <span v-if="details.finishTime">{{
                                details.finishTime
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="order_xnwes_item blr1">
                    <div class="order_bt">产品清单</div>
                    <div class="order_xnwes_text">
                        <div>
                            <p>商品名称：</p>
                            <span>{{ details.product.name }}</span>
                        </div>
                        <div>
                            <p>商品数量：</p>
                            <span>{{ details.buyCount }}</span>
                        </div>
                        <div>
                            <p>商品价格：</p>
                            <span>{{ details.totalAmount }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="invoiceContainer">
                <div class="order_type_title">发票信息</div>
                <div class="invoiceContent" v-if="details.invoice">
                    <div>
                        <p>抬头：</p>
                        <span>{{ details.invoice.titleName }}</span>
                    </div>
                    <div>
                        <p>税号：</p>
                        <span>{{ details.invoice.taxNumber }}</span>
                    </div>
                    <div>
                        <p>联系电话：</p>
                        <span>{{ details.invoice.phoneNumber }}</span>
                    </div>
                    <el-tooltip class="item" effect="dark" content="下载电子发票" placement="top" v-if="details.invoice.field1">
                        <span @click="downloadInvoice(details.invoice.field1)">
                            <i class="el-icon-download"></i>
                        </span>
                    </el-tooltip>
                </div>
                <div class="invoiceContent" v-else>
                    <div>
                        <p>发票状态：</p>
                        <span>未开具发票</span>
                    </div>
                </div>
            </div>
            <div
                v-if="details.status == '0'"
                class="pay_c_box"
                style="margin-top: 30px"
            >
                <div class="pay_title">支付方式：</div>
                <div class="pay_price_box">
                    <div class="pay_prict_type_box">
                        <div class="payTypeBox">
                            <div class="flexRow">支付平台：</div>
                            <div class="flexRow">
                                <div
                                    class="pay_type"
                                    @click="changePay('2')"
                                    :class="{ activeType: payMode === '2' }"
                                >
                                    <p></p>
                                    <img
                                        src="~assets/images/product/zfbImg.png"
                                        alt=""
                                    />
                                    <span>支付宝支付</span>
                                </div>
                                <div
                                    class="pay_type"
                                    @click="changePay('1')"
                                    :class="{ activeType: payMode === '1' }"
                                >
                                    <p></p>
                                    <img
                                        src="~assets/images/product/wxImg.png"
                                        alt=""
                                    />
                                    <span>微信支付</span>
                                </div>
                            </div>
                        </div>
                        <div class="discountBox" v-if="moneyObj.id != ''">
                            <div class="discountTitle">
                                使用红包/礼品卡/抵用：
                            </div>
                            <div class="discontRedPacket">
                                <div
                                    class="discountImgBox"
                                    @click="choseRedPacket"
                                >
                                    <img
                                        src="~assets/images/redPackImg1.png"
                                        alt=""
                                        v-show="!choseDis"
                                    />
                                    <img
                                        src="~assets/images/redPackImg.png"
                                        alt=""
                                        v-show="choseDis"
                                    />
                                    <div class="discountNews">
                                        <p>¥ {{ moneyObj.amount }}</p>
                                        <span :class="{ active: choseDis }"
                                            >仅限平台使用</span
                                        >
                                        <em :class="{ active: choseDis }"
                                            >有效期至：{{
                                                moneyObj.deadline
                                            }}</em
                                        >
                                    </div>
                                </div>
                                <p>*红包一次性抵扣，不可拆分使用。</p>
                            </div>
                        </div>
                    </div>

                    <div class="pay_box" style="margin-top: 66px">
                        <div class="total_price">
                            商品总额：¥ {{ details.totalAmount }}
                        </div>
                        <div
                            class="gppds_price"
                            :class="{ active: status == '0' }"
                        >
                            实付款：<span>¥ {{ showPrice }}</span>
                        </div>
                        <div
                            class="payBtn"
                            @click="payment"
                            :disabled="disabled"
                        >
                            立即支付
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getOrderDetails,
    orderPayment,
    queryOrder,
    getAmount,
} from "api/order";
export default {
    computed: {
        orderTypeClass() {
            return function (status) {
                let className = "";
                if (status == "3") {
                    className = "success";
                } else if (status == "0") {
                    className = "danger";
                } else if (status == "1" || status == "5") {
                    className = "warning";
                }
                return className;
            };
        },
        orderTypeName() {
            return function (status) {
                let name = "";
                if (status == "3") {
                    name = "已完成";
                } else if (status == "0") {
                    name = "待支付";
                } else if (status == "1") {
                    name = "已取消";
                } else if (status == "5") {
                    name = "待审核";
                }
                return name;
            };
        },
        second: function () {
            return this.num(this.seconds);
        },

        minute: function () {
            return this.num(this.minMinutes);
        },
        showPrice() {
            let payPrice = 0;
            if (this.choseDis) {
                let priceNum =
                    Math.floor(
                        (this.details.totalAmount - this.moneyObj.amount) * 100
                    ) / 100;
                if (priceNum <= 0) {
                    payPrice = 0;
                } else {
                    payPrice = priceNum;
                }
            } else {
                payPrice = this.details.totalAmount;
            }
            return payPrice;
        },
        orderNumber() {
            let hasRedMoneyIdx = this.details.orderNumber.indexOf("_");
            let showStr =
                hasRedMoneyIdx != -1
                    ? this.details.orderNumber.substring(0, hasRedMoneyIdx)
                    : this.details.orderNumber;
            return showStr;
        },
    },
    data() {
        return {
            hasNews: false,
            active: 2,
            orderId: "",
            title: "",
            description1: "",
            description2: "",
            status: "",
            details: {},
            payMode: "",
            disabled: false,
            payImg: "",
            seconds: 0,
            minMinutes: 3,
            timer: null,
            choseDis: false,
            moneyObj: {
                amount: 0,
                deadline: "",
                id: "",
            },
            payPrice: 0,
            promotionRecordId: "",
        };
    },
    created() {
        this.orderId = this.$route.query.orderId;
        this.fetchData(this.orderId);
        this.fetchAmount();
    },
    methods: {
        //下载电子发票
        downloadInvoice(path) {
            this.getUrlBase64(path).then((base64) => {
                let link = document.createElement("a");
                link.href = base64;
                link.download = "电子发票.png";
                link.click();
            });
        },
        getUrlBase64(url) {
            return new Promise((resolve) => {
                let canvas = document.createElement("canvas");
                let ctx = canvas.getContext("2d");
                let img = new Image();
                img.crossOrigin = "Anonymous"; //允许跨域
                img.src = url;
                img.onload = function () {
                    canvas.height = 300;
                    canvas.width = 300;
                    ctx.drawImage(img, 0, 0, 300, 300);
                    let dataURL = canvas.toDataURL("image/png");
                    canvas = null;
                    resolve(dataURL);
                };
            });
        },
        choseRedPacket() {
            this.choseDis = !this.choseDis;
            this.promotionRecordId = this.choseDis ? this.moneyObj.id : "";
        },
        payment() {
            if (this.payMode == "") {
                this.$message({
                    message: "请选择付款方式！",
                    type: "warning",
                });
            } else {
                orderPayment(
                    this.orderId,
                    this.payMode,
                    this.promotionRecordId
                ).then((res) => {
                    if (res.success && res.errorCode == "2000") {
                        this.payImg = res.body.data.orderInfo.qrcode;
                        this.orderId = res.body.data.orderInfo.id;
                        this.Countdown(res.body.data.orderInfo.id);
                        this.disabled = true;
                    } else if (res.errorCode == "3003") {
                        this.disabled = false;
                        this.$message({
                            message: res.msg,
                            type: "warning",
                        });
                    }
                });
            }
        },
        changePay(num) {
            this.payMode = num;
        },
        num(n) {
            return n < 10 ? "0" + n : "" + n;
        },
        Countdown(id) {
            var _this = this;
            _this.timer = window.setInterval(function () {
                if (_this.seconds === 0 && _this.minMinutes !== 0) {
                    _this.seconds = 59;
                    _this.minMinutes -= 1;
                } else if (_this.minMinutes === 0 && _this.seconds === 0) {
                    _this.seconds = 0;
                    window.clearInterval(_this.timer);
                    _this.disabled = false;
                    _this.payImg = "";
                } else {
                    _this.seconds -= 1;
                }
                queryOrder(id).then((res) => {
                    if (res.success) {
                        if (res.body.data.status == "3") {
                            window.clearInterval(_this.timer);
                            _this.disabled = false;
                            _this.payImg = "";
                            _this.$router.push("/product-order-success");
                        }
                    }
                });
            }, 1000);
        },
        fetchData(id) {
            getOrderDetails(id).then((res) => {
                if (res.errorCode == "2000") {
                    this.details = res.body.data;
                    this.status = res.body.data.status;
                    // 订单状态
                    if (this.status === "0") {
                        this.title = "待支付";
                        this.active = 2;
                    } else if (this.status === "3") {
                        this.title = "支付";
                        this.active = 3;
                        this.description2 = res.body.data.finishTime;
                    } else if (this.status === "2") {
                        this.title = "交易关闭";
                        this.active = 2;
                        this.description2 = res.body.data.closeTime;
                    } else if (this.status === "1") {
                        this.title = "已取消";
                        this.active = 2;
                        this.description2 = res.body.data.cancelTime;
                    } else if (this.status === "5") {
                        this.title = "待审核";
                        this.active = 2;
                        this.description2 = res.body.data.cancelTime;
                    }
                    this.tableData = {
                        img: require("@/assets/images/product/gscdImg.png"),
                        name: "asdfasd",
                        goodsNum: "12131233",
                        num: 1,
                        price: "12312",
                    };
                    this.hasNews = true;
                }
            });
        },
        fetchAmount() {
            getAmount().then((res) => {
                if (res.success) {
                    if (res.body.data.length > 0) {
                        this.moneyObj = res.body.data[0];
                    }
                }
            });
        },
    },
    watch: {
        second: {
            handler(newVal) {
                this.num(newVal);
            },
        },
        minute: {
            handler(newVal) {
                this.num(newVal);
            },
        },
    },
    beforeDestroy() {
        if (this.timer) {
            //如果定时器还在运行 或者直接关闭，不用判断
            clearInterval(this.timer); //关闭
        }
    },
};
</script>
<style>
.min-el-step .el-step__description.is-finish {
    color: #1b4094;
}
.min-el-step .el-step__title.is-finish {
    color: #1b4094;
}
.min-el-step .el-step__head.is-finish {
    color: #1b4094;
    border-color: #1b4094;
}
.min-el-step .el-steps {
    width: 100%;
}
</style>
<style lang="less" scoped>
.invoiceBtn {
    padding: 0 22px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: #818181;

}
.invoiceContent {
    margin-top: 10px;
    padding: 10px 22px;
    display: flex;
    flex-direction: row;
    div {
        flex: 1;
        display: flex;
        flex-direction: row;
        font-size: 16px;
        color: #818181;
        span {
            flex: 1;
            margin-right: 20px;
        }
    }
    >span {
        cursor: pointer;
        i {
            font-size: 16px;
            color: #409eff;
            font-weight: bold;
        }
    }

}
.invoiceContainer {
    margin-top: 30px;
    padding: 0 20px 10px;
    background: #fff;
}
.discountImgBox {
    height: 115px;
    position: relative;
    margin: 4px auto;
    cursor: pointer;
    img {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    .discountNews {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            font-size: 30px;
            font-weight: bold;
            color: #fff;
        }
        span {
            width: 140px;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            background: #b5b5b5;
        }
        span.active {
            color: #ed3c27;
            background: #ffe7af;
        }
        em {
            margin-top: 12px;
            font-size: 12px;
            color: #8d8d8d;
            font-style: normal;
        }
        em.active {
            color: #971a0b;
        }
    }
}
.discountBox {
    margin: 20px 0;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: #3b3b3b;
    .discountTitle {
        line-height: 24px;
        cursor: pointer;
    }
    .discontRedPacket {
        border: solid 1px #dadada;
        padding: 6px;
        text-align: center;
        width: 226px;
        > p {
            color: crimson;
            font-size: 12px;
        }
    }
}
.pay_prict_type_box {
    display: flex;
    flex-direction: column;
}
.codeImgBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.payImgBox {
    width: 370px;
    height: 444px;
    border: solid 1px #252b3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    img {
        border: solid 1px #ccc;
        width: 236px;
    }
    p {
        margin: 16px 0;
        font-size: 22px;
        color: #252b3a;
        em {
            color: #ff8610;
            font-style: normal;
        }
    }
    span {
        font-size: 16px;
        color: #252b3a;
        margin: 20px 0;
    }
}
.pay_c_box {
    margin-bottom: 20px;
    padding: 20px 20px 0;
    background: #fff;
}
.order_xnwes1 {
    margin-top: 30px;
    height: 210px;
    padding-top: 30px;
    padding-bottom: 27px;
    background: #fff;
    display: flex;
    flex-direction: row;
}
.blr1 {
    border-left: dashed 1px #dadada;
    border-right: dashed 1px #dadada;
}
.order_bt {
    box-sizing: content-box;
    height: 36px;
    line-height: 36px;
    font-size: 20px;
    color: #000;
    font-weight: 600;
}
.gppds_price.active span {
    color: #1b4094;
    font-weight: bold;
}
.payBtn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    background: #1b4094;
}
.gppds_price span {
    font-size: 24px;
    font-weight: bold;
}
.gppds_price {
    font-size: 16px;
    line-height: 34px;
}
.total_price {
    font-size: 16px;
    color: #3b3b3b;
    line-height: 24px;
}
.pay_title {
    padding-left: 24px;
    height: 38px;
    line-height: 38px;
    font-size: 20px;
    color: #000;
    border-bottom: solid 1px #dadada;
}
.invoice_box p {
    margin-right: 32px;
}
.invoice_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    height: 66px;
    font-size: 16px;
    color: #3b3b3b;
}
.flex1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 16px;
}
.pay_price_box {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.payTypeBox {
    padding: 0 20px;
    height: 60px;
    font-size: 16px;
    color: #3b3b3b;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.flexRow {
    margin-right: 30px;
    display: flex;
    flex-direction: row;
}
.pay_type.active p {
    background: #fff;
    border-color: #1b4094 !important;
}
.pay_type img {
    margin: 0 5px 0 13px;
}
.pay_type p {
    box-sizing: content-box;
    width: 6px;
    height: 6px;
    border: solid 5px #eaf0ee;
    background: #eaf0ee;
    border-radius: 50%;
}
.pay_type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    width: 226px;
    height: 64px;
    font-size: 14px;
    color: #3b3b3b;
    border: solid 1px #dadada;
    background: #fff;
    cursor: pointer;
}
.pay_box {
    /* float: right; */
    display: flex;
    width: 226px;
    height: 182px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.goods_img {
    width: 120px;
    height: 96px;
}
.goods_news_box {
    padding: 0 20px;
    height: 100%;
    background: #fff;
}
.order_xnwes_text div p {
    width: 94px;
    font-size: 18px;
    text-align: justify;
    text-align-last: justify;
    color: #818181;
}
.order_xnwes_text div span {
    font-size: 18px;
}
.order_xnwes_text div {
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #818181;
}
.order_xnwes_text {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.order_xnwes_item {
    height: 100%;
    width: 433.33%;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    background: #fff;
}
.order_xnwes {
    margin-top: 30px;
    height: 262px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.careful {
    display: flex;
    flex-direction: row;
    width: 196px;
    font-size: 14px;
    line-height: 18px;
    color: #ff3b44;
}
.order-bz {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 100%;
}
.order_status.danger {
    color: #ff8e09;
}
.order_status.warning {
    color: #b21f27;
}
.order_status.success {
    color: #1fb226;
}
.order_status.cancel {
    color: #e0e2e0;
}
.order_status {
    margin: 15px 0;
    font-size: 30px;
    font-weight: bold;
}
.order_num {
    font-size: 16px;
    color: #818181;
}
.order_news_status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100%;
    border-right: solid 1px #dadada;
}
.order_news {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    height: 244px;
}
.order_type_title {
    box-sizing: content-box;
    padding: 30px 22px 0;
    height: 36px;
    line-height: 36px;
    font-size: 20px;
    color: #000;
    border-bottom: solid 1px #dadada;
}
.order-type {
    padding: 0 20px;
    height: 333px;
    background: #fff;
}
.title {
    padding-left: 16px;
    font-size: 26px;
    color: #000;
    line-height: 86px;
}
.activeType {
    border: solid 2px #1b4094 !important;
}
.pay_type.activeType p {
    background: #fff;
    border-color: #1b4094 !important;
}
</style>
