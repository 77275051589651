export function isMgl(s) { //国标
    var patrn = /[\u1800-\u18FF]/gi;
    if (!patrn.exec(s))
        return false;
    else
        return true;
}

export function isMkMgl(s) { //蒙科立编码
    var patrn = /[\uE234-\uE34F]/gi;
    if (!patrn.exec(s))
        return false;
    else
        return true;
}
export function isZh(s) { //汉文
    var patrn = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
    if (!patrn.exec(s)) {
        return false;
    }
    else {
        return true;
    }
}
