<template>
    <div class="h100 order-container overflowh">
        <div class="order-title">我的订单</div>
        <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部订单" name="0"></el-tab-pane>
                <el-tab-pane label="待支付" name="1"></el-tab-pane>
                <el-tab-pane label="已支付" name="2"></el-tab-pane>
                <el-tab-pane label="已取消" name="3"></el-tab-pane>
            </el-tabs>
            <div class="order-list" v-if="page.total != 0">
                <div class="order-item" v-for="item in orderList" :key="item.id">
                    <div class="order-date-num-type">
                        <p>{{ item.createDate }}</p>
                        <p>订单号：{{ orderNumber(item) }}</p>
                        <p>订单类型：产品服务</p>
                        <span v-if="item.status == '0'" class="pointer" @click="cancel(item.id)">取消订单</span>
                    </div>
                    <div class="order-content">
                        <img :src="item.product.logo" alt="">
                        <div class="order-name">{{item.product.name}}</div>
                        <div class="serve">数量：{{item.buyCount}}</div>
                        <div class="serve">{{item.productPaymentMode.name}}服务</div>
                        <div class="order-payment">
                            <p>¥ {{item.actualAmount}}</p>
                            <span></span>
                            <p v-if="item.payMode == '3'">线下支付</p>
                            <p v-if="item.payMode == '2'">在线支付/支付宝</p>
                            <p v-if="item.payMode == '1'">在线支付/微信支付</p>
                        </div>
                        <div class="details">
                            <p :class="orderTypeClass(item.status)">{{orderTypeName(item.status)}}</p>
                            <span class="pointer" @click="enterOrderDetails(item.id)">订单详情</span>
                        </div>
                    </div>
                </div>
            </div>
            <el-row type="flex" justify="center" style="margin-top:16px;" v-if="page.total != 0">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="page.current"
                    :page-size="page.size"
                    :total="page.total"
                    layout="prev, pager, next"
                >
                </el-pagination>
            </el-row>
            <div class="noneNews" v-if="page.total == 0">
                <img src="~assets/images/personal/nonImg.png" alt="">
                <div class="promptText">
                    <p>暂无订单记录</p>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import {getOrderList, orderCancel} from 'api/personal'
export default {
    inject: ["reload"],
    computed:{
        orderTypeClass(){
            return function(status) {
                let className = ''
                if(status == '3'){
                    className = 'success'
                }else if(status == '0') {
                    className = 'danger'
                }else if(status == '1' || status == '5' ) {
                    className = 'warning'
                }
                return className
            }
        },
        orderTypeName(){
            return function(status) {
                let name = ''
                if(status == '3'){
                    name = '已完成'
                }else if(status == '0') {
                    name = '待支付'
                }else if(status == '1') {
                    name = '已取消'
                }else if(status == "5"){
                    name = "待审核";
                }
                return name
            }
        },
        orderNumber(){
            return function(item) {
                let hasRedMoneyIdx = item.orderNumber.indexOf('_');
                let showStr = hasRedMoneyIdx != -1 ? item.orderNumber.substring(0,hasRedMoneyIdx) : item.orderNumber
                return showStr
            }

        }
    },
    data() {
        return {
            activeName: 0,
            page: {
                current: 1,
                size: 5,
                total: null,
            },
            status:'',
            orderList:[],
            hasNews:false
        }
    },
    created() {
        this.getList()
    },
    methods: {
        enterOrderDetails(id) {
            this.$router.push({
                path:'/order-details',
                query:{
                    orderId:id
                }
            })
        },
        cancel(id) {
            this.$confirm('确定要取消这条订单记录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                orderCancel(id).then(res => {
                    if(res.errorCode === '2000'){
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.reload();
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        })
                    }
                })
            }).catch(() => {

            });
        },
        handleClick(tab) {
            if (tab.name === '0') {
                this.status = '';
            } else if (tab.name === '1') {
                this.status = '0';
            } else if (tab.name === '2') {
                this.status = '3';
            } else if (tab.name === '3') {
                this.status = '1';
            } else {
                this.status = '2';
            }
            this.getList()
        },
        handleCurrentChange(current) {
            this.page.current = current;
            this.getList()
        },
        getList() {
            getOrderList(
                this.status,
                this.page.current,
                this.page.size
            ).then(res => {
                if (res.errorCode === "2000") {
                    this.orderList = res.body.data.rows;
                    this.page.total = res.body.data.total;
                }
            })
        }
    }
}
</script>
<style>
.order-box .el-tabs__nav .el-tabs__item {
    height: 26px;
    line-height: 26px;
    color: #6f7583;
}
.order-box .el-tabs__nav .el-tabs__item.is-active{
    color: #1b4094;
}
.order-box .el-tabs__active-bar{
    background: #1b4094 !important;
}
</style>
<style lang="less" scoped>
.order-container{
    padding: 0 20px;
    background: #fff;
}
.order-content{
    padding: 0 14px;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    >img{
        margin-right: 30px;
        width: 38px;
        height: 38px;
    }
    .order-name{
        width: 300px;
        font-size: 15px;
        color: #252b3a;
        font-weight: bold;
        font-family: '宋体';
    }
    .serve{
        width: 90px;
        text-align: center;
        font-size: 14px;
        color: #818181;
    }
    .order-payment{
        width: 162px;
        height: 100%;
        border-left: solid 1px #e6e6e6;
        border-right: solid 1px #e6e6e6;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p{
            font-size: 14px;
            color: #818181;
        }
        span{
            margin: 6px 0;
            width: 142px;
            height: 1px;
            background: #e6e6e6;
        }
    }
    .details{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        color: #3b3b3b;
        p{
            margin-bottom: 10px;
        }
        p.warning {
            color: #b21f27;
        }
        p.danger {
            color: #ff8e09;
        }
        p.success {
            color: #1fb226;
        }
    }
}
.order-date-num-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 14px;
    height: 30px;
    background: #f3f5fa;
    p{
        margin-right: 80px;
        font-size: 12px;
        color: #636a7b;
    }
    span{
        margin-left: 120px;
        font-size: 12px;
        color: #636a7b;
    }
}
.order-item{
    height: 94px;
    border: solid 1px #e2e2e2;
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
}
.order-title{
    font-size: 18px;
    color: #252b3a;
    line-height: 56px;
    text-align: center;
    font-weight: bold;
}
.orderBox{
    padding: 8px 36px;
    background: #fff;
}
</style>
