import { request } from '@/utils/request'
//获取用户信息
export function getInformation() {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/memberInfo/getInformation?timestamp=${timestamp}`,
        method: 'get',
    });
}

//更新用户信息
export function editInformation(memberInfoVo) {
    return request({
        url: '/api/v1/memberInfo/edit/information',
        method: 'post',
        data: {
            ...memberInfoVo
        }
    });
}

//更新用户头像
export function editHeadImg(headImgBase64) {
    return request({
        url: '/api/v1/memberInfo/edit/headImg',
        method: 'post',
        data: {
            headImgBase64
        }
    });
}

//获取个人中心——消息通知
export function getPersonalNotice(current, size) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/memberNotice/getNotice?timestamp=${timestamp}`,
        method: 'get',
        params: {
            pageNo: current,
            pageSize: size
        }
    });
}

//阅读消息
export function readNotice(id) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/memberNotice/readNotice?timestamp=${timestamp}`,
        method: 'get',
        params: {
            id
        }
    });
}

//删除消息
export function delNotice(id) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/memberNotice/delNotice?timestamp=${timestamp}`,
        method: 'get',
        params: {
            id
        }
    });
}

//订单列表
export function getOrderList(status,pageNo,pageSize) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/orderInfo/order/list?timestamp=${timestamp}`,
        method: 'get',
        params: {
            status,
            pageNo,
            pageSize
        }
    });
}

//取消订单
export function orderCancel(orderId) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/orderInfo/order/cancel?timestamp=${timestamp}`,
        method: 'get',
        params: {
            orderId
        }
    });
}

//我的服务
export function getMineService(pageNo,pageSize) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/purchaseInfo/memService?timestamp=${timestamp}`,
        method: 'get',
        params: {
            pageNo,
            pageSize
        }
    });
}

//购买记录
export function getMinePurchase(pageNo,pageSize) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/purchaseInfo/memPurchase?timestamp=${timestamp}`,
        method: 'get',
        params: {
            pageNo,
            pageSize
        }
    });
}

//获取ocr批量委托列表
export function getOcrList(pageNo,pageSize) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productBatch/getPersonOCR?timestamp=${timestamp}`,
        method: 'get',
        params: {
            pageNo,
            pageSize
        }
    });
}


//个人企业认证
export function accountUpgrade(form) {
    return request({
        url: '/api/v1/productInfo/company/accountUpgrade',
        method: 'post',
        data: {
            ...form
        }
    });
}

//个人中心企业服务列表
export function gainCompanyService(pageNo,pageSize) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/productInfo/company/gainCompanyService?timestamp=${timestamp}`,
        method: 'get',
        params: {
            pageNo,
            pageSize
        }
    });
}