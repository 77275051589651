<template>
    <div class="main-container " style="background:#f3f5f8;">
        <div class="w12 personalBox overflowh">
            <div class="details-crumbs">
                <p class="bar2"></p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>个人中心</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="personal-content-box">
                <div class="tablist_box">
                    <perTabBar>
                        <PerTabItem  path="/personal/core">
                            <img src="~assets/images/personal/coreImg.png" slot="item-img" alt="">
                            <img src="~assets/images/personal/coreAImg.png" slot="item-active" alt="">
                            <p slot="item-name">个人中心</p>
                        </PerTabItem>
                        <PerTabItem  path="/personal/information">
                            <img src="~assets/images/personal/informationImg.png" slot="item-img" alt="">
                            <img src="~assets/images/personal/informationAImg.png" slot="item-active" alt="">
                            <p slot="item-name">我的信息</p>
                        </PerTabItem>
                        <PerTabItem  path="/personal/order">
                            <img src="~assets/images/personal/orderImg.png" slot="item-img" alt="">
                            <img src="~assets/images/personal/orderAImg.png" slot="item-active" alt="">
                            <p slot="item-name">我的订单</p>
                        </PerTabItem>
                        <PerTabItem  path="/personal/serve">
                            <img src="~assets/images/personal/serveImg.png" slot="item-img" alt="">
                            <img src="~assets/images/personal/serveAImg.png" slot="item-active" alt="">
                            <p slot="item-name">我的服务</p>
                        </PerTabItem>
                        <PerTabItem  path="/personal/purchase">
                            <img src="~assets/images/personal/purchaseImg.png" slot="item-img" alt="">
                            <img src="~assets/images/personal/purchaseAImg.png" slot="item-active" alt="">
                            <p slot="item-name">购买记录</p>
                        </PerTabItem>
                        <PerTabItem  path="/personal/news">
                            <img src="~assets/images/personal/newsImg.png" slot="item-img" alt="">
                            <img src="~assets/images/personal/newsAImg.png" slot="item-active" alt="">
                            <p slot="item-name">消息中心</p>
                        </PerTabItem>
                        <PerTabItem  path="/personal/ocr">
                            <img src="~assets/images/personal/ocrImg.png" slot="item-img" alt="">
                            <img src="~assets/images/personal/ocrAImg.png" slot="item-active" alt="">
                            <p slot="item-name">ocr委托</p>
                        </PerTabItem>
                        <PerTabItem  path="/personal/com-service" v-if="memberType == '1'">
                            <img src="~assets/images/personal/qyfwImg.png" slot="item-img" alt="">
                            <img src="~assets/images/personal/qyfwAImg.png" slot="item-active" alt="">
                            <p slot="item-name">企业服务</p>
                        </PerTabItem>
                    </perTabBar>
                </div>
                <div class="personal-router-box">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import perTabBar from '@/components/common/perTabBar/perTabBar'
import PerTabItem from '@/components/common/perTabBar/perTabItem'
export default {
    components:{perTabBar, PerTabItem},
    data(){
        return {
            memberType: PcCookie.get(Key.memberTypeKey),
        }
    }
}
</script>
<style lang="less" scoped>
.personal-content-box{
    margin-bottom: 52px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 672px;
    .tablist_box{
        padding: 20px 0;
        width: 264px;
        height: 100%;
        background: #fff;
    }
    .personal-router-box{
        width: 920px;
        height: 100%;
    }
}
</style>

