<template>
    <div class="wpsActivityDetais" :style="backStyle" v-if="hasNews">
        <!-- 套餐分类 -->
        <div class="wpsActivityPayBox w12">
            <div class="wpsActivityPay">
                <div class="wpsActivityTitle">
                    <span></span>
                    <p>{{packageList[0].name}}</p>
                    <span></span>
                </div>
                <div class="wpsActivityMoney">
                    <h4>{{packageList[0].price}}</h4>
                    <p>
                        <span>元</span>
                        /  7天
                    </p>
                </div>
                <div class="wpsActivityExplain">
                    <p>仅限1个账号使用</p>
                    <p>7款蒙科立AI助手，1天2000字符</p>
                    <p>超值体验</p>
                </div>
                <div class="wpsActivityPayBtn" @click="enterPay(0)">立即选购</div>
            </div>
            <div class="wpsActivityPay">
                <div class="wpsActivityTitle">
                    <span></span>
                    <p>{{packageList[1].name}}</p>
                    <span></span>
                </div>
                <div class="wpsActivityMoney">
                    <h4>{{packageList[1].price}}</h4>
                    <p>
                        <span>元</span>
                        /  1年
                    </p>
                </div>
                <div class="wpsActivityExplain">
                    <p>2个账号使用</p>
                    <p>7款蒙科立AI助手，1年500万字符</p>
                    <p>尊享体验</p>
                </div>
                <div class="wpsActivityPayBtn" @click="enterPay(1)">立即选购</div>
            </div>
            <div class="wpsActivityPay">
                <img src="~assets/images/temporary/gftjImg.png" class="gftjImg" alt="">
                <div class="wpsActivityTitle">
                    <span></span>
                    <p>{{packageList[2].name}}</p>
                    <span></span>
                </div>
                <div class="wpsActivityMoney">
                    <h4>{{packageList[2].price}}</h4>
                    <p>
                        <span>元</span>
                        /  1年
                    </p>
                </div>
                <div class="wpsActivityExplain">
                    <p>5个账号使用</p>
                    <p>7款蒙科立AI助手，1年无限量，极致</p>
                    <p>畅享体验</p>
                </div>
                <div class="wpsActivityPayBtn" @click="enterPay(2)">立即选购</div>
            </div>
        </div>
        <!-- 权益 -->
        <div class="wpsActivityRights w12">
            <h4>WPS蒙科立AI助手超值包权益</h4>
            <img src="~assets/images/temporary/wpsImg20220506.jpg" alt="">
        </div>
        <!-- 使用方法 -->
        <div class="wpsActivityRights w12">
            <h4>WPS蒙科立AI助手使用方法</h4>
            <img src="~assets/images/temporary/wpsHandleImg.jpg" alt="" class="pointer" @click="enterCollege">
        </div>
    </div>
</template>

<script>
import { PcCookie, Key } from "@/utils/cookie"
import {getProductDetails} from 'api/product'
export default {
    data(){
        return{
            backStyle: {
                backgroundImage:'url(' + require('@/assets/images/temporary/wpsActivityDetaisImg1.jpg') + ')',
                backgroundRepeat:'no-repeat',
                backgroundPosition: 'center top'
            },
            wpsPackageId:'d65340be9c224fa0ac8171d4a1026af7',
            packageList:[],
            hasNews:false,
            token: PcCookie.get(Key.tokenKey),
        }
    },
    created(){
        this.fetchDetails();
    },
    methods:{
        enterCollege(){
            this.$router.push({
                path:'/college-course-details',
                query:{
                    courseId:'44456fd6a97f472b80d3fbf92307e2d7',
                    id:'39a9b87b312841e583b296a8e6040d9a',
                    productId:'91e16bf1a53b4ecd8dc64ff0bcf6d5f9'
                }
            })
        },
        enterPay(index){
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再购买！",
                    type: "warning",
                });
            } else {
                this.$router.push({
                    path:'/activity-wps-order',
                    query:{
                        productId:this.wpsPackageId,
                        packageType: index
                    }
                })
            }
        },
        fetchDetails(){
            getProductDetails(this.wpsPackageId).then(res => {
                if(res.success){
                    this.packageList = res.body.data.productInfo.productPaymentRuleList;
                    this.hasNews = true;
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.wpsActivityRights{
    margin-top: 58px;
    font-size: 25px;
    font-weight: bold;
    font-family: '思源黑体';
    color: #fff;
    h4{
        text-align: center;
    }
    img{
        margin-top: 50px;
    }
}
.wpsActivityPayBtn{
    margin-top: 42px;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    width: 262px;
    height: 58px;
    line-height: 58px;
    text-align: center;
    background: #ff6828;
    cursor: pointer;
}
.wpsActivityExplain{
    font-size: 14px;
    p{
        line-height: 20px;
        text-align: center;
        color: #5a5a5a;
    }
}
.wpsActivityMoney{
    margin: 36px 0 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold;
    >h4{
        color: #ff6828;
        font-size: 70px;
    }
    >p{
        margin-top: 44px;
        font-size: 20px;
        span{
            color: #ff6828;
        }
    }
}
.wpsActivityTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    color: #000;
    >span{
        width: 23px;
        height: 2px;
        background: #000;
        margin: 0 6px;
    }
}
.wpsActivityPay{
    font-family: '思源黑体';
    width: 390px;
    height: 100%;
    background: #fff;
    border-radius: 9px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .4);
    position: relative;
    overflow: hidden;
    .gftjImg{
        position: absolute;
        right: -26px;
        top: -4px;
    }
}
.wpsActivityPayBox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1120px;
    height: 414px;
    box-sizing: content-box;
}
.wpsActivityDetais{
    height: 3696px;
}
</style>
