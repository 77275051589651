<template>
    <div class="main-container overflowh" style="background: #f3f5f8">
        <div class="w12 feedBackBox">
            <div class="feedBack-top">
                <div class="feedBack-top-t">
                    <div class="menkFeedBack">蒙科立</div>
                    <div class="text-box">
                        <p>欢迎反馈问题,您的建议就是我们的动力!</p>
                        <span
                            >我们会认真查阅您反馈的每一个问题，并尽快给您答复。</span
                        >
                    </div>
                </div>
                <img src="~assets/images/college/feedImg.jpg" alt="" />
            </div>
            <div class="feedNewsBox">
                <el-form ref="form" :model="form" label-width="108px" :rules="rules">
                    <el-form-item prop="suggestType" label="建议类型：">
                        <el-tabs type="card" v-model="form.suggestType">
                            <el-tab-pane
                                :name="item.value"
                                :label="item.label"
                                v-for="(item, index) in typeList"
                                :key="item.value"
                                >类型说明：{{ showText(index) }}</el-tab-pane
                            >
                        </el-tabs>
                    </el-form-item>
                    <el-form-item prop="productInfo.id" label="产品名称：">
                        <el-select
                            v-model="form.productInfo.id"
                            placeholder="请选择产品名称"
                        >
                            <el-option
                                :label="item.name"
                                :value="item.id"
                                v-for="item in productList"
                                :key="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="title" label="标题：">
                        <el-input
                            placeholder="请输入标题"
                            v-model="form.title"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="question" label="面临的问题：">
                        <el-input
                            type="textarea"
                            v-model="form.question"
                            rows="4"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="suggest" label="我的建议：">
                        <el-input
                            type="textarea"
                            v-model="form.suggest"
                            rows="4"
                        ></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="上传附件">
                        <el-upload
                            class="upload-demo"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :before-remove="beforeRemove"
                            multiple
                            :limit="3"
                            :on-exceed="handleExceed"
                            :file-list="fileList">
                            <el-button size="small" >
                                <i class="el-icon-upload2"></i>
                                点击上传
                            </el-button>
                            <div slot="tip" class="el-upload__tip">个数及大小限制：3个附件，单个不超过10MB 支持格式：txt、jpeg、png、doc、xls、pdf</div>
                        </el-upload>

                    </el-form-item> -->
                    <el-form-item label="是否公开" prop="isOpen">
                        <el-radio-group
                            v-model="form.isOpen"
                            :disabled="disabled"
                        >
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <el-row class="feedSubmit" type="flex" justify="center">
                    <el-button type="primary" @click="submitForm('form')"
                        >提交</el-button
                    >
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import { getSuggestType, saveQuestion, getProductsNav } from "api/product";
export default {
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙古文WPS2002，蒙古文字体，蒙科立学院，蒙科立整词输入法，蒙古文WPS2002，语音识别",
            },
        ],
    },
    computed: {
        showText() {
            return function (index) {
                let text = "";
                if (index == 0) {
                    text = "希望蒙科立的产品功能增加某些功能或者优化某些功能";
                } else if (index == 1) {
                    text =
                        "希望蒙科立的售后服务建议增加某些功能或者优化某些功能";
                } else if (index == 2) {
                    text =
                        "希望蒙科立的售前服务建议建议增加某些功能或者优化某些功能";
                } else if (index == 3) {
                    text = "希望蒙科立建议增加某些功能或者优化某些功能";
                }
                return text;
            };
        },
    },
    data() {
        return {
            form: {
                suggestType: "",
                productInfo: {
                    id: "",
                },
                title: "",
                question: "",
                suggest: "",
                isOpen: "",
            },
            typeList: [],
            productList: [],
            disabled: false,
            rules: {
                "productInfo.id":[
                    {
                        required: true,
                        message: "请选择产品名称",
                        trigger: "change",
                    },
                ],
                title: [
                    {
                        required: true,
                        message: "请输入标题",
                        trigger: "blur",
                    },
                ],
                question: [
                    {
                        required: true,
                        message: "请输入面临的问题",
                        trigger: "blur",
                    },
                ],
                suggest: [
                    {
                        required: true,
                        message: "请输入建议",
                        trigger: "blur",
                    },
                ],
                isOpen: [
                    {
                        required: true,
                        message: "请选择是否公开",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    created() {
        this.getTypeList();
        this.getProductTextList();
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    saveQuestion(this.form).then(res => {
                        if(res.errorCode == '2000'){
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            setTimeout(() => {
                                this.$router.push('/')
                            },2000)
                        }else{
                            this.$message.error(res.msg)
                            this.disabled = true;
                        }
                    })
                } else {
                    return false;
                }
            })
        },
        getProductTextList() {
            getProductsNav().then((res) => {
                if (res.success) {
                    this.productList = res.body.data;
                }
            });
        },
        getTypeList() {
            getSuggestType().then((res) => {
                if (res.errorCode == "2000") {
                    this.typeList = res.body.data;
                }
            });
        },

    },
};
</script>
<style>
.feedNewsBox .el-form-item__label {
    text-align: left;
}
.feedNewsBox .el-tab-pane {
    color: #9398a2;
    font-size: 12px;
}
.feedNewsBox .el-tabs__item {
    width: 162px;
    color: #6f7583;
    text-align: center;
}
.feedNewsBox .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border-radius: 0;
}
.feedNewsBox .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border: solid 1px #1b4094;
    color: #1b4094;
}
.feedNewsBox .el-select .el-input.is-focus .el-input__inner {
    border: solid 1px #1b4094;
}
.feedNewsBox .el-select {
    width: 100%;
}
.feedNewsBox .el-input__inner:focus {
    border-color: #1b4094;
}
</style>
<style lang="less" scoped>
.feedSubmit .el-button {
    width: 210px;
    background: #1b4094;
    border-color: #1b4094;
}
.feedNewsBox {
    margin: 58px auto;
    width: 758px;
}
.text-box {
    p {
        font-size: 18px;
        color: #252b3a;
    }
    span {
        font-size: 12px;
        color: #6f7583;
    }
}
.menkFeedBack {
    margin-right: 22px;
    font-size: 30px;
    font-weight: bold;
    color: #252b3a;
}
.feedBack-top-t {
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.feedBack-top {
    display: flex;
    flex-direction: column;
    height: 76px;
    > img {
        width: 100%;
    }
}
.feedBackBox {
    margin: 40px auto;
    padding: 28px 40px;
    background: #fff;
}
</style>
