<template>
    <div class="h100">
        <user-info></user-info>
        <serve-buy></serve-buy>
        <order-news></order-news>
    </div>
</template>
<script>
import UserInfo from 'views/routerViews/Personal/ChildViews/Core/UserInfo'
import ServeBuy from 'views/routerViews/Personal/ChildViews/Core/ServeBuy'
import OrderNews from 'views/routerViews/Personal/ChildViews/Core/OrderNews'

export default {
    components:{UserInfo, ServeBuy, OrderNews}
}
</script>
