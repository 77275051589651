<template>
    <div class="home">
        <Header :productList="productList" />
        <router-view></router-view>
        <Footer />
        <el-backtop :bottom="200"></el-backtop>
    </div>
</template>
<script>
import { getProductsNav } from "api/product";
import Header from "@/components/contents/application/header";
import Footer from "@/components/contents/application/footer";
export default {
    name: "Index",
    inject: ["reload"],
    components: { Header, Footer },
    data() {
        return {
            productList:[]
        };
    },
    created() {
        this.fetchProductNav();
    },
    methods: {
        fetchProductNav() {
            getProductsNav().then((res) => {
                if (res.success) {
                    const list = res.body.data;
                    this.productList = list.filter((item) => {
                        if (item.routerPath) {
                            return item;
                        }
                    });
                }
            });
        },
    },

};
</script>

<style>
/*css 引入方式*/
@import "../../assets/css/base.css";
</style>
