import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        newsType: ''
    },
    mutations: {
        changeNewsType(state, data) {
            state.newsType = data
        }
    },
    modules: {
        auth
    }
})
export default store
