<template>
    <div @click.stop="itemClick">
        <slot name="name"></slot>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
export default {
    props: {
        path: {
            type: String,
        },
        productId:{
            type: String,
            default:''
        }
    },
    data(){
        return {
            token: PcCookie.get(Key.tokenKey),
        }

    },
    methods: {
        itemClick() {
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再购买！",
                    type: "warning",
                });
            } else {
                let routeData = this.$router.resolve({
                    path: this.path,
                    query:{
                        productId:this.productId
                    }
                });
                window.open(routeData.href, "_blank");
            }
        },
    }
}
</script>
<style lang="less" scoped>
div{
    width: 50%;
    line-height: 40px;
    p{
        text-align: left;
    }
    p:hover{
        color: #1b4094;
    }
}
</style>
