<template>
    <div class="attestationBox"
        v-loading="loading"
        element-loading-text="正在提交..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
        style="background:#f3f5f8"
    >
        <div class="w12">
            <div class="attestationTitleBox">
                <img src="~assets/images/qyRzImg.png" alt="">
                <p>企业账户认证</p>
            </div>
            <div class="attestationForm">
                <div class="aFormTitle">
                    <span>个人认证  ></span>
                    <p>申请企业认证</p>
                </div>
                <div class="aFormName">
                    <p></p>
                    <span>企业资质信息</span>
                </div>
                <el-form
                    ref="attestationForm"
                    :model="attestationForm"
                    label-position="top"
                    key="attestationForm"
                    :rules="rules"
                >
                    <!-- <el-form-item prop="licensePathBase64" label="营业执照影印件" class="h250 licensePathBase64">
                        <div class="aUploadBox h200">
                             <el-upload
                                class="avatar-uploader"
                                accept="image/png,image/jpg,image/jpeg"
                                action="#"
                                :before-upload="licenseBeforeUpload"
                                :http-request="uploadImg"
                                :show-file-list="false"
                            >
                                <i class="el-icon-plus"></i>
                                <span>上传图片</span>
                            </el-upload>
                        </div>
                        <div class="aUploadBox h200" v-if="attestationForm.licensePathBase64 != ''">
                            <img :src="attestationForm.licensePathBase64" alt="" class="licenseImg">
                        </div>
                        <div class="licenseImgTips">
                            <p>·支持JPG/PNG格式的图片</p>
                            <p>·大小≤5M</p>
                            <p>·营业执照原件的彩色扫描件或复印件，<span>必须加盖公章</span></p>
                        </div>
                    </el-form-item> -->
                    <el-form-item label="企业注册名称" prop="organName">
                        <el-input
                            v-model="attestationForm.organName"
                            :disabled="disabled"
                            placeholder="请填写营业执照上的全称，仅支持中国大陆工商局或市场监督管理局登记的企业"
                        >
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item label="法人姓名" prop="corporName">
                        <el-input
                            v-model="attestationForm.corporName"
                            :disabled="disabled"
                            placeholder="请填写营业执照上的法定代表人姓名"
                        >
                        </el-input>
                    </el-form-item> -->
                    <el-form-item label="统一社会信用代码" prop="creditCode">
                        <el-input
                            v-model="attestationForm.creditCode"
                            :disabled="disabled"
                            placeholder="请填写18位统一信用社会信用代码"
                        >
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item label="企业详细地址" prop="organAddr">
                        <el-input
                            v-model="attestationForm.organAddr"
                            :disabled="disabled"
                            placeholder="请填写详细的企业所在地址，或常用地址"
                        >
                        </el-input>
                    </el-form-item> -->
                    <el-form-item class="attesationSubmit">
                        <el-button
                            :disabled="disabled"
                            @click="handleSubmit('attestationForm')"
                        >
                            提交
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import VueEvent from "@/VueEvent.js";
import { PcCookie, Key } from "@/utils/cookie";
import { accountUpgrade } from 'api/personal'
export default {
    computed: {
        userInfo() {
            return PcCookie.get(Key.userInfoKey)
                ? JSON.parse(PcCookie.get(Key.userInfoKey))
                : null;
        },
    },
    data(){
        var validCreditCode = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请填写18位统一信用社会信用代码"));
            } else {
                if(value.length != 18){
                    callback(new Error("请填写18位统一信用社会信用代码"));
                } else {
                    callback();
                }
            }
        };
        return{
            token: PcCookie.get(Key.tokenKey),
            memberType: PcCookie.get(Key.memberTypeKey),
            loading:false,
            disabled:false,
            attestationForm:{
                corporName: "",
                creditCode: "",
                licensePathBase64: "",
                organAddr: "",
                organName: ""
            },
            rules:{
                licensePathBase64: [
                    {
                        required: true,
                        message: "请填写营业执照上的全称，仅支持中国大陆工商局或市场监督管理局登记的企业",
                        trigger: "change",
                    },
                ],
                corporName: [
                    {
                        required: true,
                        message: "请填写营业执照上的法定代表人姓名",
                        trigger: "blur",
                    },
                ],
                creditCode: [
                    {
                        required: true,
                        validator: validCreditCode,
                        trigger: "blur",
                    },
                ],
                organAddr: [
                    {
                        required: true,
                        message: "请填写详细的企业所在地址，或常用地址",
                        trigger: "blur",
                    },
                ],
                organName: [
                    {
                        required: true,
                        message: "请填写营业执照上的全称，仅支持中国大陆工商局或市场监督管理局登记的企业",
                        trigger: "blur",
                    },
                ],
            }
        }
    },
    methods:{
        handleSubmit(formName){
            this.loading = true
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.disabled = true;
                    accountUpgrade(this.attestationForm).then(res => {
                        if(res.success){
                            this.$message({
                                type:res.success ? 'success' : 'error',
                                message:res.msg,
                                duration:1500
                            })
                            setTimeout(()=>{
                                VueEvent.$emit("attestation");
                                PcCookie.set(Key.memberTypeKey,'1')
                                this.$router.push('/personal/core')
                            },2500)
                        }else{
                            this.$message({
                                type: 'error',
                                message:res.msg,
                            })
                            this.loading = false;
                            this.disabled = false;
                        }
                    })
                } else {
                    this.disabled = false;
                    this.loading = false
                    return false;
                }
            });
        },
        uploadImg(data) {
            this.singleRsult = ''
            let that = this;
            let rd = new FileReader();
            let file = data.file;
            rd.readAsDataURL(file);
            rd.onloadend = function () {
                that.attestationForm.licensePathBase64 = this.result;
                that.$refs["attestationForm"].clearValidate(["licensePathBase64"]);
            };
        },
        licenseBeforeUpload(file){
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                this.$message({
                    message: "上传文件不能超过5MB！",
                    type: "warning",
                });
                return false;
            }
        }
    },
}
</script>
<style lang="less">
.attestationForm .attesationSubmit button{
    display: block;
    margin: 20px auto;
    width: 346px;
    background: #1b4094;
    color: #fff;
}
.licensePathBase64 .el-form-item__content{
    display: flex;
    flex-direction: row;
}
.attestationForm .el-form-item__label{
    color: #252b3a;
    font-weight: bold;
}
.attestationForm{
    .el-upload--text{
        width: 100%;
        height: 100%;
        color: #1b4094;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        i{
            font-size: 32px;
        }
        span{
            font-size: 14px;
        }
    }
}

</style>
<style lang="less" scoped>
.licenseImgTips{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 12px;
    color: #8d919e;
    line-height: 24px;
    span{
        font-weight: bold;
        color: #252b3a;
    }
}
.licenseImg{
    max-width: 190px;
    max-height: 170px;
}
.aUploadBox{
    width: 224px;
    background: #eef3f6;
    overflow: hidden;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    >div{
        height: 168px;
        width: 186px;
        border: dashed 1px #acb1b9;
        background: #fff;
    }
}
.h200{
    height: 200px;
}
.h250{
    height: 250px;
}
.aFormName{
    height: 86px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-family: '宋体';
    p{
        width: 6px;
        height: 26px;
        background: #1b4094;
        margin-right: 5px;
    }
    span{
        font-weight: bold;
    }
}
.aFormTitle{
    height: 62px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-family: '宋体';
    span{
        color: #1b4094;
        margin-right: 6px;
    }
    p{
        color: #000;
    }
}
.attestationForm{
    padding: 0 200px 40px;
    background: #fff;
}
.attestationTitleBox{
    position: relative;
    padding: 0 200px;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px #eeeeee;
    background: -webkit-linear-gradient(to right, #fff9ee, #fffbf4, #ffffff, #ffffff);
    background: -o-linear-gradient(to right, #fff9ee, #fffbf4, #ffffff, #ffffff);
    background: -moz-linear-gradient(to right, #fff9ee, #fffbf4, #ffffff, #ffffff);
    background: linear-gradient(to right, #fff9ee, #fffbf4, #ffffff, #ffffff);
    p{
        font-size: 25px;
        color: #252b3a;
        font-family: '宋体';
        margin-left: -24px;
        font-weight: bolder;
    }
}
.attestationBox{
    min-height: 100%;
    padding: 66px 0;
}
</style>
