import { request } from '@/utils/request'
//用户名密码登录
export function login(data,rsKey,secretKey) {
    return request({
        url: '/api/v1/memberInfo/login/namePwdLogin',
        method: 'post',
        data:{
            data,
            rsKey,
            secretKey
        }
    });
}

//退出登录
export function logout(id) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/memberInfo/loginOut?id=${id}&timestamp=${timestamp}`,
        method: 'get',
    });
}

//校验忘记密码手机验证码
export function checkForgetCode(data,rsKey,secretKey) {
    return request({
        url: '/api/v1/memberInfo/login/checkCode',
        method: 'post',
        data:{
            data,
            rsKey,
            secretKey
        }
    });
}

//发送密码重置邮件
export function forgetSendMail(email) {
    let timestamp = new Date().getTime()
    return request({
        url: `/api/v1/memberInfo/login/updatePassword/sendMail?email=${email}&timestamp=${timestamp}`,
        method: 'get',
    });
}

//重置密码
export function updatePassword(data,rsKey,secretKey) {
    return request({
        url: '/api/v1/memberInfo/login/updatePassword',
        method: 'post',
        data:{
            data,
            rsKey,
            secretKey
        }
    });
}

//短信登录
export function phoneLogin(data,rsKey,secretKey) {
    return request({
        url: '/api/v1/memberInfo/login/phoneCode',
        method: 'post',
        data:{
            data,
            rsKey,
            secretKey
        }
    });
}

