import Vue from 'vue'
import VueRouter from 'vue-router'
// 懒加载
const Index = () => import('views/index')
const Home = () => import('views/routerViews')
const News = () => import('views/routerViews/Home/News')
const NewsList = () => import('views/routerViews/Home/News/List/Content/newsList')
const NoticeList = () => import('views/routerViews/Home/News/List/Content/noticeList')
const ActivityList = () => import('views/routerViews/Home/News/List/Content/activityList')
const ArticleDetails = () => import('views/routerViews/Home/News/Details/article')
const NoticeDetails = () => import('views/routerViews/Home/News/Details/notice')
const ActivityDetails = () => import('views/routerViews/Home/News/Details/activity')

const Product = () => import('views/routerViews/Product')
const ProductDetails = () => import('views/routerViews/Product/Details')
const ProductOrder = () => import('views/routerViews/Product/Order')
const ProductOcrOrder = () => import('views/routerViews/Product/Order/ocr')
const ProductOrderSuccess = () => import('views/routerViews/Product/Order/success')

const Download = () => import('views/routerViews/Download/download')
const DownloadFont = () => import('views/routerViews/Download/Font')
const DownloadOpenType = () => import('views/routerViews/Download/Font/Guide/openType')
const DownloadAatType = () => import('views/routerViews/Download/Font/Guide/aatType')
const DownloadPhotoshopType = () => import('views/routerViews/Download/Font/Guide/photoshopType')



const College = () => import('views/routerViews/College')
const Problem = () => import('views/routerViews/College/Problem')
const ProblemDetails = () => import('views/routerViews/College/Problem/Details')
const Feedback = () => import('views/routerViews/College/Feedback')
const Course = () => import('views/routerViews/College/Course')
const CourseDetails = () => import('views/routerViews/College/Course/Details')

const Case = () => import('views/routerViews/Case')
const CaseDetails = () => import('views/routerViews/Case/Details')




const About = () => import('views/routerViews/About')
const AboutRecruit = () => import('views/routerViews/About/Recruit')
const AboutRecruitDetails = () => import('views/routerViews/About/Recruit/Details')

const Login = () => import('views/routerViews/Login')
const ForgetPassword = () => import('views/routerViews/Login/ForgetPassword')
const ResetPassrord = () => import('views/routerViews/Login/ResetPassrord')

const Register = () => import('views/routerViews/Register')
const Clause = () => import('views/routerViews/Register/clause')
const Privacy = () => import('views/routerViews/Register/privacy')

const WeChatRegister = () => import('views/routerViews/Register/weChatRegister')

const Personal = () => import('views/routerViews/Personal')
const Core = () => import('views/routerViews/Personal/ChildViews/Core')
const Information = () => import('views/routerViews/Personal/ChildViews/Information')
const Order = () => import('views/routerViews/Personal/ChildViews/Order')
const OrderDetails = () => import('views/routerViews/Personal/ChildViews/Order/Details')
const Serve = () => import('views/routerViews/Personal/ChildViews/Serve')
const Purchase = () => import('views/routerViews/Personal/ChildViews/Purchase')
const PersonalNews = () => import('views/routerViews/Personal/ChildViews/News')
const PersonalOCR = () => import('views/routerViews/Personal/ChildViews/OCR')
const PersonalComService = () => import('views/routerViews/Personal/ChildViews/ComService')

const ProtocolFont = () => import('views/routerViews/Protocol/font')
const FontShow = () => import('views/routerViews/Font')

const Archive = () => import('views/routerViews/Archive')


const ActivityRedPacket = () => import('views/routerViews/Activity/redPacket')
const ActivityWpsWelfare = () => import('views/routerViews/Activity/wpsWelfare')
const ActivityWpsOrder = () => import('views/routerViews/Activity/wpsOrder')
const Attestation = () => import('views/routerViews/Attestation')



const Application = () => import('views/Application')
const ApplicationTranslate = () => import('views/Application/Translate')
const ApplicationMenkocr = () => import('views/Application/Menkocr')
const ApplicationDictionary = () => import('views/Application/Dictionary')
const ApplicationVoiceRecognition = () => import('views/Application/VoiceRecognition')
const ApplicationConversion = () => import('views/Application/Conversion')
const ApplicationConversionMongolia = () => import('views/Application/Conversion/mongolia')

const ApplicationProof = () => import('views/Application/Proof')
const ApplicationPictureCreate = () => import('views/Application/PictureCreate')
const ApplicationSynthesis = () => import('views/Application/Synthesis')

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/index',
        name: 'Index',
        component: Index,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: Home
            },
            {
                path: '/home-news',
                name: 'News',
                component: News,
                redirect: '/home-news-list',
                children: [
                    {
                        path: '/home-news-list',
                        name: 'NewsList',
                        component: NewsList
                    },
                    {
                        path: '/home-notice-list',
                        name: 'NoticeList',
                        component: NoticeList
                    },
                    {
                        path: '/home-activity-list',
                        name: 'ActivityList',
                        component: ActivityList
                    }
                ]
            },
            {
                path: '/home-article-details',
                name: 'ArticleDetails',
                component: ArticleDetails
            },
            {
                path: '/home-notice-details',
                name: 'NoticeDetails',
                component: NoticeDetails
            },
            {
                path: '/home-activity-details',
                name: 'ActivityDetails',
                component: ActivityDetails
            },
            {
                path: '/product',
                name: 'Product',
                component: Product
            },
            {
                path: '/product-details',
                name: 'ProductDetails',
                component: ProductDetails
            },
            {
                path: '/product-order',
                name: 'ProductOrder',
                component: ProductOrder
            },
            {
                path: '/product-ocr-order',
                name: 'ProductOcrOrder',
                component: ProductOcrOrder
            },

            {
                path: '/product-order-success',
                name: 'ProductOrderSuccess',
                component: ProductOrderSuccess
            },

            {
                path: '/download',
                name: 'Download',
                component: Download
            },
            {
                path: '/download-font',
                name: 'DownloadFont',
                component: DownloadFont
            },
            {
                path: '/download-open-type',
                name: 'DownloadOpenType',
                component: DownloadOpenType
            },
            {
                path: '/download-aat-type',
                name: 'DownloadAatType',
                component: DownloadAatType
            },
            {
                path: '/download-photoshop-type',
                name: 'DownloadPhotoshopType',
                component: DownloadPhotoshopType
            },

            {
                path: '/college',
                name: 'College',
                component: College
            },
            {
                path: '/college-problem',
                name: 'Problem',
                component: Problem
            },
            {
                path: '/college-problem-details',
                name: 'ProblemDetails',
                component: ProblemDetails
            },
            {
                path: '/college-feedback',
                name: 'Feedback',
                component: Feedback
            },
            {
                path: '/college-course',
                name: 'Course',
                component: Course
            },
            {
                path: '/college-course-details',
                name: 'CourseDetails',
                component: CourseDetails
            },

            {
                path: '/case',
                name: 'Case',
                component: Case
            },
            {
                path: '/case-details',
                name: 'CaseDetails',
                component: CaseDetails
            },



            {
                path: '/about',
                name: 'About',
                component: About
            },
            {
                path: '/about-recruit',
                name: 'AboutRecruit',
                component: AboutRecruit
            },
            {
                path: '/about-recruit-details',
                name: 'AboutRecruitDetails',
                component: AboutRecruitDetails
            },


            {
                path: '/login',
                name: 'Login',
                component: Login
            },
            {
                path: '/forget-password',
                name: 'ForgetPassword',
                component: ForgetPassword
            },
            {
                path: '/reset-password',
                name: 'ResetPassrord',
                component: ResetPassrord
            },

            {
                path: '/register',
                name: 'Register',
                component: Register
            },
            {
                path: '/clause',
                name: 'Clause',
                component: Clause
            },
            {
                path: '/privacy',
                name: 'Privacy',
                component: Privacy
            },


            {
                path: '/weChat-register',
                name: 'WeChatRegister',
                component: WeChatRegister
            },

            {
                path: '/order-details',
                name: 'OrderDetails',
                component: OrderDetails
            },

            {
                path: '/activity-red-packet',
                name: 'ActivityRedPacket',
                component: ActivityRedPacket
            },
            {
                path: '/activity-wps-welfare',
                name: 'ActivityWpsWelfare',
                component: ActivityWpsWelfare
            },
            {
                path: '/activity-wps-order',
                name: 'ActivityWpsOrder',
                component: ActivityWpsOrder
            },


            {
                path: '/personal',
                component: Personal,
                name: 'Personal',
                redirect: '/personal/core',
                children: [
                    {
                        path: 'core',
                        name: 'Core',
                        component: Core
                    },
                    {
                        path: 'information',
                        name: 'Information',
                        component: Information
                    },
                    {
                        path: 'order',
                        name: 'Order',
                        component: Order
                    },
                    {
                        path: 'serve',
                        name: 'Serve',
                        component: Serve
                    },
                    {
                        path: 'purchase',
                        name: 'Purchase',
                        component: Purchase
                    },
                    {
                        path: 'news',
                        name: 'PersonalNews',
                        component: PersonalNews
                    },
                    {
                        path: 'ocr',
                        name: 'PersonalOCR',
                        component: PersonalOCR
                    },
                    {
                        path: 'com-service',
                        name: 'PersonalComService',
                        component: PersonalComService
                    },

                ]
            },
            {
                path: '/attestation',
                name: 'Attestation',
                component: Attestation
            },
            {
                path: '/protocol-font',
                name: 'ProtocolFont',
                component: ProtocolFont
            },
            {
                path: '/font-show',
                name: 'FontShow',
                component: FontShow
            },
            {
                path: '/archive',
                name: 'Archive',
                component: Archive
            },

        ]
    },
    {
        path: '/app',
        name: 'Application',
        component: Application,
        children:[
            {
                path: '/app-translate',
                name: 'ApplicationTranslate',
                component: ApplicationTranslate
            },
            {
                path: '/app-menkocr',
                name: 'ApplicationMenkocr',
                component: ApplicationMenkocr
            },
            {
                path: '/app-dictionary',
                name: 'ApplicationDictionary',
                component: ApplicationDictionary
            },
            {
                path: '/app-recognition',
                name: 'ApplicationVoiceRecognition',
                component: ApplicationVoiceRecognition
            },
            {
                path: '/app-conversion',
                name: 'ApplicationConversion',
                component: ApplicationConversion
            },
            {
                path: '/app-convert-mongolia',
                name: 'ApplicationConversionMongolia',
                component: ApplicationConversionMongolia
            },

            {
                path: '/app-proof',
                name: 'ApplicationProof',
                component: ApplicationProof
            },
            {
                path: '/app-picture-create',
                name: 'ApplicationPictureCreate',
                component: ApplicationPictureCreate
            },
            {
                path: '/app-synthesis',
                name: 'ApplicationSynthesis',
                component: ApplicationSynthesis
            },


        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

