<template>
    <div  id="applicationBox"
        v-loading="loading"
        element-loading-text="内容合成中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
    >
        <div class="w12 h100 SynthesisBox">
            <div class="SynthesisLeft">
                <!-- <div class="synthesis-left-top">
                    <div class="synthesis-announcer">
                        <p>配音员选择：</p>
                        <p v-if="speaker == -1">请在配音员列表里选择...</p>
                        <span v-else>{{speaker === 0 ? '女生' : '男生'}}</span>
                    </div>
                    <div class="synthesis-now" @click="enterSynthesis">
                        立即合成
                    </div>
                </div> -->
                <div class="synthesis-left-middle">
                    <div class="synthesis-left-middle-t">
                        <el-input
                            type="textarea"
                            class="h100 menk"
                            v-model="content"
                            placeholder="    "
                            :maxlength="maxlength"
                        ></el-input>
                    </div>
                    <div class="synthesis-left-middle-b">{{nowNumm}}/{{maxlength}}</div>
                </div>
                <div class="synthesis-left-bottom">
                    <!-- <div class="synthesis-audio-box">
                        <audio
                            controls
                            src=""
                        ></audio>
                    </div> -->
                    <div class="synthesis-now" @click="enterSynthesis">
                        立即合成
                    </div>
                    <a :href="audioSrc" class="download-audio" :class="{'haveNews': hasNews}" target="_blank">下载音频</a>
                </div>
            </div>
            <!-- <div class="SynthesisRight">
                <div class="synthesis-announcer-title">配音员</div>
                <div class="announcer-list">
                    <div class="announcer-li" @click.stop="choseAnnouncer(1)"  :class="{'active':speaker == 1}">
                        <div class="auditionBox" @click="listen(1)">
                            <i class="el-icon-video-play"></i>
                            <span>试听</span>
                        </div>
                        <img src="~assets/images/product/announcerHeadImg1.png" alt="">
                        <div class="announcer-news">
                            <h4>巴特尔</h4>
                            <p>男生</p>
                            <span>磁性有力，标准男音</span>
                        </div>
                    </div>
                    <div class="announcer-li"  @click.stop="choseAnnouncer(0)" :class="{'active':speaker == 0}">
                        <div class="auditionBox" @click="listen(0)">
                            <i class="el-icon-video-play"></i>
                            <span>试听</span>
                        </div>
                        <img src="~assets/images/product/announcerHeadImg2.png" alt="">
                        <div class="announcer-news">
                            <h4>苏日娜</h4>
                            <p>女生</p>
                            <span>自然诉说，通用女音</span>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import {synthesis} from 'api/product'
import { isMgl, isMkMgl } from "utils/fontCodeType";
export default {
    data() {
        return {
            loading:false,
            productId:'',
            speaker:0,
            content:'',
            nowNumm:0,
            maxlength:100,
            hasNews:false,
            audioSrc:''
        }
    },
    created(){
        this.productId = this.$route.query.productId
    },
    methods:{
        download(){
            console.log(this.audioSrc)
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.style.height = 0;
            iframe.src = this.audioSrc;
            document.body.appendChild(iframe);
            setTimeout(() => {
                iframe.remove();
            }, 5 * 60 * 1000);
        },
        enterSynthesis(){
            this.hasNews = false
            if(this.content == ''){
                this.$message({
                    type:'warning',
                    message:'请输入内容...'
                })
            }else{
                //是否是蒙古文字
                if (!isMkMgl(this.content) && !isMgl(this.content)) {
                    this.$message({
                        type: "error",
                        message: "请输入蒙古文！",
                    });
                    return false;
                } else {
                    this.loading = true
                    synthesis(this.content,this.productId,this.speaker).then(res => {
                        if(res.success){
                            this.audioSrc = res.body.data
                            this.hasNews = true
                            this.loading = false
                            this.$message({
                                type:'success',
                                message:'已经成功合成，可以下载啦...'
                            })
                        }else{
                            this.$message.error(res.msg)
                            this.loading = false
                        }
                    })
                }
            }
        },
        choseAnnouncer(idx){
            this.speaker = idx
        },
        listen(idx){
            this.speaker = idx
        }
    },
    watch:{
        content(value) {
            this.nowNumm = value.length;
            if (value.length == this.maxlength) {
                this.$message({
                    message: `翻译内容最大长度是${this.maxlength}个字符！`,
                    type: "warning",
                    duration: 5000,
                });
            }
        },
    }
}
</script>
<style>
.synthesis-left-middle-t .h100 textarea {
    height: 100%;
    font-size: 16px;
    color: #252b3a;
    border: 0 !important;
    padding: 20px 0 !important;
}
.menk textarea {
    text-align: justify;
    -webkit-text-orientation: sideways-right;
    text-orientation: sideways-right;
    display: table-column;
    font-family: 'Menksoft2012';
    -webkit-writing-mode: vertical-lr !important;
    writing-mode: vertical-lr !important;
    font-size: 22px !important;
}
</style>
<style lang="less" scoped>
.menk {
    -webkit-writing-mode: vertical-lr;
    -webkit-text-orientation: sideways-right;
    writing-mode: vertical-lr;
    text-orientation: sideways-right;
}
.auditionBox{
    position: absolute;
    right: 12px;
    top: 10px;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #252b3a;
    cursor: pointer;
    i{
        font-size: 18px;
    }
    span{
        font-size: 12px;
    }
}
.announcer-news{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    color: #252b3a;
    h4{
        font-size: 14px;
        font-weight: bold;
    }
    p{
        margin: 6px 0;
        font-size: 16px;
    }
    span{
        line-height: 18px;
        font-size: 12px;
    }
}
.announcer-li{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px 0 16px;
    position: relative;
    height: 108px;
    border: solid 1px #e5e5e5;
    border-radius: 6px;
    margin-bottom: 12px;
    background: #fff;
    cursor: pointer;
    >img{
        margin-right: 10px;
    }
}
.announcer-li.active{
    border-color: #004b9f;
}
.announcer-list{
    height: calc(100% - 32px);
    overflow-y: auto;
}
.synthesis-announcer-title{
    height: 32px;
    font-size: 18px;
    font-weight: bold;
    color: #252b3a;
}
.synthesis-left-bottom{
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    // justify-content: space-between;
    align-items: center;
    height: 66px;
    // height: 124px;
    border-top: solid 1px #e5e5e5;
    .download-audio{
        width: 168px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 16px;
        border: solid 1px #e4e7ed;
        background-color: #f5f7fa;
        color: #c0c4cc;
        cursor: pointer;
        margin: 0 20px;
    }
    .download-audio.haveNews{
        color: #004b9f;
        border: solid 1px #004b9f;
        background: #fff;
    }
}
.synthesis-left-middle{
    height: calc(100% - 66px);
    // height: calc(100% - 176px);
    .synthesis-left-middle-t{
        padding: 0 20px;
        height: calc(100% - 32px);
    }
    .synthesis-left-middle-b{
        padding: 0 20px;
        height: 32px;
        line-height: 32px;
        background: #eef6fb;
        text-align: right;
        font-size: 16px;
        color: #252b3a;
    }
}
.synthesis-left-top{
    padding: 0 30px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #e5e5e5;
    .synthesis-announcer{
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
            font-size: 16px;
            color: #252b3a;
        }
        span{
            height: 26px;
            line-height: 26px;
            padding: 0 24px;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            background: #004b9f;
            border-radius: 13px;
            cursor: pointer;
        }
    }

}
.synthesis-now{
    width: 172px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    background: #004b9f;
    cursor: pointer;
}
.SynthesisBox{
    padding: 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .SynthesisLeft{
        // width: 876px;
        width: 100%;
        height: 100%;
        border: solid 1px #e5e5e5;
        border-radius: 8px;
        background: #fff;
        display: flex;
        flex-direction: column;
    }
    .SynthesisRight{
        width: 304px;
        height: 100%;
    }
}
#applicationBox{
    min-height: 600px;
}
</style>
