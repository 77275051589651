<template>
    <div
        class="main-container overflowh"
        style="background: #f3f5f8"
        v-loading="loading"
        element-loading-text="正在注册中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
    >
        <div class="w12 registerBox">
            <div class="register-title-box">
                <div class="title">完善用户信息</div>
            </div>
            <div class="register-content-box">
                <div class="registerTypeBox">
                    <div class="registerType active">
                        <img
                            src="~assets/images/register/personalActive.png"
                            alt=""
                        />
                        <span>用户完善信息</span>
                    </div>
                </div>
                <div>
                    <el-form
                        ref="enterpriseForm"
                        :model="enterpriseForm"
                        :rules="rules"
                        key="enterpriseForm"
                    >
                        <el-form-item prop="phone">
                            <el-input
                                v-model="enterpriseForm.phone"
                                :disabled="disabled"
                                placeholder="手机号"
                            >
                                <template slot="prepend">+86 V</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="message-form-mesasge" prop="code">
                            <el-input
                                v-model="enterpriseForm.code"
                                placeholder="请输入短信中的验证码"
                            ></el-input>
                            <el-button
                                @click.prevent="getMessageCode"
                                :disabled="tDisabled"
                                >{{
                                    tDisabled ? timeCount + "S" : "获取验证码"
                                }}</el-button
                            >
                        </el-form-item>
                        <el-form-item prop="organName">
                            <el-input
                                v-model="enterpriseForm.organName"
                                :disabled="disabled"
                                placeholder="请输入企业名称"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password" v-if="isFirst">
                            <el-input
                                v-model="enterpriseForm.password"
                                :disabled="disabled"
                                placeholder="设置密码，6-12字符,以字母开头，包含字母、数字和下划线"
                                type="password"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="comfirePass" v-if="isFirst">
                            <el-input
                                v-model="enterpriseForm.comfirePass"
                                :disabled="disabled"
                                placeholder="确认密码"
                                type="password"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="email">
                            <el-input
                                v-model="enterpriseForm.email"
                                :disabled="disabled"
                                placeholder="邮箱"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item class="agreement_item">
                            <el-checkbox v-model="isRead" :disabled="disabled"
                                >阅读并同意</el-checkbox
                            >
                            <em>蒙科立</em>
                            <span class="agreement pointer" @click="openClause"
                                >“服务条款”</span
                            >
                            <em>和</em>
                            <span class="agreement pointer" @click="openPrivacy"
                                >“隐私权政策”</span
                            >
                        </el-form-item>
                        <el-form-item class="messageLogin">
                            <el-button
                                type="primary"
                                @click="handleRegister('enterpriseForm')"
                                :disabled="btnDisabled"
                                >{{ isFirst ? "注册" : "绑定" }}</el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import { beforeEncrypt, desDecryptData } from "@/utils/encryptData.js";
import { isvalidPass, isvalidPhone, isvalidEmail } from "@/utils/validate.js";
import { isExistAccount, bindAccount, information } from "@/api/weChatRegister";
import { getCode } from "@/api/register";
import { getPublicKey } from "api/perCheck";
export default {
    computed: {
        weChatInfo() {
            return PcCookie.get(Key.weChatInfoKey)
                ? JSON.parse(PcCookie.get(Key.weChatInfoKey))
                : null;
        },
    },
    data() {
        var validPhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号码"));
            } else if (!isvalidPhone(value)) {
                callback(new Error("请填写正确的手机号码"));
            } else {
                let formStr = this.enterpriseForm.phone;
                const phoneForm = beforeEncrypt(
                    16,
                    this.publicKey,
                    this.rsKey,
                    formStr
                );
                isExistAccount(
                    phoneForm.formDes,
                    phoneForm.rsKey,
                    phoneForm.secretKey
                ).then((response) => {
                    const data = response.body.data.data;
                    const responseData = desDecryptData(data, phoneForm.keys);
                    if (responseData.success) {
                        this.$confirm(responseData.msg, "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                            showClose: false,
                            closeOnClickModal: false,
                            closeOnPressEscape: false,
                            closeOnHashChange: false,
                        })
                            .then(() => {
                                this.disabled = true;
                                this.isFirst = false;
                                this.memberInfo =
                                    responseData.body.data.memberInfo;
                                this.enterpriseForm.phone =
                                    this.memberInfo.phone;
                                this.enterpriseForm.email =
                                    this.memberInfo.email;
                                this.enterpriseForm.organName =
                                    this.memberInfo.organName;
                                this.enterpriseForm.creditCode =
                                    this.memberInfo.creditCode;
                            })
                            .catch(() => {
                                this.isFirst = true;
                                this.$notify({
                                    message: "请选择其他手机号进行微信绑定！",
                                    type: "warning",
                                });
                                this.enterpriseForm.phone = "";
                            });
                    } else {
                        callback();
                    }
                });
            }
        };
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else if (!isvalidPass(value)) {
                callback(
                    new Error(
                        "密码以字母开头 长度在6~12之间 只能包含字母、数字和下划线"
                    )
                );
            } else {
                callback();
            }
        };
        var validatecomfirePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入确认密码"));
            } else if (value !== this.enterpriseForm.password) {
                callback(new Error("两次输入密码不一致！"));
            } else {
                callback();
            }
        };
        var validEmail = (rule, value, callback) => {
            if (value === "") {
                callback();
            } else if (!isvalidEmail(value)) {
                callback(new Error("请输入正确的邮箱"));
            } else {
                callback();
            }
        };
        var validCode = (rule, value, callback) => {
            if (value === "" || value.length != 6) {
                callback(new Error("请输入短信中的6位数验证码"));
            } else {
                callback();
            }
        };
        var validCreditCode = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请填写18位统一社会信用代码"));
            } else {
                if (value.length != 18) {
                    callback(new Error("请填写18位统一社会信用代码"));
                } else {
                    callback();
                }
            }
        };
        return {
            loading: false,
            timerCheck: null,
            enterpriseForm: {
                memberType: "1",
                organName: "",
                creditCode: "",
                password: "",
                comfirePass: "",
                phone: "",
                email: "",
                code: "",
                uuId: "",
                linkSuffix: PcCookie.get(Key.shareCodeKey)
                    ? PcCookie.get(Key.shareCodeKey)
                    : "",
            },
            disabled: false,
            isRead: false,
            isClause: false,
            isPrivacy: false,
            isFirst: true,
            memberInfo: {},
            publicKey: "",
            redisKey: "",
            btnDisabled: false,
            tDisabled: false,
            timeCount: 60,
            rules: {
                organName: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur",
                    },
                ],
                creditCode: [
                    {
                        required: true,
                        validator: validCreditCode,
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        validator: validatePass,
                        trigget: "blur",
                    },
                ],
                comfirePass: [
                    {
                        required: true,
                        validator: validatecomfirePass,
                        trigget: "blur",
                    },
                ],
                phone: [
                    { required: true, validator: validPhone, trigger: "blur" },
                ],
                email: [
                    { required: true, validator: validEmail, trigger: "blur" },
                ],
                code: [
                    { required: true, validator: validCode, trigger: "blur" },
                ],
            },
        };
    },
    mounted() {
        let that = this;
        this.timerCheck = window.setInterval(() => {
            setTimeout(() => {
                that.getKey();
            }, 0);
        }, 1500000);
    },
    beforeDestroy() {
        let that = this;
        window.clearInterval(that.timerCheck);
    },
    created() {
        this.enterpriseForm.uuId = this.$route.query.uuId;
        this.getKey();
    },
    methods: {
        handleRegister(formName) {
            this.loading = true;
            if (this.isFirst) {
                //完善用户信息
                if (!this.isRead) {
                    this.$message({
                        message: "请认真阅读蒙科立“服务条款”和“隐私权政策”",
                        type: "warning",
                    });
                    this.loading = false;
                } else {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            this.disabled = true;
                            this.btnDisabled = true;
                            this.tDisabled = true;
                            const form = this.enterpriseForm;
                            const newsData = {
                                form: form,
                            };
                            let formStr = JSON.stringify(newsData);
                            const inForm = beforeEncrypt(
                                16,
                                this.publicKey,
                                this.rsKey,
                                formStr
                            );
                            information(
                                inForm.formDes,
                                inForm.rsKey,
                                inForm.secretKey
                            ).then((res) => {
                                if (res.success) {
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                        duration: 1500,
                                    });
                                    setTimeout(() => {
                                        this.$router.push("/login");
                                    }, 2000);
                                    this.loading = false;
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: res.msg,
                                        duration:1000
                                    });
                                    setTimeout(()=>{
                                        this.loading = false;
                                        this.disabled = false;
                                        this.btnDisabled = false;
                                        if(this.timeCount == 0){
                                            this.tDisabled = false;
                                        }else{
                                            this.tDisabled = true;
                                        }
                                    },2000)
                                }
                            });
                        }
                    });
                }
            } else {
                //绑定用户信息
                this.btnDisabled = true;
                this.tDisabled = true;
                const form = this.enterpriseForm;
                const newsData = {
                    form: form,
                };
                let formStr = JSON.stringify(newsData);
                const inForm = beforeEncrypt(
                    16,
                    this.publicKey,
                    this.rsKey,
                    formStr
                );
                bindAccount(
                    inForm.formDes,
                    inForm.rsKey,
                    inForm.secretKey
                ).then((res) => {
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                            duration: 1500,
                        });
                        setTimeout(() => {
                            this.$router.push("/login");
                        }, 2000);
                        this.loading = false;
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.msg,
                            duration:1000
                        });
                        setTimeout(()=>{
                            this.loading = false;
                            this.btnDisabled = false;
                            if(this.timeCount == 0){
                                this.tDisabled = false;
                            }else{
                                this.tDisabled = true;
                            }
                        },2000)
                    }
                });
            }
        },
        //发送手机号验证码
        getMessageCode() {
            if (this.enterpriseForm.phone == "") {
                this.$message({
                    type: "warning",
                    message: "请填写手机号！",
                });
            } else {
                let formStr = this.enterpriseForm.phone;
                const phoneForm = beforeEncrypt(
                    16,
                    this.publicKey,
                    this.rsKey,
                    formStr
                );
                getCode(
                    phoneForm.formDes,
                    phoneForm.rsKey,
                    phoneForm.secretKey
                ).then((res) => {
                    this.tDisabled = true;
                    if (res.success) {
                        this.countDown();
                    } else {
                        this.tDisabled = false;
                        this.timeCount = 60;
                        this.countDown();
                    }
                    this.$message({
                        type: res.success ? "success" : "warning",
                        message: res.msg,
                    });
                });
            }
        },
        openClause() {
            this.isClause = true;
            let routeData = this.$router.resolve({ path: "/clause" });
            window.open(routeData.href, "_blank");
        },
        openPrivacy() {
            this.isPrivacy = true;
            let routeData = this.$router.resolve({ path: "/privacy" });
            window.open(routeData.href, "_blank");
        },
        getKey() {
            getPublicKey().then((res) => {
                if (res.success) {
                    const { body } = res;
                    this.publicKey = body.data.publicKey;
                    this.rsKey = body.data.redisKey;
                }
            });
        },
        //倒计时60秒
        countDown() {
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.timeCount = TIME_COUNT;
                this.tDisabled = true;
                this.timer = setInterval(() => {
                    if (this.timeCount > 0 && this.timeCount <= TIME_COUNT) {
                        this.timeCount--;
                    } else {
                        this.tDisabled = false;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        },
    },
};
</script>
<style lang="less">
.creditCodeBox .el-input {
    width: 186px;
    margin-right: 10px;
}
.creditCodeBox p{
    display: inline-block;
    >span{
        color: blue;
    }
    padding: 10px 9px 10px 5px;
    cursor: pointer;
}
.message-form-mesasge .el-input {
    width: 242px;
    margin-right: 10px;
}
.message-form-mesasge .el-button {
    width: 102px;
    padding: 12px 15px;
    font-weight: bold;
}
.register-content-box .el-upload--text i {
    font-size: 28px;
    color: #8c939d;
}
.register-content-box .el-upload--text img {
    max-width: 100%;
    max-height: 100%;
}
.register-content-box .el-upload--text {
    width: 100%;
    height: 156px;
    line-height: 156px;
    background: #f4f4f4;
    border: 1px dashed #c0ccda;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.register-content-box .el-upload__tip {
    text-align: center;
    font-size: 12px;
    color: #9a9a9a;
    text-align: center;
}
</style>
<style lang="less" scoped>
.registerTypeBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-bottom: 12px;
    .r-bar1 {
        margin: 0 20px;
        height: 24px;
        width: 1px;
        background: #858a97;
    }
    .registerType {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        img {
            margin-right: 8px;
        }
        span {
            padding-top: 5px;
            font-size: 16px;
            color: #ccc;
            font-weight: bold;
        }
    }
    .registerType.active {
        span {
            font-size: 16px;
            color: #13227a;
        }
    }
}
.messageLogin {
    margin: 0 !important;
}
.messageLogin .el-form-item__content {
    margin: 0 !important;
}
.messageLogin .el-form-item__content .el-button {
    background: #1b4094;
    border-color: #1b4094;
    width: 100%;
}
.agreement_item {
    font-style: 15px;
    color: #858a97;
    em {
        font-style: normal;
    }
    span {
        margin: 0 4px;
        color: #1b4094;
    }
}
.agreement_item em {
    font-style: normal;
}
.register-content-box {
    padding: 30px 48px;
    width: 450px;
    margin: 0 auto;
}
.register-title-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 48px;
    border-bottom: solid 1px #eeeeee;
    .title {
        font-size: 25px;
        font-weight: bold;
        color: #252b3a;
    }
    .enterLogin {
        font-size: 16px;
        color: #6f7583;
        span {
            color: #1b4094;
        }
    }
}
.registerBox {
    margin: 30px auto;
    padding-bottom: 48px;
    background: #fff;
}
</style>
