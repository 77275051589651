<template>
    <div id="footer">
        <div class="footer-top-container">
            <div class="w12 h100">
                <div class="companyNews">
                    <div>
                        <p>客服电话：0471—6929536</p>
                    </div>
                    <div>
                        <img src="~assets/images/fEmailImg.png" style="float:left;" alt="">
                    </div>
                    <div>
                        <p>地址：呼和浩特市赛罕区机场高速路128号低碳大厦7层</p>
                    </div>
                    <div>
                        <p>
                            <img src="~assets/images/gaImg.png" class="gaImg" alt="">
                            <span class="pointer" @click="enterLink('http://www.beian.gov.cn/')">蒙公网安备15010502000755号</span>
                            <span class="pointer" @click="enterLink('https://beian.miit.gov.cn')">蒙ICP备16000343号-6</span>
                            <span>蒙ISP-20090006</span>
                        </p>
                    </div>
                </div>
                <div class="img">
                    <img src="~assets/images/gghImg.png" alt="">
                    <p>微信公众号</p>
                </div>
            </div>
        </div>
        <div class="copyright">版权所有：内蒙古蒙科立蒙古文化股份有限公司</div>
    </div>
</template>

<script>
export default {
    methods:{
        enterLink(url){
            window.open(url, "_blank");
        }
    }
}
</script>

<style scoped lang="less">
.gaImg{
    float: left;
    margin: 6px 8px 0 0;
}
.copyright {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8e8e8e;
}
.companyNews {
    display: flex;
    flex-direction: column;
    div{
        line-height: 36px;
        p{
            font-size: 14px;
            font-family: "思源黑体";
            color: #d2d3d3;
            span{
                margin-right: 20px;
            }
        }
    }
}
.w12 {
    height: 158px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .img{
        text-align: center;
        img{
            border: solid 7px #fff;
        }
        p{
            margin-top: 8px;
            font-size: 14px;
            font-family: "思源黑体";
            color: #d2d3d3;
        }
    }
}
.footer-top-container {
    /* height: 157px; */
    border-bottom: solid 1px #373838;
}
#footer {
    background: #474a49;
}
</style>
