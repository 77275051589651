import DES from "@/utils/cryptoDES.js";
import JSEncrypt from 'jsencrypt/bin/jsencrypt'
//预登录、预注册数据加密处理
export function beforeEncrypt(desKeysNum,publicKey,rsKey,dataStringify) {
    let keys = DES.generatekey(desKeysNum);
    let formDes = DES.encrypt(dataStringify,keys)
    let encryptor = new JSEncrypt()
    encryptor.setPublicKey(publicKey)
    let secretKey = encryptor.encrypt(keys)
    let formData = {
        formDes,
        rsKey,
        secretKey,
        keys
    }
    return formData
}
//登录后数据加密
export function afterEncrypt(desKeysNum,publicKey,dataStringify) {
    let keys = DES.generatekey(desKeysNum);
    let formDes = DES.encrypt(dataStringify,keys)
    let encryptor = new JSEncrypt()
    encryptor.setPublicKey(publicKey)
    let secretKey = encryptor.encrypt(keys)
    let formData = {
        formDes,
        secretKey,
        keys
    }
    return formData
}

//解密DES后台加密返回数据
export function desDecryptData(word, keyStr) {
    let resData = JSON.parse(DES.decrypt(word,keyStr))
    return resData
}



