<template>
    <div id="homeMarket">
        <img src="~assets/images/earth1.png" alt="" class="earth">
        <div class="w12 h100 homeMarket" style="z-index:99">
            <div class="title">
                <div class="cmNameBox">
                    <h4 class="menkTitle">    <br/>    <br/></h4>
                    <p>市场覆盖和分布</p>
                </div>
                <span class="pointer" @click="enterAbout">了解更多蒙科立信息>></span>
            </div>
            <!-- <div class="numberBox"></div> -->
            <div class="pictureBox">
                <img src="~assets/images/map2.png" class="img1" alt="">
                <img src="~assets/images/map1.png" class="img2" alt="">
            </div>
            <div class="authenticationBox">
                <div class="h100">
                    <img src="~assets/images/rzImg1.png" alt="">
                    <p>ISO27001</p>
                </div>
                <div class="h100">
                    <img src="~assets/images/rzImg2.png" alt="">
                    <p>ISO9001</p>
                </div>
                <div class="h100">
                    <img src="~assets/images/rzImg3.png" alt="">
                    <p>知识产权管理体系认证</p>
                </div>
                <div class="h100">
                    <img src="~assets/images/rzImg4.png" alt="">
                    <p> CS等级证书</p>
                </div>
                <div class="h100">
                    <img src="~assets/images/rzImg5.png" alt="">
                    <p>信息安全服务资质认证</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        enterAbout() {
            this.$router.push('/about')
        }
    }
}
</script>
<style lang="less" scoped>
.authenticationBox{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 192px;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgb(212 217 222);
    .h100{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            margin-bottom: 10px;
        }
    }
}

.pictureBox{
    margin-top: 126px;
    height: 452px;
    overflow: hidden;
    .img2{
        margin-left: 66px;
        opacity: 0.8;
    }
    .img1{
        float: left;
        margin-top: 80px;
        width: 450px;
    }
}
.numberBox{
    width: 900px;
    margin: 66px auto 0;
    height: 66px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
     color: #252b3a;
    .number{
        display: flex;
        flex-direction: column;
        p{
            font-size: 35px;
            font-weight: bold;
        }
        span{
        font-size: 18px;
    }
    }
}
.title{
    margin-top: 100px;
    height: 66px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #252b3a;
    justify-content: space-between;
    .cmNameBox{
        display: flex;
        flex-direction: row;
        h4{
            margin-right: 12px;
        }
    }
    p{
        font-size: 35px;
    }
    span{
        font-size: 18px;
    }
}
.earth{
    position: absolute;
    left: 50%;
    top: 166px;
    margin-left: -721px;
    z-index: 3;
    opacity: 0.3;
}
.homeMarket{
    width:1200px;
    position: absolute;
    left: 50%;
    margin-left: -600px;
    z-index: 6;
}
#homeMarket{
    position: relative;
    height: 1000px;
    background: #f3f5f8;
    overflow: hidden;
}
</style>
