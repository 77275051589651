<template>
    <div class="home">
        <Header />
        <router-view></router-view>
        <Footer />
        <el-backtop :bottom="200"></el-backtop>
    </div>
</template>
<script>
import VueEvent from "@/VueEvent.js";
import Header from "@/components/contents/header";
import Footer from "@/components/contents/footer";
export default {
    name: "Index",
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙科立，蒙科立综合服务，蒙科立输入法，蒙古文AI人工智能",
            },
        ],
    },
    inject: ["reload"],
    components: { Header, Footer },
    data() {
        return {
        };
    },
    mounted() {
        VueEvent.$on("login", () => {
            this.reload();
        });
        VueEvent.$on("logout", () => {
            this.reload();
        });
        VueEvent.$on("attestation", () => {
            this.reload();
        });
        VueEvent.$on("cooperation", () => {
            this.reload();
        });
    },

};
</script>

<style>
/*css 引入方式*/
@import "../assets/css/base.css";
</style>
