<template>
    <div id="applicationBox"
        v-loading="loading"
        element-loading-text="内容识别中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
    >
        <div class="codeImgBox" v-if="isPay">
            <div class="payImgBox">
                <p>
                    三分钟倒计时: <em>00:{{ minute }}:{{ second }}</em>
                </p>
                <img :src="payImg" alt="" class="payImg" />
                <span>提示：请在三分钟之内进行微信支付</span>
                <h4>套餐已核减，另需支付超出部分费用，请勿关闭此窗口或刷新页面，支付完成后可查看完整结果。</h4>
            </div>
        </div>
        <div class="w12 h100">
            <div class="recordeBox">
                <div class="recordeTitleBox">
                    <p>语音识别</p>
                    <p>识别结果</p>
                </div>
                <div class="recorde-handle-box">
                    <!--按钮容器-->
                    <div class="r-btn-box">
                        <div class="outBar10">
                            <div
                                class="middelBar3"
                                :class="{ animal: isBegin }"
                            ></div>
                        </div>
                    </div>
                    <div class="r-trueBtn" v-if="isRecorde && isBegin" @click="stopRecorde">
                        停止<br />录音
                    </div>
                    <div
                        class="r-trueBtn"
                        v-if="!isRecorde && !isBegin"
                        @click="handleStart"
                    >
                        开始<br />录音
                    </div>
                    <div class="r-trueBtn" v-if="isFinished" @click="goDiscern">
                        开始<br />识别
                    </div>
                    <div class="recorde-news-box bright1">
                        <div class="r-type-box">
                            <p>类别：</p>
                            <span>蒙古语</span>
                        </div>
                        <div class="r-clear-box">
                            <i
                                class="el-icon-close"
                                v-if="isFinished"
                                @click="clearAudio"
                            ></i>
                        </div>
                        <div class="r-list-box">
                            <p v-if="isRecorde && !isFinished">正在录制...{{number}}S</p>
                            <img
                                v-if="isRecorde && !isFinished"
                                src="~assets/images/product/recordeImg.png"
                                alt=""
                            />
                            <p v-if="!isRecorde && isFinished">上传文件</p>
                            <audio
                                id="audioVoice"
                                controls
                                :src="audioSrc"
                                v-if="isFinished && !isIeWeb"
                            ></audio>
                        </div>
                        <div class="r-notes-box">
                            <div>
                                <span>*</span>
                                <p>
                                    点击<em>“开始录音”</em>开始录音，请对我说你想说的话，我可以识别你说的内容哦~请允许浏览器获取麦
                                    克风权限
                                </p>
                            </div>
                            <div>
                                <span>*</span>
                                <p>
                                    <em>“上传音频”</em>文件大小不能超过2M
                                    音频格式：wav（不压缩、pcm编码）或pcm，采样率：16000
                                    16bit 单声道
                                </p>
                            </div>
                            <div class="r-upload-btn-box">
                                <div class="ieUploadBox" @click="ieUpload">
                                    <i class="el-icon-upload ieUploadIcon" v-if="isIeWeb"></i>
                                    <p v-if="isIeWeb" class="ieUploadText">上传音频</p>
                                </div>
                                <el-upload
                                    v-if="!isIeWeb"
                                    action="#"
                                    :http-request="uploadAudio"
                                    :show-file-list="false"
                                    :before-upload="beforeAvatarUpload"
                                >
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">上传音频</div>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                    <div class="recorde-news-box bleft1">
                        <div class="r-discern-text  menkTitle">
                            <p v-if="showResultTxt">{{ showText }}</p>
                        </div>
                        <el-row type="flex" justify="end" class="elRowBox">
                            <el-tooltip
                                v-if="showText.length != 0"
                                class="item pointer"
                                effect="dark"
                                content="复制识别结果文字"
                                placement="top"
                            >
                                <i
                                    id="result"
                                    class="el-icon-document-copy"
                                    :data-clipboard-text="showText"
                                    @click="copytext()"
                                ></i>
                            </el-tooltip>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * 重要提示：请用360极速模式，谷歌浏览器，火狐等浏览器，兼容模式不好使
 * 1.初始化的时候显示开始录音 - isRecorded => true
 * 2.如果点击开始录音
 *      2.1. 检测是否有麦克风，有——> 进行2.2 无——> return false）
 *      2.2. 进行录音，倒计时30秒 ，倒计时结束之前显示正在录音及图片
 *      2.3. 30秒结束——》停止录音 -》 把录音的base64赋给audio标签（用isFinished判断） -》 显示《开始识别》按钮
 *      2.4. 开始识别-》调用接口 -》成功 ->
 *      2.5. 调用付款接口 -> 付完款 -》 显示右侧识别结果
 *      2.6. 允许复制
 * 3.上传音频
 *      3.1. 上传音频 -》 符合要求 3.2. 不符合 rerutn false
 *      3.2. 隐藏开始录音，显示开始识别，把附件的base64赋给audio标签 （用isFinished判断）
 *      2.4.
 *      2.5.
 *      2.6.
 *
 */
import Recorder from "js-audio-recorder";
import Clipboard from "clipboard";
import { recordeAf, socrlt } from "api/product";
import { queryOrder } from "api/order";
export default {
    computed: {
        second: function () {
            return this.num(this.seconds);
        },

        minute: function () {
            return this.num(this.minMinutes);
        },
    },
    data() {
        return {
            loading:false,
            //是否录音
            isRecorde: false,
            //开始录音
            isBegin: false,
            isFinished: false,
            number: 30,
            payTimer: null,
            showText: "",
            audioSrc:'',
            productId: "0d6feabbe43c44859852941d58b0e81c",
            recorder: null,
            isIeWeb: false,
            isPay: false,
            payImg: require('@/assets/images/product/iosFontImg.png'),
            timer: null,
            seconds: 0,
            minMinutes: 3,
            rlt_key: "",
            orderId: "",
            showResultTxt: false,
        };
    },
    created() {
        //判断是否IE浏览器
        var userAgent = navigator.userAgent;
        if (
            (userAgent.indexOf("Trident") > -1 &&
                userAgent.indexOf("rv:11.0")) ||
            (userAgent.indexOf("compatible") > -1 &&
                userAgent.indexOf("MSIE") > -1)
        ) {
            this.isIeWeb = true;
            this.$notify({
                message:`该产品功能请谷歌浏览器、360极速浏览器、火狐浏览器使用！`,
                type: 'warning',
                duration:0
            });
        }
        else {
            this.isIeWeb = false;
        }
    },
    methods: {
        copytext() {
            let that = this;
            let clipboard = new Clipboard("#result");
            clipboard.on("success", () => {
                that.$message.success("复制成功！");
                clipboard.destroy();
            });
            clipboard.on("error", () => {
                that.$message.success("复制失败！");
                clipboard.destroy();
            });
        },
        goDiscern() {
            if (this.audioSrc.length > 0) {
                this.loading = true
                recordeAf(this.audioSrc, this.productId).then((res) => {
                    if (res.success && res.errorCode == "2000") {
                        if (res.body.shouldPay) {
                            //需再支付
                            this.isPay = true;
                            this.payImg = res.body.data.qrcode;
                            this.rlt_key = res.body.rlt_key;
                            this.orderId = res.body.data.id;
                            this.Countdown(this.orderId);
                            this.showResultTxt = false;
                        } else {
                            //无需再支付
                            this.showResultTxt = true;
                            this.loading = false
                            this.showText = res.body.data;
                        }

                    } else {
                        this.clearAudio()
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                        this.loading = false
                    }
                });
            } else {
                this.$message({
                    message: "请上传音频或进行录音后进行识别！",
                    type: "warning",
                });
            }
        },
        //清空录音或上传文件,并显示开始录音,把倒计时的number整到30
        clearAudio() {
            //如果是录音——销毁实例
            if (this.isRecorde) {
                this.handleDestroy();
            }
            this.audioSrc = "";
            this.isFinished = false;
            this.isBegin = false;
            this.isRecorde = false;
            this.number = 30;
            this.showText = ''
        },
        beforeAvatarUpload(file) {
            const testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const extension = testmsg === "wav";
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!extension) {
                this.$message.error("上传文件只能是wav格式!");
            } else {
                if (!isLt2M) {
                    this.$message.error("上传文件大小不能超过 2MB!");
                    return false;
                }
            }
        },
        uploadAudio(data) {
            this.showText = "";
            let that = this;
            let rd = new FileReader();
            let file = data.file;
            rd.readAsDataURL(file);
            rd.onloadend = function () {
                that.isFinished = true;
                that.audioSrc = this.result;
            };
        },
        ieUpload(){
            this.$notify({
                message:`该产品功能请谷歌浏览器、360极速浏览器使用。`,
                type: 'warning',
                duration:0
            });
        },
        // 开始录音
        handleStart() {
            if(this.isIeWeb){
                this.$notify({
                    message:`该产品功能请谷歌浏览器、360极速浏览器使用。`,
                    type: 'warning',
                    duration:0
                });
            }else{
                this.recorder = new Recorder({
                    sampleBits: 16,
                    sampleRate: 16000,
                    numChannels: 1,
                });
                Recorder.getPermission().then(
                    () => {
                        this.isRecorde = true;
                        this.isBegin = true;
                        this.countDownNum();
                        this.recorder.start();
                    },
                    () => {
                        this.$message({
                            message: "找不到录音设备",
                            type: "error",
                        });
                    }
                );
            }
        },
        //停止录音按钮事件
        stopRecorde(){
            let  that = this
            window.clearInterval(that.payTimer);
            that.isBegin = false;
            that.isFinished = true;
            that.number = 30;
            this.handleStop()
        },
        // 停止录音
        handleStop() {
            this.recorder.stop();
            let blob = this.recorder.getWAVBlob();
            let file = new File([blob], "recorded.wav", { type: "audio/wav" });
            let reader = new FileReader();
            let that = this;
            reader.readAsDataURL(file);
            reader.onloadend = function (res) {
                //赋值完，可以播放audio
                that.audioSrc = res.target.result;
            };
        },
        // 毁实例
        handleDestroy() {
            this.recorder.destroy();
        },
        countDownNum() {
            let that = this;
            that.payTimer = window.setInterval(function () {
                that.number--;
                if (that.number == 0) {
                    window.clearInterval(that.payTimer);
                    that.isBegin = false;
                    that.isFinished = true;
                    that.handleStop();
                }
            }, 1000);
        },
        Countdown(id) {
            var _this = this;
            _this.timer = window.setInterval(function () {
                if (_this.seconds === 0 && _this.minMinutes !== 0) {
                    _this.seconds = 59;
                    _this.minMinutes -= 1;
                } else if (_this.minMinutes === 0 && _this.seconds === 0) {
                    _this.seconds = 0;
                    window.clearInterval(_this.timer);
                    _this.isPay = false;
                    _this.payImg = "";
                } else {
                    _this.seconds -= 1;
                }
                queryOrder(id).then((res) => {
                    if (res.success) {
                        if (res.body.data.status == "3") {
                            window.clearInterval(_this.timer);
                            socrlt(_this.rlt_key).then(res => {
                                if(res.success) {
                                    _this.showResultTxt = true;
                                }else{
                                    _this.$message({
                                        message: res.msg,
                                        type: "warning",
                                    });
                                }
                                _this.isPay = false;
                                _this.payImg = "";
                                _this.showResultTxt = true;
                                _this.showText = res.body.data
                                _this.loading = false
                            })
                        }
                    }
                });
            }, 1000);
        },
        num(n) {
            return n < 10 ? "0" + n : "" + n;
        },
    },
    watch: {
        second: {
            handler(newVal) {
                this.num(newVal);
            },
        },
        minute: {
            handler(newVal) {
                this.num(newVal);
            },
        },
    },
    beforeDestroy() {
        //关闭定时器
        if (this.timer) {
            clearInterval(this.timer);
        }
        if (this.payTimer) {
            clearInterval(this.payTimer);
        }
    },
};
</script>
<style lang="less">
.elRowBox {
    height: 14px;
}
.ieUploadIcon{
    font-size: 22px;
    color: #252b3a;
    margin-right: 4px;
}
.ieUploadText{
    font-size: 15px;
    color: #252b3a;
}
.ieUploadBox{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
.r-upload-btn-box .el-upload {
    display: flex;
    flex-direction: row;
    i {
        font-size: 22px;
        color: #252b3a;
        margin-right: 4px;
    }
    div {
        font-size: 15px;
        color: #252b3a;
    }
}
</style>
<style lang="less" scoped>
.codeImgBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.payImgBox {
    width: 370px;
    height: 444px;
    border: solid 1px #252b3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    img {
        border: solid 1px #ccc;
        width: 236px;
    }
    p {
        margin: 16px 0;
        font-size: 22px;
        color: #252b3a;
        em {
            color: #ff8610;
            font-style: normal;
        }
    }
    span {
        font-size: 16px;
        color: #252b3a;
        margin: 20px 0;
    }
    h4{
        padding: 0 20px;
        font-size: 18px;
        color: #d10e0e;
    }
}
</style>
<style lang="less" scoped>
.r-trueBtn {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 101;
    margin: -33px 0 0 -33px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    background: #1c4392;
    font-size: 16px;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
}
@-webkit-keyframes animal {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}
.r-btn-box {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99;
    margin: -46px 0 0 -46px;
    width: 92px;
    height: 92px;
    box-sizing: content-box;
    .outBar10 {
        width: 92px;
        height: 92px;
        border: solid 10px #ced8e7;
        border-radius: 50%;
        .middelBar3 {
            width: 70px;
            height: 70px;
            border: solid 3px #fff;
            border-right: solid 3px #ced8e7;
            border-radius: 50%;
        }
        .middelBar3.animal {
            -webkit-animation: animal 1s infinite linear;
            -webkit-transform-origin: center center;
            -ms-transform-origin: center center;
            transform-origin: center center;
        }
    }
}
.r-list-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        font-size: 16px;
        color: #252b3a;
        margin: 16px 0;
    }
}
.r-notes-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 156px;
    > div {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        color: #252b3a;
        line-height: 22px;
        span {
            margin-right: 4px;
        }
        em {
            font-style: normal;
            margin: 0 2px;
            color: #1b4094;
        }
    }
}
.r-clear-box {
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    i {
        font-size: 22px;
        color: #b0b0b0;
        cursor: pointer;
    }
}
.r-type-box {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-family: "思源黑体";
    p {
        color: #252b3a;
    }
    span {
        margin-left: 14px;
        width: 88px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        border-radius: 15px;
        background: #1b4094;
    }
}
.bright1 {
    border-right: solid 1px #fff;
    padding-right: 52px !important;
}
.bleft1 {
    border-left: solid 1px #e5e5e5;
    background: #fafafa;
    padding-left: 52px !important;
}
.recorde-news-box {
    display: flex;
    flex-direction: column;
    width: calc(50% - 1px);
    height: 100%;
    padding: 16px;
}
.r-discern-text {
    height: calc(100% - 20px);
    overflow-x: auto;
}
.recorde-handle-box {
    display: flex;
    flex-direction: row;
    height: calc(100% - 46px);
    border: solid 1px #e5e5e5;
    border-radius: 12px;
    background: #ffffff;
    position: relative;
}
.recordeTitleBox {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: bold;
    font-family: "思源黑体";
    color: #252b3a;
    height: 46px;
    p {
        width: 50%;
        padding-left: 16px;
    }
}
.recordeBox {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    height: 100%;
}
#applicationBox {
    min-height: 500px;
}
</style>
