<template>
    <div class="sceneBox">
        <div class="w12 h100">
            <div class="scene-title">
                <p class="menkTitle">   <br/></p>
                <span>应用场景</span>
            </div>
            <div class="scene-list">
                <scene>
                    <div v-for="(item, index) in scene" :key="item.id" class="item">
                        <scene-item v-if="(index % 2 ) == 0" top="0" :img="item.img">
                            <img :src="item.imgUrl" class="sceneImg"  slot="sceneImg" alt="">
                            <div class="title" slot="title">{{item.name}}</div>
                            <div class="text" slot="text">{{item.substance}}</div>
                        </scene-item>
                        <scene-item v-if="(index % 2 ) != 0" bottom="0" :img="item.img">
                            <img :src="item.imgUrl" class="sceneImg"  slot="sceneImg" alt="">
                            <div class="title" slot="title">{{item.name}}</div>
                            <div class="text" slot="text">{{item.substance}}</div>
                        </scene-item>
                    </div>
                </scene>
            </div>
        </div>
    </div>
</template>
<script>
import Scene from 'components/common/scene/Scene'
import SceneItem from 'components/common/scene/SceneItem'
export default {
    components: {Scene, SceneItem},
    props:{
        scene:{
            type:Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {

        }
    }
}
</script>
<style lang="less" scoped>
.sceneBox{
    height: 714px;
    background: #fff;
    .scene-title{
        height: 166px;
        padding-top: 66px;
        font-size: 35px;
        color: #252b3a;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        p{
            margin: 0 12px;
        }
    }
    .scene-list{
        height: 450px;
    }
}
</style>
