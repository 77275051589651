<template>
    <el-dialog
        :visible.sync="visible"
        width="468px"
        title="发票信息"
        :show-close="false"
        :before-close="handleClose"
        :close-on-click-modal="false"
        :lock-scroll="true"
        class="invoiceBox"
    >
        <el-form
            :model="formData"
            :rules="rules"
            ref="formData"
            label-width="120px"
            label-position="right"
            status-icon
        >
            <el-form-item label="发票抬头:" prop="status">
                <el-radio-group v-model="formData.status">
                    <!-- <el-radio :label="0">个人</el-radio> -->
                    <el-radio :label="1">单位</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="发票类型:"> 电子发票 </el-form-item>
            <el-form-item
                label="姓名:"
                prop="userName"
                v-if="formData.status === 0"
                key="userName"
            >
                <el-input v-model="formData.userName"></el-input>
            </el-form-item>
            <el-form-item
                label="单位名称:"
                prop="companyName"
                v-if="formData.status === 1"
                key="companyName"
            >
                <el-input v-model="formData.companyName"></el-input>
            </el-form-item>
            <el-form-item
                label="纳税人识别号:"
                prop="invoiceNum"
                v-if="formData.status === 1"
                key="invoiceNum"
            >
                <el-input v-model="formData.invoiceNum"></el-input>
            </el-form-item>
            <el-form-item
                label="联系电话:"
                prop="phone"
                key="phone"
                v-if="formData.status === 0"
            >
                <el-input v-model="formData.phone"></el-input>
            </el-form-item>
            <el-form-item
                label="联系电话:"
                prop="companyPhone"
                key="companyPhone"
                v-if="formData.status === 1"
            >
                <el-input v-model="formData.companyPhone"></el-input>
            </el-form-item>
            <el-form-item class="btnBox">
                <el-button
                    type="primary"
                    @click="submitForm('formData')"
                    sizi="mini"
                    >提交</el-button
                >
                <el-button @click="handleClose" sizi="mini">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import { isvalidPhone } from "@/utils/validate";
export default {
    name: "Success",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        invoiceInfo: {
            type: Object,
            default() {},
        },
        remoteClose: Function,
    },
    data() {
        var validPhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号码"));
            } else if (!isvalidPhone(value)) {
                callback(new Error("请填写正确的手机号码"));
            } else {
                callback();
            }
        };
        return {
            rules: {
                userName: [
                    //prop的值
                    { required: true, message: "请输入姓名", trigget: "blur" },
                ],
                status: [
                    {
                        required: true,
                        type: "number",
                        message: "请选择发票抬头",
                        trigget: "blur",
                    },
                ],
                companyName: [
                    {
                        required: true,
                        type: "string",
                        message: "请输入单位名称",
                        trigget: "blur",
                    },
                ],
                invoiceNum: [
                    {
                        required: true,
                        type: "string",
                        message: "请输入纳税人识别号",
                        trigget: "blur",
                    },
                ],
                phone: [
                    { required: true, validator: validPhone, trigger: "blur" },
                ],
                companyPhone: [
                    { required: true, validator: validPhone, trigger: "blur" },
                ],
            },
            formData: {
                status: 1,
                userName: "",
                companyName: "",
                phone: "",
                companyPhone: "",
                invoiceNum: "",
            },
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit("incoiceDetails", this.formData);
                    this.handleClose();
                    this.$nextTick(() => {
                        this.$refs["formData"].resetFields();
                    });
                } else {
                    return false;
                }
            });
        },
        handleClose() {
            this.remoteClose();
        },
    },
};
</script>
<style>
.el-dialog__body {
    padding: 20px;
}
.btnBox .el-form-item__content {
    margin-left: 0 !important;
    text-align: center;
}
.invoiceBox .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #1b4094;
}
.invoiceBox .el-checkbox__input.is-focus .el-checkbox__inner,
.invoiceBox .el-checkbox__inner:hover {
    border-color: #1b4094;
}
.invoiceBox .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #1b4094;
    background: #1b4094;
}
.invoiceBox .el-radio__inner:hover {
    border-color: #1b4094;
}
.invoiceBox .el-radio__input.is-checked + .el-radio__label {
    color: #1b4094;
}
.invoiceBox .el-radio__input.is-checked .el-radio__inner {
    border-color: #1b4094;
    background: #1b4094;
}
.invoiceBox .el-input__inner:hover,
.invoiceBox .el-input__inner:focus {
    border-color: #1b4094;
}
.el-dialog__header span {
    font-size: 24px;
    color: #fff;
}
.el-dialog__header {
    background: #1b4094;
}
</style>
<style  scoped>
.mine_btn {
    display: block;
    margin: 52px auto 42px;
    width: 238px;
    background: #b21f27;
    border-color: #b21f27;
}
.ygan span {
    color: #ff8e09;
    margin: 0 16px;
}
.ygan p {
    color: #ff8e09;
    cursor: pointer;
}
.text_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 100%;
    font-size: 16px;
    color: #818181;
}
.content_box p {
    margin: 6px 0;
    font-size: 16px;
    color: #818181;
}
.content_box h4 {
    font-size: 26px;
    line-height: 42px;
    color: #000;
}
.content_box img {
    margin-right: 14px;
}
.content_box {
    margin-bottom: 24px;
    height: 66px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
</style>>
