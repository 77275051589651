<template>
    <div class="main-container overflowh" style="background: #f3f5f8">
        <div class="w12">
            <div class="breadcrumb">
                <p class="bar2"></p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/college' }"
                        >蒙科立学院</el-breadcrumb-item
                    >
                    <el-breadcrumb-item :to="{ path: breadcrumb == '精品课程' ? '/college-course':'/college' }"
                        >{{breadcrumb}}
                    </el-breadcrumb-item>

                    <el-breadcrumb-item>{{ courseName }} </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="videoNewsBox">
                <div class="videoBox">
                    <div class="videoPlayBox h100">
                        <vue-core-video-player
                            :src="videoUrl"
                        ></vue-core-video-player>
                    </div>
                    <div class="videoListBox h100" id="videoListBox">
                        <div
                            class="video-item pointer"
                            v-for="(item, index) in videoList"
                            :key="index"
                            :id="index"
                            :class="{ active: choseIndex == index }"
                            @click="playVideo(item, index)"
                        >
                            <div class="video-name-box">
                                <i class="el-icon-video-play"></i>
                                <p class="ellipsis">
                                    {{ item.coursewareVideo.name }}
                                </p>
                            </div>
                            <p class="video-time">
                                {{
                                    Math.round(
                                        (item.coursewareVideo.totalDuration /
                                            60) *
                                            100
                                    ) / 100
                                }}分钟
                            </p>
                        </div>
                    </div>
                </div>
                <div class="videoContent" id="videoContent">
                    <div class="video-content-name">{{ videName }}</div>
                    <div class="video-content-text">
                        <p>{{ videoList.length - 1 }}个相关视频</p>
                        <p>{{ studyCount }}人学习过</p>
                        <el-button size="mini" round>{{
                            courseName
                        }}</el-button>
                        <el-button size="mini" round>{{
                            type == "0" ? "常见问题" : "产品培训"
                        }}</el-button>
                    </div>
                </div>
            </div>
        </div>
        <College @reloadCourse="reloadCourse" />
    </div>
</template>
<script>
import { getVideoDetails } from "api/college";
import College from "views/routerViews/College/Course/Details/College";
export default {
    inject: ["reload"],
    components: { College },
    data() {
        return {
            id: "",
            courseId: "",
            videoUrl: require("@/utils/1.mp4"),
            courseName: "",
            videoList: [],
            studyCount: 0,
            videName: "",
            type: "",
            choseIndex: 0,
            hasNews: false,
            breadcrumb:''
        };
    },
    beforeRouteEnter(to, from, next) {
        let breadcrumb = ''
        if (from.fullPath == "/college") {
            breadcrumb = "好课推荐"
        } else {
            breadcrumb = "精品课程"
        }
        next( vm => {
            vm.breadcrumb = breadcrumb
        })
    },
    created() {
        this.id = this.$route.query.id;
        this.courseId = this.$route.query.courseId;
        this.fetchData();
    },
    methods: {
        reloadCourse(data) {
            this.courseId = data.courseId;
            this.id = data.id;
            this.fetchData();
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        playVideo(item, index) {
            this.choseIndex = index;
            this.videName = item.coursewareVideo.name;
            this.videoUrl = item.coursewareVideo.videoUrl;
        },
        fetchData() {
            getVideoDetails(this.courseId, this.id).then((res) => {
                if (res.errorCode == "2000") {
                    const details = res.body.data;
                    this.courseName = details.name;
                    this.videoList = details.videoList;
                    this.studyCount = details.studyCount;
                    this.type = details.type;
                    details.videoList.forEach((item, index) => {
                        if (item.beStudying) {
                            this.choseIndex = index;
                            this.videName = item.coursewareVideo.name;
                            this.videoUrl = item.coursewareVideo.videoUrl;
                            this.hasNews = true;
                        }
                    });
                    this.$nextTick(() => {
                        document
                            .getElementById("videoListBox")
                            .scrollTo(0, this.choseIndex * 86);
                    });
                }
            });
        },
    },
};
</script>
<style>
.problem-breadcrumb .el-breadcrumb__inner {
    color: #515765 !important;
}
</style>
<style lang="less" scoped>
.video-content-text {
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
        font-size: 14px;
        color: #6d6e72;
        margin-right: 40px;
    }
    .el-button {
        padding: 4px 15px;
        color: #6d6e72;
    }
}
.video-content-name {
    font-size: 18px;
    color: #252b3a;
    font-weight: bold;
    line-height: 54px;
}
.videoContent {
    padding: 0 20px;
    flex: 1;
    background: #fff;
}
.video-time {
    margin-top: 4px;
    padding: 0 28px;
    font-size: 12px;
    color: #868686;
}
.video-name-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    font-size: 16px;
    color: #a7acb6;
    i {
        font-size: 18px;
        color: #d1d1d1;
        margin-right: 12px;
    }
    p {
        width: 306px;
    }
}
.video-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px 12px;
    height: 86px;
    background: #2e3545;
    margin-bottom: 1px;
}
.video-item.active {
    background: linear-gradient(to right, #2f4066, #2f4066);
    /* Firefox 3.6+ */
    background: -moz-linear-gradient(left, #2f4066, #2f4066);
    /* Safari 5.1+, Chrome 10+ */
    background: -webkit-linear-gradient(left, #2f4066, #2f4066);
    /* Opera 11.10+ */
    background: -o-linear-gradient(left, #2f4066, #2f4066);
    .video-name-box {
        i {
            color: #408cff;
        }
        // p{
        //     color: #fff;
        // }
    }
}
.videoListBox {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    background: #252b3a;
}
.videoListBox::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}
.videoListBox::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
}
.videoListBox::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
}
.videoPlayBox {
    width: 800px;
    background: red;
}
.videoBox {
    display: flex;
    flex-direction: row;
    height: 412px;
}
.videoNewsBox {
    display: flex;
    flex-direction: column;
    height: 512px;
    box-shadow: 0 2px 12px 0 #bbbfc7;
}
.breadcrumb {
    padding-top: 48px;
    height: 96px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .bar2 {
        height: 16px;
        width: 2px;
        background: #6f7583;
        margin-right: 5px;
    }
}
</style>
