import { render, staticRenderFns } from "./AdvantageBox.vue?vue&type=template&id=1239eac5&scoped=true&"
var script = {}
import style0 from "./AdvantageBox.vue?vue&type=style&index=0&id=1239eac5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1239eac5",
  null
  
)

export default component.exports