<template>
    <div class="container">
        <div class="newsListBox">
            <div class="news-item pointer" v-for="item in articleList" :key="item.id" @click="enterDetails(item)">
                <el-card class="box-card" :body-style="{padding:0,height:'146px'}" shadow="hover">
                    <div class="news-item-content">
                        <img :src="item.imageUrl" alt="">
                        <div class="news-item-text">
                            <div class="title">{{ item.title }}</div>
                            <div class="date">
                                <i class="el-icon-time"></i>
                                <span>
                                    {{item.createDate}}
                                </span>
                            </div>
                            <div class="content" v-html="unEscapeHTML(item.summary)"></div>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <el-row type="flex" justify="center">
            <el-pagination
                 @current-change="handleCurrentChange"
                :current-page="page.current"
                :page-size="page.size"
                :total="page.total"
                layout="prev, pager, next"
            >
            </el-pagination>
        </el-row>
    </div>
</template>
<script>
import { getArticleList } from "api/article";
export default {
    data() {
        return {
            page:{
                total:0,
                pageNo:1,
                pageSize:10
            },
            articleList:[],
            hasArticle:false
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData(){
            getArticleList(
                this.page.pageNo,
                this.page.pageSize
            ).then((res) => {
                if (res.success && res.errorCode === '2000') {
                    this.articleList = res.body.data.list
                    this.page.total = res.body.data.count
                    this.hasArticle = true
                }
            });
        },
        handleCurrentChange(current) {
            this.page.pageNo = current
            this.fetchData()
        },
        enterDetails(item) {
            this.$router.push({
                path:'/home-article-details',
                query:{
                    articleId:item.id,
                }
            });
        },
        unEscapeHTML(html) {
            html= "" +html;
            return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'");
        },
    }
}
</script>
<style lang="less">
.news-item .el-card{
    height:146px;
    border:0;
    background: #f6f6f6;

}
.news-item .el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover{
    box-shadow: 0 3px 8px 3px rgb(200 200 200) !important;
}
.news-item .el-card:hover{
    background: #56627e;
    .title,.date,.content{
        color: #fff !important;
    }
    .date span,.date i{
        color: #fff !important;
    }
}
</style>
<style lang="less" scoped>
.news-item-content{
    padding: 20px 16px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    >img{
        width: 160px;
        height: 106px;
    }
    .news-item-text{
        width: 246px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        .title{
            font-size: 16px;
            line-height: 18px;
            color: #252b3a;
            font-weight: bold;
            max-height: 36px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .date{
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 18px;
            margin: 6px 0;
            i{
                margin-right: 4px;
            }
            i,span{
                font-size: 12px;
                color: #323846;
            }
        }
        .content{
            color: #6f7583;
            font-size: 14px;
            line-height: 16px;
            max-height: 32px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
}
.news-item{
    margin: 12px 0;
    width: 446px;
    height: 146px;
}
.newsListBox{
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
