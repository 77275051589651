<template>
    <div
        class="h100 informationBox overflowh"
        v-loading="loading"
        element-loading-text="个人信息保存中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <div class="submit" v-if="memberType === '0'">
            <p class="pointer" @click="handleEdit('form')" >保存</p>
        </div>
        <div style="height:30px;" v-if="memberType === '1'"></div>
        <div class="formBox">
            <div class="avatarNameBox">
                <div class="avatarBox pointer" @mouseleave="changeImg = false" @mouseover="changeImg = true">
                    <img
                        :src="
                            userInfo.headimgurl
                                ? userInfo.headimgurl
                                : headimgurl
                        "
                        alt="用户头像"
                        class="userImg"
                    />
                    <el-upload
                        class="avatar-uploader"
                        v-if="changeImg"
                        accept="image/png,image/jpg,image/jpeg"
                        action="#"
                        :http-request="changHeadImg"
                        :show-file-list="false"
                    >
                       <p class="changeImg">修改头像</p>
                    </el-upload>
                </div>
                <p class="userName">ID:{{ userInfo.memberName }}</p>
            </div>
            <div class="in-overy-box">
                <el-form
                    ref="form"
                    :model="form"
                    label-width="112px"
                    :rules="rules"
                    v-if="memberType == '0'"
                >
                    <el-form-item label="性别" prop="sex">
                        <el-radio-group v-model="form.sex">
                            <el-radio label="0">男</el-radio>
                            <el-radio label="1">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="birth" label="出生年月">
                        <el-date-picker
                            type="date"
                            placeholder="请选择出生年月"
                            v-model="form.birth"
                            style="width: 100%"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <!-- <el-row :gutter="10" type="flex" align="middle">
                        <el-col :span="20">
                            <el-form-item prop="phone" label="手机号">
                                <el-input
                                    placeholder="请输入手机号"
                                    v-model="form.phone"
                                    :disabled="disabled"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <p class="changeFont">修改</p>
                        </el-col>
                    </el-row> -->
                    <el-form-item prop="phone" label="手机号">
                        <el-input
                            placeholder="请输入手机号"
                            v-model="form.phone"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="微信" prop="nickname">
                        <el-input
                            v-model="form.nickname"
                            :disabled="disabled"
                            placeholder="输入您的微信"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input
                            v-model="form.email"
                            :disabled="disabled"
                            placeholder="输入您常用的邮箱"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="city" label="所在城市">
                        <el-cascader
                            size="large"
                            :options="options"
                            v-model="form.city"
                            placeholder="请选择省/市/区"
                        >
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="所属行业" prop="industry">
                        <el-select
                            v-model="form.industry"
                            placeholder="请选择所属行业"
                            @change="changeIndustry"
                        >
                            <el-option
                                v-for="(item, index) in industryList"
                                :key="index"
                                :label="item"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职业" prop="occupation">
                        <el-select
                            v-model="form.profession"
                            placeholder="请选择职业"
                            :disabled="proDisabled"
                        >
                            <el-option
                                v-for="(item, index) in showProfessionList"
                                :key="index"
                                :label="item"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-form
                    label-width="136px"
                    ref="enterpriseForm"
                    :model="enterpriseForm"
                    key="enterpriseForm"
                    v-if="memberType == '1'"
                >
                    <el-form-item label="企业名称" prop="organName">
                        <el-input
                            v-model="enterpriseForm.organName"
                            :disabled="disabled"
                            placeholder="请输入企业名称"
                        >
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item label="法人姓名" prop="corporName">
                        <el-input
                            v-model="enterpriseForm.corporName"
                            :disabled="disabled"
                            placeholder="请输入法人姓名"
                        >
                        </el-input>
                    </el-form-item> -->
                    <!-- <el-form-item label="统一社会信用代码" prop="creditCode">
                        <el-input
                            v-model="enterpriseForm.creditCode"
                            :disabled="disabled"
                            placeholder="请输入统一社会信用代码"
                        >
                        </el-input>
                    </el-form-item> -->
                    <!-- <el-form-item label="企业地址" prop="organAddr">
                        <el-input
                            v-model="enterpriseForm.organAddr"
                            :disabled="disabled"
                            placeholder="请输入企业地址"
                        >
                        </el-input>
                    </el-form-item> -->
                    <!-- <el-form-item label="营业执照" prop="licensePathBase64">
                        <img
                            v-if="enterpriseForm.licensePath"
                            :src="enterpriseForm.licensePath"
                            class="yyzzImg"
                        />
                    </el-form-item> -->
                    <el-form-item label="手机号" prop="phone">
                        <el-input
                            v-model="enterpriseForm.phone"
                            :disabled="disabled"
                            placeholder="手机号"
                        >
                            <template slot="prepend">+86 V</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input
                            v-model="enterpriseForm.email"
                            :disabled="disabled"
                            placeholder="邮箱"
                        >
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>

        </div>
    </div>
</template>
<script>
import VueEvent from '@/VueEvent.js'
import { PcCookie, Key } from "@/utils/cookie";
import { regionData } from "element-china-area-data";
import { getInformation, editInformation, editHeadImg } from "api/personal";
export default {
    computed: {
        userInfo() {
            return PcCookie.get(Key.userInfoKey)
                ? JSON.parse(PcCookie.get(Key.userInfoKey))
                : null;
        },
        showProfessionList() {
            let showList = [];
            if (this.professionList.length != 0) {
                showList = this.professionList.split(",");
            } else {
                showList = [];
            }
            return showList;
        },
    },
    data() {
        const validateCity = (rule, value, callback) => {
            if (this.form.city.length == 1) {
                callback(new Error("请选择省/市/区"));
            } else {
                callback();
            }
        };
        return {
            memberType:'1',
            rules: {
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "请输入常用的邮箱",
                        trigger: "blur",
                    },
                ],
                city: [
                    {
                        type: "array",
                        required: true,
                        validator: validateCity,
                    },
                ],
                profession: [
                    {
                        required: true,
                        message: "请选择职业",
                        trigger: "change",
                    },
                ],
            },
            loading: false,
            actionUrl: "/api/v1/memberInfo/edit/headImg",
            options: regionData,
            form: {
                sex: "",
                birth: "",
                phone: "",
                nickname: "",
                email: "",
                city: [],
                industry: "",
                profession: "",
            },
            enterpriseForm:{
                memberType:'1',
                corporName:'',
                organName:'',
                creditCode:'',
                organAddr:'',
                licensePath:'',
                memberName: "",
                password: "",
                comfirePass: "",
                phone: "",
                email: "",
            },
            industryList: [],
            professionList: [],
            professionAllList: [],
            disabled: true,
            proDisabled: true,
            changeImg: false,
            headimgurl: require("@/assets/images/userHeadImg.png"),
            isFirst: true,
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            getInformation().then( res => {
                const { body, success } = res;
                if (success) {
                    this.memberType = body.data.memberInfo.memberType
                    if(body.data.memberInfo.memberType == '0') {
                        if (body.data.memberInfo.sex) {
                            this.form.sex = body.data.memberInfo.sex;
                        }
                        if (body.data.memberInfo.birth) {
                            this.form.birth = body.data.memberInfo.birth;
                        }
                        this.form.phone = body.data.memberInfo.phone;
                        this.form.nickname = body.data.memberInfo.nickname;
                        this.form.email = body.data.memberInfo.email;
                        this.form.city = body.data.memberInfo.cityList;
                        if (body.data.memberInfo.industry) {
                            this.form.industry = body.data.memberInfo.industry;
                        }
                        if (body.data.memberInfo.profession) {
                            this.form.profession = body.data.memberInfo.profession;
                        }
                    }else if(body.data.memberInfo.memberType == '1') {
                        this.enterpriseForm.corporName = body.data.memberInfo.corporName
                        this.enterpriseForm.organName = body.data.memberInfo.organName
                        this.enterpriseForm.creditCode = body.data.memberInfo.creditCode
                        this.enterpriseForm.organAddr = body.data.memberInfo.organAddr
                        this.enterpriseForm.licensePath = body.data.memberInfo.licensePath
                        this.enterpriseForm.memberName = body.data.memberInfo.memberName
                        this.enterpriseForm.phone = body.data.memberInfo.phone
                        this.enterpriseForm.email = body.data.memberInfo.email
                    }

                    this.getIndustryList(body.data.works);
                    this.getProfessionList(body.data.works);
                    this.changeIndustry();
                    this.isFirst = false;
                }
            });
        },
        changHeadImg(data) {
            let that = this
            let rd = new FileReader()
            let file = data.file
            rd.readAsDataURL(file)
            rd.onloadend = function () {
                editHeadImg(this.result).then(res => {
                    const { success, msg, body } = res
                    if(success){
                        let userInfo = {
                            "memberName": that.userInfo.memberName,
                            "headimgurl": body.data,
                            "userId": that.userInfo.userId
                        }
                        PcCookie.set(Key.userInfoKey, JSON.stringify(userInfo))
                        that.$message({
                            type: 'success',
                            message: msg
                        })
                        VueEvent.$emit('changeHeadImg')
                    }else{
                        that.$message({
                            type: 'warning',
                            message: msg
                        })
                    }
                })
            }
        },
        handleEdit(formName) {
            this.loading = true;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    editInformation(this.form).then(res => {
                        const { success, msg } = res
                        if(success){
                            this.$message({
                                type: 'success',
                                message: msg
                            })
                            this.loading = false;
                        }else{
                            this.$message({
                                type: 'warning',
                                message: msg
                            })
                        }
                    });
                } else {
                    this.$message({
                        message: "请填写信息！",
                        type: "warning",
                    });
                    this.loading = false;
                    return false;
                }
            });
        },
        changeIndustry() {
            if (this.form.industry != "") {
                const industry = this.form.industry;
                this.industryList.forEach((item, index) => {
                    if (item === industry) {
                        this.professionList = this.professionAllList[index];
                    }
                });
                this.proDisabled = false;
            }
            if (!this.isFirst) {
                this.form.profession = "";
            }
        },
        getIndustryList(works) {
            works.forEach((item) => {
                this.industryList.push(item.industry);
            });
        },
        getProfessionList(works) {
            works.forEach((item) => {
                this.professionAllList.push(item.profession);
            });
        },
    },
};
</script>
<style>
.formBox .el-select,
.formBox .el-cascader {
    width: 100%;
}
.formBox .el-col-4 p {
    text-align: right;
}
</style>
<style lang="less" scoped>
.in-overy-box{
    height: calc(100% - 112px);
    width: 420px;
    margin: 0 auto;
}
.userImg {
    width: 66px;
    height: 66px;
    border-radius: 50%;
}
.changeFont {
    font-size: 14px;
    color: #ff8610;
    height: 40px;
}
.yyzzImg{
    width: 284px;
    height: 212px;
}
.avatarBox {
    margin: 0 auto;
    height: 66px;
    width: 66px;
    border: solid 1px #ccc;
    border-radius: 50%;
    position: relative;
    .changeImg {
        height: 66px;
        width: 66px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
        line-height: 66px;
        text-align: center;
        background: rgba(0, 0, 0, 0.6);
    }
}
.userName {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    color: #252b3a;
}
.avatarNameBox {
    height: 112px;
}
.formBox {
    height: calc(100% - 70px);
    margin: 0 auto;
    overflow-y: auto;
}
.submit {
    height: 36px;
    margin: 24px 48px 0 0;
    p {
        float: right;
        width: 100px;
        height: 100%;
        line-height: 36px;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        background: #ff8610;
    }
}
.informationBox {
    background: #fff;
}
</style>
