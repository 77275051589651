<template>
    <div class="problemBox">
        <img
            src="~assets/images/college/proposalimg.png"
            alt=""
            class="proposalimg pointer"
            @click="enterProposal"
        />
        <div class="problem-breadcrumb">
            <div class="w12 breadcrumb">
                <p class="bar2"></p>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/college' }"
                        >蒙科立学院</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>常见问题</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="problem-content">
            <div class="w12">
                <div class="problem-list-module">
                    <div class="module-title">
                        <p>{{productName}}常见问题</p>
                        <span
                            >查找常见问题，帮您快速定位故障，提供有效解决方案</span
                        >
                    </div>
                    <div class="dataNone" v-if="page.total == 0">
                        <img src="~assets/images/personal/nonImg.png" alt="" />
                    </div>
                    <div class="problem-list" v-if="page.total > 0">
                        <div class="problem-li pointer" v-for="item in questionList" :key="item.id">
                            <div class="problem-li-title ellipsis" @click="enterDetails(item)">
                                {{item.question}}
                            </div>
                            <div class="problem-li-content" @click="enterDetails(item)">
                                {{item.answer}}
                            </div>
                        </div>
                    </div>
                    <!-- 分页 -->
                    <el-row type="flex" justify="center" style="margin: 30px 0" v-if="page.total > 0">
                        <!-- current-change 事件是当前页码变动时候触发的事件 -->
                        <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="page.current"
                            :page-size="page.size"
                            :total="page.total"
                            background
                            layout="prev, pager, next"
                            prev-text=" 上一页 "
                            next-text=" 下一页 "
                        >
                        </el-pagination>
                    </el-row>
                </div>
                <div class="problem-sort-module">
                    <div class="module-title">
                        <h4 class="menkTitle">   <br/></h4>
                        <p>问题分类</p>
                    </div>
                    <div class="problem-sort-list">
                        <div
                            class="problem-sort-li pointer"
                            v-for="item in productNavList"
                            :key="item.id"
                            @click="changeQuestionList(item)"
                        >
                            <img
                                :src="item.field3"
                                alt=""
                            />
                            <p>{{item.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import { getProductsNav, getQuestions } from "api/product";
export default {
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙古文WPS2002，蒙古文字体，蒙科立学院，蒙科立整词输入法，蒙古文WPS2002，语音识别",
            },
        ],
    },
    data() {
        return {
            page: {
                size: 5,
                total: 0,
                current: 1,
            },
            productNavList: [],
            productName: '',
            productId: '',
            questionList:[],
            token: PcCookie.get(Key.tokenKey),
        };
    },
    created() {
        this.productName = this. $route.query.productName
        this.productId = this. $route.query.productId
        this.fetchProductNav();
        this.getQuestionsData(this.productId,this.page.current,this.page.size)
    },
    methods: {
        enterProposal() {
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再使用！",
                    type: "warning",
                });
            } else {
                this.$router.push("/college-feedback")
            }
        },
        enterDetails(item) {
            this.$router.push({
                path:'/college-problem-details',
                query:{
                    questionId:item.id
                }
            })
        },
        changeQuestionList(item) {
            this.productName = item.name
            this.page.current = 1;
            this.getQuestionsData(item.id,this.page.current,this.page.size)
        },
        handleCurrentChange(current) {
            this.page.current = current;
            this.getQuestionsData(this.productId,this.page.current,this.page.size)
        },
        fetchProductNav() {
            getProductsNav().then((res) => {
                if (res.success) {
                    this.productNavList = res.body.data;
                }
            });
        },
        getQuestionsData(productId,current,size) {
            getQuestions(productId,current,size).then(res => {
                if(res.success){
                    this.page.total = res.body.data.count
                    this.questionList = res.body.data.list
                }
            })
        },
    },
};
</script>
<style>
.problem-breadcrumb .el-breadcrumb__inner {
    color: #515765 !important;
}
</style>
<style lang="less" scoped>
.dataNone{
    border: solid 1px #ccc;
    text-align: center;
    position: relative;
    img{
        margin-top: 60px;
    }
}
.proposalimg {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 999;
    margin-top: -98px;
}
.problem-sort-list {
    display: flex;
    flex-wrap: wrap;
        margin-top: 20px;
    .problem-sort-li {
        width: 33.333%;
        height: 126px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            margin-top: 20px;
            font-size: 14px;
            color: #000;
            padding: 0 12px;
            text-align: center;
        }
        img {
            height: 46px;
        }
    }
}
.problem-li {
    height: 96px;
    .problem-li-title {
        height: 36px;
        line-height: 36px;
        font-size: 18px;
        font-weight: bold;
        color: #000;
    }
    .problem-li-content {
        font-size: 14px;
        color: #5c5c5c;
        line-height: 22px;
        height: 44px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}
.module-title {
    height: 84px;
    display: flex;
    flex-direction: row;
    align-items: center;
    h4{
        color: #252b3a;
        margin-right: 12px;
    }
    p {
        font-size: 30px;
        color: #252b3a;
    }
    span {
        margin-top: 10px;
        margin-left: 8px;
        font-size: 14px;
        color: #9f9f9f;
    }
}
.problem-content {
    padding: 20px 0 80px;
    background: #fff;
    .w12 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .problem-list-module {
            width: 748px;
        }
        .problem-sort-module {
            width: 388px;
        }
    }
}
.problem-breadcrumb {
    padding-top: 48px;
    background: #f3f5f8;
    .bar2 {
        height: 16px;
        width: 2px;
        background: #6f7583;
        margin-right: 5px;
    }
    .breadcrumb {
        height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
</style>
