<template>
    <div class=" main-container" style="background:#efefef;overflow:hidden">
        <div class="w12 fontBox">
           <img src="~assets/images/product/photoshopImg.jpg" alt="">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeIndex:0,
        }
    }
}
</script>
<style lang="less" scoped>
.fontBox{
    margin-top: 98px;
    overflow: hidden;
    text-align: center;

}
</style>
