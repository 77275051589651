<template>
    <div class="companyIntroduction">
        <img src="~assets/images/buildingImg.png" alt="" class="buildingImg">
        <div class="companyBox">
        <p>关于蒙科立</p>
        <span>
            "蒙科立"是一家专门从事少数
            民族文字信息化、数字化、网
            络化技术开发与服务的少数民
            族专业化高技术服务实体。企
            业经过20年艰苦奋斗，开发出
            8个少数民族12种文字......
        </span>
        <i class="pointer" @click="enterAbout">了解更多详情 >></i>
        </div>
    </div>
</template>
<script>
export default {
    methods:{
        enterAbout() {
            this.$router.push('/about')
        }
    }
}
</script>
<style lang="less" scoped>
.companyIntroduction{
    width: 320px;
    height: 100%;
    border-right: solid 1px #e6e6e6;
    position: relative;
    overflow: hidden;
    .buildingImg{
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .companyBox{
      padding: 0 50px;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: -36px;
      z-index: 99;
      background: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      p{
        font-size: 16px;
        margin-bottom: 12px;
        color: #252b3a;
        font-weight: bold;
        line-height: 32px;
        text-align: left;
      }
      span{
        margin-bottom: 8px;
        color: #6f7583;
        line-height: 24px;
        text-align: left;
      }
      i{
        color: #37539c;
        line-height: 32px;
        font-style: normal;
        text-align: left;
        margin: 0;
      }
    }
}
</style>
