<template>
    <div class="main-container overflowh" style="background: #f3f5f8">
        <div class="w12 payment-success">
            <div class="payment-news">
                <i class="el-icon-circle-check"></i>
                <div>
                    <h3>恭喜您，支付成功</h3>
                    <p>产品服务购买成功后即刻生效</p>
                </div>
            </div>
            <div class="backBox">
                <p>{{num}}</p>
                <span>秒钟后自动返回,个人中心——我的订单</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            num: 10,
        };
    },
    created() {
        var that = this;
        window.setInterval(() => {
            setTimeout(() => {
                that.num--;
            }, 0);
        }, 1000);
    },
    watch: {
        num(val) {
            if (val == 0) {
                this.$router.push("/personal/order");
            }
        },
    },
};
</script>
<style lang="less" scoped>
.backBox {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    p {
        color: #ff8610;
        margin-right: 6px;
    }
    span {
        color: #252b3a;
    }
}
.payment-news {
    display: flex;
    flex-direction: row;
    justify-content: center;
    i {
        font-size: 40px;
        color: #ff8610;
        margin-right: 14px;
    }
    div {
        h3 {
            font-size: 25px;
            color: #252b3a;
            margin-bottom: 13px;
        }
        p {
            font-size: 16px;
            color: #6f7583;
        }
    }
}
.payment-success {
    margin-top: 58px;
    height: 372px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>
