<template>
    <div class="orcBox h100">
        <div class="listBox"  v-if="page.total != 0">
            <div class="list-top-box">
                <p class="time">委托时间</p>
                <p class="type">识别状态</p>
                <p class="passText">认证文件</p>
                <p class="resultText">识别结果</p>
                <p class="resultText">状态</p>
            </div>
            <div class="li-box" v-for="item in list" :key="item.id">
                <p class="time">{{ item.createDate }}</p>
                <p class="type">
                    {{ item.discernStatus == "0" ? "受理中" : "已完成" }}
                </p>
                <p class="passText">
                    <em
                        @click="downLoaSecretTxt(item.secretKey)"
                        v-if="!item.discernFile"
                        >下载</em
                    >
                </p>
                <p class="resultText" v-if="item.discernFile">
                    <em @click="downLoaDisTxt(item.discernFile)"
                        >下载<br />（7天有效）</em
                    >
                </p>
                <p class="resultText" v-else>识别中</p>
                <p class="resultText">
                    <em>{{ strToDate(item.effectiveTime) }}</em>
                </p>
            </div>
            <el-row type="flex" justify="center" style="margin-top: 40px">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="page.current"
                    :page-size="page.size"
                    :total="page.total"
                    layout="prev, pager, next"
                >
                </el-pagination>
            </el-row>
        </div>
        <div class="noneNews" v-if="page.total == 0">
            <img src="~assets/images/personal/nonImg.png" alt="">
            <div class="promptText">
                <p>暂无委托记录</p>
            </div>
        </div>
    </div>
</template>
<script>
import { getOcrList } from "api/personal";
export default {
    computed: {
        fileName() {
            return function (file) {
                let name = "";
                let index = file.lastIndexOf("/");
                name = file.substring(index + 1, file.length);
                return name;
            };
        },
        strToDate() {
            return function (dateString) {
                if (dateString) {
                    var isType = ''
                    let d1=new Date();
                    let d2=dateString.replace(/-/g,"/");
                    d2 = new Date(d2)
                    if(d1.valueOf()>d2.valueOf()){
                        isType = '已过期'
                    }else{
                        isType = '生效中'
                    }
                    return isType;
                }
            };
        },
    },
    data() {
        return {
            list: [],
            page: {
                current: 1,
                size: 12,
                total: null,
            },
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        downLoaSecretTxt(path) {
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.style.height = 0;
            iframe.src = path;
            document.body.appendChild(iframe);
            setTimeout(() => {
                iframe.remove();
            }, 5 * 60 * 1000);
        },
        downLoaDisTxt(path) {
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.style.height = 0;
            iframe.src = path;
            document.body.appendChild(iframe);
            setTimeout(() => {
                iframe.remove();
            }, 5 * 60 * 1000);
        },
        handleCurrentChange(current) {
            this.page.current = current;
            this.fetchData();
        },
        fetchData() {
            getOcrList(this.page.current, this.page.size).then((res) => {
                if (res.success) {
                    this.list = res.body.data.list;
                    this.page.total = res.body.data.count;
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.li-box {
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border-bottom: dashed 1px #ccc;
    color: #909399;
    .time {
        width: 167px;
    }
    .type {
        width: 167px;
    }
    .passText {
        width: 167px;
    }
    .resultText {
        width: 167px;
    }
    > p {
        span {
            color: #1b4094;
            margin-left: 10px;
            padding-left: 10px;
            border-left: 1px solid rgb(139, 135, 135);
            cursor: pointer;
        }
        em {
            color: #1b4094;
            cursor: pointer;
            font-style: normal;
        }
    }
}
.list-top-box {
    margin-bottom: 20px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: bolder;
    color: #909399;
    text-align: center;
    border-bottom: solid 2px #ccc;
    .time {
        width: 167px;
    }
    .type {
        width: 167px;
    }
    .passText {
        width: 167px;
    }
    .resultText {
        width: 167px;
    }
}
.listBox {
    display: flex;
    flex-direction: column;
}
.filenewsBox {
    display: flex;
    flex-direction: row;
    span {
        margin-left: 12px;
        white-space: nowrap;
        cursor: pointer;
        color: #1b4094;
    }
}
.orcBox {
    padding: 0 42px;
    background: #fff;
}
</style>
