<template>
    <div class="main-container">
        <Banner/>
        <Content/>
    </div>
</template>
<script>
import Banner from 'views/routerViews/Home/News/List/Banner'
import Content from 'views/routerViews/Home/News/List/Content'
export default {
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙科立，蒙科立综合服务，蒙科立输入法，蒙古文AI人工智能",
            },
        ],
    },
    components: {Banner, Content}
}
</script>
